import { memo, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Modal, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const BillingConfirmDemoModal = memo(({ visible, onCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToSettingsSetup = useCallback(() => {
    onCancel();
    navigate("/settings/setup");
  }, [navigate, onCancel]);

  return (
    <Modal open={visible} onCancel={onCancel} footer={null}>
      <div className="flex flex-col items-center justify-center m-5 space-y-5">
        <ExclamationCircleOutlined className="text-orange1 text-[25px]" />
        <Text className="text-base text-center">
          <Trans
            i18nKey="billing.you_are_currently_using_dummy_data_please_clear_your_data_first_before_upgrading"
            values={{
              click_here: `<br/><Link>${t("button.click_here")}</Link>`,
            }}
            components={{
              Link: (
                <span
                  className="text-link-primary font-bold hover:underline hover:opacity-70 hover:cursor-pointer"
                  onClick={goToSettingsSetup}
                />
              ),
            }}
          />
        </Text>
      </div>
    </Modal>
  );
});

export default BillingConfirmDemoModal;
