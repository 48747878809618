import { createSlice } from "@reduxjs/toolkit";
import { updateObject } from "utils/helper";

const initialState = {
  show: false,
  error: null,
};

const slice = createSlice({
  name: "loginModal",
  initialState,
  reducers: {
    showLoginModal: (state) =>
      updateObject(state, {
        ...state,
        show: true,
      }),
    hideLoginModal: (state) =>
      updateObject(state, {
        ...state,
        show: false,
      }),
    setError: (state, { payload }) =>
      updateObject(state, {
        ...state,
        error: payload.error,
      }),
    resetLoginState: (state) =>
      updateObject(state, {
        ...initialState,
      }),
  },
});

export const { showLoginModal, hideLoginModal, setError, resetLoginState } =
  slice.actions;

export default slice;
