import * as React from "react";
// import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
// import generatePicker from "antd/es/date-picker/generatePicker";
import "antd/es/date-picker/style/index";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { localeSelector } from "redux/selectors";
import id from "antd/es/date-picker/locale/id_ID";
import en from "antd/es/date-picker/locale/en_US";
import { DatePicker } from "antd";

// const DatePicker = generatePicker(dayjsGenerateConfig);

const RangeTimePicker = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const localeConfig = useSelector(localeSelector);
  const locale = localeConfig === "en-US" ? en : id;
  return (
    <DatePicker.RangePicker
      {...props}
      picker="time"
      mode={undefined}
      ref={ref}
      locale={{ ...locale, lang: { ...locale.lang, ok: t("button.save") } }}
    />
  );
});

RangeTimePicker.displayName = "RangeTimePicker";

export default RangeTimePicker;
