import axios from "utils/axios";
import { stringify } from "query-string";

export const getClients = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/clients/pagination?${query}`);
};

export const getClient = (data) => {
  return axios.get(`/hr/clients/${data?.id}`);
};

export const addClient = (data) => {
  return axios.post(`/hr/clients`, data);
};

export const editClient = (data) => {
  return axios.put(`/hr/clients/${data?.id}`, data);
};

export const deleteClient = (id) => {
  return axios.delete(`/hr/clients/${id}`);
};

export const archiveClient = (id) => {
  return axios.patch(`/hr/clients/${id}/archive`);
};

export const unarchiveClient = (id) => {
  return axios.patch(`/hr/clients/${id}/unarchive`);
};

export const uploadImportClient = (data) => {
  return axios.post("/hr/clients/uploadImport", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const executeImportClient = (data) => {
  return axios.post("/hr/clients/executeImport", data);
};
