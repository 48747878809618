import { Result, Modal, Spin } from "antd";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";

const BlockLoader = ({
  visible,
  title,
  message,
  status = "info",
  ...props
}) => {
  return (
    <Modal
      title={null}
      open={visible}
      destroyOnClose
      width={350}
      closable={false}
      footer={null}
      {...props}
    >
      <Result
        icon={<InfoCircleOutlined className="text-blue-500" />}
        status={status}
        title={<span className="text-[18px] font-bold">{title}</span>}
        subTitle={
          <div className="flex flex-col items-center justify-center space-y-5">
            <h5 className="text-gray-500">{message}</h5>
            <Spin indicator={<LoadingOutlined />} size="large" />
          </div>
        }
        extra={null}
      />
    </Modal>
  );
};

export default BlockLoader;
