import * as Sentry from "@sentry/react";

const setup = () => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
      normalizeDepth: 5,
      autoSessionTracking: false,
      environment: "production",
      debug: false,
      ignoreErrors: [
        "ResizeObserver loop limit exceeded",
        "Cannot redefine property: googletag",
        "ResizeObserver loop completed with undelivered notifications",
        /Loading chunk [\d]+ failed/,
        "Unexpected token _ in JSON at position 0",
      ],
    });
  }
};
export default setup;
