import { useSettingSelector } from "./useSelector";

const usePerPage = (key) => {
  const setting = useSettingSelector();
  if (key && typeof key === "string" && setting?.[key]) {
    return setting?.[key];
  }
  return 15;
};

export default usePerPage;
