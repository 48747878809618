import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {locale as localeStatic} from 'utils/static';
import en from './en-US';
import id from './id-ID';

// Translations
const resources = {
  [localeStatic.EN_US]: {translation: en.messages},
  [localeStatic.ID_ID]: {translation: id.messages},
};

i18n.use(initReactI18next).init({
  resources,
  lng: localeStatic.ID_ID,
  fallbackLng: localeStatic.ID_ID,
  keySeparator: false,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  // compatibilityJSON: "v3",
});

export default i18n;
