import { Empty, Select, Spin } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const { Option } = Select;

const SelectMultiple = ({
  loading,
  loadingMore,
  placeholder,
  search,
  options,
  showSelectAll,
  withPagination,
  onSearch,
  onSelectAll,
  getMoreData,
  ...props
}) => {
  const { t } = useTranslation();

  const handleScroll = (event) => {
    const target = event?.target;
    if (
      !!withPagination &&
      !loadingMore &&
      Math.ceil(target?.scrollTop + target?.offsetHeight) ===
        target?.scrollHeight
    ) {
      if (typeof getMoreData === "function") {
        getMoreData();
      }
      target.scrollTo(0, target?.scrollHeight);
    }
  };

  return (
    <Select
      mode="multiple"
      showSearch
      filterOption={false}
      allowClear
      loading={loading}
      placeholder={placeholder}
      searchValue={search}
      onSearch={onSearch}
      onPopupScroll={handleScroll}
      onBlur={() => onSearch("")}
      notFoundContent={
        (loading || loadingMore) && !!withPagination ? <div /> : <Empty />
      }
      {...props}
      dropdownRender={(menu) => (
        <div onScroll={handleScroll}>
          {menu}
          {(loading || loadingMore) && !_.isEmpty(options) ? (
            <div className="flex w-full items-center justify-center">
              <Spin />
            </div>
          ) : null}
        </div>
      )}
    >
      {showSelectAll && options.length > 0 && !search && (
        <Option key="select-all" onMouseDown={onSelectAll}>
          {t("button.select_all")}
        </Option>
      )}
      {options.map((opt) => (
        <Option key={opt?.id} value={opt?.id}>
          {`${opt?.name || "-"}${opt?.code ? ` (${opt?.code})` : ""}`}
        </Option>
      ))}
    </Select>
  );
};

SelectMultiple.propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  placeholder: PropTypes.string,
  search: PropTypes.string,
  showSelectAll: PropTypes.bool,
  withPagination: PropTypes.bool,
  options: PropTypes.array,
  onSearch: PropTypes.func,
  onSelectAll: PropTypes.func,
};

SelectMultiple.defaultProps = {
  options: [],
  onSearch: () => {},
};

export default SelectMultiple;
