import { useCallback, useMemo, useRef, useState } from "react";
import { Popover, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { FaClock } from "react-icons/fa6";
import { css } from "@emotion/css";

import { useDesktopViewSelector } from "hooks/useSelector";
import AuditLog from "pages/auditLog";
import HideFeature from "components/UI/HideFeature";
import QueueStats from "components/apps/AuditLog/QueueStats";

const AuditActions = () => {
  const { t } = useTranslation();
  const scrollParentRef = useRef();
  const isDesktopView = useDesktopViewSelector();

  const [open, setOpen] = useState(false);

  const openChangeHandler = useCallback((visible) => setOpen(visible), []);
  const hideHandler = useCallback(() => setOpen(false), []);

  const content = useMemo(() => {
    return (
      <div className="sm:w-[100vw] lg:w-[400px]">
        <QueueStats enabled={open} />
        <HideFeature>
          <div className="h-[400px] overflow-auto pt-5" ref={scrollParentRef}>
            <AuditLog
              scrollProps={{ getScrollParent: () => scrollParentRef.current }}
              onHideParent={hideHandler}
            />
          </div>
        </HideFeature>
      </div>
    );
  }, [hideHandler, open]);

  return (
    <Popover
      id="popover-audit"
      placement={!isDesktopView ? "bottom" : "bottomRight"}
      trigger={["click"]}
      destroyTooltipOnHide={true}
      content={content}
      open={open}
      onOpenChange={openChangeHandler}
      overlayClassName={`pt-4 ${css`
        .ant-popover-arrow {
          display: none !important;
        }
        .ant-popover-inner {
          padding: 0px !important;
        }
      `}`}
    >
      <Tooltip
        open={open ? false : undefined}
        title={t("topBar.data_changes_timeline")}
        trigger={["hover"]}
      >
        <div className="flex flex-row items-center gap-1 cursor-pointer">
          <div className="flex items-center justify-center">
            <FaClock className="text-white" size={18} />
          </div>
          <MdOutlineArrowDropDown className="text-white" />
        </div>
      </Tooltip>
    </Popover>
  );
};

export default AuditActions;
