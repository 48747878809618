import { forwardRef } from "react";
// import DatePicker from "components/UI/DatePicker";
import { TimePicker as TimePickerAntd } from "antd";
import id from "antd/es/date-picker/locale/id_ID";
import en from "antd/es/date-picker/locale/en_US";
import { useSelector } from "react-redux";
import { localeSelector } from "redux/selectors";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/css";

const TimePicker = ({ innerRef, ...props }) => {
  const { t } = useTranslation();
  const localeConfig = useSelector(localeSelector);
  const locale = localeConfig === "en-US" ? en : id;
  return (
    <TimePickerAntd
      ref={innerRef}
      locale={{ ...locale, lang: { ...locale.lang, ok: t("button.save") } }}
      popupClassName={css`
        ul {
          column-gap: 10px !important;
        }
      `}
      {...props}
    />
  );
};

export default forwardRef((props, ref) => (
  <TimePicker innerRef={ref} {...props} />
));
