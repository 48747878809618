import { memo } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FaRegTrashAlt } from "react-icons/fa";

const Delete = ({ id, title, icon, type, onClick, className, ...props }) => {
  const { t } = useTranslation();
  const btnTitle = title ? title : t("button.delete");
  return (
    <Button
      className={`flex items-center justify-center hover:opacity-70 ${className}`}
      id={id}
      type={type}
      icon={icon}
      onClick={onClick}
      {...props}
    >
      {btnTitle}
    </Button>
  );
};

Delete.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
};

Delete.defaultProps = {
  type: "primary",
  icon: <FaRegTrashAlt />,
  onClick: () => {},
  id: "btn-delete",
  danger: true,
};

export default memo(Delete);
