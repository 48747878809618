import axios from "utils/axios";
import { stringify } from "query-string";

export const getEventCalendars = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/eventCalendars?${query}`);
};

export const getEventCalendar = (data) => {
  return axios.get(`/hr/eventCalendars/${data?.id}`);
};

export const addEventCalendar = (data) => {
  return axios.post("/hr/eventCalendars", data);
};

export const editEventCalendar = (data) => {
  return axios.put(`/hr/eventCalendars/${data?.id}`, data);
};

export const deleteEventCalendar = (id) => {
  return axios.delete(`/hr/eventCalendars/${id}`);
};
