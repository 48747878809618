import axios from "utils/axios";
import { stringify } from "query-string";

export const getClientVisits = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/clientVisits/pagination?${query}`);
};

export const editClientVisit = (data) => {
  return axios.patch(`/hr/clientVisits/${data?.id}`, data);
};
