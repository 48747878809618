import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { message } from "antd";
import { setSetting } from "redux/configSlice";
import { settingSelector } from "redux/selectors";
import * as api from "apis/setup";

// Query untuk reset data
export const useSetupClearData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const oldSettings = useSelector(settingSelector);
  return useMutation(() => api.setupClearData(), {
    onMutate: async () => {
      dispatch(setSetting({ app_demo: 0 }));
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      dispatch(setSetting(oldSettings));
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};
