import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { message } from "antd";
import * as api from "apis/organizationalStructure";

//Query untuk get all struktur organisasi
export function useOrgStructures(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "orgStructures",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getOrgStructures();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail struktur organisasi
export function useOrgStructure({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["orgStructure", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getOrgStructure(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get all struktur organisasi tree
export function useOrgStructuresTree(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["orgStructures/tree", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getOrgStructuresTree(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get parents struktur organisasi
export function useOrgStructureParents({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["orgStructure/parents", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getOrgStructureParents(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail struktur organisasi employee
export function useOrgStructureEmployee(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["orgStructure/employee", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getOrgStructureEmployee(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get level struktur organisasi
export function useOrgStructureLevels(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["orgStructure/levels"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getOrgStructureLevels();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) => {},
      ...options,
    }
  );
}

// Query untuk add struktur organisasi
export const useAddOrgStructure = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addOrgStructure(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit struktur organisasi
export const useEditOrgStructure = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editOrgStructure(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete struktur organisasi
export const useDeleteOrgStructure = () => {
  const { t } = useTranslation();
  return useMutation((id) =>
    api.deleteOrgStructure(id, {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => message.error(t("error.failed_save_data_to_server")),
    })
  );
};

//Query untuk archive organization structure
export const useArchiveOrgStructure = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.archiveOrgStructure(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

//Query untuk unarchive organization structure
export const useUnarchiveOrgStructure = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.unarchiveOrgStructure(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
