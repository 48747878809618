import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/salaryComponent";

//Query untuk get all salary components
export function useSalaryComponents({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["salaryComponents", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSalaryComponents(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get all salary components pagination
export function useSalaryComponentsPagination({
  payload = {},
  enabled = false,
}) {
  const { t } = useTranslation();
  return useQuery(
    ["salaryComponents/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSalaryComponentsPagination(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get detail salary components
export function useSalaryComponent({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["salaryComponent", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSalaryComponent(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk add salary component
export const useAddSalaryComponent = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addSalaryComponent(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit salary component
export const useEditSalaryComponent = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editSalaryComponent(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete salary component
export const useDeleteSalaryComponent = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteSalaryComponent(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk archive salary component
export const useArchiveSalaryComponent = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.archiveSalaryComponent(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk archive salary component
export const useUnarchiveSalaryComponent = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.unarchiveSalaryComponent(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
