import axios from "utils/axios";

const resource = 'options';

export const getOptions = () => {
  return axios.get(`/${resource}`);
};

export const updateOptions = (data) => {
  return axios.put(`/${resource}`, data);
};

export const getPropertyOptions = () => {
  return axios.get(`/${resource}/property`);
};

export const setLockDate = (data) => {
  return axios.put(`/${resource}/lock_date`, data);
};

export const isLockDateDeletable = () => {
  return axios.get('/options/lock_date/deletable');
};

export const deleteLockDate = () => {
  return axios.delete(`/${resource}/lock_date`);
};

export const onBoarding = (data) => {
  return axios.post(`/${resource}/onboarding`, data);
};

export const sendEmailConfirmation = () => {
  return axios.post(`/${resource}/confirmationEmail/resend`);
};

export const confirmsEmail = (data) => {
  return axios.post(`/${resource}/confirmationEmail`, data);
};

export const getOption = (name) => {
  return axios.get(`/${resource}/${name}`);
};

export const getOptionNotification = () => {
  return axios.get(`/${resource}/notifications`);
};

export const editOptionNotification = (name, data) => {
  return axios.put(`/${resource}/notifications/${name}`, data);
};
