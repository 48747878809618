import { Layout } from "antd";
// import { Link } from "react-router-dom";
// import Logo from "assets/images/logo.png";
import Footer from "./Footer";

const { Content } = Layout;

const PublicContainer = ({ children, inverse, withFooter, ...props }) => {
  const shouldRenderFooter = withFooter !== undefined ? withFooter : true;

  return (
    <Layout className="h-screen" {...props}>
      <Content className="flex items-center justify-center flex-col">
        {children}
      </Content>
      {shouldRenderFooter && <Footer inverse={inverse} />}
    </Layout>
  );
};

export default PublicContainer;
