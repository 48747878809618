import { useQuery, useMutation, useInfiniteQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/specialLeave";

//Query untuk get all special leave
export function useSettingSpecialLeaves(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["specialLeaves/setting", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSettingSpecialLeaves(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get special leave pagination
export function useSettingSpecialLeavesPagination(
  { payload = {} },
  options = {}
) {
  const { t } = useTranslation();
  return useQuery(
    ["specialLeaves/setting/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSettingSpecialLeavesPagination(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useSettingSpecialLeavesSuggestion = ({
  payload = {},
  enabled = false,
}) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["specialLeaves/suggestion", payload],
    async ({ pageParam = 0 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSettingSpecialLeavesPagination({
          page: pageParam,
          ...payload,
        });
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
};

//Query untuk get detail special leave
export function useSettingSpecialLeave({ payload = {} }) {
  const { t } = useTranslation();
  return useQuery(
    ["specialLeave/setting", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSettingSpecialLeave(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get detail special leave can be deleted
export function useSettingSpecialLeavesCanBeDeleted() {
  const { t } = useTranslation();
  return useQuery(
    ["specialLeaves/setting/canBeDeleted"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSettingSpecialLeaveCanBeDeleted();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

// Query untuk add special leave
export const useAddSettingSpecialLeave = () => {
  return useMutation((payload) => api.addSettingSpecialLeave(payload));
};

// Query untuk edit special leave
export const useEditSettingSpecialLeave = () => {
  return useMutation((payload) => api.editSettingSpecialLeave(payload));
};

// Query untuk delete special leave
export const useDeleteSettingSpecialLeave = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteSettingSpecialLeave(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
