import { forwardRef, useCallback, useMemo, useState } from "react";
import { Form, Select, Spin } from "antd";
import _ from "lodash";

import { useEmployeeSuggestion } from "queries";
import { useDebounceValue } from "hooks";
import { cleanBlankValue } from "utils/helper";

const { Option } = Select;

const EmployeeSuggestion = ({
  innerRef,
  id,
  name,
  placeholder,
  formItemProps,
  onSelect,
  defaultOptions = [],
  defaultEmployee,
  includeEmployee,
  excludeEmployee,
  sourceData,
  containerProps,
  perPage,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(
    (defaultOptions || [])?.find((e) => e?.id === defaultEmployee)
  );

  const debouncedSearch = useDebounceValue(search, 1000);

  const defaultPerPage = useMemo(() => {
    const loadData = excludeEmployee.length >= 100 ? 1000 : 100;
    return loadData;
  }, [excludeEmployee]);

  const payload = useMemo(
    () =>
      cleanBlankValue({
        search: debouncedSearch,
        per_page: perPage ? perPage : defaultPerPage,
      }),
    [debouncedSearch, perPage, defaultPerPage]
  );
  const { status, data, isFetchingNextPage, fetchNextPage } =
    useEmployeeSuggestion({ payload: payload });

  const formattedData = useMemo(() => {
    if (!data) {
      return [];
    }
    const newData = [];
    (data?.pages || []).forEach((page) => {
      (page?.data || []).forEach((row) => {
        newData.push(row);
      });
    });
    return newData;
  }, [data]);

  const optionsAccount = useMemo(() => {
    let options = [];
    let newData = _.cloneDeep(formattedData);
    if (!!defaultOptions && !_.isEmpty(defaultOptions)) {
      const filterDefault = (defaultOptions || [])?.filter(
        (item) => !newData.some((row) => item?.id === row?.id)
      );
      newData = [...filterDefault, ...newData];
    }
    //insert default value to top
    if (!!defaultEmployee) {
      const findDefaultEmployee = (newData || [])?.find(
        (e) => e.id === defaultEmployee
      );
      if (findDefaultEmployee?.name) {
        options.push({
          ...findDefaultEmployee,
          name: findDefaultEmployee?.name,
        });
      } else {
        options.push({
          ...selected,
        });
      }
    }
    if (excludeEmployee && !_.isEmpty(excludeEmployee)) {
      newData = (newData || [])?.filter(
        (row) => !_.includes(excludeEmployee, row.id)
      );
    }
    // set option from default item, usally for update view, add option, filter
    options.forEach((row) => {
      const findItem = (newData || [])?.find((item) => row.id === item.id);
      if (!findItem) {
        newData.unshift(row);
      }
    });
    return newData;
  }, [
    formattedData,
    defaultEmployee,
    excludeEmployee,
    defaultOptions,
    selected,
  ]);

  const onSearchEmployee = useCallback((value) => {
    setSearch(value);
  }, []);

  const selectHandler = useCallback(
    (e) => {
      onSelect(e);
      const data = (formattedData || [])?.find((row) => row?.id === e);
      if (data) {
        setSelected(data);
      }
    },
    [formattedData, onSelect]
  );

  const onScroll = (event) => {
    if (!sourceData) {
      var target = event?.target;
      if (
        !isFetchingNextPage &&
        Math.ceil(target?.scrollTop + target?.offsetHeight) ===
          target?.scrollHeight
      ) {
        fetchNextPage();
        target.scrollTo(0, target?.scrollHeight);
      }
    }
    return false;
  };

  return (
    <div key={`_${id}`} id={`suggestion-employee-${id}`} {...containerProps}>
      <Form.Item name={name} {...formItemProps}>
        <Select
          id={`suggestion-employee-${id}`}
          filterOption={false}
          placeholder={placeholder}
          onFocus={() => onSearchEmployee("")}
          loading={status === "loading"}
          onPopupScroll={onScroll}
          searchValue={search}
          onSearch={onSearchEmployee}
          onSelect={selectHandler}
          showSearch
          {...props}
        >
          {(optionsAccount || []).map((item, index) => (
            <Option key={index} value={item.id}>
              {item?.name || "-"}
            </Option>
          ))}
          {isFetchingNextPage || status === "loading" ? (
            <Option key={"loading"} disabled value="">
              <div className="flex w-full items-center justify-center">
                <Spin size="small" />
              </div>
            </Option>
          ) : null}
        </Select>
      </Form.Item>
    </div>
  );
};

EmployeeSuggestion.defaultProps = {
  onSelect: () => {},
};

export default forwardRef((props, ref) => (
  <EmployeeSuggestion innerRef={ref} {...props} />
));
