import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Form, message } from "antd";
import _ from "lodash";

const useUpload = ({
  name = "attachments",
  max = 5,
  mimeType,
  maxWeight = 10,
}) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const watchAttachments = Form.useWatch(name, form);

  const beforeUploadHandler = useCallback(
    async (file) => {
      //handle condition count max attachment file can upload
      const isAttachmentMax = watchAttachments.length >= max;
      if (isAttachmentMax) {
        message.error(`You can only upload up to ${max} files`);
      }
      //handle condition type file can upload
      const isValidMime = mimeType.includes(file.type);
      if (!isValidMime) {
        message.error("You can only upload .jpg, .png or .pdf file.");
      }
      //handle condition max file size can upload
      const isLt1M = file.size / 1024 / 1024 <= maxWeight;
      if (!isLt1M) {
        message.error(`File must smaller than ${maxWeight}MB!`);
      }

      return new Promise((resolve, reject) => {
        if (isValidMime && isLt1M && !isAttachmentMax) {
          return resolve(true);
        }
        return reject;
      });
    },
    [watchAttachments, maxWeight, mimeType, max]
  );

  const changeUploadHandler = useCallback(
    ({ file, fileList, event }) => {
      const files = [];

      (fileList || []).forEach((row) => {
        if (row?.attachment_url) {
          files.push(row);
        } else {
          if (
            Array.isArray(row?.response?.data) &&
            !_.isEmpty(row?.response?.data)
          ) {
            row.response.data.forEach((item) => {
              files.push({
                ...row,
                ...item,
              });
            });
          } else {
            const responseAttachments = row?.response?.data?.attachments || [];
            const findAttachment = responseAttachments.filter(
              (e) => files.filter((val) => val?.id === e?.id).length === 0
            );

            const currentAttachment = !_.isEmpty(responseAttachments)
              ? !_.isEmpty(findAttachment)
                ? findAttachment[0]
                : {}
              : responseAttachments.length === 1
              ? responseAttachments[0]
              : {};

            const responseData = row?.response?.data || {};

            files.push({
              ...responseData,
              ...row,
              ...currentAttachment,
              attachment_url:
                currentAttachment?.original_url || responseData?.url || "",
            });
          }
        }
      });

      form.setFieldValue(name, files);

      if (file?.status === "uploading") {
        return;
      }
      if (file?.status === "done") {
        message.success(
          `${file?.name} ${t("property.file_uploaded_successfully")}`
        );
      } else if (file?.status === "error") {
        message.error(`${file.name} ${t("property.file_upload_failed")}`);
      }
      return files;
    },
    [t, form, name]
  );

  const previewHandler = useCallback((file) => {
    if (file?.preview_url) {
      window.open(file?.preview_url, "_blank", "noopener,noreferrer");
    }
  }, []);

  return {
    beforeUpload: beforeUploadHandler,
    onChange: changeUploadHandler,
    onPreview: previewHandler,
    watchAttachments,
  };
};

export default useUpload;
