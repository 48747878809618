import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import store from "store";
import { setConfig } from "redux/configSlice";
import { useConfigSelector } from "./useSelector";

// Hook untuk handle responsive UI dari redux
const useResponsive = () => {
  const dispatch = useDispatch();
  const { isMobileView = false, isTabView = false } = useConfigSelector();

  const isDesktopLayout = useMemo(
    () => !isMobileView && !isTabView,
    [isMobileView, isTabView]
  );

  const setLayoutView = useCallback(
    (load = false) => {
      const windowWidth = global.window.innerWidth;
      const currentMobileState = windowWidth < 768;
      const prevMobileState = store.get("app.settings.isMobileView");
      const currentTabState = windowWidth >= 768 && windowWidth <= 992;
      const prevTabState = store.get("app.settings.isTabView");

      if (
        currentMobileState !== prevMobileState ||
        currentTabState !== prevTabState ||
        load
      ) {
        store.set("app.settings.isMobileView", currentMobileState);
        store.set("app.settings.isTabView", currentTabState);
        dispatch(
          setConfig({
            isMobileView: currentMobileState,
            isTabView: currentTabState,
          })
        );
      }
    },
    [dispatch]
  );

  return { isMobileView, isTabView, isDesktopLayout, setLayoutView };
};

export default useResponsive;
