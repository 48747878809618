import { forwardRef, useCallback } from "react";
import currencyFormatter from "currency-formatter";
import { NumericFormat } from "react-number-format";
import { Input } from "antd";
import localStore from "store";
import dayjs from "dayjs";
import useFormatting from "hooks/useFormatting";

export const currency = (
  val,
  unformat = false,
  symbolNon = false,
  ifNull = false,
  precision
) => {
  let symbol = "Rp ";
  if (symbolNon) {
    symbol = "";
  }

  if (precision === undefined) {
    precision = "2.";
  }

  let format = { symbol, thousand: ".", decimal: ",", precision };
  let result = 0;
  if (unformat) {
    val = `${val || ""}`.split(format.thousand || "").join("");
    result = currencyFormatter.unformat(val || 0, format);
  } else {
    result = currencyFormatter.format(val || 0, format);
  }

  if (ifNull && result === "0") {
    return "-";
  }
  if (unformat === "format") {
    return format;
  }
  return result;
};

export const InputCurrency = forwardRef(
  (
    {
      onBlur,
      onPressEnter,
      onFocus,
      onChange,
      showPrefix,
      className,
      ...props
    },
    ref
  ) => {
    const { currency } = useFormatting();
    let format = currency(0, "format", !showPrefix, false, 0);

    const onFocusHandler = useCallback(
      (e) => {
        e.target.select();
        if (onFocus) {
          onFocus(e);
        }
      },
      [onFocus]
    );

    const onKeyDownHandler = useCallback(
      (e) => {
        if (e.keyCode === 13) {
          if (onPressEnter) {
            onPressEnter(e.target.value, currency(e.target.value, true));
          }
        }
      },
      [onPressEnter, currency]
    );

    const onBlurHandler = useCallback(
      (e) => {
        if (onBlur) {
          onBlur(e.target.value, currency(e.target.value, true));
        }
      },
      [onBlur, currency]
    );

    format = {
      defaultValue: 0,
      decimalSeparator: format.decimal,
      thousandSeparator: format.thousand,
      prefix: format.symbol,
      className,
      style: {
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
        textAlign: "right",
      },
      onKeyDown: onKeyDownHandler,
      onBlur: onBlurHandler,
      ...props,
      onChange: (e) => onChange(e.target.value, currency(e.target.value, true)),
      // isNumericString: true,
      decimalScale: 2,
      onFocus: onFocusHandler,
    };

    return <NumericFormat customInput={Input} getInputRef={ref} {...format} />;
  }
);

export const formatDateDB = (date = new Date()) => {
  const newDate = dayjs(date);

  return newDate.isValid() ? newDate.format("YYYY-MM-DD") : "";
};

export const isObject = (value) => {
  return value && typeof value === "object" && value.constructor === Object;
};

const staticTranslation = {
  "id-ID": {
    settings: "Pengaturan",
  },
  "en-US": {
    settings: "Settings",
  },
};

export const appMessage = (id) => {
  const locale = localStore.get("app.settings.locale");
  if (staticTranslation[locale]) {
    return staticTranslation[locale][id] ? staticTranslation[locale][id] : id;
  }
  return id;
};

export const formatNpwp = (value) => {
  if (typeof value === "string") {
    value = value.replace(/[A-Za-z\W\s_]+/g, "");
    let split = 6;
    const dots = [];

    for (let i = 0, len = value.length; i < len; i += split) {
      split = i >= 2 && i <= 6 ? 3 : i >= 8 && i <= 12 ? 4 : 2;
      dots.push(value.substr(i, split));
    }

    const temp = dots.join(".");
    return temp.length > 12
      ? `${temp.substring(0, 12)}-${temp.substring(12, temp.length)}`
      : temp;
  }
  return "-";
};
