import axios from "utils/axios";
import { stringify } from "query-string";

export const getCompanyPolicies = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/companyPolicies?${query}`);
};

export const getCompanyPolicy = (data) => {
  return axios.get(`/hr/companyPolicies/${data?.id}`);
};

export const addCompanyPolicy = (data) => {
  return axios.post(`/hr/companyPolicies`, data);
};

export const editCompanyPolicy = (data) => {
  return axios.put(`/hr/companyPolicies/${data?.id}`, data);
};

export const moveCompanyPolicy = (data) => {
  return axios.patch(
    `/hr/companyPolicies/${data?.id}/move?dest_id=${data?.dest_id}`
  );
};

export const deleteCompanyPolicy = (id) => {
  return axios.delete(`/hr/companyPolicies/${id}`);
};

export const deleteAttachmentCompanyPolicy = (data) => {
  return axios.delete(
    `/hr/companyPolicies/${data?.companyPolicyId}/attachment/delete/${data?.id}`
  );
};
