import axios from "utils/axios";
import { stringify } from "query-string";

export const getAttendanceLogs = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/attendanceLogs/pagination?${query}`);
};

export const getAttendanceLogsApproval = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/attendanceLogs/approval/pagination?${query}`);
};

export const getAttendanceLogsLocation = (data) => {
  return axios.get(`/hr/attendanceLogs/${data?.id}/location`);
};

export const getAttendanceLogsAddress = (data) => {
  return axios.get(`/hr/attendanceLogs/${data?.id}/address`);
};

export const approveAttendanceLog = (data) => {
  return axios.patch(`/hr/attendanceLogs/approve`, data);
};

export const rejectAttendanceLog = (data) => {
  return axios.patch(`/hr/attendanceLogs/reject`, data);
};
