import { memo } from "react";
import { Button} from "antd";
import { useTranslation } from "react-i18next";
import { MdOutlineFilterList } from "react-icons/md";
import PropTypes from "prop-types";

const Filter = ({ id, title, icon, type, onClick, ...props }) => {
  const { t } = useTranslation();
  const btnTitle = title ? title : t("button.filter");
  return (
    <Button id={id} type={type} title={btnTitle} onClick={onClick} {...props}>
      <div className="flex items-center justify-center space-x-2">
        {icon}<span>{btnTitle}</span>
      </div>
    </Button>
  );
};

Filter.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

Filter.defaultProps = {
  type: "default",
  icon: <MdOutlineFilterList size={20} />,
  onClick: () => {},
  id: "btn-filter",
};

export default memo(Filter);
