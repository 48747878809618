import axios from "utils/axios";
import { stringify } from "query-string";

export const getDashboards = (id, params) => {
  const query = stringify(params);
  return axios.get(`/hr/dashboards/${id}?${query}`);
};

export const moveDashboard = (data) => {
  return axios.patch(`/hr/dashboards/user/${data?.id}/move`, data);
};

export const activateDashboard = (data) => {
  return axios.patch(`/hr/dashboards/user/${data?.id}/activate`);
};

export const deactivateDashboard = (data) => {
  return axios.patch(`/hr/dashboards/user/${data?.id}/deactivate`);
};
