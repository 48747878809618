import axios from "utils/axios";

export const getBankAccounts = () => {
  return axios.get(`/hr/bankAccounts`);
};

export const addBankAccount = (data) => {
  return axios.post(`/hr/bankAccounts`, data);
};

export const editBankAccount = (data) => {
  return axios.put(`/hr/bankAccounts/${data.id}`, data);
};

export const deleteBankAccount = (id) => {
  return axios.delete(`/hr/bankAccounts/${id}`);
};
