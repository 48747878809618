import { memo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import {
  useBillingSelector,
  useOptionSelector,
  useSettingSelector,
} from "hooks/useSelector";
import { Upgrade } from "components/UI";
import BillingConfirmDemoModal from "components/apps/Billing/Content/BillingConfirmDemo";
import useUrlKledo from "hooks/useUrlKledo";

const { Text } = Typography;

const HideFeature = ({
  children,
  disabled = false,
  code = "",
  maxCode,
  currentCount,
  title,
  isEliteOnly = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const billing = useBillingSelector();
  const setting = useSettingSelector();
  const option = useOptionSelector();
  let label = isEliteOnly
    ? t("billing.this_feature_is_available_in_the_package_elite")
    : t("billing.this_feature_is_available_in_the_package_pro_and_elite");
  const urlBillingKledo = useUrlKledo("#/settings/billing");

  const [visibleDemo, setVisibleDemo] = useState(false);

  const goToUpgradeHandler = useCallback(() => {
    if (setting && !!setting.app_demo) {
      setVisibleDemo(true);
      return;
    }
    navigate("/settings/billing/upgrade");
  }, [navigate, setting]);

  const hideConfirmDemo = useCallback(() => {
    setVisibleDemo(false);
  }, []);

  if (_.isEmpty(billing)) {
    return children;
  }
  if (maxCode && billing[maxCode] && (billing[maxCode] || 0) <= currentCount) {
    label = title ? title : label;
    // } else if (
    //   isEliteOnly &&
    //   billing &&
    //   billing.app_plan &&
    //   billing.app_plan.id !== 4 &&
    //   billing.app_plan.id !== 5
    // ) {
    //   label = title
    //     ? title
    //     : t("billing.this_feature_is_available_in_the_package_elite");
  } else if (
    !isEliteOnly &&
    !code &&
    !disabled &&
    billing &&
    billing.app_plan &&
    billing.app_plan.id !== 1
  ) {
    return children;
  } else if (
    disabled ||
    (code &&
      billing?.hidden_feature &&
      _.isArray(billing?.hidden_feature) &&
      (billing?.hidden_feature || [])?.filter((e) => e === code).length === 0)
  ) {
    return children;
  }

  return (
    <div>
      <div className="relative min-h-[160px]">
        {children}
        <div className="z-[11] absolute top-0 left-0 w-full h-full bg-[#efefff99] flex flex-col justify-center items-center py-[2rem]">
          <div className="flex flex-col items-center justify-center space-y-3 bg-white dark:bg-dark2 p-[20px] rounded-[10px]">
            <Text className="font-semibold text-base">{label}</Text>
            {option?.bundling_finance_hr === 1 ? (
              <Upgrade
                href={urlBillingKledo}
                target="_blank"
                rel="noopener noreferrer"
                type="primary"
                title={t("billing.upgrade_now")}
                className="w-fit text-white"
              />
            ) : (
              <Upgrade
                className="w-fit"
                title={t("billing.upgrade_now")}
                onClick={goToUpgradeHandler}
              />
            )}
          </div>
        </div>
      </div>
      <BillingConfirmDemoModal
        visible={visibleDemo}
        onCancel={hideConfirmDemo}
      />
    </div>
  );
};

export default memo(HideFeature);
