import { useEffect, useState } from "react";
import { Alert, Layout } from "antd";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import dayjs from "dayjs";
import Progressbar from "@badrap/bar-of-progress";
import store from "store";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import { isTokenExist } from "utils/cookies";
import {
  isMobileViewSelector,
  loadingInitSelector,
  loginModalSelector,
  loadedInitSelector,
} from "redux/selectors";
import useLoadInits from "hooks/useLoadInits";
import ChatSupport from "components/apps/ChatSupport";
import LoginModal from "pages/user/login/LoginModal";
import { logout } from "redux/configSlice";
import TopBar from "./TopBar";
import SideMenu from "./SideMenu";
import Footer from "./Footer";
import BreadCrumb from "./BreadCrumb";
import InstallPromptWrapper from "./InstallPromptWrapper";
import {
  useAnnouncementSelector,
  useBillingSelector,
  usePermissionsSetting,
  useSettingSelector,
  useUserSelector,
} from "hooks/useSelector";
import OneSignalApp from "utils/oneSignal";
import NPS from "pages/nps";
import { useInitEmployee } from "hooks";

const { Header, Content } = Layout;

const progress = new Progressbar({
  size: 4,
  color: "#46be8a",
  className: "z-40",
  delay: 100,
});

const Main = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobileView = useSelector(isMobileViewSelector);
  const loadingInit = useSelector(loadingInitSelector);
  const loadedInit = useSelector(loadedInitSelector);
  const loginModal = useSelector(loginModalSelector);
  const tokenExist = isTokenExist();
  const billing = useBillingSelector();
  const setting = useSettingSelector();
  const user = useUserSelector();
  const announcement = useAnnouncementSelector();
  const { isRoleEmployee } = useInitEmployee();
  const isHasAccessAudit = usePermissionsSetting("setting_audit");

  const [initializeOneSignal, setInitializeOneSignal] = useState(false);

  useEffect(() => {
    if (!tokenExist) {
      store.remove("app.endpoint");
      store.set("app.previousPathname", location.pathname);
      dispatch(logout());
      navigate("/user/login", { replace: true, state: { from: location } });
    }
    //handle untuk billing ketika expired
    if (
      billing &&
      !!billing?.is_expired &&
      !!billing?.expiry_date &&
      dayjs(billing.expiry_date).isBefore(dayjs()) &&
      !location.pathname.includes("/billing") &&
      ((!location.pathname.includes("/setup") && !!setting?.app_demo) ||
        !setting?.app_demo)
    ) {
      navigate("/settings/billing", { replace: true });
    }
  }, [dispatch, location, navigate, tokenExist, billing, setting]);

  useLoadInits(true);

  useEffect(() => {
    if (loadingInit) {
      progress.start();
    } else {
      progress.finish();
    }
  }, [loadingInit]);

  //initialize push notification one signal
  useEffect(() => {
    if (
      loadedInit &&
      user &&
      user.id &&
      process.env.NODE_ENV === "production"
    ) {
      OneSignalApp.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        id: user.id,
        onOpenNotification: (data) => console.log(data),
        initialized: initializeOneSignal,
        setInizialized: () => setInitializeOneSignal(true),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedInit, user]);

  if (!tokenExist) {
    return null;
  }

  return (
    <Layout className="min-h-screen" hasSider>
      <SideMenu />
      <Layout>
        {!_.isEmpty(announcement) && (
          <Alert
            style={{ backgroundColor: "#ffefb3" }}
            className="text-gray-900"
            message={announcement}
            type="warning"
            showIcon
            closable
          />
        )}
        <Header className="bg-white dark:bg-dark1 p-0 lg:h-14 md:h-20 sm:h-20">
          <TopBar
            hideContent={!loadedInit}
            hideTimeline={isRoleEmployee || _.isEmpty(isHasAccessAudit)}
            hideNews={isRoleEmployee}
            hideDarkModeSelector={isRoleEmployee}
          />
          {!isMobileView && loadedInit && <BreadCrumb />}
        </Header>
        <Content className="m-2 md:m-5 md:mt-10 pt-5">
          <Outlet />
        </Content>
        <Footer />
        <ChatSupport />
        {!isRoleEmployee && <NPS />}
        {loginModal.show && <LoginModal />}
      </Layout>
      <InstallPromptWrapper />
    </Layout>
  );
};

export default Main;
