import { useQuery, useMutation, useInfiniteQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/attendanceLocations";

export function useAttendanceLocations({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["attendance/locations", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getAttendanceLocations(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAttendanceLocationSuggestion = (
  { payload = {} },
  options = {}
) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["attendance/locations/suggestion", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getAttendanceLocations({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export function useAttendanceLocation({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["attendance/location", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getAttendanceLocation(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAddAttendanceLocation = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addAttendanceLocation(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditAttendanceLocation = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editAttendanceLocation(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteAttendanceLocation = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteAttendanceLocation(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useArchiveAttendanceLocation = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.archiveAttendanceLocation(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useUnarchiveAttendanceLocation = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.unarchiveAttendanceLocation(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
