import { useQuery, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import _ from "lodash";

import * as api from "apis/roles";

//Query untuk get all roles
export function useRoles(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["roles"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getRoles();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail role
export function useRole({ payload = {} }) {
  const { t } = useTranslation();
  return useQuery(
    ["role", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getRole(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

// Query untuk add role
export const useAddRole = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addRole(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit role
export const useEditRole = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.updateRole(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk move role
export const useMoveRole = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => api.moveRole({ id: payload.srcId, dest_id: payload.destId }),
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries("roles");
        const previousRoles = queryClient.getQueryCache("roles");

        queryClient.setQueryData("roles", (old) => {
          if (old && Array.isArray(old.data)) {
            const roles = [...old.data];
            const srcRole = roles[payload.dragIndex];
            roles.splice(payload.dragIndex, 1);
            roles.splice(payload.hoverIndex, 0, srcRole);
            old.data = [...roles];
            return { ...old };
          }
        });

        return { previousRoles };
      },
      onSuccess: ({ data }) => {
        if (data.success) {
          message.success(data.message);
        } else {
          message.error(data.message);
        }
      },
      onError: (_err, role, context) => {
        queryClient.setQueryData("roles", context.previousRoles);
        message.error(
          !_.isEmpty(_err) && _.isString(_err)
            ? _err
            : t("roles.error_cannot_move_the_channel")
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries("roles");
      },
    }
  );
};

// Query untuk delete role
export const useDeleteRole = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteRole(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
