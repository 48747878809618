import axios from "utils/axios";
import { stringify } from "query-string";

export const getShiftSchedules = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/shiftSchedules?${query}`);
};

export const getShiftSchedulesPagination = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/shiftSchedules/pagination?${query}`);
};

export const addShiftSchedule = (data) => {
  return axios.post("/hr/shiftSchedules", data);
};

export const deleteShiftSchedule = (id) => {
  return axios.delete(`/hr/shiftSchedules/${id}`);
};

export const uploadImportShiftSchedules = (data) => {
  return axios.post("/hr/shiftSchedules/uploadImport", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadImportHorizontalShiftSchedules = (data) => {
  return axios.post("/hr/shiftSchedules/horizontal/uploadImport", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const executeImportShiftSchedules = (data) => {
  return axios.post("/hr/shiftSchedules/executeImport", data);
};

export const executeImportHorizontalShiftSchedules = (data) => {
  return axios.post("/hr/shiftSchedules/horizontal/executeImport", data);
};
