import { Navigate } from "react-router-dom";
import _ from "lodash";

import { useLoadedInitSelector, usePermissionsMenu } from "hooks/useSelector";
import { useInitEmployee } from "hooks";
import Page404 from "pages/404";
import useDefaultPath from "components/apps/Login/hooks/useDefaultPath";

const ProtectedRoute = ({ children, name, isDefaultPath, isNotEmployee }) => {
  const defaultPath = useDefaultPath();
  const loadedInit = useLoadedInitSelector();
  const { isRoleEmployee } = useInitEmployee();
  const permission = usePermissionsMenu(name);

  if (loadedInit && name && _.isEmpty(permission)) {
    if (isDefaultPath) {
      return <Navigate to={defaultPath()} replace />;
    }
    return <Page404 />;
  }

  if (loadedInit && !name && isNotEmployee && isRoleEmployee) {
    return <Page404 />;
  }

  if (!loadedInit) {
    return null;
  }

  return children;
};

export default ProtectedRoute;
