import { useMemo } from "react";
import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Breadcrumb } from "antd";
import { MdOutlineArrowRight } from "react-icons/md";
import _ from "lodash";

import routes from "routes";
import { useMenuDashboard } from "hooks/useSelector";
import useDefaultPath from "components/apps/Login/hooks/useDefaultPath";

const excludePaths = [
  "/settings/calendar/detail",
  "/settings/roles/edit",
  "/employee/detail",
  "/employee/detail/:id/payroll/detail-payroll",
  "/payroll/detail",
  "/settings/payroll/salary-slip/edit",
  "/payroll/manual-component-import",
  "/payroll/payroll-note-import",
  "/presence/work-schedule/shift-schedule/shift",
  "/settings/schedule/add/select/shift",
  "/faqs/detail",
  "/client/edit",
  "/employee/detail/:id/career/terminate",
  "/employee/detail/:id/leaves/annual-leave/detail",
  "/employee/detail/:id/leaves/special-leave/detail",
  "/employee/detail/:id/leaves/sick-leave/detail",
  "/employee/detail/:id/leaves/permission-leave/detail",
  "/employee/detail/:id/leaves/unpaid-leave/detail",
  "/employee/detail/:id/leaves/annual-leave/leave-history/detail",
  "/employee/detail/:id/payroll/edit",
  "/employee/detail/:id/career/detail",
  "/employee/detail/:id/finance/cash-receipt/detail",
  "/employee/detail/:id/finance/reimbursement/detail",
  "/employee/detail/:id/finance/reimbursement/edit",
  "/employee/detail/:id/task/detail",
  "/presence/work-schedule/detail",
  "/presence/work-schedule/detail/:id/schedule",
  "/presence/leaves/annual-leave/detail",
  "/presence/leaves/special-leave/detail",
  "/presence/leaves/sick-leave/detail",
  "/presence/leaves/permission-leave/detail",
  "/presence/leaves/unpaid-leave/detail",
  "/report/payroll-summary/detail",
  "/report/payroll-summary-per-organization/detail",
  "/report/payroll-summary-per-component/detail",
  "/report/payroll/detail",
  "/approval/waiting-approval/leave",
  "/approval/waiting-approval/leave/detail",
  "/approval/waiting-approval/reimbursement",
  "/approval/waiting-approval/reimbursement/edit",
  "/approval/waiting-approval/reimbursement/detail",
  "/approval/waiting-approval/cash-receipt",
  "/approval/waiting-approval/cash-receipt/detail",
  "/approval/approved/leave",
  "/approval/approved/leave/detail",
  "/approval/approved/reimbursement",
  "/approval/approved/reimbursement/edit",
  "/approval/approved/reimbursement/detail",
  "/approval/approved/cash-receipt",
  "/approval/approved/cash-receipt/detail",
  "/approval/rejected/leave",
  "/approval/rejected/leave/detail",
  "/approval/rejected/reimbursement",
  "/approval/rejected/reimbursement/edit",
  "/approval/rejected/reimbursement/detail",
  "/approval/rejected/cash-receipt",
  "/approval/rejected/cash-receipt/detail",
  "/finance/cash-receipt/waiting-approval/detail",
  "/finance/cash-receipt/approved/detail",
  "/finance/cash-receipt/rejected/detail",
  "/finance/cash-receipt/payment/detail",
  "/finance/reimbursement/detail",
  "/finance/reimbursement/edit",
  "/settings/schedule/edit/:id/select/shift",
  "/settings/company/organitation-structure/edit",
  "/settings/company/job-title/detail",
  "/settings/company/position/edit",
  "/settings/company/company-policy/detail",
  "/settings/payroll/salary-slip/add/income",
  "/settings/payroll/salary-slip/add/cut",
  "/settings/payroll/salary-slip/edit/:id/income",
  "/settings/payroll/salary-slip/edit/:id/cut",
  "/settings/attendance-location/edit",
  "/settings/schedule/detail",
  "/settings/approval-rule/detail",
  "/settings/approval-rule/edit",
  "/settings/apps/detail",
  "/settings/schedule/edit",
  "/location-tracking/detail",
  "/settings/overtime/detail",
  "/settings/overtime/edit",
  "/presence/attendance/daily/tracking",
  "/settings/company/organitation-structure/detail",
  "/settings/company/position/detail",
  "/settings/fingerprint-connect/edit",
  "/settings/fingerprint-connect/synchronization",
  "/task/detail",
];

const BreadCrumbs = () => {
  const defaultPath = useDefaultPath();
  //condition validasi breadcrumb on main menu when not have permission
  const menuApproval = useMenuDashboard("attendance", "presence_approval");
  if (_.isEmpty(menuApproval)) {
    excludePaths.push("/presence");
  }

  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: true,
    excludePaths,
  });
  const countBreadCrumb = breadcrumbs.length;

  const items = useMemo(() => {
    const itemBreadcrumbs = [];
    itemBreadcrumbs.push({
      key: "home",
      title: <Link to={defaultPath()}>Home</Link>,
    });
    itemBreadcrumbs.push({
      type: "separator",
      separator: <MdOutlineArrowRight className="text-primary self-center" />,
    });
    if (!_.isEmpty(breadcrumbs)) {
      breadcrumbs.forEach(({ match, breadcrumb }, index) => {
        if (countBreadCrumb === index + 1) {
          itemBreadcrumbs.push({
            key: match.pathname,
            title: breadcrumb || "",
          });
        } else {
          itemBreadcrumbs.push({
            key: match.pathname,
            title: <Link to={match.pathname}>{breadcrumb}</Link>,
          });
          itemBreadcrumbs.push({
            type: "separator",
            separator: (
              <MdOutlineArrowRight className="text-primary self-center" />
            ),
          });
        }
      });
    }
    return itemBreadcrumbs;
  }, [breadcrumbs, countBreadCrumb, defaultPath]);

  return (
    <div className="h-10 p-5 bg-white dark:bg-dark1 flex items-center">
      <Breadcrumb className="flex items-center" items={items} separator={""} />
    </div>
  );
};

export default BreadCrumbs;
