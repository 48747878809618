import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Progress, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";

const DownloadProgress = ({ id, open, percentage, type, title }) => {
  const { t } = useTranslation();
  return (
    <Modal
      id={id}
      open={open}
      footer={null}
      destroyOnClose={true}
      closable={false}
    >
      <div className="flex flex-col">
        <Typography.Text className="text-base font-bold">
          {title ? title : `Download ${type?.name || ""}`}
        </Typography.Text>
        <Typography.Text className="text-gray-400">
          {t("reportTax.this_process_requires_a_stable_internet_connection")}
        </Typography.Text>
        <div className="mt-5 space-y-1">
          <div className="flex flex-row items-center gap-x-2">
            {percentage === 0 && (
              <Spin indicator={<LoadingOutlined />} spinning />
            )}
            <Typography.Text>
              {t("reportTax.we_re_preparing_your_file_recap", {
                type: type?.name || "",
              })}
            </Typography.Text>
          </div>
          <Progress
            percent={percentage}
            size={[null, 25]}
            status="active"
            strokeColor="#E53F6A"
          />
        </div>
      </div>
    </Modal>
  );
};

export default DownloadProgress;
