import { stringify } from "query-string";
import axios from "utils/axios";

export const getJobPositions = (data) => {
  return axios.get("/hr/jobPositions", data);
};

export const getJobPosition = (id) => {
  return axios.get(`/hr/jobPositions/${id}`);
};

export const getJobPositionsTree = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/jobPositions/tree?${query}`);
};

export const getJobPositionParents = (id) => {
  return axios.get(`/hr/jobPositions/availableParents/${id}`);
};

export const getJobPositionEmployee = (data) => {
  const query = stringify(data);
  return axios.get(`/hr/jobPositions/${data?.id}/employees?${query}`);
};

export const addJobPosition = (data) => {
  return axios.post("/hr/jobPositions", data);
};

export const editJobPosition = (data) => {
  return axios.put(`/hr/jobPositions/${data.id}`, data);
};

export const deleteJobPosition = (id) => {
  return axios.delete(`/hr/jobPositions/${id}`);
};

export const archiveJobPosition = (id) => {
  return axios.patch(`/hr/jobPositions/${id}/archive`);
};

export const unarchiveJobPosition = (id) => {
  return axios.patch(`/hr/jobPositions/${id}/unarchive`);
};
