import { Layout, Divider } from "antd";

const { Footer } = Layout;

const PublicFooter = (props) => {
  return (
    <Footer className="px-2 md:px-5 pb-0">
      <div className="relative rounded-t bg-gray-200 dark:bg-dark1 flex flex-col items-center px-5 md:px-10 py-10">
        <div className="flex self-start justify-center w-full">
          <a
            href="https://play.google.com/store/apps/details?id=com.gajihub.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/resources/images/download-gplay.png"
              alt="Google Play"
              className="h-[40px] mx-[5px]"
            />
          </a>
          <a
            href="https://apps.apple.com/gb/app/gajihub-payroll-absensi/id1604540147"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/resources/images/download-appstore.png"
              alt="App Store"
              className="h-[40px] mx-[5px]"
            />
          </a>
        </div>
        <Divider className="my-3"/>
        <div className="flex self-start w-full justify-center" {...props}>
          <span className="text-gray-500">
            © {new Date().getFullYear()}{" "}
            <a
              href="https://gajihub.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-link-primary"
            >
              {`Gajihub Software v${process.env.REACT_APP_VERSION}`}
            </a>{" "}
            All rights reserved
          </span>
        </div>
      </div>
    </Footer>
  );
};

export default PublicFooter;
