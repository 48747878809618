import { useMutation, useQuery } from "react-query";
import { message } from "antd";

import * as api from "apis/attendanceLogRule";
import { useTranslation } from "react-i18next";

export function useAttendanceLogRules(options = {}) {
  return useQuery(
    ["attendance/log/rules"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getAttendanceLogRules();
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(error.message || "Failed to load data from server!"),
      ...options,
    }
  );
}

// Query untuk add attendance log rule
export const useAddAttendanceLogRule = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addAttendanceLogRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) =>
      message.error(error?.message || t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit attendance log rule
export const useEditAttendanceLogRule = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editAttendanceLogRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) =>
      message.error(error?.message || t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete attendance log rule
export const useDeleteAttendanceLogRule = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteAttendanceLogRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) =>
      message.error(error?.message || t("error.failed_save_data_to_server")),
  });
};
