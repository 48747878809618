import { useEffect } from "react";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createBrowserHistory } from "history";
// import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";
import SentryErrorBoundary from "components/SentryErrorBoundary";
import { useAppSelector } from "hooks/useSelector";
import useResponsive from "hooks/useResponsive";
import { initializeFp } from "utils/gtm";
import { store } from "./redux/store";
import Router from "./router";
import ConfigProvider from "./ConfigProvider";
// import UpdateAppWrapper from "./UpdateAppWrapper";
// import { ServiceWorkerProvider } from "./ServiceWorkerProvider";
// import "./App.less";

// create history
const history = createBrowserHistory({ window });
window.customHistory = history;
window.appReduxStore = store;

// Use query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const { user } = useAppSelector();
  const { setLayoutView } = useResponsive();

  // Reinitialize facebook pixel
  useEffect(() => {
    if (user?.email) {
      if (process.env.NODE_ENV === "production") {
        initializeFp(user.email)
      }
    }
  }, [user?.email]);

  // Set resonsive listener
  useEffect(() => {
    setLayoutView(true);
    window.addEventListener("resize", () => {
      setLayoutView();
    });

    return () => window.removeEventListener("resize", setLayoutView);
  }, [setLayoutView]);

  return (
    <SentryErrorBoundary
      showDialog
      dialogOptions={{
        user: {
          name: user?.name,
          email: user?.email,
        },
      }}
    >
      <Router />
    </SentryErrorBoundary>
  );
}

function AppContainer() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider>
          <HistoryRouter history={history}>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            >
              <App />
            </GoogleOAuthProvider>
          </HistoryRouter>
        </ConfigProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default AppContainer;
