import axios from "utils/axios";

export const getInits = () => {
  return axios.get("/hr/inits");
};

export const getInitsPublic = () => {
  return axios.get("/hr/inits/public", {
    headers: { Authorization: false },
  });
};
