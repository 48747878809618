import { useCallback } from "react";
import Drawer from "rc-drawer";
import { useDispatch } from "react-redux";
import store from "store";
import { css } from "@emotion/css";
import { useConfigSelector, useSettingSelector } from "hooks/useSelector";
import { setConfig } from "redux/configSlice";
import Sider from "./Sider";

const SideMenu = () => {
  const dispatch = useDispatch();
  const settings = useSettingSelector();
  const { isMobileView, isMobileMenuOpen, isMenuCollapsed } =
    useConfigSelector();

  const toggleDrawerHandler = useCallback(() => {
    store.set("app.settings.isMobileMenuOpen", !isMobileMenuOpen);
    store.set("app.menu.isMenuCollapsed", !isMenuCollapsed);
    dispatch(
      setConfig({
        isMobileMenuOpen: !isMobileMenuOpen,
        isMenuCollapsed: !isMenuCollapsed,
      })
    );
  }, [dispatch, isMenuCollapsed, isMobileMenuOpen]);

  if (isMobileView) {
    return (
      <Drawer
        getContainer={null}
        level={null}
        open={isMobileMenuOpen}
        onClose={toggleDrawerHandler}
        onHandleClick={toggleDrawerHandler}
        className={`${css`
          .drawer-handle {
            top: 120px !important;
          }
          .drawer-content-wrapper {
            background-color: ${settings?.dark_mode
              ? "#141414"
              : "#FFFFFF"}!important;
          }
          .drawer-handle {
            background-color: ${settings?.dark_mode
              ? "#141414"
              : "#FFFFFF"}!important;
          }
          .drawer-handle-icon {
            background-color: ${settings?.dark_mode
              ? isMobileMenuOpen
                ? "transparent"
                : "#FFFFFF"
              : isMobileMenuOpen
              ? "transparent"
              : "#333"}!important;
          }
          .drawer-handle-icon::before {
            background-color: ${settings?.dark_mode
              ? "#FFFFFF"
              : "#333"}!important;
          }
          .drawer-handle-icon::after {
            background-color: ${settings?.dark_mode
              ? "#FFFFFF"
              : "#333"}!important;
          }
        `}`}
      >
        <Sider />
      </Drawer>
    );
  }
  return <Sider />;
};

export default SideMenu;
