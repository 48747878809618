import axios from "utils/axios";

export const getPaymentConnectSetting = (id) => {
  return axios.get(`/hr/paymentConnectSetting/${id}`);
};

export const updatePaymentConnectSetting = (data) => {
  return axios.put("/hr/paymentConnectSetting", data);
};

export const testConnectionPaymentConnectSetting = (data) => {
  return axios.post("/hr/paymentConnectSetting/testConnection", data);
};
