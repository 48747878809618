import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import { RollbackOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

function Reset({ ...props }) {
  const { t } = useTranslation();

  const title = props.title === "button.reset" ? t(props.title) : props.title;

  return (
    <Button {...props} title={title}>
      {title}
    </Button>
  );
}

Reset.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  danger: PropTypes.bool,
};

Reset.defaultProps = {
  type: "primary",
  icon: <RollbackOutlined />,
  title: "button.reset",
  onClick: () => {},
  id: "btn-reset",
  danger: true,
};

export default Reset;
