import { memo } from "react";
import { Alert } from "antd";
import { Trans } from "react-i18next";
import PropTypes from "prop-types";

const MessageInfo = ({ title, className, ...props }) => {
  return (
    <Alert
      className={`bg-[#C2E7F4] border-[#93E3FD] mb-5 text-gray-900 ${className}`}
      message={<Trans i18nKey={title} />}
      {...props}
    />
  );
};

MessageInfo.propTypes = {
  title: PropTypes.string,
};

MessageInfo.defaultProps = {
  title: "",
};

export default memo(MessageInfo);
