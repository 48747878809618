import { memo } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import classname from "classname";
import { SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const Save = ({
  id,
  title,
  icon,
  type,
  onClick,
  className,
  defaultClassName,
  ...props
}) => {
  const { t } = useTranslation();
  const btnTitle = title ? title : t("button.save");
  return (
    <Button
      className={
        defaultClassName
          ? classname(
              "flex items-center justify-center hover:opacity-70",
              className
            )
          : ""
      }
      id={id}
      type={type}
      icon={icon}
      onClick={onClick}
      {...props}
    >
      {btnTitle}
    </Button>
  );
};

Save.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

Save.defaultProps = {
  type: "primary",
  icon: <SaveOutlined />,
  onClick: () => {},
  defaultClassName: true,
  id: "btn-save",
};

export default memo(Save);
