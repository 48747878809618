import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import { checkApp, appConnect } from "apis/app";

export function useCheckApp({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["checkApp", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await checkApp(payload.app);
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// export const useAppConnect = () => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const oldOptions = useSelector(optionsSelector);
//   return useMutation((payload) => appConnect(payload.app), {
//     onMutate: async () => {
//       dispatch(setOption({ app_connect_finance: true }));
//     },
//     onSuccess: ({ data }) => {
//       if (data?.success) {
//         message.success(data?.message);
//       } else {
//         message.error(data?.message);
//       }
//     },
//     onError: (error) => {
//       dispatch(setOption(oldOptions));
//       message.error(error?.message || t("error.failed_save_data_to_server"));
//     },
//   });
// };

export const useAppConnect = () => {
  return useMutation((payload) => appConnect(payload.app));
};
