import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "store";
import { useQueryClient } from "react-query";

import { setApp, setConfig, resetData } from "redux/configSlice";
import { generateBaseUri } from "utils/helper";
import Axios from "utils/axios";

// Digunakan untuk handle milih perusahaan dan setup aplikasi
const useSelectedCompany = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const selectCompanyHandler = (selected, path, withResetRedux = true) => {
    store.set("app.endpoint", selected.endpoint);
    Axios.defaults.baseURL = generateBaseUri();
    queryClient.clear();
    if (withResetRedux) {
      dispatch(resetData());
    }

    dispatch(
      setConfig({ selectedCompany: selected, endpoint: selected.endpoint })
    );
    dispatch(setApp({ website_id: selected.website_id }));
    // dispatch(setLoadedInit(false));
    navigate(path || store.get("app.previousPathname") || "/dashboard", {
      replace: true,
    });
  };

  return { onSelectCompany: selectCompanyHandler };
};

export default useSelectedCompany;
