import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import localeData from "dayjs/plugin/localeData";
import duration from "dayjs/plugin/duration";
import preciseDiff from "dayjs-precise-range";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import "dayjs/locale/id";

const setupDayjs = function () {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale("id");
  dayjs.extend(relativeTime);
  dayjs.extend(localeData);
  dayjs.extend(duration);
  dayjs.extend(preciseDiff);
  dayjs.extend(weekday);
  dayjs.extend(weekOfYear);
  dayjs.extend(weekYear);
  dayjs.extend(advancedFormat);
  dayjs.extend(customParseFormat);
};
export default setupDayjs;
