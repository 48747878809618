import axios from "utils/axios";

export const getOauthClients = () => {
  return axios.get(`/oauth/clients`);
};

export const getOauthClient = (id) => {
  return axios.get(`/oauth/clients/${id}`);
};

export const addOauthClient = (data) => {
  return axios.post("/oauth/clients", data);
};

export const editOauthClient = (data) => {
  return axios.put(`/oauth/clients/${data.id}`, data);
};

export const deleteOauthClient = (id) => {
  return axios.delete(`/oauth/clients/${id}`);
};
