import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/billing";

//Query untuk get all billing transactions
export function useBillingTransactions({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["billing/transactions", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBillingTransactions(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get billing
export function useBillings(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["billings"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBillings();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get billing upgrade
export function useBillingUpgrade() {
  const { t } = useTranslation();
  return useQuery(
    ["billing/upgrade"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBillingUpgrade();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get billing renew
export function useBillingRenew() {
  const { t } = useTranslation();
  return useQuery(
    ["billing/renew"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBillingRenew();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get billing quantity
export function useBillingQuantity() {
  const { t } = useTranslation();
  return useQuery(
    ["billing/quantity"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBillingQuantity();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get billing addon
export function useBillingAddon(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["billing/addons"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBillingAddons();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get billing upgrade amount
export function useBillingUpgradeAmount({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["billing/upgrade/amount", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBillingUpgradeAmount(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get billing renew amount
export function useBillingRenewAmount({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["billing/renew/amount", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBillingRenewAmount(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get billing upgrade quantity
export function useBillingQuantityAmount({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["billing/quantity/amount", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBillingQuantityAmount(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get billing addons amount
export function useBillingAddonsAmount({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["billing/addons/amount", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBillingAddonAmount(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk billing token
export function useBillingToken() {
  return useMutation((payload) => api.getBillingToken(payload));
}

// Query untuk upgrade billing plan
export const useUpgradeBillingPlan = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.upgradeBillingPlan(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk upgrade billing quantity
export const useUpgradeBillingQuantity = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.upgradeBillingQuantity(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk renew billing plan
export const useRenewBillingPlan = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.renewBillingPlan(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk order billing addon
export const useOrderBillingAddon = () => {
  const { t } = useTranslation();
  return useMutation((data) => api.orderBillingAddon(data), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete billing transaction
export const useDeleteBillingTransaction = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteBillingTransaction(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
