import { useQuery, useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import * as api from "apis/prorateRule";

//Query untuk get option list prorate rules
export function useProrateRules(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["prorateRules"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getProrateRules();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail prorate rule
export function useProrateRule(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["prorateRule", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getProrateRule(payload?.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk add prorate rule
export const useAddProrateRule = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.addProrateRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries(["prorateRules"]);
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit prorate rule
export const useEditProrateRule = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.editProrateRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries(["prorateRules"]);
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete prorate rule
export const useDeleteProrateRule = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.deleteProrateRule(payload?.id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries(["prorateRules"]);
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) =>
      message.error(t("error.failed_to_delete_data_from_server")),
  });
};
