import { memo } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { CheckOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import classname from "classname";

const Approve = ({
  id,
  title,
  icon = <CheckOutlined />,
  type = "primary",
  disabled = false,
  btnClassName,
  ...props
}) => {
  const { t } = useTranslation();
  const btnTitle = title ? title : t("button.approve");
  return (
    <Button
      className={classname(
        `border-0 justify-center items-center flex hover:opacity-70 ${btnClassName}`,
        {
          "bg-green1": !disabled,
        }
      )}
      id={id}
      type={type}
      icon={icon}
      disabled={disabled}
      {...props}
    >
      {btnTitle}
    </Button>
  );
};

Approve.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export default memo(Approve);
