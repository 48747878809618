import { useQuery, useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import * as api from "apis/companyPolicy";

//Query untuk get company policies
export function useCompanyPolicies({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["company/policies", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCompanyPolicies(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail company policy
export function useCompanyPolicy({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["company/policy", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCompanyPolicy(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk add company policy
export const useAddCompanyPolicy = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addCompanyPolicy(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit company policy
export const useEditCompanyPolicy = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editCompanyPolicy(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk move company policy
export const useMoveCompanyPolicy = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) =>
      api.moveCompanyPolicy({ id: payload.srcId, dest_id: payload.destId }),
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries([
          "company/policies",
          payload.payloadQuery,
        ]);
        const previousData = queryClient.getQueryCache([
          "company/policies",
          payload.payloadQuery,
        ]);

        queryClient.setQueryData(
          ["company/policies", payload.payloadQuery],
          (old) => {
            if (old && Array.isArray(old)) {
              const data = [...old];
              const srcData = data[payload.dragIndex];
              data.splice(payload.dragIndex, 1);
              data.splice(payload.hoverIndex, 0, srcData);
              old = [...data];
              return old;
            }
          }
        );
        return previousData;
      },
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => message.error(t("error.failed_save_data_to_server")),
    }
  );
};

// Query untuk delete company policy
export const useDeleteCompanyPolicy = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteCompanyPolicy(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteAttachmentCompanyPolicy = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteAttachmentCompanyPolicy(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
