import { useMutation, useQuery } from "react-query";

import { message } from "antd";
import * as api from "apis/dashboard";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useDashboardSelector } from "hooks/useSelector";
import { setDashboard } from "redux/configSlice";
import { dashboardType } from "utils/static";

const attendance = [
  dashboardType.LIST_OF_LATE_EMPLOYEES,
  dashboardType.LIST_OF_EMPLOYEES_LEAVE,
];

//Query untuk get dashboard
export function useDashboards({ payload = {} }, options = {}) {
  // const { t } = useTranslation();
  return useQuery(
    ["dashboard", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getDashboards(payload.id, payload.params);
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) => {
        //message.error(t("error.failed_to_load_data_from_server"));
      },
      ...options,
    }
  );
}

// Query untuk move dashboard
export const useMoveDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const oldData = useDashboardSelector();
  return useMutation(
    (payload) =>
      api.moveDashboard({ id: payload?.srcId, dest_id: payload?.destId }),
    {
      onMutate: async (payload) => {
        if (oldData && Array.isArray(oldData)) {
          const dashboards = [...oldData];
          const srcDashboard = dashboards[payload.dragIndex];
          dashboards.splice(payload.dragIndex, 1);
          dashboards.splice(payload.hoverIndex, 0, srcDashboard);
          dispatch(setDashboard(dashboards));
        }
      },
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => {
        message.error(t("error.failed_save_data_to_server"));
        dispatch(setDashboard(oldData));
      },
    }
  );
};

// Query untuk activate dashboard
export const useActivateDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const oldData = useDashboardSelector();
  return useMutation((payload) => api.activateDashboard(payload), {
    onMutate: async (payload) => {
      if (oldData && Array.isArray(oldData)) {
        const dashboards = oldData.map((value) => {
          if (payload?.id === value?.id) {
            return { ...value, is_active: 1 };
          } else if (
            attendance.includes(payload?.type) &&
            attendance.includes(value?.type)
          ) {
            return { ...value, is_active: 1 };
          } else {
            return value;
          }
        });
        dispatch(setDashboard(dashboards));
      }
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
      dispatch(setDashboard(oldData));
    },
  });
};

// Query untuk deactivate dashboard
export const useDeactivateDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const oldData = useDashboardSelector();
  return useMutation((payload) => api.deactivateDashboard(payload), {
    onMutate: async (payload) => {
      if (oldData && Array.isArray(oldData)) {
        const dashboards = oldData.map((value) => {
          if (payload?.id === value?.id) {
            return { ...value, is_active: 0 };
          } else if (
            attendance.includes(payload?.type) &&
            attendance.includes(value?.type)
          ) {
            return { ...value, is_active: 0 };
          } else {
            return value;
          }
        });
        dispatch(setDashboard(dashboards));
      }
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
      dispatch(setDashboard(oldData));
    },
  });
};
