import axios from "utils/axios";
import { stringify } from "query-string";

export const getSalaryComponents = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/salaryComponents?${query}`);
};

export const getSalaryComponentsPagination = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/salaryComponents/pagination?${query}`);
};

export const getSalaryComponent = (id) => {
  return axios.get(`/hr/salaryComponents/${id}`);
};

export const addSalaryComponent = (data) => {
  return axios.post("/hr/salaryComponents", data);
};

export const editSalaryComponent = (data) => {
  return axios.put(`/hr/salaryComponents/${data.id}`, data);
};

export const deleteSalaryComponent = (id) => {
  return axios.delete(`/hr/salaryComponents/${id}`);
};

export const archiveSalaryComponent = (id) => {
  return axios.patch(`/hr/salaryComponents/${id}/archive`);
};

export const unarchiveSalaryComponent = (id) => {
  return axios.patch(`/hr/salaryComponents/${id}/unarchive`);
};
