import { memo, useCallback } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const Back = ({ id, title, icon, type, onClick, ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const btnTitle = title ? title : t("button.back");

  const backHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Button
      className="bg-orange1 border-none hover:opacity-70"
      id={id}
      type={type}
      icon={icon}
      onClick={onClick || backHandler}
      {...props}
    >
      {btnTitle}
    </Button>
  );
};

Back.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

Back.defaultProps = {
  type: "primary",
  icon: <ArrowLeftOutlined />,
  id: "btn-back",
};

export default memo(Back);
