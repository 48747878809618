import React from "react";
import { createRoot } from "react-dom/client";
import setupSentry from "utils/sentry";
import setupDayjs from "utils/dayjs";

import App from "./App";
// import "antd/dist/";
import "assets/styles/global.css";
import { initializeFp, initializeTagManager } from "utils/gtm";

// Setup sentry
setupSentry();
//Setup extend dayjs
setupDayjs();

if (process.env.NODE_ENV === "production") {
  initializeTagManager();
  initializeFp();
}

const root = createRoot(document.getElementById("root"));
root.render(<App />);
