import { Navigate } from "react-router-dom";
import loadable from "@loadable/component";

import {
  Layout,
  TranslationBreadcrumb,
  EmployeeBreadcrum,
  Loader,
} from "components/UI";
import ProtectedRoute from "components/UI/ProtectedRoute";

const Login = loadable(() => import("pages/user/login"), {
  fallback: <Loader />,
});
const ForgotPassword = loadable(() => import("pages/user/forgot"), {
  fallback: <Loader />,
});
const ResetPassword = loadable(() => import("pages/user/resetPassword"), {
  fallback: <Loader />,
});
const Dashboard = loadable(() => import("pages/dashboard"), {
  fallback: <Loader />,
});
const SelectCompany = loadable(() => import("pages/user/selectCompany"), {
  fallback: <Loader />,
});
const LoginSso = loadable(() => import("pages/user/login/sso"), {
  fallback: <Loader />,
});
const Employee = loadable(() => import("pages/employee"), {
  fallback: <Loader />,
});
const DetailEmployee = loadable(() => import("pages/employee/detail"), {
  fallback: <Loader />,
});
const AddEmployee = loadable(() => import("pages/employee/add"), {
  fallback: <Loader />,
});
const AddEmployeePersonal = loadable(
  () => import("pages/employee/add/AddEmployeePersonal"),
  {
    fallback: <Loader />,
  }
);
const AddEmployeeCareer = loadable(
  () => import("pages/employee/add/AddEmployeeCareer"),
  {
    fallback: <Loader />,
  }
);
const AddEmployeePayroll = loadable(
  () => import("pages/employee/add/AddEmployeePayroll"),
  {
    fallback: <Loader />,
  }
);
const ImportAddEmployee = loadable(
  () => import("pages/employee/import/importAddEmployee"),
  {
    fallback: <Loader />,
  }
);
const ImportUpdateEmployee = loadable(
  () => import("pages/employee/import/importUpdateEmployee"),
  {
    fallback: <Loader />,
  }
);
const Payroll = loadable(() => import("pages/payroll"), {
  fallback: <Loader />,
});
const DetailPayroll = loadable(() => import("pages/payroll/detail"), {
  fallback: <Loader />,
});
const PaymentPayroll = loadable(() => import("pages/payroll/payment"), {
  fallback: <Loader />,
});
const ImportPayroll = loadable(() => import("pages/payroll/import/payroll"), {
  fallback: <Loader />,
});
const ImportManualPayrollComponent = loadable(
  () => import("pages/payroll/import/manualComponent"),
  {
    fallback: <Loader />,
  }
);
const ImportPayrollNote = loadable(
  () => import("pages/payroll/import/payrollNote"),
  {
    fallback: <Loader />,
  }
);
const Schedule = loadable(() => import("pages/schedule"), {
  fallback: <Loader />,
});
const DetailSchedule = loadable(() => import("pages/schedule/detail"), {
  fallback: <Loader />,
});
const EditDetailSchedule = loadable(() =>
  import("pages/schedule/edit/EditScheduleEmployee")
);
const ContentFixedSchedule = loadable(() =>
  import("pages/schedule/content/FixedSchedule")
);
const ContentShiftSchedule = loadable(() =>
  import("pages/schedule/content/ShiftSchedule")
);
const ImportContentShiftSchedule = loadable(
  () => import("pages/schedule/import/shift"),
  {
    fallback: <Loader />,
  }
);
const Attendance = loadable(() => import("pages/attendance"), {
  fallback: <Loader />,
});
const DailyAttendance = loadable(() =>
  import("pages/attendance/content/DailyAttendance")
);
const SummaryAttendance = loadable(() =>
  import("pages/attendance/content/SummaryAttendance")
);
const Leave = loadable(() => import("pages/leave"), {
  fallback: <Loader />,
});
const ContentLeave = loadable(() => import("pages/leave/content"));
const ImportRemainingLeave = loadable(
  () => import("pages/leave/import/remainingLeave"),
  {
    fallback: <Loader />,
  }
);
const AddLeave = loadable(() => import("pages/leave/add"));
const AddEmployeeLeave = loadable(() =>
  import("pages/employee/detail/leave/add")
);
const DetailLeave = loadable(() => import("pages/leave/edit"));
const DetailEmployeeLeave = loadable(() =>
  import("pages/employee/detail/leave/edit")
);
const Timeline = loadable(() => import("pages/timeline"), {
  fallback: <Loader />,
});
const ContentTimeline = loadable(() => import("pages/timeline/content"));
const ContentAnnouncement = loadable(() =>
  import("pages/timeline/content/Announcement")
);
const DetailContentTimeline = loadable(() =>
  import("pages/timeline/detail/TimelineDetail")
);
const DetailContentAnnouncement = loadable(() => import("pages/timeline/edit"));
const ReadDetailAnnouncement = loadable(() =>
  import("pages/timeline/detail/ReadDetail")
);
const AddAnnouncement = loadable(() => import("pages/timeline/add"));

const Accountancy = loadable(() => import("pages/accountancy"), {
  fallback: <Loader />,
});
const AccountancySuccess = loadable(
  () => import("pages/accountancy/SuccessIntegration"),
  {
    fallback: <Loader />,
  }
);
const Approval = loadable(() => import("pages/approval"), {
  fallback: <Loader />,
});
const ContentApproval = loadable(() => import("pages/approval/content"));
const Page404 = loadable(() => import("pages/404"), {
  fallback: <Loader />,
});
const MainDetailPersonal = loadable(() =>
  import("pages/employee/detail/personal")
);
const DetailPersonal = loadable(() =>
  import("pages/employee/detail/personal/personal")
);
const DetailPersonalCareer = loadable(() =>
  import("pages/employee/detail/personal/career")
);
const MainDetailLeaveAndPermission = loadable(() =>
  import("pages/employee/detail/leave")
);
const MainNavigateEmployeeLeave = loadable(() =>
  import("pages/employee/detail/leave/content/MainLeave")
);
const DetailContentLeave = loadable(() =>
  import("pages/employee/detail/leave/content")
);
const MainEmployeePayroll = loadable(() =>
  import("pages/employee/detail/payroll")
);
const DetailEmployeePayroll = loadable(() =>
  import("pages/employee/detail/payroll/detail")
);
const EmployeeAddPayroll = loadable(() =>
  import("pages/employee/detail/payroll/add")
);
const EmployeeEditPayroll = loadable(() =>
  import("pages/employee/detail/payroll/edit")
);
const MainEmployeeSalaryHistory = loadable(() =>
  import("pages/employee/detail/salaryHistory")
);
const EmployeeCareer = loadable(() => import("pages/employee/detail/career"));
const AddCareer = loadable(() => import("pages/employee/detail/career/add"));
const DetailEmployeeCareer = loadable(() =>
  import("pages/employee/detail/career/edit")
);
const TerminateCareer = loadable(() =>
  import("pages/employee/detail/career/terminate")
);
const OnlyEmployeeRole = loadable(() => import("pages/user/onlyEmployeeRole"), {
  fallback: <Loader />,
});
const ListCompany = loadable(() => import("pages/companies"), {
  fallback: <Loader />,
});
const AddCompany = loadable(() => import("pages/companies/add"), {
  fallback: <Loader />,
});
const Report = loadable(() => import("pages/report"), {
  fallback: <Loader />,
});
const Tracking = loadable(() => import("pages/tracking"), {
  fallback: <Loader />,
});
const DetailTracking = loadable(() => import("pages/tracking/detail"), {
  fallback: <Loader />,
});
const AttendanceTracking = loadable(() => import("pages/attendance/tracking"), {
  fallback: <Loader />,
});
const EmployeeTracking = loadable(
  () => import("pages/employee/detail/Tracking"),
  {
    fallback: <Loader />,
  }
);
const ReportTaxPermanentEmployeeReference = loadable(
  () => import("pages/report/taxPermanentEmployeeReference"),
  {
    fallback: <Loader />,
  }
);
const ReportTaxWithholdingAnnualPermanentEmployee = loadable(
  () => import("pages/report/taxWithholdingAnnualPermanentEmployee"),
  {
    fallback: <Loader />,
  }
);
const ReportTaxWithholdingAnnualFreelanceEmployee = loadable(
  () => import("pages/report/taxWithholdingAnnualFreelanceEmployee"),
  {
    fallback: <Loader />,
  }
);
const ReportTaxPermanentEmployeeMonthly = loadable(
  () => import("pages/report/taxPermanentEmployeeMonthly"),
  {
    fallback: <Loader />,
  }
);
const ReportPayrollSummary = loadable(
  () => import("pages/report/payrollSummary"),
  {
    fallback: <Loader />,
  }
);
const ReportPayrollSummaryPerOrganization = loadable(
  () => import("pages/report/payrollSummaryPerOrganization"),
  {
    fallback: <Loader />,
  }
);
const ReportPayrollSummaryPerComponent = loadable(
  () => import("pages/report/payrollSummaryPerComponent"),
  {
    fallback: <Loader />,
  }
);
const ReportPayroll = loadable(() => import("pages/report/payroll"), {
  fallback: <Loader />,
});
const DetailReportPayroll = loadable(
  () => import("pages/report/payroll/detail"),
  {
    fallback: <Loader />,
  }
);
const DetailReportPayrollSummary = loadable(
  () => import("pages/report/payrollSummary/detail"),
  {
    fallback: <Loader />,
  }
);
const DetailReportPayrollSummaryPerOrganization = loadable(
  () => import("pages/report/payrollSummaryPerOrganization/detail"),
  {
    fallback: <Loader />,
  }
);
const DetailReportSummaryPerComponent = loadable(
  () => import("pages/report/payrollSummaryPerComponent/detail"),
  {
    fallback: <Loader />,
  }
);
const ReportBpjsEmployement = loadable(
  () => import("pages/report/bpjsEmployement"),
  {
    fallback: <Loader />,
  }
);
const ReportBpjsHelathcare = loadable(
  () => import("pages/report/bpjsHealthcare"),
  {
    fallback: <Loader />,
  }
);
const Settings = loadable(() => import("pages/settings"), {
  fallback: <Loader />,
});
const Company = loadable(() => import("pages/settings/company"), {
  fallback: <Loader />,
});
const CompanyInfo = loadable(() =>
  import("pages/settings/company/companyInfo")
);
const JobLevel = loadable(() => import("pages/settings/company/jobLevel"));
const DetailJobLevel = loadable(() =>
  import("pages/settings/company/jobLevel/edit")
);
const AddJobLevel = loadable(() =>
  import("pages/settings/company/jobLevel/add")
);
const DeleteJobLevel = loadable(() =>
  import("pages/settings/company/jobLevel/delete")
);
const OrganitationalStructure = loadable(() =>
  import("pages/settings/company/organizationalStructure")
);
const DetailOrganitationalStructure = loadable(() =>
  import("pages/settings/company/organizationalStructure/detail")
);
const AddOrganitationalStructure = loadable(() =>
  import("pages/settings/company/organizationalStructure/add")
);
const EditOrganitationalStructure = loadable(() =>
  import("pages/settings/company/organizationalStructure/edit")
);
const Position = loadable(() => import("pages/settings/company/position"));
const DetailPosition = loadable(() =>
  import("pages/settings/company/position/detail")
);
const AddPosition = loadable(() =>
  import("pages/settings/company/position/add")
);
const EditPosition = loadable(() =>
  import("pages/settings/company/position/edit")
);
const CompanyPolicy = loadable(
  () => import("pages/settings/company/companyPolicy"),
  {
    fallback: <Loader />,
  }
);
const AddCompanyPolicy = loadable(() =>
  import("pages/settings/company/companyPolicy/add")
);
const DetailCompanyPolicy = loadable(() =>
  import("pages/settings/company/companyPolicy/edit")
);
const AnnualLeaveSetting = loadable(
  () => import("pages/settings/annualLeave"),
  {
    fallback: <Loader />,
  }
);
const SpecialLeaveSetting = loadable(
  () => import("pages/settings/specialLeave"),
  {
    fallback: <Loader />,
  }
);
const SickLeaveSetting = loadable(() => import("pages/settings/sickLeave"), {
  fallback: <Loader />,
});
const ScheduleSetting = loadable(() => import("pages/settings/schedule"), {
  fallback: <Loader />,
});
const DetailScheduleSetting = loadable(
  () => import("pages/settings/schedule/detail"),
  {
    fallback: <Loader />,
  }
);
const AddScheduleSetting = loadable(
  () => import("pages/settings/schedule/add"),
  {
    fallback: <Loader />,
  }
);
const EditScheduleSetting = loadable(
  () => import("pages/settings/schedule/edit"),
  {
    fallback: <Loader />,
  }
);
const SelectShift = loadable(() => import("pages/settings/schedule/shift"));
const AddShift = loadable(() => import("pages/settings/schedule/shift/add"));
const EditShift = loadable(() => import("pages/settings/schedule/shift/edit"));
const CalendarSetting = loadable(() => import("pages/settings/calendar"), {
  fallback: <Loader />,
});
const AddCalendarSetting = loadable(() =>
  import("pages/settings/calendar/add")
);
const DetailCalendarSetting = loadable(() =>
  import("pages/settings/calendar/edit")
);
const PayrollSetting = loadable(() => import("pages/settings/payroll"), {
  fallback: <Loader />,
});
const MainPayroll = loadable(() => import("pages/settings/payroll/payroll"));
const AddPayrollSalarySlip = loadable(() =>
  import("pages/settings/payroll/payroll/add")
);
const EditPayrollSalarySlip = loadable(() =>
  import("pages/settings/payroll/payroll/edit")
);
const AddIncomeComponent = loadable(() =>
  import("pages/settings/payroll/payroll/add/AddIncomeComponent")
);
const AddCutComponent = loadable(() =>
  import("pages/settings/payroll/payroll/add/AddCutComponent")
);
const DetailIncomeComponents = loadable(() =>
  import("pages/settings/payroll/payroll/detail/DetailIncomeComponent")
);
const DetailCutComponents = loadable(() =>
  import("pages/settings/payroll/payroll/detail/DetailCutComponent")
);
const EditIncomeComponent = loadable(() =>
  import("pages/settings/payroll/payroll/edit/EditIncomeComponent")
);
const EditCutComponent = loadable(() =>
  import("pages/settings/payroll/payroll/edit/EditCutComponent")
);
const SortPayrollComponents = loadable(() =>
  import("components/apps/SalarySlip/Content/SortPayrollComponent")
);
const Thr = loadable(() => import("pages/settings/payroll/thr"));
const LeaveAllowance = loadable(() =>
  import("pages/settings/payroll/leaveAllowance")
);
const UnpaidLeave = loadable(() =>
  import("pages/settings/payroll/unpaidLeave")
);
const OvertimePay = loadable(() =>
  import("pages/settings/payroll/overtimePay")
);
const ProRate = loadable(() => import("pages/settings/payroll/proRate"), {
  fallback: <Loader />,
});
const TaxPayroll = loadable(() => import("pages/settings/payroll/tax"));
const BpjsPayroll = loadable(() => import("pages/settings/payroll/bpjs"));
const HistoryBpjsEmployement = loadable(() =>
  import("pages/settings/payroll/bpjs/historyBpjsEmployement")
);
const HistoryBpjsHealthcare = loadable(() =>
  import("pages/settings/payroll/bpjs/historyBpjsHealthcare")
);
const CompanyAccount = loadable(() =>
  import("pages/settings/payroll/companyAccount")
);
const ImportMultiplierBpjs = loadable(
  () => import("pages/settings/payroll/bpjs/import/bpjsMultiplier"),
  {
    fallback: <Loader />,
  }
);
const GradingPayroll = loadable(() => import("pages/settings/payroll/grading"));
const ApprovalSetting = loadable(() => import("pages/settings/approval"), {
  fallback: <Loader />,
});
const DetailApprovalSetting = loadable(
  () => import("pages/settings/approval/detail"),
  {
    fallback: <Loader />,
  }
);
const AddApprovalSetting = loadable(
  () => import("pages/settings/approval/add"),
  {
    fallback: <Loader />,
  }
);
const EditApprovalSetting = loadable(
  () => import("pages/settings/approval/edit"),
  {
    fallback: <Loader />,
  }
);
const Profile = loadable(() => import("pages/settings/profile"), {
  fallback: <Loader />,
});
const Roles = loadable(() => import("pages/settings/roles"), {
  fallback: <Loader />,
});
const AddRoles = loadable(() => import("pages/settings/roles/add"), {
  fallback: <Loader />,
});
const EditRoles = loadable(() => import("pages/settings/roles/edit"), {
  fallback: <Loader />,
});
const Users = loadable(() => import("pages/settings/users"), {
  fallback: <Loader />,
});
const BusinessFlow = loadable(() => import("pages/settings/businessFlow"), {
  fallback: <Loader />,
});
const Setup = loadable(() => import("pages/settings/setup"), {
  fallback: <Loader />,
});
const SetupSuccess = loadable(
  () => import("pages/settings/setup/SetupSuccess"),
  {
    fallback: <Loader />,
  }
);
const AccountMapping = loadable(() => import("pages/settings/accountMapping"), {
  fallback: <Loader />,
});
const MobileMenu = loadable(() => import("pages/settings/mobileMenu"), {
  fallback: <Loader />,
});
const AttendanceValidationSetting = loadable(
  () => import("pages/settings/attendanceValidation"),
  {
    fallback: <Loader />,
  }
);
const AttendanceValidationPresenceSetting = loadable(
  () =>
    import(
      "pages/settings/attendanceValidation/content/SettingAttendanceLogPresence"
    ),
  {
    fallback: <Loader />,
  }
);
const AttendanceValidationOvertimeSetting = loadable(
  () =>
    import(
      "pages/settings/attendanceValidation/content/SettingAttendanceLogOvertime"
    ),
  {
    fallback: <Loader />,
  }
);
const ImportAddOvertime = loadable(
  () => import("pages/attendance/import/overtime/importAddOvertime"),
  {
    fallback: <Loader />,
  }
);
const ImportUpdateOvertime = loadable(
  () => import("pages/attendance/import/overtime/importUpdateOvertime"),
  {
    fallback: <Loader />,
  }
);
const AttendanceValidationClientVisitSetting = loadable(
  () =>
    import(
      "pages/settings/attendanceValidation/content/SettingAttendanceLogClientVisit"
    ),
  {
    fallback: <Loader />,
  }
);
const AttendanceLocations = loadable(
  () => import("pages/settings/attendanceLocation"),
  {
    fallback: <Loader />,
  }
);
const AddAttendanceLocations = loadable(
  () => import("pages/settings/attendanceLocation/add"),
  {
    fallback: <Loader />,
  }
);
const EmployeeAttendanceDaily = loadable(() =>
  import("pages/employee/detail/attendanceDaily")
);
const EmployeeAttendanceHistory = loadable(() =>
  import("pages/employee/detail/attendanceHistory")
);
const AttendanceApproval = loadable(() => import("pages/attendanceApproval"), {
  fallback: <Loader />,
});
const ContentAttendanceApproval = loadable(() =>
  import("pages/attendanceApproval/content")
);
const ClientVisit = loadable(() => import("pages/clientVisit"), {
  fallback: <Loader />,
});
const EditAttendanceLocations = loadable(
  () => import("pages/settings/attendanceLocation/edit"),
  {
    fallback: <Loader />,
  }
);
const ImportAttendance = loadable(
  () => import("pages/attendance/import/attendance"),
  {
    fallback: <Loader />,
  }
);
const CashReceipt = loadable(() => import("pages/cashReceipt"), {
  fallback: <Loader />,
});
const ImportCashReceipt = loadable(() => import("pages/cashReceipt/import"), {
  fallback: <Loader />,
});
const ContentCashReceipt = loadable(() => import("pages/cashReceipt/content"));
const AddCashReceipt = loadable(() => import("pages/cashReceipt/add"));
const DetailCashReceipt = loadable(() => import("pages/cashReceipt/detail"));
const EmployeeCashReceipt = loadable(() =>
  import("pages/employee/detail/cashReceipt")
);
const AddEmployeeCashReceipt = loadable(() =>
  import("pages/employee/detail/cashReceipt/add")
);
const DetailEmployeeCashReceipt = loadable(() =>
  import("pages/employee/detail/cashReceipt/detail")
);
const Reimbursement = loadable(() => import("pages/reimburse"), {
  fallback: <Loader />,
});
const EmployeeReimburse = loadable(() =>
  import("pages/employee/detail/reimburse")
);
const DetailReimburse = loadable(() => import("pages/reimburse/detail"));
const AddReimburse = loadable(() => import("pages/reimburse/add"));
const AddReimburseEmployee = loadable(() =>
  import("pages/employee/detail/reimburse/add")
);
const EditReimburse = loadable(() => import("pages/reimburse/edit"));
const Billing = loadable(() => import("pages/settings/billing"), {
  fallback: <Loader />,
});
const UpgradeBilling = loadable(
  () => import("pages/settings/billing/UpgradeBilling"),
  {
    fallback: <Loader />,
  }
);
const AddAddonBilling = loadable(
  () => import("pages/settings/billing/AddAddon"),
  {
    fallback: <Loader />,
  }
);
const UpgradeEmployeeBilling = loadable(
  () => import("pages/settings/billing/UpgradeEmployee"),
  {
    fallback: <Loader />,
  }
);
const SuccessBilling = loadable(
  () => import("pages/settings/billing/Success"),
  { fallback: <Loader /> }
);
const FailedBilling = loadable(() => import("pages/settings/billing/Failed"), {
  fallback: <Loader />,
});
const SettingTemplate = loadable(() => import("pages/settings/template"), {
  fallback: <Loader />,
});
const EmailTemplate = loadable(() =>
  import("pages/settings/template/content/Email")
);

const MobileAppsTemplate = loadable(() =>
  import("pages/settings/template/content/MobileApps")
);
const LayoutSalarySlip = loadable(
  () => import("pages/settings/layoutSalarySlip"),
  {
    fallback: <Loader />,
  }
);
const SettingOpenApi = loadable(() => import("pages/settings/openApi"), {
  fallback: <Loader />,
});
const SettingAddOpenApi = loadable(() => import("pages/settings/openApi/add"), {
  fallback: <Loader />,
});
const SettingDetailOpenApi = loadable(
  () => import("pages/settings/openApi/detail"),
  {
    fallback: <Loader />,
  }
);
const SettingAuditLog = loadable(() => import("pages/settings/auditLog"), {
  fallback: <Loader />,
});
const SettingCashReceipt = loadable(
  () => import("pages/settings/cashReceipt"),
  {
    fallback: <Loader />,
  }
);
const SettingReimbursement = loadable(
  () => import("pages/settings/reimbursement"),
  {
    fallback: <Loader />,
  }
);
const SettingImportPlafonCashReceipt = loadable(
  () => import("pages/settings/cashReceipt/import"),
  {
    fallback: <Loader />,
  }
);
const SettingTracking = loadable(
  () => import("pages/settings/locationTracking"),
  {
    fallback: <Loader />,
  }
);
const SettingOvertime = loadable(() => import("pages/settings/overtime"), {
  fallback: <Loader />,
});
const AddSettingOvertime = loadable(
  () => import("pages/settings/overtime/add"),
  {
    fallback: <Loader />,
  }
);
const DetailSettingOvertime = loadable(
  () => import("pages/settings/overtime/detail"),
  {
    fallback: <Loader />,
  }
);
const EditSettingOvertime = loadable(
  () => import("pages/settings/overtime/edit"),
  {
    fallback: <Loader />,
  }
);
const Numbers = loadable(() => import("pages/settings/numbers"), {
  fallback: <Loader />,
});
const Faq = loadable(() => import("pages/faq"), {
  fallback: <Loader />,
});
const DetailFaq = loadable(() => import("pages/faq/Detail"), {
  fallback: <Loader />,
});
const Invite = loadable(() => import("pages/user/invite"), {
  fallback: <Loader />,
});
const Referral = loadable(() => import("pages/referral"), {
  fallback: <Loader />,
});
const Client = loadable(() => import("pages/client"), {
  fallback: <Loader />,
});
const ImportClient = loadable(() => import("pages/client/import"), {
  fallback: <Loader />,
});
const AddClient = loadable(() => import("pages/client/add"), {
  fallback: <Loader />,
});
const EditClient = loadable(() => import("pages/client/edit"), {
  fallback: <Loader />,
});
const Fingerprint = loadable(() => import("pages/settings/fingerprint"), {
  fallback: <Loader />,
});
const SSO = loadable(() => import("pages/settings/sso"), {
  fallback: <Loader />,
});
const SelectDeviceFingerprint = loadable(
  () => import("pages/settings/fingerprint/selectDevice"),
  {
    fallback: <Loader />,
  }
);
const AddFingerprint = loadable(
  () => import("pages/settings/fingerprint/add"),
  {
    fallback: <Loader />,
  }
);
const EditFingerprint = loadable(
  () => import("pages/settings/fingerprint/edit"),
  {
    fallback: <Loader />,
  }
);
const SynchronizationFingerprint = loadable(
  () => import("pages/settings/fingerprint/synchronization"),
  {
    fallback: <Loader />,
  }
);
const SettingPaymentConnect = loadable(
  () => import("pages/settings/paymentConnect"),
  {
    fallback: <Loader />,
  }
);
const DetailSettingPaymentConnect = loadable(
  () => import("pages/settings/paymentConnect/detail"),
  {
    fallback: <Loader />,
  }
);
const Task = loadable(() => import("pages/task"), {
  fallback: <Loader />,
});
const DetailTask = loadable(() => import("pages/task/detail"), {
  fallback: <Loader />,
});
const AddTask = loadable(() => import("pages/task/add"));
const EditTask = loadable(() => import("pages/task/edit"));
const DuplicateTask = loadable(() => import("pages/task/duplicate"));
const EmployeeTask = loadable(() => import("pages/employee/detail/task"));
const EmployeeDetailTask = loadable(() =>
  import("pages/employee/detail/task/detail")
);
const SettingTask = loadable(() => import("pages/settings/task"), {
  fallback: <Loader />,
});
const SettingPayrollTask = loadable(() =>
  import("pages/settings/task/payroll")
);
const SettingClientVisitTask = loadable(() =>
  import("pages/settings/task/clientVisit")
);

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: (
          <ProtectedRoute name="dashboard" isDefaultPath>
            <Navigate to="/dashboard" replace />
          </ProtectedRoute>
        ),
      },
      {
        path: "/user/login",
        element: <Login />,
      },
      {
        path: "/user/forgot",
        element: <ForgotPassword />,
      },
      {
        path: "/user/reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "/user/select-company",
        element: <SelectCompany />,
      },
      {
        path: "/user/login/sso",
        element: <LoginSso />,
      },
      {
        path: "/dashboard",
        element: (
          <ProtectedRoute name="dashboard">
            <Dashboard />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="dashboard" />,
      },
      {
        path: "/finance",
        element: (
          <ProtectedRoute name="cash_receipt">
            <Navigate to="cash-receipt/balance" replace />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"breadcrumb.finance"} />,
      },
      {
        path: "/presence",
        element: (
          <ProtectedRoute name="presence_approval">
            <Navigate to="presence-approval/waiting-approval" replace />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"breadcrumb.presence"} />
        ),
      },
      {
        path: "/accounting",
        element: (
          <ProtectedRoute isNotEmployee>
            <Accountancy />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="accounting" />,
      },
      {
        path: "/accounting/success",
        element: (
          <ProtectedRoute isNotEmployee>
            <AccountancySuccess />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="accounting" />,
      },
      {
        path: "/employee",
        element: (
          <ProtectedRoute name="employee">
            <Employee />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"employee.title"} />,
      },
      {
        path: "/employee/add",
        element: (
          <ProtectedRoute name="employee_add">
            <AddEmployee />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"employee.add_employee"} />
        ),
        children: [
          {
            path: "/employee/add/personal",
            element: <AddEmployeePersonal />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"employee.personal"} />
            ),
          },
          {
            path: "/employee/add/personal/:id",
            element: <AddEmployeePersonal />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"employee.personal"} />
            ),
          },
          {
            path: "/employee/add/career",
            element: <AddEmployeeCareer />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"employee.staffing"} />
            ),
          },
          {
            path: "/employee/add/career/:id",
            element: <AddEmployeeCareer />,
            breadcrumb: null,
          },
          {
            path: "/employee/add/payroll",
            element: <AddEmployeePayroll />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"employee.payroll"} />
            ),
          },
          {
            path: "/employee/add/payroll/:id",
            element: <AddEmployeePayroll />,
            breadcrumb: null,
          },
        ],
      },
      {
        path: "/employee/import-add-employee",
        element: (
          <ProtectedRoute name="employee_add">
            <ImportAddEmployee />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"employee.import_add_employee"} />
        ),
      },
      {
        path: "/employee/import-update-employee",
        element: (
          <ProtectedRoute name="employee_add">
            <ImportUpdateEmployee />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"employee.import_update_employee"} />
        ),
      },
      {
        path: "/employee/detail/:id",
        element: (
          <ProtectedRoute name="employee">
            <DetailEmployee />
          </ProtectedRoute>
        ),
        breadcrumb: EmployeeBreadcrum,
        children: [
          {
            path: "/employee/detail/:id/personal",
            element: <MainDetailPersonal />,
            breadcrumb: null,
            children: [
              {
                index: true,
                element: <DetailPersonal />,
                breadcrumb: null,
              },
              {
                path: "/employee/detail/:id/personal/career",
                element: <DetailPersonalCareer />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/employee/detail/:id/leaves",
            element: (
              <ProtectedRoute name="leaves">
                <MainDetailLeaveAndPermission />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"breadcrumb.leave_and_permission"} />
            ),
            children: [
              { index: true, element: <MainNavigateEmployeeLeave /> },
              {
                path: "/employee/detail/:id/leaves/annual-leave",
                element: (
                  <DetailContentLeave key="annual-leave" type="annual-leave" />
                ),
                breadcrumb: null,
                children: [
                  {
                    path: "/employee/detail/:id/leaves/annual-leave/add",
                    element: <AddEmployeeLeave />,
                    breadcrumb: null,
                  },
                  {
                    path: "/employee/detail/:id/leaves/annual-leave/detail/:leaveId",
                    element: <DetailEmployeeLeave />,
                    breadcrumb: null,
                  },
                ],
              },
              {
                path: "/employee/detail/:id/leaves/special-leave",
                element: (
                  <DetailContentLeave
                    key="special-leave"
                    type="special-leave"
                  />
                ),
                breadcrumb: null,
                children: [
                  {
                    path: "/employee/detail/:id/leaves/special-leave/add",
                    element: <AddEmployeeLeave />,
                    breadcrumb: null,
                  },
                  {
                    path: "/employee/detail/:id/leaves/special-leave/detail/:leaveId",
                    element: <DetailEmployeeLeave />,
                    breadcrumb: null,
                  },
                ],
              },
              {
                path: "/employee/detail/:id/leaves/sick-leave",
                element: (
                  <DetailContentLeave key="sick-leave" type="sick-leave" />
                ),
                breadcrumb: null,
                children: [
                  {
                    path: "/employee/detail/:id/leaves/sick-leave/add",
                    element: <AddEmployeeLeave />,
                    breadcrumb: null,
                  },
                  {
                    path: "/employee/detail/:id/leaves/sick-leave/detail/:leaveId",
                    element: <DetailEmployeeLeave />,
                    breadcrumb: null,
                  },
                ],
              },
              {
                path: "/employee/detail/:id/leaves/permission-leave",
                element: (
                  <DetailContentLeave
                    key="permission-leave"
                    type="permission-leave"
                  />
                ),
                breadcrumb: null,
                children: [
                  {
                    path: "/employee/detail/:id/leaves/permission-leave/add",
                    element: <AddEmployeeLeave />,
                    breadcrumb: null,
                  },
                  {
                    path: "/employee/detail/:id/leaves/permission-leave/detail/:leaveId",
                    element: <DetailEmployeeLeave />,
                    breadcrumb: null,
                  },
                ],
              },
              {
                path: "/employee/detail/:id/leaves/unpaid-leave",
                element: (
                  <DetailContentLeave key="unpaid-leave" type="unpaid-leave" />
                ),
                breadcrumb: null,
                children: [
                  {
                    path: "/employee/detail/:id/leaves/unpaid-leave/add",
                    element: <AddEmployeeLeave />,
                    breadcrumb: null,
                  },
                  {
                    path: "/employee/detail/:id/leaves/unpaid-leave/detail/:leaveId",
                    element: <DetailEmployeeLeave />,
                    breadcrumb: null,
                  },
                ],
              },
              {
                path: "/employee/detail/:id/leaves/annual-leave/leave-history",
                element: (
                  <DetailContentLeave
                    key="leave-history"
                    type="leave-history"
                  />
                ),
                breadcrumb: null,
                children: [
                  {
                    path: "/employee/detail/:id/leaves/annual-leave/leave-history/detail/:leaveId",
                    element: <DetailEmployeeLeave />,
                    breadcrumb: null,
                  },
                ],
              },
            ],
          },
          {
            path: "/employee/detail/:id/attendance-daily",
            element: (
              <ProtectedRoute name="presence">
                <EmployeeAttendanceDaily />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"employee.title_attendance_daily"} />
            ),
          },
          {
            path: "/employee/detail/:id/attendance-history",
            element: (
              <ProtectedRoute name="presence">
                <EmployeeAttendanceHistory />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"employee.attendance_history"} />
            ),
          },
          {
            path: "/employee/detail/:id/payroll",
            element: (
              <ProtectedRoute name="payroll_payment">
                <MainEmployeePayroll />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"payroll.payroll"} />
            ),
          },
          {
            path: "/employee/detail/:id/payroll/detail-payroll/:idPayroll",
            element: (
              <ProtectedRoute name="payroll_payment">
                <DetailEmployeePayroll />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"payroll.payroll_history"} />
            ),
          },
          {
            path: "/employee/detail/:id/payroll/add",
            element: (
              <ProtectedRoute name="payroll_payment_add">
                <EmployeeAddPayroll />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"payroll.add_payroll"} />
            ),
          },
          {
            path: "/employee/detail/:id/payroll/add/:idPayroll",
            element: (
              <ProtectedRoute name="payroll_payment_add">
                <EmployeeAddPayroll />
              </ProtectedRoute>
            ),
            breadcrumb: null,
          },
          {
            path: "/employee/detail/:id/payroll/edit/:idPayroll",
            element: (
              <ProtectedRoute name="payroll_payment_edit">
                <EmployeeEditPayroll />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"payroll.edit_payroll"} />
            ),
          },
          {
            path: "/employee/detail/:id/salary-history",
            element: (
              <ProtectedRoute name="payroll_payment">
                <MainEmployeeSalaryHistory />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"payroll.salary_history"} />
            ),
          },
          {
            path: "/employee/detail/:id/career",
            element: <EmployeeCareer />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"employee.career"} />
            ),
            children: [
              {
                path: "/employee/detail/:id/career/add",
                element: <AddCareer />,
                breadcrumb: null,
              },
              {
                path: "/employee/detail/:id/career/detail/:idCareer",
                element: <DetailEmployeeCareer />,
                breadcrumb: null,
              },
              {
                path: "/employee/detail/:id/career/terminate/:idCareer",
                element: <TerminateCareer />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/employee/detail/:id/finance",
            element: (
              <ProtectedRoute name="cash_receipt">
                <Navigate to="cash-receipt" replace />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"breadcrumb.finance"} />
            ),
          },
          {
            path: "/employee/detail/:id/finance/cash-receipt",
            element: (
              <ProtectedRoute name="cash_receipt">
                <EmployeeCashReceipt />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"cashReceipt.title"} />
            ),
            children: [
              {
                path: "/employee/detail/:id/finance/cash-receipt/add",
                element: <AddEmployeeCashReceipt />,
                breadcrumb: null,
              },
              {
                path: "/employee/detail/:id/finance/cash-receipt/detail/:cashReceiptId",
                element: <DetailEmployeeCashReceipt />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/employee/detail/:id/finance/reimbursement",
            element: (
              <ProtectedRoute name="reimbursement">
                <EmployeeReimburse />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"reimbursement.title"} />
            ),
            children: [
              {
                path: "/employee/detail/:id/finance/reimbursement/add",
                element: <AddReimburseEmployee />,
                breadcrumb: null,
              },
              {
                path: "/employee/detail/:id/finance/reimbursement/detail/:reimbursementId",
                element: <DetailReimburse />,
                breadcrumb: null,
              },
              {
                path: "/employee/detail/:id/finance/reimbursement/edit/:reimbursementId",
                element: <EditReimburse />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/employee/detail/:id/attendance-daily/tracking",
            element: (
              <ProtectedRoute name="location_tracking">
                <EmployeeTracking />
              </ProtectedRoute>
            ),
            breadcrumb: () => <TranslationBreadcrumb name={"tracking.title"} />,
          },
          {
            path: "/employee/detail/:id/task",
            element: <EmployeeTask />,
            breadcrumb: () => <TranslationBreadcrumb name={"task.title"} />,
            children: [
              {
                path: "/employee/detail/:id/task/add",
                element: <AddTask />,
                breadcrumb: () => (
                  <TranslationBreadcrumb name="task.add_task" />
                ),
              },
            ],
          },
          {
            path: "/employee/detail/:id/task/detail/:idTask",
            element: <EmployeeDetailTask />,
            breadcrumb: () => <TranslationBreadcrumb name="task.detail" />,
            children: [
              {
                path: "/employee/detail/:id/task/detail/:idTask/duplicate",
                element: <DuplicateTask />,
                breadcrumb: () => (
                  <TranslationBreadcrumb name="task.duplicate_task" />
                ),
              },
              {
                path: "/employee/detail/:id/task/detail/:idTask/edit",
                element: <EditTask />,
                breadcrumb: () => (
                  <TranslationBreadcrumb name="task.edit_task" />
                ),
              },
            ],
          },
        ],
      },
      {
        path: "/presence/work-schedule",
        element: (
          <ProtectedRoute name="work_schedule">
            <Schedule />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"schedule.work_schedule"} />
        ),
        children: [
          {
            path: "/presence/work-schedule/fixed-schedule",
            element: <ContentFixedSchedule />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"schedule.fixed_schedule"} />
            ),
          },
          {
            path: "/presence/work-schedule/shift-schedule",
            element: <ContentShiftSchedule />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"schedule.shift_schedule"} />
            ),
            children: [
              {
                path: "/presence/work-schedule/shift-schedule/add-shift",
                element: <AddShift />,
                breadcrumb: null,
              },
              {
                path: "/presence/work-schedule/shift-schedule/shift/:idShift",
                element: <EditShift />,
                breadcrumb: null,
              },
            ],
          },
        ],
      },
      {
        path: "/presence/work-schedule/shift-schedule/import",
        element: (
          <ProtectedRoute name="work_schedule_add">
            <ImportContentShiftSchedule />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"schedule.import_shift_schedule"} />
        ),
      },
      {
        path: "/presence/work-schedule/detail/:id",
        element: (
          <ProtectedRoute name="work_schedule">
            <DetailSchedule />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"schedule.detail"} />,
        children: [
          {
            path: "/presence/work-schedule/detail/:id/schedule/:idSchedule",
            element: <EditDetailSchedule />,
            breadcrumb: null,
          },
        ],
      },
      {
        path: "/presence/client-visit",
        element: (
          <ProtectedRoute name="client_visit">
            <ClientVisit />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"clientVisit.title"} />,
      },
      {
        path: "/presence/attendance",
        element: (
          <ProtectedRoute name="presence">
            <Attendance />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"attendance.attendance_data"} />
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/presence/attendance/daily" replace />,
          },
          {
            path: "/presence/attendance/daily",
            element: <DailyAttendance />,
            breadcrumb: null,
          },
          {
            path: "/presence/attendance/summary",
            element: <SummaryAttendance />,
            breadcrumb: null,
          },
        ],
      },
      {
        path: "/presence/attendance/daily/import",
        element: (
          <ProtectedRoute name="presence">
            <ImportAttendance />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="attendance.import_attendance" />
        ),
      },
      {
        path: "/presence/attendance/daily/import-add-overtime",
        element: (
          <ProtectedRoute name="presence_overtime_add">
            <ImportAddOvertime />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="attendance.import_add_overtime" />
        ),
      },
      {
        path: "/presence/attendance/daily/import-update-overtime",
        element: (
          <ProtectedRoute name="presence_overtime_edit">
            <ImportUpdateOvertime />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="attendance.import_update_overtime" />
        ),
      },
      {
        path: "/presence/attendance/daily/tracking/:id",
        element: (
          <ProtectedRoute name="location_tracking">
            <AttendanceTracking />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"tracking.title"} />,
      },
      {
        path: "/presence/leaves",
        element: (
          <ProtectedRoute name="leaves">
            <Leave />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"employee.permission_and_leave"} />
        ),
        children: [
          {
            path: "/presence/leaves/annual-leave",
            element: <ContentLeave key="annual-leave" type="annual-leave" />,
            breadcrumb: null,
            children: [
              {
                path: "/presence/leaves/annual-leave/add",
                element: <AddLeave />,
                breadcrumb: null,
              },
              {
                path: "/presence/leaves/annual-leave/detail/:leaveId",
                element: <DetailLeave />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/presence/leaves/special-leave",
            element: <ContentLeave key="special-leave" type="special-leave" />,
            breadcrumb: null,
            children: [
              {
                path: "/presence/leaves/special-leave/add",
                element: <AddLeave />,
                breadcrumb: null,
              },
              {
                path: "/presence/leaves/special-leave/detail/:leaveId",
                element: <DetailLeave />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/presence/leaves/sick-leave",
            element: <ContentLeave key="sick-leave" type="sick-leave" />,
            breadcrumb: null,
            children: [
              {
                path: "/presence/leaves/sick-leave/add",
                element: <AddLeave />,
                breadcrumb: null,
              },
              {
                path: "/presence/leaves/sick-leave/detail/:leaveId",
                element: <DetailLeave />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/presence/leaves/permission-leave",
            element: (
              <ContentLeave key="permission-leave" type="permission-leave" />
            ),
            breadcrumb: null,
            children: [
              {
                path: "/presence/leaves/permission-leave/add",
                element: <AddLeave />,
                breadcrumb: null,
              },
              {
                path: "/presence/leaves/permission-leave/detail/:leaveId",
                element: <DetailLeave />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/presence/leaves/unpaid-leave",
            element: <ContentLeave key="unpaid-leave" type="unpaid-leave" />,
            breadcrumb: null,
            children: [
              {
                path: "/presence/leaves/unpaid-leave/add",
                element: <AddLeave />,
                breadcrumb: null,
              },
              {
                path: "/presence/leaves/unpaid-leave/detail/:leaveId",
                element: <DetailLeave />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/presence/leaves/annual-leave/remaining-leave",
            element: (
              <ContentLeave key="remaining-leave" type="remaining-leave" />
            ),
            breadcrumb: null,
            children: [
              {
                path: "/presence/leaves/annual-leave/remaining-leave/add",
                element: <AddLeave />,
                breadcrumb: null,
              },
            ],
          },
        ],
      },
      {
        path: "/presence/leaves/annual-leave/remaining-leave/import",
        element: (
          <ProtectedRoute name="leaves_add">
            <ImportRemainingLeave />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="leave.import_remaining_annual_leave" />
        ),
      },
      {
        path: "/presence/presence-approval",
        element: (
          <ProtectedRoute name="presence_approval">
            <AttendanceApproval />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"attendanceApproval.title"} />
        ),
        children: [
          {
            path: "/presence/presence-approval/waiting-approval",
            element: <ContentAttendanceApproval type="waiting-approval" />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"approval.waiting_approval"} />
            ),
          },
          {
            path: "/presence/presence-approval/approved",
            element: <ContentAttendanceApproval type="approved" />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"approval.approved"} />
            ),
          },
          {
            path: "/presence/presence-approval/rejected",
            element: <ContentAttendanceApproval type="rejected" />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"approval.rejected"} />
            ),
          },
        ],
      },
      {
        path: "/payroll",
        element: (
          <ProtectedRoute name="payroll_payment">
            <Payroll />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"payroll.payroll"} />,
      },
      {
        path: "/payroll/detail/:id",
        element: (
          <ProtectedRoute name="payroll_payment">
            <DetailPayroll />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"payroll.detail"} />,
      },
      {
        path: "/payroll/detail/:id/payment",
        element: (
          <ProtectedRoute name="payroll_payment">
            <PaymentPayroll />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"payroll.payment"} />,
      },
      {
        path: "/payroll/import",
        element: (
          <ProtectedRoute name="payroll_payment_add">
            <ImportPayroll />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"payroll.import"} />,
      },
      {
        path: "/payroll/manual-component-import/:id",
        element: (
          <ProtectedRoute name="payroll_payment_add">
            <ImportManualPayrollComponent />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb
            name={"payroll.import_manual_payroll_component"}
          />
        ),
      },
      {
        path: "/payroll/payroll-note-import/:id",
        element: (
          <ProtectedRoute name="payroll_payment_add">
            <ImportPayrollNote />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"payroll.import_payroll_notes"} />
        ),
      },
      {
        path: "/timeline",
        element: (
          <ProtectedRoute name="timeline">
            <Timeline />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"timeline.title"} />,
        children: [
          {
            index: true,
            element: <Navigate to="/timeline/main" replace />,
          },
          {
            path: "/timeline/main",
            element: <ContentTimeline />,
            breadcrumb: null,
            children: [
              {
                path: "/timeline/main/:id",
                element: <DetailContentTimeline />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/timeline/announcement",
            element: <ContentAnnouncement />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"timeline.announcement"} />
            ),
            children: [
              {
                path: "/timeline/announcement/:id",
                element: <DetailContentAnnouncement />,
                breadcrumb: null,
              },
              {
                path: "/timeline/announcement/read/:id",
                element: <ReadDetailAnnouncement />,
                breadcrumb: null,
              },
              {
                path: "/timeline/announcement/add",
                element: <AddAnnouncement />,
                breadcrumb: null,
              },
            ],
          },
        ],
      },
      {
        path: "/report",
        element: (
          <ProtectedRoute name="report">
            <Report />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"report.report_title"} />
        ),
      },
      {
        path: "/report/permanent-employee-tax-data-reference",
        element: (
          <ProtectedRoute name="report_tax">
            <ReportTaxPermanentEmployeeReference />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb
            name={"report.permanent_employee_tax_data_reference"}
          />
        ),
      },
      {
        path: "/report/proof-of-withholding-annual-permanent-employee-tax",
        element: (
          <ProtectedRoute name="report_tax">
            <ReportTaxWithholdingAnnualPermanentEmployee />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb
            name={"report.proof_of_withholding_annual_permanent_employee_tax"}
          />
        ),
      },
      {
        path: "/report/proof-of-withholding-annual-freelance-employee-tax",
        element: (
          <ProtectedRoute name="report_tax">
            <ReportTaxWithholdingAnnualFreelanceEmployee />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb
            name={"report.proof_of_withholding_annual_freelance_employee_tax"}
          />
        ),
      },
      {
        path: "/report/permanent-employee-monthly-tax",
        element: (
          <ProtectedRoute name="report_tax">
            <ReportTaxPermanentEmployeeMonthly />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb
            name={"report.permanent_employee_monthly_tax"}
          />
        ),
      },
      {
        path: "/report/payroll-summary",
        element: (
          <ProtectedRoute name="report_payroll">
            <ReportPayrollSummary />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"report.payroll_summary"} />
        ),
      },
      {
        path: "/report/payroll-summary/detail/:id",
        element: (
          <ProtectedRoute name="report_payroll">
            <DetailReportPayrollSummary />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"breadcrumb.detail"} />,
      },
      {
        path: "/report/payroll-summary-per-organization",
        element: (
          <ProtectedRoute name="report_payroll">
            <ReportPayrollSummaryPerOrganization />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb
            name={"report.payroll_summary_per_organization"}
          />
        ),
      },
      {
        path: "/report/payroll-summary-per-organization/detail/:id",
        element: (
          <ProtectedRoute name="report_payroll">
            <DetailReportPayrollSummaryPerOrganization />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"breadcrumb.detail"} />,
      },
      {
        path: "/report/payroll-summary-per-component",
        element: (
          <ProtectedRoute name="report_payroll">
            <ReportPayrollSummaryPerComponent />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb
            name={"report.payroll_summary_per_component"}
          />
        ),
      },
      {
        path: "/report/payroll-summary-per-component/detail/:id",
        element: (
          <ProtectedRoute name="report_payroll">
            <DetailReportSummaryPerComponent />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"breadcrumb.detail"} />,
      },
      {
        path: "/report/payroll",
        element: (
          <ProtectedRoute name="report_payroll">
            <ReportPayroll />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"reportPayroll.salary_payment"} />
        ),
      },
      {
        path: "/report/payroll/detail/:id",
        element: (
          <ProtectedRoute name="report_payroll">
            <DetailReportPayroll />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"breadcrumb.detail"} />,
      },
      {
        path: "/report/bpjs-employement",
        element: (
          <ProtectedRoute name="report_bpjs">
            <ReportBpjsEmployement />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"bpjs.bpjs_of_employement"} />
        ),
      },
      {
        path: "/report/bpjs-healthcare",
        element: (
          <ProtectedRoute name="report_bpjs">
            <ReportBpjsHelathcare />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"bpjs.bpjs_healthcare"} />
        ),
      },
      {
        path: "/approval",
        element: (
          <ProtectedRoute name="approval">
            <Approval />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"approval.approval_title"} />
        ),
        children: [
          {
            path: "/approval/waiting-approval",
            element: <ContentApproval type="waiting-approval" />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"approval.waiting_approval"} />
            ),
            children: [
              {
                path: "/approval/waiting-approval/leave/detail/:leaveId",
                element: <DetailLeave />,
                breadcrumb: null,
              },
              {
                path: "/approval/waiting-approval/reimbursement/edit/:reimbursementId",
                element: <EditReimburse />,
                breadcrumb: null,
              },
              {
                path: "/approval/waiting-approval/reimbursement/detail/:reimbursementId",
                element: <DetailReimburse />,
                breadcrumb: null,
              },
              {
                path: "/approval/waiting-approval/cash-receipt/detail/:cashReceiptId",
                element: <DetailCashReceipt />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/approval/approved",
            element: <ContentApproval type="approved" />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"approval.approved"} />
            ),
            children: [
              {
                path: "/approval/approved/leave/detail/:leaveId",
                element: <DetailLeave />,
                breadcrumb: null,
              },
              {
                path: "/approval/approved/reimbursement/edit/:reimbursementId",
                element: <EditReimburse />,
                breadcrumb: null,
              },
              {
                path: "/approval/approved/reimbursement/detail/:reimbursementId",
                element: <DetailReimburse />,
                breadcrumb: null,
              },
              {
                path: "/approval/approved/cash-receipt/detail/:cashReceiptId",
                element: <DetailCashReceipt />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/approval/rejected",
            element: <ContentApproval type="rejected" />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"approval.rejected"} />
            ),
            children: [
              {
                path: "/approval/rejected/leave/detail/:leaveId",
                element: <DetailLeave />,
                breadcrumb: null,
              },
              {
                path: "/approval/rejected/reimbursement/edit/:reimbursementId",
                element: <EditReimburse />,
                breadcrumb: null,
              },
              {
                path: "/approval/rejected/reimbursement/detail/:reimbursementId",
                element: <DetailReimburse />,
                breadcrumb: null,
              },
              {
                path: "/approval/rejected/cash-receipt/detail/:cashReceiptId",
                element: <DetailCashReceipt />,
                breadcrumb: null,
              },
            ],
          },
        ],
      },
      {
        path: "/finance/cash-receipt",
        element: (
          <ProtectedRoute name="cash_receipt">
            <CashReceipt />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="cashReceipt.title" />,
        children: [
          {
            path: "/finance/cash-receipt/balance",
            element: <ContentCashReceipt />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"cashReceipt.balance"} />
            ),
            children: [
              {
                path: "/finance/cash-receipt/balance/add",
                element: <AddCashReceipt />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/finance/cash-receipt/waiting-approval",
            element: <ContentCashReceipt />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"approval.waiting_approval"} />
            ),
            children: [
              {
                path: "/finance/cash-receipt/waiting-approval/add",
                element: <AddCashReceipt />,
                breadcrumb: null,
              },
              {
                path: "/finance/cash-receipt/waiting-approval/detail/:cashReceiptId",
                element: <DetailCashReceipt />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/finance/cash-receipt/approved",
            element: <ContentCashReceipt />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"approval.approved"} />
            ),
            children: [
              {
                path: "/finance/cash-receipt/approved/add",
                element: <AddCashReceipt />,
                breadcrumb: null,
              },
              {
                path: "/finance/cash-receipt/approved/detail/:cashReceiptId",
                element: <DetailCashReceipt />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/finance/cash-receipt/rejected",
            element: <ContentCashReceipt />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"approval.rejected"} />
            ),
            children: [
              {
                path: "/finance/cash-receipt/rejected/add",
                element: <AddCashReceipt />,
                breadcrumb: null,
              },
              {
                path: "/finance/cash-receipt/rejected/detail/:cashReceiptId",
                element: <DetailCashReceipt />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/finance/cash-receipt/payment",
            element: <ContentCashReceipt />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"cashReceipt.payment"} />
            ),
            children: [
              {
                path: "/finance/cash-receipt/payment/add",
                element: <AddCashReceipt />,
                breadcrumb: null,
              },
              {
                path: "/finance/cash-receipt/payment/detail/:cashReceiptId",
                element: <DetailCashReceipt />,
                breadcrumb: null,
              },
            ],
          },
        ],
      },
      {
        path: "/finance/cash-receipt/import",
        element: (
          <ProtectedRoute name="cash_receipt_add">
            <ImportCashReceipt />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="cashReceipt.import_cash_receipt" />
        ),
      },
      {
        path: "/finance/reimbursement",
        element: (
          <ProtectedRoute name="reimbursement">
            <Reimbursement />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"reimbursement.title"} />
        ),
        children: [
          {
            path: "/finance/reimbursement/add",
            element: <AddReimburse />,
            breadcrumb: null,
          },
          {
            path: "/finance/reimbursement/detail/:reimbursementId",
            element: <DetailReimburse />,
            breadcrumb: null,
          },
          {
            path: "/finance/reimbursement/edit/:reimbursementId",
            element: <EditReimburse />,
            breadcrumb: null,
          },
        ],
      },
      {
        path: "/companies",
        element: (
          <ProtectedRoute isNotEmployee>
            <ListCompany />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="company" />,
      },
      {
        path: "/companies/add",
        element: (
          <ProtectedRoute isNotEmployee>
            <AddCompany />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="company" />,
      },
      {
        path: "/only-employee-role",
        element: <OnlyEmployeeRole />,
        breadcrumb: null,
      },
      {
        path: "/location-tracking",
        element: (
          <ProtectedRoute name="location_tracking">
            <Tracking />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="tracking.title" />,
      },
      {
        path: "/location-tracking/detail/:id",
        element: (
          <ProtectedRoute name="location_tracking">
            <DetailTracking />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="tracking.detail_tracking" />
        ),
      },
      {
        path: "/settings",
        element: (
          <ProtectedRoute name="setting">
            <Settings />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"breadcrumb.settings"} />
        ),
      },
      {
        path: "/settings/annual-leave",
        element: (
          <ProtectedRoute name="setting_annual_leave">
            <AnnualLeaveSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="annual_leave" />,
      },
      {
        path: "/settings/special-leave",
        element: (
          <ProtectedRoute name="setting_special_leave">
            <SpecialLeaveSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="special_leave" />,
      },
      {
        path: "/settings/sick-leave",
        element: (
          <ProtectedRoute name="setting_sick_leave">
            <SickLeaveSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="leave.sick_leave" />,
      },
      {
        path: "/settings/schedule",
        element: (
          <ProtectedRoute name="setting_schedule">
            <ScheduleSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="schedule" />,
      },
      {
        path: "/settings/schedule/detail/:id",
        element: (
          <ProtectedRoute name="setting_schedule">
            <DetailScheduleSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"schedule.detail_schedule"} />
        ),
      },
      {
        path: "/settings/schedule/add",
        element: (
          <ProtectedRoute name="setting_schedule_add">
            <AddScheduleSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"schedule.add_schedule"} />
        ),
        children: [
          {
            path: "/settings/schedule/add/select",
            element: <SelectShift />,
            breadcrumb: null,
            children: [
              {
                path: "/settings/schedule/add/select/add-shift",
                element: <AddShift />,
                breadcrumb: null,
              },
              {
                path: "/settings/schedule/add/select/shift/:idShift",
                element: <EditShift />,
                breadcrumb: null,
              },
            ],
          },
        ],
      },
      {
        path: "/settings/schedule/edit/:id",
        element: (
          <ProtectedRoute name="setting_schedule_edit">
            <EditScheduleSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"schedule.edit_schedule"} />
        ),
        children: [
          {
            path: "/settings/schedule/edit/:id/select",
            element: <SelectShift />,
            breadcrumb: null,
            children: [
              {
                path: "/settings/schedule/edit/:id/select/add-shift",
                element: <AddShift />,
                breadcrumb: null,
              },
              {
                path: "/settings/schedule/edit/:id/select/shift/:idShift",
                element: <EditShift />,
                breadcrumb: null,
              },
            ],
          },
        ],
      },
      {
        path: "/settings/approval-rule",
        element: (
          <ProtectedRoute name="setting_approval_rule">
            <ApprovalSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="approval_rule" />,
      },
      {
        path: "/settings/approval-rule/detail/:id",
        element: (
          <ProtectedRoute name="setting_approval_rule">
            <DetailApprovalSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="approval.detail" />,
      },
      {
        path: "/settings/approval-rule/add",
        element: (
          <ProtectedRoute name="setting_approval_rule_add">
            <AddApprovalSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"approval.add_rule_approval"} />
        ),
      },
      {
        path: "/settings/approval-rule/edit/:id",
        element: (
          <ProtectedRoute name="setting_approval_rule_edit">
            <EditApprovalSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name={"approval.edit_approval_title"} />
        ),
      },
      {
        path: "/settings/company",
        element: (
          <ProtectedRoute name="setting_company">
            <Company />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="company" />,
        children: [
          {
            index: true,
            element: <Navigate to="/settings/company/company-info" replace />,
          },
          {
            path: "/settings/company/company-info",
            element: (
              <ProtectedRoute name="setting_company_info">
                <CompanyInfo />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"company.company_info"} />
            ),
          },
          {
            path: "/settings/company/organitation-structure",
            element: (
              <ProtectedRoute name="setting_company_org_structure">
                <OrganitationalStructure />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb
                name={"company.organizational_structure"}
              />
            ),
            children: [
              {
                path: "/settings/company/organitation-structure/detail/:id",
                element: <DetailOrganitationalStructure />,
                breadcrumb: null,
              },
              {
                path: "/settings/company/organitation-structure/add",
                element: <AddOrganitationalStructure />,
                breadcrumb: null,
              },
              {
                path: "/settings/company/organitation-structure/edit/:id",
                element: <EditOrganitationalStructure />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/settings/company/job-title",
            element: (
              <ProtectedRoute name="setting_company_job_level">
                <JobLevel />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"company.job_title"} />
            ),
            children: [
              {
                path: "/settings/company/job-title/add",
                element: <AddJobLevel />,
                breadcrumb: null,
              },
              {
                path: "/settings/company/job-title/delete",
                element: <DeleteJobLevel />,
                breadcrumb: null,
              },
              {
                path: "/settings/company/job-title/detail/:id",
                element: <DetailJobLevel />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/settings/company/position",
            element: (
              <ProtectedRoute name="setting_company_job_position">
                <Position />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"company.position"} />
            ),
            children: [
              {
                path: "/settings/company/position/detail/:id",
                element: <DetailPosition />,
                breadcrumb: null,
              },
              {
                path: "/settings/company/position/add",
                element: <AddPosition />,
                breadcrumb: null,
              },
              {
                path: "/settings/company/position/edit/:id",
                element: <EditPosition />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/settings/company/company-policy",
            element: (
              <ProtectedRoute name="setting_company_policy">
                <CompanyPolicy />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name={"company.company_policy"} />
            ),
            children: [
              {
                path: "/settings/company/company-policy/add",
                element: <AddCompanyPolicy />,
                breadcrumb: null,
              },
              {
                path: "/settings/company/company-policy/detail/:id",
                element: <DetailCompanyPolicy />,
                breadcrumb: null,
              },
            ],
          },
        ],
      },
      {
        path: "/settings/calendar",
        element: (
          <ProtectedRoute name="setting_calendar">
            <CalendarSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="calendar" />,
        children: [
          {
            path: "/settings/calendar/add",
            element: <AddCalendarSetting />,
            breadcrumb: null,
          },
          {
            path: "/settings/calendar/detail/:id",
            element: <DetailCalendarSetting />,
            breadcrumb: null,
          },
        ],
      },
      {
        path: "/settings/payroll",
        element: (
          <ProtectedRoute name="setting_payroll">
            <PayrollSetting />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="payroll" />,
        children: [
          {
            path: "/settings/payroll/salary-slip",
            element: <MainPayroll />,
            breadcrumb: () => (
              <TranslationBreadcrumb name="payroll.salary_slip" />
            ),
          },
          {
            path: "/settings/payroll/salary-slip/add",
            element: <AddPayrollSalarySlip />,
            breadcrumb: () => (
              <TranslationBreadcrumb name="payroll.add_salary_slip" />
            ),
            children: [
              {
                path: "/settings/payroll/salary-slip/add/add-income",
                element: <AddIncomeComponent />,
                breadcrumb: null,
              },
              {
                path: "/settings/payroll/salary-slip/add/add-cut",
                element: <AddCutComponent />,
                breadcrumb: null,
              },
              {
                path: "/settings/payroll/salary-slip/add/income/:idComponent",
                element: <DetailIncomeComponents />,
                breadcrumb: null,
              },
              {
                path: "/settings/payroll/salary-slip/add/income/:idComponent/edit-component",
                element: <EditIncomeComponent />,
                breadcrumb: null,
              },
              {
                path: "/settings/payroll/salary-slip/add/cut/:idComponent",
                element: <DetailCutComponents />,
                breadcrumb: null,
              },
              {
                path: "/settings/payroll/salary-slip/add/cut/:idComponent/edit-component",
                element: <EditCutComponent />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/settings/payroll/salary-slip/edit/:id",
            element: <EditPayrollSalarySlip />,
            breadcrumb: () => (
              <TranslationBreadcrumb name="payroll.edit_salary_slip" />
            ),
            children: [
              {
                path: "/settings/payroll/salary-slip/edit/:id/add-income",
                element: <AddIncomeComponent />,
                breadcrumb: null,
              },
              {
                path: "/settings/payroll/salary-slip/edit/:id/add-cut",
                element: <AddCutComponent />,
                breadcrumb: null,
              },
              {
                path: "/settings/payroll/salary-slip/edit/:id/income/:idComponent",
                element: <DetailIncomeComponents />,
                breadcrumb: null,
              },
              {
                path: "/settings/payroll/salary-slip/edit/:id/income/:idComponent/edit-component",
                element: <EditIncomeComponent />,
                breadcrumb: null,
              },
              {
                path: "/settings/payroll/salary-slip/edit/:id/cut/:idComponent",
                element: <DetailCutComponents />,
                breadcrumb: null,
              },
              {
                path: "/settings/payroll/salary-slip/edit/:id/cut/:idComponent/edit-component",
                element: <EditCutComponent />,
                breadcrumb: null,
              },
              {
                path: "/settings/payroll/salary-slip/edit/:id/sort",
                element: <SortPayrollComponents />,
                breadcrumb: null,
              },
            ],
          },
          {
            path: "/settings/payroll/thr",
            element: <Thr />,
            breadcrumb: () => <TranslationBreadcrumb name={"thr.title"} />,
          },
          {
            path: "/settings/payroll/leaveAllowance",
            element: <LeaveAllowance />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"leaveAllowance.title"} />
            ),
          },
          {
            path: "/settings/payroll/unpaidLeave",
            element: <UnpaidLeave />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"unpaidLeave.title"} />
            ),
          },
          {
            path: "/settings/payroll/overtimePay",
            element: <OvertimePay />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"overtimePay.title"} />
            ),
          },
          {
            path: "/settings/payroll/proRate",
            element: <ProRate />,
            breadcrumb: () => <TranslationBreadcrumb name={"proRate.title"} />,
          },
          {
            path: "/settings/payroll/tax",
            element: <TaxPayroll />,
            breadcrumb: () => <TranslationBreadcrumb name={"tax.title"} />,
          },
          {
            path: "/settings/payroll/bpjs",
            element: <BpjsPayroll />,
            breadcrumb: () => <TranslationBreadcrumb name={"bpjs.title"} />,
          },
          {
            path: "/settings/payroll/bpjs/history-bpjs-employement",
            element: <HistoryBpjsEmployement />,
            breadcrumb: () => (
              <TranslationBreadcrumb
                name={"bpjs.history_bpjs_of_employement"}
              />
            ),
          },
          {
            path: "/settings/payroll/bpjs/history-bpjs-healthcare",
            element: <HistoryBpjsHealthcare />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"bpjs.history_bpjs_healthcare"} />
            ),
          },
          {
            path: "/settings/payroll/companyAccount",
            element: <CompanyAccount />,
            breadcrumb: () => (
              <TranslationBreadcrumb name={"companyAccount.title"} />
            ),
          },
          {
            path: "/settings/payroll/grading",
            element: <GradingPayroll />,
            breadcrumb: () => <TranslationBreadcrumb name="grading" />,
          },
        ],
      },
      {
        path: "/settings/payroll/bpjs/import-bpjs-multiplier",
        element: (
          <ProtectedRoute name="setting_payroll_add">
            <ImportMultiplierBpjs />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb
            name={"bpjs.import_bpjs_multiplier_per_employee"}
          />
        ),
      },
      {
        path: "/settings/profile",
        element: (
          <ProtectedRoute name="setting_profile">
            <Profile />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="profile" />,
      },
      {
        path: "/settings/roles",
        element: (
          <ProtectedRoute name="setting_roles">
            <Roles />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="roles" />,
      },
      {
        path: "/settings/roles/add",
        element: (
          <ProtectedRoute name="setting_roles_add">
            <AddRoles />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"roles.add_role"} />,
      },
      {
        path: "/settings/roles/edit/:id",
        element: (
          <ProtectedRoute name="setting_roles_edit">
            <EditRoles />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name={"roles.edit_role"} />,
      },
      {
        path: "/settings/users",
        element: (
          <ProtectedRoute name="setting_users">
            <Users />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="users" />,
      },
      {
        path: "/settings/business-flow",
        element: (
          <ProtectedRoute name="businessflow">
            <BusinessFlow />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="businessflow" />,
      },
      {
        path: "/settings/setup",
        element: (
          <ProtectedRoute name="setting">
            <Setup />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="setup" />,
      },
      {
        path: "/settings/setup/success",
        element: (
          <ProtectedRoute name="setting">
            <SetupSuccess />
          </ProtectedRoute>
        ),
        breadcrumb: null,
      },
      {
        path: "/settings/account-mapping",
        element: (
          <ProtectedRoute name="setting_account_mapping">
            <AccountMapping />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="accountMapping.account_mapping" />
        ),
      },
      {
        path: "/settings/mobile-menu",
        element: (
          <ProtectedRoute name="mobile_menu">
            <MobileMenu />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="mobileMenu.application_menu" />
        ),
      },
      {
        path: "/settings/attendance",
        element: (
          <ProtectedRoute name="setting_attendance_validation">
            <AttendanceValidationSetting />
          </ProtectedRoute>
        ),
        breadcrumb: null,
        children: [
          {
            path: "/settings/attendance/presence",
            element: <AttendanceValidationPresenceSetting />,
            breadcrumb: () => (
              <TranslationBreadcrumb name="attendance.presence_app_validation" />
            ),
          },
          {
            path: "/settings/attendance/overtime",
            element: <AttendanceValidationOvertimeSetting />,
            breadcrumb: () => (
              <TranslationBreadcrumb name="attendance.presence_app_validation" />
            ),
          },
          {
            path: "/settings/attendance/client-visit",
            element: <AttendanceValidationClientVisitSetting />,
            breadcrumb: () => (
              <TranslationBreadcrumb name="attendance.presence_app_validation" />
            ),
          },
        ],
      },
      {
        path: "/settings/attendance-location",
        element: (
          <ProtectedRoute name="setting_attendance_location">
            <AttendanceLocations />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="attendanceLocation.office_location" />
        ),
      },
      {
        path: "/settings/attendance-location/add",
        element: (
          <ProtectedRoute name="setting_attendance_location_add">
            <AddAttendanceLocations />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="attendanceLocation.add_office_location" />
        ),
      },
      {
        path: "/settings/attendance-location/edit/:id",
        element: (
          <ProtectedRoute name="setting_attendance_location_edit">
            <EditAttendanceLocations />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="attendanceLocation.edit_office_location" />
        ),
      },
      {
        path: "/settings/billing",
        element: (
          <ProtectedRoute name="setting_billing">
            <Billing />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="billing.title" />,
      },
      {
        path: "/settings/billing/upgrade",
        element: (
          <ProtectedRoute name="setting_billing_add">
            <UpgradeBilling />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="billing.upgrade" />,
      },
      {
        path: "/settings/billing/upgrade-employee",
        element: (
          <ProtectedRoute name="setting_billing_add">
            <UpgradeEmployeeBilling />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="employee.add_employee" />
        ),
      },
      {
        path: "/settings/billing/addon",
        element: (
          <ProtectedRoute name="setting_billing_add">
            <AddAddonBilling />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="billing.add_addon" />,
      },
      {
        path: "/settings/template",
        element: (
          <ProtectedRoute name="setting_template">
            <SettingTemplate />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="template.notification_templates" />
        ),
        children: [
          {
            path: "/settings/template/email",
            element: <EmailTemplate />,
            breadcrumb: () => <TranslationBreadcrumb name="template.email" />,
          },
          // {
          //   path: "/settings/template/whatsapp",
          //   element: (
          //
          //       <WhatsappTemplate />
          //
          //   ),
          //   breadcrumb: () => (
          //     <TranslationBreadcrumb name="template.whatsapp" />
          //   ),
          // },
          {
            path: "/settings/template/mobile-apps",
            element: <MobileAppsTemplate />,
            breadcrumb: () => (
              <TranslationBreadcrumb name="template.mobile_apps" />
            ),
          },
        ],
      },
      {
        path: "/settings/layout",
        element: (
          <ProtectedRoute name="setting_layout">
            <LayoutSalarySlip />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="layoutSalarySlip.title" />
        ),
      },
      {
        path: "/settings/apps",
        element: (
          <ProtectedRoute name="apps">
            <SettingOpenApi />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="appSetting.apps" />,
      },
      {
        path: "/settings/apps/add",
        element: (
          <ProtectedRoute name="apps_add">
            <SettingAddOpenApi />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="appSetting.add_apps" />,
      },
      {
        path: "/settings/apps/detail/:id",
        element: (
          <ProtectedRoute name="apps">
            <SettingDetailOpenApi />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="appSetting.detail_apps" />
        ),
      },
      {
        path: "/settings/audit",
        element: (
          <ProtectedRoute name="setting_audit">
            <SettingAuditLog />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="audit.title" />,
      },
      {
        path: "/settings/cash-receipt",
        element: (
          <ProtectedRoute name="setting_cash_receipt">
            <SettingCashReceipt />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="cashReceipt.title" />,
      },
      {
        path: "/settings/reimbursement",
        element: (
          <ProtectedRoute name="setting_reimbursement">
            <SettingReimbursement />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="reimbursement.title" />,
      },
      {
        path: "/settings/cash-receipt/import",
        element: (
          <ProtectedRoute name="setting_cash_receipt_add">
            <SettingImportPlafonCashReceipt />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="cashReceipt.import_cash_receipt_plafon" />
        ),
      },
      {
        path: "/settings/location-tracking-rule",
        element: (
          <ProtectedRoute name="setting_location_tracking_rule">
            <SettingTracking />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="tracking.title" />,
      },
      {
        path: "/settings/overtime",
        element: (
          <ProtectedRoute name="setting_overtime">
            <SettingOvertime />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="settingOvertime.setting_overtime" />
        ),
      },
      {
        path: "/settings/overtime/add",
        element: (
          <ProtectedRoute name="setting_overtime_add">
            <AddSettingOvertime />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="settingOvertime.add_setting_overtime" />
        ),
      },
      {
        path: "/settings/overtime/detail/:id",
        element: (
          <ProtectedRoute name="setting_overtime">
            <DetailSettingOvertime />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="settingOvertime.detail_setting_overtime" />
        ),
      },
      {
        path: "/settings/overtime/edit/:id",
        element: (
          <ProtectedRoute name="setting_overtime_edit">
            <EditSettingOvertime />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="settingOvertime.edit_setting_overtime" />
        ),
      },
      {
        path: "/settings/automatic-sequencing",
        element: (
          <ProtectedRoute name="setting_automatic_sequencing">
            <Numbers />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="automaticSequencing.title" />
        ),
      },
      {
        path: "/settings/fingerprint-connect",
        element: (
          <ProtectedRoute name="setting_fingerprint_connect">
            <Fingerprint />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="fingerprint.fingerprint_connect" />
        ),
      },
      {
        path: "/settings/sso",
        element: <SSO />,
        breadcrumb: "Pengaturan SSO",
      },
      {
        path: "/settings/fingerprint-connect/select-device",
        element: (
          <ProtectedRoute name="setting_fingerprint_connect">
            <SelectDeviceFingerprint />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="fingerprint.select_device" />
        ),
      },
      {
        path: "/settings/fingerprint-connect/add",
        element: (
          <ProtectedRoute name="setting_fingerprint_connect">
            <AddFingerprint />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="fingerprint.add_fingerprint_connect" />
        ),
      },
      {
        path: "/settings/fingerprint-connect/edit/:id",
        element: (
          <ProtectedRoute name="setting_fingerprint_connect">
            <EditFingerprint />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="fingerprint.edit_fingerprint_connect" />
        ),
      },
      {
        path: "/settings/fingerprint-connect/synchronization/:id",
        element: (
          <ProtectedRoute name="setting_fingerprint_connect">
            <SynchronizationFingerprint />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="fingerprint.fingerprint_connect_synchronization" />
        ),
      },
      {
        path: "/settings/task",
        element: (
          <ProtectedRoute name="setting_task">
            <SettingTask />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="task.title" />,
        children: [
          {
            index: true,
            element: <Navigate to="/settings/task/payroll" replace />,
          },
          {
            path: "/settings/task/payroll",
            breadcrumb: () => <TranslationBreadcrumb name="payroll.payroll" />,
            element: <SettingPayrollTask />,
          },
          {
            path: "/settings/task/client-visit",
            breadcrumb: () => (
              <TranslationBreadcrumb name="attendance.client_visit" />
            ),
            element: <SettingClientVisitTask />,
          },
        ],
      },
      {
        path: "/settings/payment-connect",
        element: (
          <ProtectedRoute name="setting_payment_connect">
            <SettingPaymentConnect />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="paymentConnect.title" />,
      },
      {
        path: "/settings/payment-connect/:id",
        element: (
          <ProtectedRoute name="setting_payment_connect_edit">
            <DetailSettingPaymentConnect />
          </ProtectedRoute>
        ),
        breadcrumb: () => (
          <TranslationBreadcrumb name="paymentConnect.detail" />
        ),
      },
      {
        path: "/referral",
        element: (
          <ProtectedRoute isNotEmployee>
            <Referral />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="referral.referral" />,
      },
      {
        path: "/user/invite/:token",
        element: <Invite />,
        breadcrumb: null,
      },
      {
        path: "/faqs",
        element: (
          <ProtectedRoute isNotEmployee>
            <Faq />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="FAQ" />,
      },
      {
        path: "/faqs/detail/:id",
        element: (
          <ProtectedRoute isNotEmployee>
            <DetailFaq />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="payroll.detail" />,
      },
      {
        path: "/user/billing/success",
        element: <SuccessBilling />,
        breadcrumb: null,
      },
      {
        path: "/user/billing/failed",
        element: <FailedBilling />,
        breadcrumb: null,
      },
      {
        path: "/client",
        element: (
          <ProtectedRoute name="client">
            <Client />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="client.title" />,
      },
      {
        path: "/client/import",
        element: (
          <ProtectedRoute name="client_add">
            <ImportClient />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="client.import_client" />,
      },
      {
        path: "/client/add",
        element: (
          <ProtectedRoute name="client_add">
            <AddClient />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="client.add_client" />,
      },
      {
        path: "/client/edit/:id",
        element: (
          <ProtectedRoute name="client_edit">
            <EditClient />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="client.edit_client" />,
      },
      {
        path: "/task",
        element: (
          <ProtectedRoute name="task">
            <Task />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="task.title" />,
        children: [
          {
            path: "/task/add",
            element: (
              <ProtectedRoute name="task_add">
                <AddTask />
              </ProtectedRoute>
            ),
            breadcrumb: () => <TranslationBreadcrumb name="task.add_task" />,
          },
        ],
      },
      {
        path: "/task/detail/:id",
        element: (
          <ProtectedRoute name="task">
            <DetailTask />
          </ProtectedRoute>
        ),
        breadcrumb: () => <TranslationBreadcrumb name="task.detail" />,
        children: [
          {
            path: "/task/detail/:id/duplicate",
            element: (
              <ProtectedRoute name="task_add">
                <DuplicateTask />
              </ProtectedRoute>
            ),
            breadcrumb: () => (
              <TranslationBreadcrumb name="task.duplicate_task" />
            ),
          },
          {
            path: "/task/detail/:id/edit",
            element: (
              <ProtectedRoute name="task_edit">
                <EditTask />
              </ProtectedRoute>
            ),
            breadcrumb: () => <TranslationBreadcrumb name="task.edit_task" />,
          },
        ],
      },
      {
        path: "*",
        element: <Page404 />,
        breadcrumb: () => <TranslationBreadcrumb name={"breadcrumb.404"} />,
      },
    ],
  },
];

export default routes;
