import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import * as api from "apis/emailTemplate";

//Query untuk get all email templates
export const useEmailTemplates = (options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["emailTemplates"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEmailTemplates();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

//Query untuk get detail email template
export const useEmailTemplate = ({ payload = {} }, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["emailTemplate", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEmailTemplate(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

// Query untuk edit email template
export const useEditEmailTemplate = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editEmailTemplate(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk revert email template
export const useRevertEmailTemplate = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.revertEmailTemplate(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
