import axios from "utils/axios";
import { stringify } from "query-string";

export const getTaskActivities = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/taskActivities/pagination?${query}`);
};

export const getTaskActivity = (id) => {
  return axios.get(`/hr/taskActivities/${id}`);
};

export const addTaskActivity = (data) => {
  return axios.post("/hr/taskActivities", data);
};

export const editTaskActivity = (data) => {
  return axios.put(`/hr/taskActivities/${data?.id}`, data);
};

export const deleteTaskActivity = (id) => {
  return axios.delete(`/hr/taskActivities/${id}`);
};

export const deleteAttachmentTaskActivity = (data) => {
  return axios.delete(
    `/hr/taskActivities/${data?.taskActivityId}/attachment/delete/${data?.id}`
  );
};
