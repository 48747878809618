import { MoreOutlined } from "@ant-design/icons";

const MoreIcon = ({ className, ...props }) => {
  return (
    <MoreOutlined
      className={`text-xl text-gray-900 dark:text-white ${className || ""}`}
      {...props}
    />
  );
};

export default MoreIcon;
