import axios from "utils/axios";
import { stringify } from "query-string";

export const getApprovals = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/approvals/pagination?${query}`);
};

export const approveApproval = (data) => {
  return axios.patch("/hr/approvals/approve", data);
};

export const declineApproval = (data) => {
  return axios.patch("/hr/approvals/decline", data);
};

export const revertApproval = (id) => {
  return axios.patch(`/hr/approvals/revert/${id}`);
};
