import { useQuery, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import _ from "lodash";

import * as api from "apis/approvalRules";

//Query untuk get all approval rules
export function useApprovalRules({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["approvalRules", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getApprovalRules(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail Approval Rule
export function useApprovalRule({ payload = {} }, options) {
  const { t } = useTranslation();
  return useQuery(
    ["approvalRule", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getApprovalRule(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk add approval rule
export const useAddApprovalRule = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addApprovalRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit approval rule
export const useEditApprovalRule = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editApprovalRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete approval rule
export const useDeleteApprovalRule = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteApprovalRule(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk move role
export const useMoveApprovalRule = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) =>
      api.moveApprovalRule({ id: payload.srcId, dest_id: payload.destId }),
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries(["approvalRules", payload.data]);
        const previousRoles = queryClient.getQueryCache([
          "approvalRules",
          payload.data,
        ]);

        queryClient.setQueryData(["approvalRules", payload.data], (old) => {
          if (old && Array.isArray(old)) {
            const approvalRules = [...old];
            const srcApprovalRule = approvalRules[payload.dragIndex];
            approvalRules.splice(payload.dragIndex, 1);
            approvalRules.splice(payload.hoverIndex, 0, srcApprovalRule);
            old = [...approvalRules];
            return old;
          }
        });

        return { previousRoles };
      },
      onSuccess: ({ data }) => {
        if (data.success) {
          message.success(data.message);
        } else {
          message.error(data.message);
        }
      },
      onError: (_err, payload, context) => {
        queryClient.setQueryData(
          ["approvalRules", payload?.data],
          context.previousRoles
        );
        message.error(
          !_.isEmpty(_err) && _.isString(_err)
            ? _err
            : t("roles.error_cannot_move_the_channel")
        );
      },
      onSettled: (response, _err, payload) => {
        queryClient.invalidateQueries(["approvalRules", payload?.data]);
      },
    }
  );
};
