import { useQuery, useMutation, useInfiniteQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/clients";

export function useClients(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["clients", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getClients(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useClientsSuggestion = (payload = {}, options = {}) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["clients/suggestion", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getClients({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export function useClient(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["client", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getClient(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAddClient = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addClient(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditClient = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editClient(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteClient = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteClient(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useArchiveClient = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.archiveClient(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useUnarchiveClient = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.unarchiveClient(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query upload import client
export const useUploadImportClient = () => {
  return useMutation((payload) => api.uploadImportClient(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query execute import client
export const useExecuteImportClient = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.executeImportClient(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
