import { useCallback, useMemo } from "react";
import { useBillingSelector } from "hooks/useSelector";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

// Hook untuk handle url Kledo
const useBilling = () => {
  const { t } = useTranslation();
  const billing = useBillingSelector();

  //free plan on id 1
  const isFreePlan = useMemo(() => {
    if (billing && billing?.app_plan && billing?.app_plan?.id === 1) {
      return true;
    }
    return false;
  }, [billing]);

  //pro plan on id 2 and 3
  const isProPlan = useMemo(() => {
    if (
      billing &&
      billing?.app_plan &&
      (billing?.app_plan?.id === 2 || billing?.app_plan?.id === 3)
    ) {
      return true;
    }
    return false;
  }, [billing]);

  //elite plan on id 4 and 5
  const isElitePlan = useMemo(() => {
    if (
      billing &&
      billing?.app_plan &&
      (billing?.app_plan?.id === 4 || billing?.app_plan?.id === 5)
    ) {
      return true;
    }
    return false;
  }, [billing]);

  const showValidationProElitePlan = useCallback(() => {
    if (isFreePlan) {
      notification.warning({
        description: t(
          "billing.this_feature_is_available_in_the_package_pro_and_elite"
        ),
        key: "upgrade-pro-and-elite-plan",
      });
    }
  }, [isFreePlan, t]);

  const showValidationElitePlan = useCallback(() => {
    if (!isElitePlan) {
      notification.warning({
        description: t(
          "billing.this_feature_is_available_in_the_package_elite"
        ),
        key: "upgrade-elite-plan",
      });
    }
  }, [isElitePlan, t]);

  return {
    billing,
    isFreePlan,
    isProPlan,
    isElitePlan,
    showValidationProElitePlan,
    showValidationElitePlan,
  };
};

export default useBilling;
