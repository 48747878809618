import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useSelector } from "react-redux";
import WhatsAppOutlined from "@ant-design/icons/WhatsAppOutlined";
import {
  optionsSelector,
  userSelector,
  isMobileViewSelector,
} from "redux/selectors";

const ChatSupport = () => {
  const { t } = useTranslation();
  const option = useSelector(optionsSelector);
  const user = useSelector(userSelector);
  const isMobileView = useSelector(isMobileViewSelector);

  if (option.chat_support === 1) {
    return (
      <div className="fixed right-4 bottom-4 z-20">
        <Button
          href={`https://api.whatsapp.com/send?phone=${option?.chat_number}&text=Hallo,%20saya%20${user?.name}%20dari%20${option?.property_name}%20ingin%20tanya%20tentang%20Gajihub.`}
          target="_blank"
          rel="noopener noreferrer"
          type="primary"
          shape={isMobileView ? "circle" : "round"}
          icon={<WhatsAppOutlined />}
          className="bg-[#13c90a] border-[#13c90a] hover:opacity-70"
        >
          {isMobileView ? null : t("button.hello_can_i_help")}
        </Button>
      </div>
    );
  }
  return null;
};

export default ChatSupport;
