import { combineReducers } from "redux";
import configSlice from "./configSlice";
import referenceSlice from "./referenceSlice";
import loginSlice from "./loginSlice";

export default combineReducers({
  gajihub: configSlice.reducer,
  references: referenceSlice.reducer,
  loginModal: loginSlice.reducer,
});
