import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/timeline";

export function usePosts({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["posts/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPosts(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function usePost({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["post", payload?.id],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPost(payload?.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAddPost = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addPost(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditPost = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editPost(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useRestorePost = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.restorePost(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useRestoreComment = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.restoreComment(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const usePinPost = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.pinPost(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useUnpinPost = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.unpinPost(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeletePost = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deletePost(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_to_delete_data_from_server")),
  });
};

export const useDeleteComment = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteComment(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_to_delete_data_from_server")),
  });
};

export const useDeleteAttachmentPost = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteAttachmentPost(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_to_delete_data_from_server")),
  });
};
