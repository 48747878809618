import axios from "utils/axios";
import { stringify } from "query-string";

export const getJobLevels = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/jobLevels?${query}`);
};

export const getJobLevelsPagination = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/jobLevels/pagination?${query}`);
};

export const getJobLevelsCanBeDeleted = () => {
  return axios.get(`/hr/jobLevels/canBeDeleted`);
};

export const getJobLevel = (id) => {
  return axios.get(`/hr/jobLevels/${id}`);
};

export const addJobLevel = (data) => {
  return axios.post("/hr/jobLevels", data);
};

export const editJobLevel = (data) => {
  return axios.put(`/hr/jobLevels/${data.id}`, data);
};

export const deleteJobLevel = (id) => {
  return axios.delete(`/hr/jobLevels/${id}`);
};

export const archiveJobLevel = (id) => {
  return axios.patch(`/hr/jobLevels/${id}/archive`);
};

export const unarchiveJobLevel = (id) => {
  return axios.patch(`/hr/jobLevels/${id}/unarchive`);
};
