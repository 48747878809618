import axios from "utils/axios";
import { stringify } from "query-string";

export const getTasksEss = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/tasks/employees/pagination?${query}`);
};

export const getTaskEss = (id) => {
  return axios.get(`/hr/tasks/employees/${id}`);
};

export const editStatusTaskEss = (data) => {
  return axios.patch(`/hr/tasks/employees/${data?.id}/status`, data);
};
