import axios, { getOnce } from "utils/axios";

export const getTenants = () => {
  return getOnce("/tenants");
};

export const getTenantFieldData = () => {
  return axios.get("/tenants/fieldData");
};

export const deleteTenant = (id) => {
  return axios.delete(`/tenants/${id}`);
};

export const registerCompany = (data) => {
  return axios.post("/tenants/companies", data);
};

export const checkTenant = (name) => {
  return axios.get(`/tenants/exist/${name}`);
};

export const registerTenant = (data) => {
  return axios.post("/tenants/register", data);
};
