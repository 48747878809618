import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { isTokenExist } from "utils/cookies";
import store from "store";
import PublicContainer from "../Public";

const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isTokenExist()) {
      if (store.get("app.endpoint")) {
        navigate(store.get("app.previousPathname") || "/dashboard", {
          replace: true,
        });
      }
    }
  }, [navigate]);
  return (
    <PublicContainer>
      <Outlet />
    </PublicContainer>
  );
};

export default Login;
