import { Tooltip } from "antd";
import PropTypes from "prop-types";

const ProgressBar = ({ bgColor, bgBar, completed, hidePercentage }) => {
  const fillerStyles = {
    width: `${completed > 0 && completed < 20 ? 20 : completed}%`,
    backgroundColor: bgColor,
    borderRadius: "inherit",
  };

  return (
    <Tooltip title={`${completed}%`} color="#E53F6A">
      <div
        className={`h-[18px] w-full ${
          bgBar ? bgBar : "bg-gray-200 dark:bg-gray-400"
        } rounded-full`}
      >
        <div
          style={fillerStyles}
          className="h-full text-right items-center flex justify-end"
        >
          {!hidePercentage && (
            <span className="text-[10px] text-white font-semibold pr-1">{`${completed}%`}</span>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

ProgressBar.propTypes = {
  bgColor: PropTypes.string,
  completed: PropTypes.number,
  hidePercentage: PropTypes.bool,
};

export default ProgressBar;
