import { useQuery } from "react-query";
import { message } from "antd";

import * as api from "apis/reports";
import { useTranslation } from "react-i18next";

//Query untuk get report espt pph 21
export function useSptPph21Report({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["reports/esptPph21", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSptPph21Report(payload);
        response = data;
      } catch (error) {
        if (error?.data?.message) {
          throw new Error(error?.data?.message);
        } else {
          throw new Error(t("error.failed_to_load_data_from_server"));
        }
      }
      return response;
    },
    {
      onError: (error) => {},
      ...options,
    }
  );
}

//Query untuk get report payroll payment summary
export function usePayrollPaymentSummaryReport({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["reports/payrollPaymentSummary", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPayrollPaymentSummaryReport(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get report payroll payment summary detail
export function usePayrollPaymentSummaryDetailReport(
  { payload = {} },
  options = {}
) {
  const { t } = useTranslation();
  return useQuery(
    ["reports/payrollPaymentSummary/detail", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getDetailPayrollPaymentSummaryReport(
          payload?.id,
          payload?.params
        );
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get report payroll payment
export function usePayrollPaymentReport({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["reports/payrollPayment", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPayrollPaymentReport(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get report payroll payment detail
export function usePayrollPaymentDetailReport({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["reports/payrollPayment/detail", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getDetailPayrollPaymentReport(payload?.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get report payroll payment summary per organisasi
export function usePayrollPaymentSummaryReportPerOrganization(
  { payload = {} },
  options = {}
) {
  const { t } = useTranslation();
  return useQuery(
    ["reports/payrollPaymentSummary/organization", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPayrollPaymentSummaryReportPerOrganization(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get report payroll payment summary detail per organisasi
export function usePayrollPaymentSummaryDetailReportPerOrganization(
  { payload = {} },
  options = {}
) {
  const { t } = useTranslation();
  return useQuery(
    ["reports/payrollPaymentSummary/organization/detail", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getDetailPayrollPaymentSummaryReportPerOrganization(
          payload?.id,
          payload?.params
        );
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get report payroll summary per component
export function usePayrollSummaryPerComponentReport(
  { payload = {} },
  options = {}
) {
  const { t } = useTranslation();
  return useQuery(
    ["reports/payrollSummary/component", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPayrollSummaryPerComponentReport(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail report payroll summary per component
export function useDetailPayrollSummaryPerComponentReport(
  { payload = {} },
  options = {}
) {
  const { t } = useTranslation();
  return useQuery(
    ["reports/payrollSummary/component/detail", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getDetailPayrollSummaryPerComponentReport(payload?.id, payload?.params);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}
