import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";

const useLastPathname = (urlIndex) => {
  const {pathname} = useLocation()
  const currentPathname = useMemo(() => {
    const pathSnippets = pathname.split("/").filter((i) => i);
    if (!Array.isArray(pathSnippets)) {
      return "";
    }
    if(urlIndex) {
      return pathSnippets[urlIndex]
    }
    return _.last(pathSnippets);
  }, [pathname, urlIndex]);

  return currentPathname
}

export default useLastPathname