import { memo } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

import { CopyOutlined } from "@ant-design/icons";

const Copy = ({ title, ...props }) => {
  return (
    <Button
      className="bg-green1 border-green1 hover:opacity-70"
      type="primary"
      title={title}
      {...props}
    >
      {title}
    </Button>
  );
};

Copy.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

Copy.defaultProps = {
  icon: <CopyOutlined />,
  title: "button.copy_to_clipboard",
  type: "success",
  onClick: () => {},
  id: "btn-copy",
};

export default memo(Copy);
