import axios from "utils/axios";
import { stringify } from "query-string";

export const getTasks = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/tasks/pagination?${query}`);
};

export const getTask = (id) => {
  return axios.get(`/hr/tasks/${id}`);
};

export const getAttachmentTask = (url) => {
  return axios.get(`${url}`, {
    responseType: "blob",
  });
};

export const addTask = (data) => {
  return axios.post("/hr/tasks", data);
};

export const addDuplicateTask = (data) => {
  return axios.post(`/hr/tasks/${data?.id}/duplicate`, data);
};

export const editTask = (data) => {
  return axios.put(`/hr/tasks/${data?.id}`, data);
};

export const editStatusTask = (data) => {
  return axios.patch(`/hr/tasks/${data?.id}/status`, data);
};

export const archiveTask = (id) => {
  return axios.patch(`/hr/tasks/${id}/archive`);
};

export const unarchiveTask = (id) => {
  return axios.patch(`/hr/tasks/${id}/unarchive`);
};

export const deleteTask = (id) => {
  return axios.delete(`/hr/tasks/${id}`);
};

export const massDeleteTask = (data) => {
  return axios.delete("/hr/tasks/massDelete", { data });
};

export const deleteAttachmentTask = (data) => {
  return axios.delete(
    `/hr/tasks/${data?.taskId}/attachment/delete/${data?.id}`
  );
};
