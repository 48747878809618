import { useInfiniteQuery } from "react-query";
import { getProvinces } from "apis";

import { message } from "antd";
import { useTranslation } from "react-i18next";

export const useProvinces = ({ payload = {} }) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["provinces", payload],
    async ({ pageParam = 0 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await getProvinces({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
};
