import { useMemo } from "react";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { usePermissionsMenu } from "hooks/useSelector";
import { useEmployee } from "queries";

const EmployeeBreadcrumb = ({ match }) => {
  const { t } = useTranslation();
  const permissionsMenu = usePermissionsMenu("employee");
  const id = match.params?.id;
  const queryClient = useQueryClient();
  const employee = queryClient.getQueryData(["employee", { id }]);

  const { data } = useEmployee(
    { payload: { id } },
    { enabled: !employee && !!id && !_.isEmpty(permissionsMenu) }
  );

  const dataEmployee = useMemo(() => {
    if (!employee || !data) {
      return;
    }
    return employee ? employee : data;
  }, [employee, data]);

  return <span>{dataEmployee?.name || t("employee.employee_name")}</span>;
};

export default EmployeeBreadcrumb;
