import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const Download = ({ title, icon, type, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button
      type={type || "primary"}
      icon={icon || <DownloadOutlined />}
      {...props}
    >
      {title || t("button.download")}
    </Button>
  );
};

export default Download;
