import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/paymentConnectSetting";

export function usePaymentConnectSetting(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["paymentConnect/setting", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPaymentConnectSetting(payload?.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useUpdatePaymentConnectSetting = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.updatePaymentConnectSetting(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useTestConnectionPaymentConnectSetting = () => {
  const { t } = useTranslation();
  return useMutation(
    (payload) => api.testConnectionPaymentConnectSetting(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
};
