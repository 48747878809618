import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/reimbursement";

export function useReimbursements({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["reimbursements/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getReimbursements(payload);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useEmployeeReimbursements({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["reimbursements/employee/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEmployeeReimbursements(payload);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useReimbursement({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["reimbursement", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getReimbursement(payload);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAddReimbursement = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addReimbursement(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditReimbursement = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editReimbursement(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const usePaymentReimbursement = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.paymentReimbursement(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteReimbursement = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteReimbursement(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteAttachmentReimbursement = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteAttachmentReimbursement(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
