import { Steps as StepsAntd } from "antd";
import { css } from "@emotion/css";

const Steps = ({ current, items, size, labelPlacement, ...props }) => {
  return (
    <StepsAntd
      className={`w-full flex items-center justify-center ${css`
        .ant-steps-item-icon {
          border-color: #fff0f1 !important;
        }
      `}`}
      current={current}
      size={size}
      labelPlacement={labelPlacement}
      responsive={false}
      items={items}
      {...props}
    />
  );
};

Steps.defaultProps = {
  current: 0,
  items: [],
  size: "small",
  labelPlacement: "vertical",
};

export default Steps;
