import { stringify } from "query-string";
import axios from "utils/axios";

export const getLayoutsSalarySlip = () => {
  return axios.get(`/hr/layouts/salarySlip`);
};

export const getPreviewLayoutSalarySlip = () => {
  return axios.get(`/hr/layouts/salarySlip/preview`);
};

export const editLayoutsSalarySlip = (data) => {
  return axios.patch(`/hr/layouts/salarySlip`, data);
};

export const resetLayoutsSalarySlip = () => {
  return axios.post(`/hr/layouts/salarySlip/reset`);
};

export const resetLogoLayoutsSalarySlip = (data) => {
  return axios.post(`/hr/layouts/salarySlip/resetImage`, data);
};

export const uploadSignatureSalarySlip = (data) => {
  const query = stringify(data?.params);
  return axios.post(
    `/hr/layouts/salarySlip/uploadSignature?${query}`,
    data?.data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
