import { Button } from "antd";
import PropTypes from "prop-types";
import { UnorderedListOutlined } from "@ant-design/icons";

const ShowColumn = (props) => {
  return <Button {...props} />;
};

ShowColumn.propTypes = {
  icon: PropTypes.object,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

ShowColumn.defaultProps = {
  icon: <UnorderedListOutlined />,
  onClick: () => {},
  id: "btn-show-column",
};

export default ShowColumn;
