import { memo, useMemo } from "react";
import { Dropdown } from "antd";
import {
  DownOutlined,
  ImportOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { MdOutlineUpload } from "react-icons/md";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ImportExportMenu = ({ id, title, icon, onClick, ...props }) => {
  const { t } = useTranslation();
  // const [visible, setVisible] = useState(false);

  const menuItems = useMemo(() => {
    const items = [
      {
        key: 1,
        icon: <ImportOutlined />,
        onClick: () => {
          // setVisible(false);
        },
        label: t("button.import"),
      },
      {
        key: 2,
        icon: <ExportOutlined />,
        onClick: () => {
          // setVisible(false);
        },
        label: t("button.export"),
      },
    ];
    return items;
  }, [t]);

  return (
    <Dropdown.Button
      className="flex items-center justify-center"
      id={id}
      icon={icon}
      trigger={["click"]}
      menu={{ items: menuItems }}
      // onClick={() => {
      //   setVisible((prevState) => !prevState);
      // }}
      // overlayClassName="w-[130px]"
      // open={visible}
      // onOpenChange={(flag) => setVisible(flag)}
      {...props}
    >
      <div className="flex items-center justify-center">
        <MdOutlineUpload className="mr-2 text-primary" size={19} />
        {title ? title : t("button.import")}
      </div>
    </Dropdown.Button>
  );
};

ImportExportMenu.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

ImportExportMenu.defaultProps = {
  icon: <DownOutlined />,
  onClick: () => {},
  id: "btn-import",
};

export default memo(ImportExportMenu);
