import { stringify } from "query-string";
import axios from "utils/axios";

export const getOrgStructures = () => {
  return axios.get("/hr/orgStructures");
};

export const getOrgStructure = (id) => {
  return axios.get(`/hr/orgStructures/${id}`);
};

export const getOrgStructuresTree = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/orgStructures/tree?${query}`);
};

export const getOrgStructureParents = (id) => {
  return axios.get(`/hr/orgStructures/availableParents/${id}`);
};

export const getOrgStructureEmployee = (data) => {
  const query = stringify(data);
  return axios.get(`/hr/orgStructures/${data?.id}/employees?${query}`);
};

export const getOrgStructureLevels = () => {
  return axios.get(`/hr/orgStructures/availableLevels`);
};

export const addOrgStructure = (data) => {
  return axios.post("/hr/orgStructures", data);
};

export const editOrgStructure = (data) => {
  return axios.put(`/hr/orgStructures/${data.id}`, data);
};

export const deleteOrgStructure = (id) => {
  return axios.delete(`/hr/orgStructures/${id}`);
};

export const archiveOrgStructure = (id) => {
  return axios.patch(`/hr/orgStructures/${id}/archive`);
};

export const unarchiveOrgStructure = (id) => {
  return axios.patch(`/hr/orgStructures/${id}/unarchive`);
};
