import React from "react";

const Loader = () => {
  return (
    <div className="flex justify-center items-center h-full w-full">
      <span className="animate-ping absolute inline-flex h-5 w-5 rounded-full bg-primary opacity-75"></span>
      <span className="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
    </div>
  );
};

export default Loader;
