import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { message } from "antd";

import * as api from "apis/thrProgressive";

//Query untuk get thr progressive
export function useThrProgressive(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["thrProgressive"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getThrProgressive();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk edit thr progressive
export const useEditThrProgressive = () => {
  // const { t } = useTranslation();
  return useMutation((payload) => api.editThrProgressive(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      // message.error(t("error.failed_save_data_to_server"));
    },
  });
};
