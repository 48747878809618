import { useQuery, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/shiftSchedule";

//Query untuk get all shift schedules
export function useShiftSchedules({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["schedules/shift", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getShiftSchedules(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get shift schedules pagination
export function useShiftSchedulesPagination({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["schedules/shift/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getShiftSchedulesPagination(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk add shift schedule
export const useAddShiftSchedule = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation((payload) => api.addShiftSchedule(payload), {
    onMutate: async (payload) => {
      const params = payload?.paramsSchedule;

      queryClient.cancelQueries(["schedules/shift/pagination", params]);

      const previousData = queryClient.getQueryCache([
        "schedules/shift/pagination",
        params,
      ]);

      queryClient.setQueryData(
        ["schedules/shift/pagination", params],
        (old) => {
          if (old && Array.isArray(old?.data)) {
            const shiftSchedules = [...old?.data];
            const updateData = shiftSchedules.map((row) => {
              if (row?.id === payload?.hr_employee_id) {
                const schedules = [...row?.hr_shift_schedules];
                const addSchedule = {
                  id: `temp-${payload?.id}-${payload?.hr_shift_id}-${payload?.date}`,
                  date: payload?.date,
                  hr_shift_id: payload?.hr_shift_id,
                  hr_shift: payload?.shift,
                };
                schedules.push(addSchedule);
                return { ...row, hr_shift_schedules: schedules };
              }
              return { ...row };
            });
            return { ...old, data: updateData };
          }
        }
      );

      return { previousData };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message || "");
      } else {
        message.error(data?.message || "");
      }
    },
    onError: (_err, payload, context) => {
      const params = payload?.paramsSchedule;
      queryClient.setQueryData(
        ["schedules/shift/pagination", params],
        context.previousData
      );
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: (response, _err, payload) => {
      const params = payload?.paramsSchedule;
      queryClient.invalidateQueries(["schedules/shift/pagination", params]);
    },
  });
};

// Query untuk delete shift schedule
export const useDeleteShiftSchedule = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.deleteShiftSchedule(payload?.id), {
    onMutate: async (payload) => {
      const params = payload?.paramsSchedule;

      queryClient.cancelQueries(["schedules/shift/pagination", params]);

      const previousData = queryClient.getQueryCache([
        "schedules/shift/pagination",
        params,
      ]);

      queryClient.setQueryData(
        ["schedules/shift/pagination", params],
        (old) => {
          if (old && Array.isArray(old?.data)) {
            const shiftSchedules = [...old?.data];
            const updateData = shiftSchedules.map((row) => {
              if (row?.id === payload?.idEmployee) {
                const schedules = [...row?.hr_shift_schedules];
                const filterSchedules = schedules.filter(
                  (schedule) =>
                    schedule?.id !== payload?.id &&
                    schedule?.date !== payload?.date
                );
                return { ...row, hr_shift_schedules: filterSchedules };
              }
              return { ...row };
            });
            return { ...old, data: updateData };
          }
        }
      );
      return { previousData };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (_err, payload, context) => {
      const params = payload?.paramsSchedule;
      queryClient.setQueryData(
        ["schedules/shift/pagination", params],
        context.previousData
      );
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: (response, _err, payload) => {
      const params = payload?.paramsSchedule;
      queryClient.invalidateQueries(["schedules/shift/pagination", params]);
    },
  });
};

// Query  upload import shift schedules
export const useUploadImportShiftSchedules = () => {
  return useMutation((payload) => api.uploadImportShiftSchedules(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query  upload import horizontal shift schedules
export const useUploadImportHorizontalShiftSchedules = () => {
  return useMutation(
    (payload) => api.uploadImportHorizontalShiftSchedules(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => {},
    }
  );
};

// Query  execute import shift schedules
export const useExecuteImportShiftSchedules = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.executeImportShiftSchedules(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query  execute import horizontal shift schedules
export const useExecuteImportHorizontalShiftSchedules = () => {
  const { t } = useTranslation();
  return useMutation(
    (payload) => api.executeImportHorizontalShiftSchedules(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => message.error(t("error.failed_save_data_to_server")),
    }
  );
};
