import axios from "utils/axios";

export const getConnectFinanceSetting = () => {
  return axios.get(`/hr/settings/connects/finance/settings`);
};

export const getConnectFinanceTags = () => {
  return axios.get(`/hr/settings/connects/finance/tags`);
};

export const updateConnectFinanceSetting = (payload) => {
  return axios.patch(`/hr/settings/connects/finance/settings`, payload);
};

export const updateConnectFinanceTag = (payload) => {
  return axios.patch(`/hr/settings/connects/finance/tags`, payload);
};

export const updateConnectFinanceSalaryComponent = (payload) => {
  return axios.patch(
    `/hr/settings/connects/finance/salaryComponents/${payload?.id}`,
    payload
  );
};

export const updateConnectFinanceBankAccount = (payload) => {
  return axios.patch(
    `/hr/settings/connects/finance/bankAccounts/${payload?.id}`,
    payload
  );
};
