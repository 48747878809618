import { useInfiniteQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/account";

export const useAccountSuggestion = ({ payload = {} }, options = {}) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["accounts/suggestion", payload],
    async ({ pageParam = 0 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSuggestionAccount({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};
