import axios from "utils/axios";
import { stringify } from "query-string";

export const getApprovalRules = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/approvalRules?${query}`);
};

export const getApprovalRule = (id) => {
  return axios.get(`/hr/approvalRules/${id}`);
};

export const addApprovalRule = (data) => {
  return axios.post("/hr/approvalRules/", data);
};

export const editApprovalRule = (data) => {
  return axios.put(`/hr/approvalRules/${data.id}`, data);
};

export const moveApprovalRule = (data) => {
  return axios.patch(`/hr/approvalRules/${data?.id}/move`, data);
};

export const deleteApprovalRule = (id) => {
  return axios.delete(`/hr/approvalRules/${id}`);
};
