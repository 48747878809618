import axios from "utils/axios";

const resource = "authentication";

export const login = (email, password, remember) => {
  const data = {
    email,
    password,
    remember_me: remember,
  };

  return axios.post(`/${resource}/login`, data, {
    headers: { Authorization: false },
  });
};

export const singleLogin = (email, password, remember) => {
  const data = {
    email,
    password,
    remember_me: remember,
  };

  return axios.post(`/${resource}/singleLogin`, data, {
    headers: { Authorization: false },
  });
};

export const verifyGoogleToken = (code) => {
  return axios.post(
    `/google-auth/verify`,
    { code },
    { headers: { Authorization: false } }
  );
};

export const updateProfile = (data) => {
  return axios.put(`/${resource}/user`, data);
};

export const changePassword = (data) => {
  return axios.put(`/${resource}/password`, data);
};

export const revokeLogin = () => {
  return axios.post(`/${resource}/revoke`);
};

export const invite = (data) => {
  return axios.post(`/users/register`, data, {
    headers: { Authorization: false },
  });
};

export const reset = (data) => {
  return axios.post(`/authentication/password/recovery/reset`, data, {
    headers: { Authorization: false },
  });
};

export const changeLanguage = (lang) => {
  return axios.patch(`/${resource}/user/lang`, { lang });
};

export const logout = (apiToken) => {
  return axios.post(
    `/${resource}/logout`,
    {},
    { headers: { Authorization: `Bearer ${apiToken}` } }
  );
};

export const verifyToken = (token) => {
  return axios.get(`/authentication/password/recovery/${token}`, {
    headers: { Authorization: false },
  });
};

export const requestRecovery = (data) => {
  return axios.post("/authentication/password/recovery", data, {
    headers: { Authorization: false },
  });
};

export const register = (data) => {
  return axios.post("/tenants/register", data, {
    headers: { Authorization: false },
  });
};

export const loginWithSso = (data) => {
  return axios.post(`/sso/login`, data, { headers: { Authorization: false } })
}
