import { useCallback } from "react";
import { useSettingSelector } from "./useSelector";
import { parseMultipleFilterSettingToUri } from "utils/helper";

const useURLDefaultParams = () => {
  const setting = useSettingSelector();

  const addURLDefaultParams = useCallback(
    (url) => {
      const parseUriParams = (filters) => {
        return filters
          .map(([paramName, filterKey]) =>
            parseMultipleFilterSettingToUri(paramName, filterKey, setting)
          )
          .join("&");
      };
      let searchParams = "";

      switch (url) {
        case "/presence/attendance/daily":
          searchParams = parseUriParams([
            ["org_structures", "filter_daily_attendance_hr_org_structure_ids"],
          ]);
          return `/presence/attendance/daily?${searchParams}`;
        case "/presence/attendance/summary":
          searchParams = parseUriParams([
            [
              "org_structures",
              "filter_summary_attendance_hr_org_structure_ids",
            ],
          ]);
          return `/presence/attendance/summary?${searchParams}`;
        default:
          return url;
      }
    },
    [setting]
  );

  return { addURLDefaultParams };
};

export default useURLDefaultParams;
