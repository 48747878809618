import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { message } from "antd";

import { addUmpTenants, getUmpTenants } from "apis/umpTenant";

export const useUmpTenants = (options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    "umpTenants",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getUmpTenants();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useUpdateUmpTenant = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => addUmpTenants(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries("umpTenants")
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};
