import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import * as api from "apis/taxWithholder";

//Query untuk get all tax withholders
export function useTaxWithholders() {
  const { t } = useTranslation();
  return useQuery(
    ["taxWithholders"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTaxWithholders();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

// Query untuk add tax withholder
export const useAddTaxWithholder = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addTaxWithholder(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit tax withholder
export const useEditTaxWithholder = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editTaxWithholder(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete tax withholder
export const useDeleteTaxWithholder = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteTaxWithholder(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
