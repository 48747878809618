import axios from "utils/axios";
import { stringify } from "query-string";

export const getUsers = (payload) => {
  const query = stringify(payload);
  return axios.get(`/users?${query}`);
};

export const checkInvitationToken = (data) => {
  return axios.get(`/users/register/${data}`, {
    headers: { Authorization: false },
  });
};

export const addUser = (data) => {
  return axios.post(`/users`, data);
};

export const registerUser = (data) => {
  return axios.post(`/users/register`, data, {
    headers: { Authorization: false },
  });
};

export const acceptInvitation = (data) => {
  return axios.post(`/users/acceptInvitation`, data, {
    headers: { Authorization: false },
  });
};

export const resendInvitation = (id) => {
  return axios.post(`/users/resendInvitation/${id}`);
};

export const updateUser = (data) => {
  return axios.put(`/users/${data.id}`, data);
};

export const deleteUser = (id) => {
  return axios.delete(`/users/${id}`);
};
