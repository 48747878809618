import antdData from "antd/lib/locale/en_US";

const messages = {
  "topBar.issuesHistory": "Issues History",
  "topBar.projectManagement": "Project Management",
  "topBar.typeToSearch": "Type to search...",
  "topBar.buyNow": "Buy Now $24",
  "topBar.bitcoin": "Bitcoin",
  "topBar.profileMenu.hello": "Hello",
  "topBar.profileMenu.billingPlan": "Billing Plan",
  "topBar.profileMenu.role": "Role",
  "topBar.profileMenu.email": "Email",
  "topBar.profileMenu.phone": "Phone",
  "topBar.profileMenu.editProfile": "Edit Profile",
  "topBar.profileMenu.logout": "Logout",
  "topBar.billing.plan": "Plan",
  "topBar.billing.forever": "Forever",
  "topBar.billing.active_until": "Active until",
  "topBar.change_language": "Change Language",
  "topBar.data_changes_timeline": "Data Changes Timeline",
  "topBar.latest_gajihub_update": "Latest Gajihub Update",

  "notification.a_new_version_is_available": "A new version is available!",
  "notification.please_wait_prepare_to_reload_page":
    "Please wait, prepare to reload page on {{countDown}} seconds. {{errorAutoReloadMessage}}",
  "notification.autoreload_failed": "Auto reload failed.",
  "notification.reload_immediately": "Reload immediately",
  "notification.login_expired": "Your session is expired!",
  "notification.login_google_failure": "Login google failure!",
  "notification.gajihub_is_now_on_google_play":
    "Gajihub is now available on Google Play",
  "notification.gajihub_is_now_on_app_store":
    "Gajihub is now available on App Store",
  "notification.install_now": "Install Now",
  "notification.later": "Later",

  "button.save": "Save",
  "button.save_add_new": "Save & Add New",
  "button.cancel": "Cancel",
  "button.add": "Add",
  "button.duplicate": "Duplicate",
  "button.import": "Import",
  "button.export": "Export",
  "button.print": "Print",
  "button.filter": "Filter",
  "button.clear_filter": "Clear Filter",
  "button.delete": "Delete",
  "button.reset": "Reset",
  "button.edit": "Edit",
  "button.next": "Next",
  "button.back": "Back",
  "button.approve": "Approve",
  "button.reject": "Reject",
  "button.yes": "Yes",
  "button.no": "No",
  "button.download": "Download",
  "button.confirmation": "Confirmation",
  "button.activate": "Activate",
  "button.non_activate": "Non Activate",
  "button.detail": "Detail",
  "button.add_line": "Add Line",
  "button.select_all": "Select All",
  "button.deselect_all": "Remove All",
  "button.schedule_now": "Schedule Now",
  "button.hello_can_i_help": "Hello, can I help you?",
  "button.close": "Close",
  "button.slide_to_confirm": "Slide to confirm",
  "button.confirmed": "Confirmed!",
  "button.click_here": "Click here",
  "button.upgrade": "Upgrade",
  "button.renew": "Renew",
  "button.copy_to_clipboard": "Copy Link to Clipboard",
  "button.archive": "Archive",
  "button.unarchive": "Unarchive",
  "button.copy_link": "Copy Link",
  "button.upload": "Upload",
  "button.send": "Send",
  "button.search": "Search",

  "message.cannot_update_data": "Cannot update data.",
  "message.cannot_return_data": "Data is not returnable.",
  "message.link_has_been_copied": "Link has been copied.",
  "message.on": "on",
  "message.no_data": "No data {{value}}",

  "popconfirm.title.delete": "Are you sure to delete?",
  "popconfirm.title.archive": "Are you sure to archive?",
  "popconfirm.title.unarchive": "Are you sure to unarchive?",
  "popconfirm.title.reset": "Are you sure to reset?",

  "tooltip.hidden": "Hidden",

  "queue.data_is_waiting_to_be_processed":
    "{{value}} data is waiting to be processed...",

  "attachment.open_document": "Open Document",
  "attachment.open_gallery": "Open Gallery",
  "attachment.take_photo": "Take Photo",
  "attachment.maximum_file_size": "Maximum file size {{value}} MB",
  "attachment.upload_failed": "Upload failed",
  "attachment.delete_attachment": "Delete attachment",

  "validation.min": "Min {{value}}",
  "validation.max": "Max {{value}}",

  "input.search": "Search",
  "input.other": "Other",
  "input.all": "All",
  "input.select_year": "Select year",
  "input.select_month": "Select month",
  "input.cannot_be_empty": "Cannot be empty",

  "date.today": "Today",
  "date.yesterday": "Yesterday",
  "date.week": "Week",
  "date.this_week": "This Week",
  "date.last_week": "Last Week",
  "date.this_month": "This Month",
  "date.last_month": "Last Month",
  "date.last_7_days": "Last 7 Days",
  "date.last_30_days": "Last 30 Days",
  "date.this_year": "This Year",
  "date.last_year": "Last Year",
  "date.daily": "Daily",
  "date.monthly": "Monthly",
  "date.yearly": "Yearly",
  "date.year": "Year",
  "date.month": "Month",
  "date.day": "Day",
  "date.days": "Days",
  "date.date": "Date",
  "date.hour": "Hour",
  "date.hours": "Hours",
  "date.minute": "Minute",
  "date.minutes": "Minutes",
  "date.time": "Time",
  "date.date_range": "Date Range",

  "preview.image_preview": "Image Preview",
  "preview.failed_to_load_image": "Failed to load image",

  "nps.how_likely_you_recommend":
    "How likely is that you would recommend Gajihub to a friend?",
  "nps.not_likely": "Not at all likely",
  "nps.very_likely": "Very likely",
  "nps.please_mention_another":
    "Please mention something else you would like to say through comment box below",
  "nps.cannot_hide_nps": "Cannot hide NPS",
  "nps.cannot_send_nps": "Cannot send NPS",
  "nps.thank_you_your_input":
    "Thank you, your input is very meaningful. We analyze every input that comes in.",

  "users.remember_me": "Remember me",
  "users.forgot_password_": "Forgot password?",
  "users.welcome_to2": "Welcome to ",
  "users.login": "Login",
  "users.login_with_google": "Sign in with Google",
  "users.login_failed": "Login Failed",
  "users.please_log_in": "Please log in",
  "users.you_have_logged_out_automatically":
    "You have been logged out automatically, please continue to proceed.",
  "users.email_is_required": "Email is required",
  "users.email_is_not_a_valid_email": "Email is not a valid email",
  "users.please_input_your_email_address": "Please input your e-mail address",
  "users.email": "Email",
  "users.password": "Password",
  "users.please_input_your_password": "Please input your password",
  "users.hello": "Hello",
  "users.please_select_company_to_proceed":
    "please select a company to proceed",
  "users.leave_page": "Leave page?",
  "users.changes_that_you_made_may_not_be_saved":
    "Changes that you made may not be saved.",
  "users.registration_completed": "Regsitration completed!",
  "users.password_has_been_sent_to_email_sms":
    "Password has been sent to your email and sms, please check it out",
  "users.users": "Users",
  "users.name": "Name",
  "users.roles": "Roles",
  "users.search_by_name_or_email": "Search by name or email",
  "users.search_by_name": "Search by name",
  "users.pending_invitation": "(Pending invitation)",
  "users.add_user": "Add User",
  "users.delete_user": "Delete User",
  "users.edit_user": "Edit User",
  "users.please_input_the_email_address": "Please input email address.",
  "users.max_100_characters": "Max 100 characters.",
  "users.invalid_email_address_format": "Invalid email address format.",
  "users.please_select_a_role": "Please select a role.",
  "users.select_roles": "Select Role",
  "users.information": "Information",
  "users.information_invitation_email":
    "A invitation email already sent to {{email}} . Please ask your colleague to follow the instruction in the email to activate the account. If email hasn't received please check the bulk and spam folder.",
  "users.only_displays_users_other_than_you":
    "Only displays users other than you",
  "users.please_select_a_user": "Please select a user.",
  "users.select_user": "Select user",
  "users.select_user_to_delete": "Select User to Delete",
  "users.two_passwords_that_you_enter_is_inconsistent_":
    "Two passwords that you enter is inconsistent.",
  "users.register_now": "Register now",
  "users.restore_password": "Restore Password",
  "users.enter_the_email_address_associated_with_your_account_and_well_email_you_a_link_to_reset_your_password":
    "Enter the email address associated with your account, and we'll email you a link to reset your password",
  "users.back_to_login": "Back to login",
  "users.send_reset_link": "Send Reset Link",
  "users.please_check_your_email_inbox_and_follow_the_instruction_to_reset_your_password_if_email_hasnt_received_please_check_the_bulk_and_spam_folder":
    "Please check your email inbox and follow the instruction to reset your password. If email hasn't received please check the bulk and spam folder",
  "users.reset_password": "Reset Password",
  "users.request_new_password_reset": "Request new password reset",
  "users.new_password": "New Password",
  "users.invalid_password_reset_token": "Invalid password reset token",
  "users.your_password_reset_token_is_invalid_please_check_it_again_and_reload_the_page":
    "Your password reset token is invalid, please check it again and reload the page",
  "users.confirm_new_password": "Confirm New Password",
  "users.please_input_new_password": "Please input New Password",
  "users.status": "Status",
  "users.add_as_employee": "Add as Employee",
  "users.employee": "Employee",
  "users.email_is_not_registered_with_gajihub":
    "Email is not registered with GajiHub.",
  "users.please_fill_the_register_form": "Please fill the register form",
  "users.invalid_invitation_token": "Invalid invitation token",
  "users.your_invitation_token_is_invalid_please_check_it_again_and_reload_the_page":
    "Please check it again and reload the page",
  "users.you_are_invited_to_join": "You are invited to join",
  "users.do_you_agree_to_join": "Do You agree to join",
  "users.please_input_your_name": "Please input your name.",
  "users.phone_number": "Phone Number",
  "users.phone_number_is_required": "Phone Number is required",
  "users.no_phone_number_worng": "Phone Number worng",
  "users.phone_number_must_be_at_least_5_characters":
    "Phone Number must be at least 5 characters",
  "users.phone_number_cannot_be_longer_than_20_characters":
    "Phone Number cannot be longer than 20 characters",
  "users.please_input_your_phone": "Please input your phone.",
  "users.activate": "Activate",
  "users.waiting_for_confirmation": "Waiting for Confirmation",
  "users.resending_help":
    "To confirm, please open your email inbox, and click the link sent by Gajihub.",
  "users.resending": "Resending",
  "users.success_resending": "Successfully Sending Invitations",
  "users.success_resending_desc":
    "Invitation successfully resent to email {{email}}. Please open your email inbox, and click on the link that has been sent by Gajihub.",
  "users.you_have_registered_on_gajiHub_with_your_username":
    "You have registered on GajiHub with your username",
  "users.click_the_button_below_to_log_in_to_gajiHub":
    "Click the button below to log in to GajiHub",
  "users.info_is_only_has_role_employee":
    "You are logged in as an employee. To continue using the gajihub application, please select the following option",
  "users.login_to_the_ess_application": "Login to the ESS Application",

  "companies.company_list": "Company List",
  "companies.add_company": "Add Company",
  "companies.created_on": "Created on ",
  "companies.as": "As",
  "companies.are_you_sure_to_delete_this_company":
    "Are You sure to delete this company?<br />After deleted, the data cannot be returned",
  "companies.choose_package": "Choose a Package",
  "companies.full_name": "Full Name",
  "companies.please_input_full_name": "Please input full name",
  "companies.company_name": "Company Name",
  "companies.please_input_company_name": "Please input company name",
  "companies.role_on_company": "Role on Company as",
  "companies.please_input_role_on_company": "Please input role on company",
  "companies.number_of_employee": "Number of Employee",
  "companies.please_input_number_of_employee": "Please input number of company",
  "companies.please_input_email": "Please input email",
  "companies.please_input_phone_number": "Please input phone number",
  "companies.phone_number_must_08": "Phone number must begin with 08",
  "companies.next_per_month": "Next {price}/month",
  "companies.free_forever": "Free Forever",
  "companies.max_characters": "Max {{value}} characters.",
  "companies.min_characters": "Min {{value}} characters.",
  "companies.select_company": "Select Company",

  "employee.title": "Employee",
  "employee.add_employee": "Add Employee",
  "employee.add_submission": "Add Submission",
  "employee.import_add_employee": "Import Add Employee",
  "employee.import_update_employee": "Import Update Employee",
  "employee.date": "Date",
  "employee.name": "Name",
  "employee.organization": "Organization",
  "employee.job_title": "Job Title",
  "employee.job_level": "Job Level",
  "employee.status": "Status",
  "employee.email": "Email",
  "employee.telp": "Telp",
  "employee.personal": "Personal",
  "employee.staffing": "Career",
  "employee.attendance": "Attendance",
  "employee.attendance_history": "Attendance History",
  "employee.calendar": "Calendar",
  "employee.permission_and_leave": "Permission & Leave",
  "employee.overtime": "Overtime",
  "employee.business_trip": "Business Trip",
  "employee.career": "Career",
  "employee.payroll": "Payroll",
  "employee.finance": "Finance",
  "employee.loan": "Loan",
  "employee.reimburse": "Reimburse",
  "employee.cash_receipt": "Cash Receipt",
  "employee.inventory": "Inventory",
  "employee.all": "All",
  "employee.approved": "Approved",
  "employee.waiting_for_approval": "Waiting For Approval",
  "employee.rejected": "Rejected",
  "employee.submission_date": "Submission Date",
  "employee.number_of_days": "Number of Days",
  "employee.leave_date": "Leave Date",
  "employee.approval": "Approval",
  "employee.leave_type": "Leave Type",
  "employee.available_leave": "Leave Available",
  "employee.leave_taken": "Leave Taken",
  "employee.mass_leave": "Mass Leave",
  "employee.remaining_leave_this_periode": "Remaining Leave This Period",
  "employee.total_leave_days_this_month": "Total Leave Days This Month",
  "employee.total_leave_days_this_year": "Total Leave Days This Year",
  "employee.total_sick_days_this_month": "Total Sick Days This Month",
  "employee.total_sick_days_this_year": "Total Sick Days This Year",
  "employee.total_permit_days_this_month": "Total Permit Days This Month",
  "employee.total_permit_days_this_year": "Total Permit Days This Year",
  "employee.total_number_of_days_this_month": "Total Number of Days This Month",
  "employee.total_number_of_days_this_year": "Total Number of Days This Year",
  "employee.sick_leave_date": "Sick Leave Date",
  "employee.permission_date": "Permission Date",
  "employee.annual_leave": "Annual Leave",
  "employee.special_leave": "Special Leave",
  "employee.sick": "Sick",
  "employee.permission": "Permission",
  "employee.unpaid_leave": "Unpaid Leave",
  "employee.drag_file_to_upload_photo": "Drag file to upload photo",
  "employee.file_type": "File Type",
  "employee.employee_id": "Employee ID",
  "employee.full_name": "Full Name",
  "employee.date_of_birth": "Date of Birth",
  "employee.place_of_birth": "Place of Birth",
  "employee.gender": "Gender",
  "employee.male": "Male",
  "employee.female": "Female",
  "employee.maritial_status": "Maritial Status",
  "employee.married": "Married",
  "employee.not_married": "Not Married",
  "employee.blood_type": "Blood Type",
  "employee.religion": "Religion",
  "employee.nationality": "Nationality",
  "employee.personal_information": "Personal Information",
  "employee.contact_information": "Contact Information",
  "employee.contact": "Contact",
  "employee.no_hp": "Mobile Number",
  "employee.no_phone": "Telephone Number",
  "employee.address": "Address",
  "employee.identity_card_address": "Identity Card Address",
  "employee.residence_address": "Residence Address",
  "employee.residence_country": "Residence Country",
  "employee.residence_province": "Residence Province",
  "employee.residence_city": "Residence City",
  "employee.emergency_contact_name": "Emergency Contact Name",
  "employee.emergency_contact": "Emergency Contact",
  "employee.emergency_phone": "Emergency Phone",
  "employee.last_education": "Last Education",
  "employee.last_education_level": "Last Education Level",
  "employee.educational_institution_name": "Educational Institution Name",
  "employee.study_program": "Study Program",
  "employee.elementary_school": "Elementary School",
  "employee.junior_high_school": "Junior High School",
  "employee.senior_high_school": "Senior High School",
  "employee.bachelor_degree": "Bachelor Degree",
  "employee.attachment": "Attachment",
  "employee.employee_status": "Employee Status",
  "employee.join_date": "Join Date",
  "employee.resign_date": "Resign Date",
  "employee.job_position": "Job Position",
  "employee.schedule": "Schedule",
  "employee.select_schedule": "Select Schedule",
  "employee.salary": "Salary",
  "employee.base_salary": "Base Salary",
  "employee.effective_date": "Effective Date",
  "employee.account_number": "Account Number",
  "employee.bank": "Bank",
  "employee.branch": "Branch",
  "employee.owner_name": "Owner's name",
  "employee.NPWP": "NPWP",
  "employee.NPWP_number": "NPWP Number",
  "employee.BPJS": "BPJS",
  "employee.No_KPJ_BPJS_Ketenagakerjaan": "No KPJ BPJS Ketenagakerjaan",
  "employee.No_BPJS_Ketenagakerjaan": "No BPJS Ketenagakerjaan",
  "employee.No_BPJS_Kesehatan": "No BPJS Kesehatan",
  "employee.add_annual_leave_application": "Add Annual Leave Application",
  "employee.add_special_leave_application": "Add Special Leave Application",
  "employee.add_sick_leave_application": "Add Sick Leave Application",
  "employee.add_permission_application": "Add Permission Application",
  "employee.add_unpaid_leave_application": "Add Unpaid Leave Application",
  "employee.edit_annual_leave_application": "Edit Annual Leave Application",
  "employee.edit_special_leave_application": "Edit Special Leave Application",
  "employee.edit_sick_leave_application": "Edit Sick Leave Application",
  "employee.edit_permission_application": "Edit Permission Application",
  "employee.edit_unpaid_leave_application": "Edit Unpaid Leave Application",
  "employee.employee_name": "Employee Name",
  "employee.select_employee": "Select Employee",
  "employee.days": "Days",
  "employee.day": "Day",
  "employee.max": "Max",
  "employee.full_day_leave_date": "Full Day Leave Date",
  "employee.half_day_leave_date": "Half Day Leave Date",
  "employee.half_day_permission_date": "Half Day Permission Date",
  "employee.description": "Description",
  "employee.leave_description": "Leave Description",
  "employee.approved_or_rejected_description":
    "Approved / Rejected Description",
  "employee.type_of_leave": "Leave Type",
  "employee.identity": "Identity",
  "employee.place_date_of_birth": "Place, Date of Birth",
  "employee.taxpayer_status": "Taxpayer Status",
  "employee.bank_account": "Bank Account",
  "employee.are_you_sure_to_delete_employee":
    "Are you sure to delete employee?",
  "employee.country": "Country",
  "employee.province": "Province",
  "employee.city": "City",
  "employee.identity_card_types": "Tipe Kartu Identitas",
  "employee.id_identity_card": "ID Kartu Identitas",
  "employee.please_select": "Please select",
  "employee.please_input": "Please input",
  "employee.email_not_valid": "Email not valid",
  "employee.please_add_bank_account": "Please add Bank Account",
  "employee.citizen": "Citizen",
  "employee.working_period": "Working Period",
  "employee.edit_personal_identity": "Edit Personal Identity",
  "employee.edit_personal_contact": "Edit Personal Contact",
  "employee.edit_personal_education": "Edit Personal Education",
  "employee.edit_attachment": "Edit Attachment",
  "employee.edit_working_period": "Edit Working Period",
  "employee.edit_npwp": "Edit NPWP",
  "employee.edit_bpjs": "Edit BPJS",
  "employee.edit_bank_account": "Edit Bank Account",
  "employee.end_after": "End After",
  "employee.select_duration": "Select Duration",
  "employee.npwp_withholder": "NPWP Withholder",
  "employee.reason_for_status_change_npwp_status":
    "Reason for Status Change NPWP Status",
  "employee.information_detail_employee_change_status_npwp":
    "The status of this personnel tax NPWP has been changed by {{name}} on {{date}}, to {{status}} and will take effect in the next period {{year}}",
  "employee.not_active": "Not Active",
  "employee.active": "Active",
  "employee.terminated_date": "Terminated Date",
  "employee.terminated_reason": "Terminated Reason",
  "employee.login_ess": "Login ESS",
  "employee.last_activity": "Last Activity",
  "employee.havent_used_ess_yet": "Haven't used ESS yet",
  "employee.last_logged_in": "Last logged in",
  "employee.user_name": "User Name",
  "employee.login_email": "Email Login",
  "employee.role": "Role",
  "employee.havent_started_working_yet": "Haven't started working yet",
  "employee.the_emergency_telephone_number_cannot_be_the_same_as_the_main_telephone_number":
    "The emergency telephone number cannot be the same as the main telephone number.",
  "employee.enter_the_15_digit_npwp": "Enter the 15 digits NPWP",
  "employee.enter_the_15_or_16_digit_npwp": "Enter the 15 or 16 digits NPWP",
  "employee.enter_your_nik_npwp": "Enter your NIK/NPWP",
  "employee.attendance_daily": "Daily",
  "employee.title_attendance_daily": "Attendance Daily",
  "employee.sorry_employee_information_details_not_found":
    "Sorry, Employee Information Details Not Found.",
  "employee.back_to_list_employee": "Back to list employee",
  "employee.only_number_allowed": "Only number allowed",
  "employee.import_employee": "Import Employee",
  "employee.upload_file": "Upload File",
  "employee.check_data": "Check Data",
  "employee.import_in_progress": "Import in Progress",
  "employee.import_done": "Import Done",
  "employee.follow_these_steps_to_import_employee":
    "Follow these steps to import employee",
  "employee.follow_these_steps_to_import_update_employee":
    "Follow these steps to import update employee",
  "employee.1_download_file_template_employee":
    "1. Download file template employee",
  "employee.start_by_downloading_file_template_employee":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from employee.",
  "employee.download_file_template": "Download File Template",
  "employee.download_file_example": "Download File Example",
  "employee.2_copy_employee_data_into_the_template":
    "2. Copy employee data into the template",
  "employee.how_to_custom_file_template_employee":
    "Using Excel or other spreadsheet software, enter employee data into the template file from Gajihub. Make sure the employee data matches the header column in the template.",
  "employee.important": "Important",
  "employee.important_import_info":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 employee data can be imported at a time.<br/> When importing, Gajihub will only enter new data, no data will be deleted or updated.<br/>",
  "employee.3_upload_the_updated_template_here":
    "3. Upload the updated template here",
  "employee.save_the_updated_template_file": "Save the updated template file",
  "employee.drop_the_file_to_upload": "Drop the file to upload",
  "employee.please_check_the_imported_data": "Please check the imported data",
  "employee.how_to_action_import_data":
    'If the imported data is what you expected, please click the "Import Now" button.',
  "employee.valid": "Valid",
  "employee.invalid": "Invalid",
  "employee.total": "Total",
  "employee.country_name": "Country Name",
  "employee.mobile_number": "Mobile Number",
  "employee.telephone_number": "Telephone Number",
  "employee.work_placement": "Work Placement",
  "employee.npwp_status": "NPWP Status",
  "employee.npwp_number": "NPWP Number",
  "employee.import_now": "Import Now",
  "employee.import_employee_is_still_in_progress":
    "Import is still in progress",
  "employee.its_ok_to_close_this_page": `It's ok OK to close this page or import another file. When import done, you will be notified via email.`,
  "employee.row_imported": "{{imported}} of {{total}} rows imported",
  "employee.import_another_file": "Import Another File",
  "employee.go_to_the_employee_list_page": "Go to the employee list page",
  "employee.employee_successfully_imported":
    "{{rowCount}} employee successfully imported.",
  "employee.show_detail": "Show Detail",
  "employee.hide_detail": "Hide Detail",
  "employee.date_started_work_information":
    "The maximum date that can be selected is the date based on <span>the active date of the first career</span>. If you don't have a career, the date that can be chosen is any date",
  "employee.employee_termination_reminder": "Employee Termination Reminder",
  "employee.type_reminder_duration_employee_termination":
    "Type reminder duration employee termination",
  "employee.reminder_duration_employee_termination":
    "Reminder duration employee termination",
  "employee.reminder_duration_employee_termination_info":
    "Gajihub will automatically send a reminder to the admin when an employee's contract period is about to end.",
  "embloyee.how_long_before_the_contract_expires_a_reminder_email_is_sent":
    "How long before the contract expires a reminder email is sent?",
  "employee.end_of_employment_period": "End of Employement Period",
  "employee.reset_and_send_password": "Reset & Send Password",
  "employee.success_reset_password": "Success Reset Password",
  "employee.success_reset_password_desc":
    "Password reset was successfully sent to employee whatsapp and email. Please open your whatsapp and email inbox.",
  "employee.residence_address_is_the_same_as_the_identity_card_address":
    "Residence address is the same as the identity card address",
  "employee.is_the_data_to_be_deleted_confirmed":
    "Is the data to be deleted confirmed?",
  "employee.deleted_employee_data_cannot_be_restored_continue":
    "Deleted employee data <span>cannot be restored</span>, continue?",
  "employee.rehire": "Rehire",
  "employee.rehire_employee": "Rehire Employee",
  "employee.employee_has_been_rehired": "Employee has been rehired",
  "employee.export_employee_detail": "Export Employee Detail",
  "employee.select": "Select {{value}}",
  "employee.export_employee": "Export Employee",
  "employee.end_of_employment": "End of Employment",
  "employee.id_identity": "ID Identity",
  "employee.bpjs_of_employement": "BPJS of Employment",
  "employee.bpjs_healthcare": "BPJS Healthcare",
  "employee.working_status": "Working Status",

  "leave.leave_and_permission": "Leave & Permission",
  "leave.see_remaining_leave": "See Remaining Leave",
  "leave.approval_progress": "Approval Progress",
  "leave.annual_leave_application": "Annual Leave Application",
  "leave.special_leave_application": "Special Leave Application",
  "leave.sick_leave_application": "Sick Leave Application",
  "leave.permission_application": "Permission Application",
  "leave.unpaid_leave_application": "Unpaid Leave Application",
  "leave.view_submissions": "View Submissions",
  "leave.leave_allowance": "Leave Allowance",
  "leave.leave_taken": "Leave Taken",
  "leave.carry_forward": "Carry Forward",
  "leave.carry_forward_taken": "Carry Forward Taken",
  "leave.total_taken": "Total Taken",
  "leave.remaining_leave": "Remaining Leave",
  "leave.annual_leave": "Annual Leave",
  "leave.special_leave": "Special Leave",
  "leave.date_of_entry": "Date of Entry",
  "leave.same": "Same",
  "leave.at_once": "At Once",
  "leave.per_month": "Per Month",
  "leave.base_salary": "Base Salary",
  "leave.base_and_allowances": "Base + Allowances",
  "leave.does_the_leave_period_match_the_employee_date_of_entry_or_is_it_the_same_for_all_employees":
    "Does the leave period match the employee's date of entry or is it the same for all employees?",
  "leave.what_date_does_the_leave_period_reset_every":
    "What date does the leave period reset every?",
  "leave.how_many_months_after_work_does_the_employee_start_to_get_time_off":
    "How many months after work does the employee start to get time off",
  "leave.how_many_days_of_leave_do_employees_get_each_year":
    "How many days of leave do employees get each year?",
  "leave.do_employees_get_paid_leave_at_once_at_the_beginning_or_proportionally_every_month":
    "Do employees get paid leave at once at the beginning or proportionally every month?",
  "leave.can_the_remaining_leave_be_used_for_the_following_year":
    "Can the remaining leave be used for the following year?",
  "leave.can_the_remaining_leave_of_the_previous_year_be_cashed":
    "Can the remaining leave of the previous year be cashed?",
  "leave.the_rest_of_the_paid_leave_is_calculated_based_on_basic_salary_or_along_with_allowances":
    "The rest of the paid leave is calculated based on basic salary or along with allowances?",
  "leave.does_joint_leave_cut_the_leave_ration":
    "Does joint leave cut the leave ration?",
  "leave.type_of_special_leave": "Type of Special Leave",
  "leave.please_input_special_leave_name": "Please input special leave name.",
  "leave.max_day": "Max Day",
  "leave.please_input_max_day_leave": "Please input max day leave.",
  "leave.max_day_or_submission": "Max Day / Submission",
  "leave.add_special_leave": "Add Special Leave",
  "leave.edit_special_leave": "Edit Special Leave",
  "leave.delete_special_leave": "Delete Special Leave",
  "leave.select_special_leave": "Select Special Leave",
  "leave.are_you_sure_to_delete_special_leave":
    "Are you sure to delete special leave?",
  "leave.approval_rule": "Approval Rule",
  "leave.approval": "Approval",
  "leave.please_select_approval_rule": "Please select approval rule.",
  "leave.please_select_employee": "Please select employee.",
  "leave.please_select_leave_type": "Please select leave type.",
  "leave.please_input_date_request": "Please input date request.",
  "leave.please_input_date_leaves": "Please input date leaves.",
  "leave.please_select_approval": "Please select approval.",
  "leave.are_you_sure_to_delete_leave":
    "Are you sure to delete <span>{{leave_type}}</span>?",
  "leave.unable_to_apply_for_annual_leave": "Unable to apply for annual leave",
  "leave.select_date": "Select date",
  "leave.minimal_value": "Minimal value is {{value}}",
  "leave.archive": "Archive",
  "leave.cancel_archive": "Cancel Archive",
  "leave.are_you_sure_to_cancel_archive": "Are you sure to cancel archive?",
  "leave.remaining_annual_leave": "Remaining annual leave",
  "leave.view_leave_history": "View leave history",
  "leave.history_of_remaining_leave": "History of Remaining Leave",
  "leave.number_of_days": "Number of Days",
  "leave.remaining_leave_for_this_period": "Remaining Leave for This Period",
  "leave.remaining_leave_last_period": "Remaining Leave Last Period",
  "leave.description": "Description",
  "leave.cancel_approval": "Cancel Approval",
  "leave.cancel_rejection": "Cancel Rejection",
  "leave.are_you_sure_to_cancel_approval": "Are you sure to cancel approval?",
  "leave.are_you_sure_to_cancel_rejection": "Are you sure to cancel rejection?",
  "leave.remaining_leave_this_period": "Remaining Leave This Period",
  "leave.this_period_leave_taken": "This Period Leave Taken",
  "leave.last_period_leave_taken": "Last Periode Leave Taken",
  "leave.total_leave_remaining": "Total Leave Remaining",
  "leave.total_leave_taken": "Total Leave Taken",
  "leave.leave_remaining": "Leave Remaining",
  "leave.period": "Period",
  "leave.total_sick_days_this_month": "Total sick days this month",
  "leave.total_sick_days_this_year": "Total sick days this year",
  "leave.total_permission_days_this_month": "Total permission days this month",
  "leave.total_permission_days_this_year": "Total permission days this year",
  "leave.total_unpaid_leave_days_this_month":
    "Total unpaid leave days this month",
  "leave.total_unpaid_leave_days_this_year":
    "Total unpaid leave days this year",
  "leave.manage_leave_policy": "Manage Leave Policy",
  "leave.manage_special_leave_policy": "Manage Special Leave Policy",
  "leave.does_joint_leave_count_as_debt_for_employees_who_do_not_have_leave_allowance":
    "Does joint leave count as debt for employees who do not have leave allowance?",
  "leave.leave_and_permission_type": "Leave & Permission Type",
  "leave.does_the_remaining_annual_leave_for_the_past_period_have_a_limit":
    "Does the remaining annual leave for the past period have a limit?",
  "leave.what_is_the_maximum_remaining_annual_leave_from_the_previous_period_that_can_be_carried_over_to_the_next_period":
    "What is the maximum remaining annual leave from the previous period that can be carried over to the next period?",
  "leave.does_the_remaining_annual_leave_from_the_past_period_have_a_expiration_date":
    "Does the remaining annual leave from the past period have a expiration date?",
  "leave.how_many_months": "How many months?",
  "leave.does_annual_leave_is_calculated_as_attendance_on_the_attendance_based_component":
    "Does annual leave is calculated as attendance on the attendance based component?",
  "leave.the_maximum_number_of_leave_days_that_can_be_cashed_out":
    "The maximum number of leave days that can be cashed out",
  "leave.number_of_working_days_per_month": "Number of working days per month",
  "leave.cashable_leave_is_taxable": "Cashable leave is taxable?",
  "leave.tax_category": "Tax category",
  "leave.import_remaining_annual_leave": "Import Remaining Annual Leave",
  "leave.follow_these_steps_to_import_remaining_annual_leave":
    "Follow these steps to import remaining annual leave",
  "leave.1_download_file_template_remaining_annual_leave":
    "1. Download file template remaining annual leave",
  "leave.start_by_downloading_file_template_remaining_annual_leave":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from remaining annual leave.",
  "leave.2_copy_remaining_annual_leave_data_into_the_template":
    "2. Copy remaining annual leave data into the template",
  "leave.how_to_custom_file_template_remaining_annual_leave":
    "Using Excel or other spreadsheet software, insert remaining annual leave data into the template file from Gajihub. Make sure the remaining annual leave data matches the header column in the template.",
  "leave.important_import_info_remaining_annual_leave":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 remaining annual leave data can be imported at a time.<br/> When importing, Gajihub will only enter new data, no data will be deleted or updated.<br/>",
  "leave.remaining_annual_leave_successfully_imported":
    "{{rowCount}} remaining annual leave successfully imported.",
  "leave.go_to_the_remaining_annual_leave_list_page":
    "Go to remaining annual leave list page",
  "leave.sick_leave": "Sick Leave",
  "leave.are_employees_required_to_attach_files_when_applying_for_sick_leave":
    "Are employees required to attach files when applying for sick leave?",
  "leave.is_the_number_of_applications_limited_to_employees_who_apply_for_sick_leave":
    "Is the number of applications limited to employees who apply for sick leave?",
  "leave.what_is_the_maximum_number_of_sick_leave_applications_permitted_each_month":
    "What is the maximum number of sick leave applications permitted each month?",
  "leave.export_based_on_filters": "Export Based on Filters",
  "leave.export_all_leave_and_permissions": "Export All Leave and Permissions",
  "leave.how_many_days_before_the_first_leave_date_can_an_employee_apply_for_leave_from_the_ess_application":
    "How many days before the first leave date can an employee apply for leave from the ess application?",
  "leave.can_apply_for_half_day_annual_leaves":
    "Can apply for half day annual leaves?",
  "leave.does_annual_leave_have_a_maximum_number_of_consecutive_days_when_submitting_an_application":
    "Does annual leave have a maximum number of consecutive days when submitting an application?",
  "leave.what_is_the_maximum_number_of_consecutive_leaves_when_applying":
    "What is the maximum number of consecutive leaves when applying?",
  "leave.how_many_days_before_the_first_leave_date_can_an_employee_apply_for_full_day_leave_from_the_ess_application":
    "How many days before the first leave date can an employee apply for full day leave from the ess application?",
  "leave.how_many_days_before_the_first_leave_date_can_an_employee_apply_for_half_day_leave_from_the_ess_application":
    "How many days before the first leave date can an employee apply for half day leave from the ess application?",
  "leave.do_you_want_to_activate_the_automatic_addition_of_annual_leave_per_month":
    "Do you want to activate the automatic addition of annual leave per month?",
  "leave.on_what_date_is_the_monthly_annual_leave_allowance_added":
    "On what date is the monthly annual leave allowance added?",
  "leave.how_much_additional_monthly_annual_leave_is_added":
    "How much additional monthly annual leave is added?",
  "leave.on_date": "On date",
  "leave.total_remaining_annual_leave_deducted_by_the_date_of_joint_leave":
    "Total Remaining Annual Leave Deducted by The Date of Joint Leave",
  "leave.if_joint_leave_deducted_into_annual_leave_is_the_calculation_also_a_limitation_in_the_submission":
    "If mass leave deduction annual leave, is the calculation also a limitation in the submission?",
  "leave.summary_info_total_remaining_leave":
    "Information on total annual leave :<br/><ul><li>Total remaining annual leave = {{annual_leave_current_balance}}</li><li>Total next period mass leave = {{next_mass_leave_count}}</li><li>Total remaining annual leave if mass leave is deducted = {{annual_leave_balance}}</li></ul>",
  "leave.summary_info_total_remaining_leave_with_debt":
    "Information on total annual leave :<br/><ul><li>Total remaining annual leave = {{annual_leave_current_balance}}</li><li>Total remaining annual leave debt = {{annual_leave_total_current_debt_balance}}</li><li>Total next period mass leave = {{next_mass_leave_count}}</li><li>Total remaining annual leave if mass leave is deducted = {{annual_leave_balance}}</li></ul>",
  "leave.does_your_company_implement_an_annual_leave_debt_system":
    "Does your company implement an annual leave debt system?",
  "leave.maximum_number_of_days_of_annual_leave_debt":
    "Maximum number of days of annual leave debt",
  "leave.implement_an_annual_leave_debt_system":
    "Implement an Annual Leave Debt System",
  "leave.total_remaining_annual_leave_debt":
    "Total remaining annual leave debt",

  "attendance.attendance_data": "Presence Data",
  "attendance.daily_attendance_data": "Daily Attendance Data",
  "attendance.daily_attendance": "Daily Attendance",
  "attendance.summary_attendance_data": "Summary Attendance Data",
  "attendance.edit_attendance_employee": "Edit Attendance Employee",
  "attendance.daily": "Daily",
  "attendance.summary": "Summary",
  "attendance.shift": "Shift",
  "attendance.status": "Status",
  "attendance.in": "In",
  "attendance.out": "Out",
  "attendance.out_next_day": "Out Next Day",
  "attendance.overtime_duration": "Overtime Duration",
  "attendance.break_duration": "Break Duration",
  "attendance.detail_attendance": "Detail Attendance",
  "attendance.late": "Late",
  "attendance.note": "Note",
  "attendance.list_employee": "List Employee",
  "attendance.number_of_days": "Number of Days",
  "attendance.weekdays": "Weekdays",
  "attendance.attend_weekdays": "Attend Weekdays",
  "attendance.attend_not_weekdays": "Attend Not Weekdays",
  "attendance.working_hours": "Working Hours",
  "attendance.overtime_hours": "Overtime Hours",
  "attendance.annual_leave": "Annual Leave",
  "attendance.special_leave": "Special Leave",
  "attendance.sick": "Sick",
  "attendance.please_select_shift": "Please select shift",
  "attendance.please_select_status": "Please select status",
  "attendance.please_select_overtime_duration":
    "Please select overtime duration",
  "attendance.select_shift": "Select shift",
  "attendance.select_status": "Select status",
  "attendance.select_overtime_duration": "Select overtime duration",
  "attendance.please_input": "Please input",
  "attendance.presence_app_validation": "Presence App Validation",
  "attendance.add_setting": "Add Setting",
  "attendance.please_select": "Please select",
  "attendance.rule_name": "Rule Name",
  "attendance.applies_to": "Applies To",
  "attendance.please_add": "Please add",
  "attendance.setting_default": "Setting Default",
  "attendance.presence": "Presence",
  "attendance.overtime": "Overtime",
  "attendance.add_overtime": "Add Overtime",
  "attendance.client_visit": "Client Visit",
  "attendance.is_approval_always_required_at_the_time_of_attendance":
    "Is approval always required at the time of attendance?",
  "attendance.do_employees_have_to_be_present_from_within_the_specified_gps_radius":
    "Do employees have to be present from within the specified GPS radius?",
  "attendance.is_approval_required_for_presence_outside_the_gps_radius":
    "Is approval required for presence outside the GPS radius?",
  "attendance.do_employees_have_to_be_present_from_the_specified_ip_address":
    "Do employees have to be present from the specified IP address?",
  "attendance.is_approval_required_for_presence_outside_the_specified_ip":
    "Is approval required for presence outside the specified IP?",
  "attendance.do_employees_have_to_take_selfies_during_attendance":
    "Do employees have to take selfies during attendance?",
  "attendance.are_employees_able_to_input_attendance_time_manually":
    "Are employees able to input attendance time manually?",
  "attendance.is_approval_required_if_the_time_is_entered_manually":
    "Is approval required if the time is entered manually?",
  "attendance.is_approval_always_required_at_the_time_of_overtime":
    "Is approval always required at the time of overtime?",
  "attendance.do_employees_have_to_be_overtime_from_within_the_specified_gps_radius":
    "Do employees have to be overtime from within the specified GPS radius?",
  "attendance.is_approval_required_for_overtime_outside_the_gps_radius":
    "Is approval required for overtime outside the GPS radius?",
  "attendance.do_employees_have_to_be_overtime_from_the_specified_ip_address":
    "Do employees have to be overtime from the specified IP address?",
  "attendance.is_approval_required_for_overtime_outside_the_specified_ip":
    "Is approval required for overtime outside the specified IP?",
  "attendance.do_employees_have_to_take_selfies_during_overtime":
    "Do employees have to take selfies during overtime?",
  "attendance.are_employees_able_to_input_overtime_time_manually":
    "Are employees able to input overtime time manually?",
  "attendance.do_employees_have_to_take_selfies_during_client_visit":
    "Do employees have to take selfies during client visit?",
  "attendance.are_employees_only_able_to_presence_clock_out_when_working_hours_end":
    "Are employees only able to presence clock out when working hours end?",
  "attendance.break_started": "Break Started",
  "attendance.break_ended": "Break Ended",
  "attendance.break_started_next_day": "Break Started Next Day",
  "attendance.break_ended_next_day": "Break Ended Next Day",
  "attendance.overtime_started": "Overtime Started",
  "attendance.overtime_ended": "Overtime Ended",
  "attendance.overtime_ended_next_day": "Overtime Ended Next Day",
  "attendance.out_of_office_duty": "Out of Office Duty",
  "attendance.non_working_day": "Non Working Day",
  "attendance.leave": "Leave",
  "attendance.half_day_leave": "Half Day Leave",
  "attendance.joint_leave": "Joint Leave",
  "attendance.absent": "Absent",
  "attendance.no_status": "No Status",
  "attendance.here": "here",
  "attendance.you_can_specify_the_office_location_here":
    "You can specify the office location {{click_here}}",
  "attendance.you_can_specify_the_ip_address_of_your_office_here":
    "You can specify the IP address of your office {{click_here}}.",
  "attendance.time_is_not_changed_manually":
    "Time <span>{{type}}</span> is not changed manually",
  "attendance.time_is_changed_manually":
    "Time <span>{{type}}</span> is changed manually",
  "attendance.time_before_edit":
    "Time <span>{{type}}</span> before edit <span>{{time}}</span>",
  "attendance.uses_office_ip":
    "<div><span>{{type}}</span> uses office IP</div>",
  "attendance.does_not_use_office_ip":
    "<div><span>{{type}}</span> does not use office IP</div>",
  "attendance.please_upgrade_to_the_pro_or_elite_plan_to_see_the_ip_addresses_used_by_employees_for_type":
    "Please upgrade to the Pro or Elite plan to see the IP addresses used by employees for <span>{{type}}</span>",
  "attendance.please_upgrade_to_the_pro_or_elite_package_to_see_details_of_the_devices_used_by_employees_for_type":
    "Please upgrade to the Pro or Elite package to see details of the devices used by employees for <span>{{type}}</span>",
  "attendance.the_plan_only_displays_photos_from_the_last_duration":
    "The <span>{{plan}}</span> plan only displays photos from the last <span>{{duration}}</span>.",
  "attendance.please_upgrade_plan_to_display_photos":
    "Please <Link>upgrade plan</Link> to display photos",
  "attendance.does_attendance_automatically_come_out_if_employees_forget":
    "Does attendance automatically come out if employees forget?",
  "attendance.check_out_attendance_is_automatically_carried_out_after_the_presence_employee_has_entered_for_how_many_hours":
    "Check-out attendance is automatically carried out after the presence employee has entered for how many hours?",
  "attendance.hours": "Hours",
  "attendance.do_you_always_need_notes_on_client_visits":
    "Do you always need notes on client visits?",
  "attendance.client_visit_note": "Client visit note",
  "attendance.export_detail": "Export Detail",
  "attendance.export_summary": "Export Summary",
  "attendance.row": "Row",
  "attendance.export_attendance_summary": "Export Attendance Summary",
  "attendance.download": "Download {{type}}",
  "attendance.import_attendance": "Import Attendance",
  "attendance.follow_these_steps_to_import_attendance":
    "Follow these steps to import attendance",
  "attendance.1_download_file_template_attendance":
    "1. Download file template attendance",
  "attendance.start_by_downloading_file_template_attendance":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from attendance.",
  "attendance.2_copy_attendance_data_into_the_template":
    "2. Copy attendance data into the template",
  "attendance.how_to_custom_file_template_attendance":
    "Using Excel or other spreadsheet software, enter attendance data into the template file from Gajihub. Make sure the attendance data matches the header column in the template.",
  "attendance.important_import_info":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 attendance data can be imported at a time.<br/> When importing, Gajihub will only enter new data, no data will be deleted or updated.<br/>",
  "attendance.attendance_successfully_imported":
    "{{rowCount}} attendance successfully imported.",
  "attendance.go_to_the_attendance_list_page": "Go to attendance list page",
  "attendance.date_attendance": "Date Attendance",
  "attendance.late_time": "Late Time",
  "attendance.late_time_after_break": "Late Time After Break",
  "attendance.late_duration": "Late Duration",
  "attendance.late_duration_after_break": "Late Duration After Break",
  "attendance.does_it_always_need_attendance_note":
    "Does it always need attendance note?",
  "attendance.late_after_break": "Late After Break",
  "attendance.is_the_attendance_on_workdays_status_generated_from_the_employees_leaving_attendance":
    "Is the attendance on workdays status generated from the employee's leaving attendance?",
  "attendance.if_yes_the_automatic_exit_presence_does_not_apply_and_if_not_the_automatic_exit_presence_appears_and_can_be_adjusted":
    "If <span>Yes</span>, the automatic exit presence does not apply and if <span>Not</span>, the automatic exit presence appears and can be adjusted",
  "attendance.is_attendance_automatically_based_on_work_arrival_time":
    "Is attendance automatically based on work arrival time?",
  "attendance.if_it_is_not_active_then_it_is_based_on_employee_attendance":
    "If it is not active, then it is based on employee attendance.",
  "attendance.are_records_needed_when_employees_are_late_for_attendance_or_attendance_after_a_break":
    "Are records needed when employees are late for attendance or attendance after a break?",
  "attendance.how_to_calculate_late_employee_attendance":
    "How to calculate late employee attendance?",
  "attendance.is_attendance_clock_in_access_closed_if_an_employee_is_late":
    "Is attendance clock in access closed if an employee is late?",
  "attendance.is_face_recognition_verification_required_for_attendance":
    "Is face recognition verification required for attendance?",
  "attendance.is_face_recognition_verification_required_for_overtime":
    "Is face recognition verification required for overtime?",
  "attendance.is_face_recognition_verification_required_for_client_visit":
    "Is face recognition verification required for client visit?",
  "attendance.accuracy_level_of_face_recognition_verification_during_attendance":
    "Accuracy level of face recognition verification during attendance",
  "attendance.accuracy_level_of_face_recognition_verification_during_overtime":
    "Accuracy level of face recognition verification during overtime",
  "attendance.accuracy_level_of_face_recognition_verification_during_client_visit":
    "Accuracy level of face recognition verification during client visit",
  "attendance.low": "Low",
  "attendance.medium": "Medium",
  "attendance.high": "High",
  "attendance.import_overtime": "Import Overtime",
  "attendance.follow_these_steps_to_import_overtime":
    "Follow these steps to import overtime",
  "attendance.1_download_file_template_overtime":
    "1. Download file template overtime",
  "attendance.start_by_downloading_file_template_overtime":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from overtime.",
  "attendance.2_copy_overtime_data_into_the_template":
    "2. Copy overtime data into the template",
  "attendance.how_to_custom_file_template_overtime":
    "Using Excel or other spreadsheet software, enter overtime data into the template file from Gajihub. Make sure the overtime data matches the header column in the template.",
  "attendance.important_import_overtime_info":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 overtime data can be imported at a time.<br/> When importing, Gajihub will only enter new data, no data will be deleted or updated.<br/>",
  "attendance.overtime_successfully_imported":
    "{{rowCount}} overtime successfully imported.",
  "attendance.follow_these_steps_to_import_update_overtime":
    "Follow these steps to import update overtime",
  "attendance.1_download_file_template_update_overtime":
    "1. Download file template update overtime",
  "attendance.start_by_downloading_file_template_update_overtime":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from update overtime.",
  "attendance.2_copy_update_overtime_data_into_the_template":
    "2. Copy update overtime data into the template",
  "attendance.how_to_custom_file_template_update_overtime":
    "Using Excel or other spreadsheet software, enter update overtime data into the template file from Gajihub. Make sure the update overtime data matches the header column in the template.",
  "attendance.important_import_update_overtime_info":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 update overtime data can be imported at a time.<br/> When importing, Gajihub will only update data, no data will be delete or add.<br/>",
  "attendance.update_overtime_successfully_imported":
    "{{rowCount}} update overtime successfully imported.",
  "attendance.date_overtime": "Date Overtime",
  "attendance.overtime_clock_in": "Overtime Clock In",
  "attendance.overtime_clock_out": "Overtime Clock Out",
  "attendance.the_form_will_be_disabled_when_attendance_clock_in_access_is_closed_if_the_employee_is_late_for_being_active":
    "The form will be disabled when attendance clock in access is closed if the employee is late for being active",
  "attendance.can_attendance_only_be_carried_out_at_the_office_location_specified_in_the_shift":
    "Can attendance only be carried out at the office location specified in the shift?",
  "attendance.do_attendance_rules_also_apply_to_attendance_break":
    "Do attendance rules also apply to attendance break?",
  "attendance.does_the_break_schedule_reduce_work_duration_if_the_employee_does_not_attend_a_break":
    "Does the break schedule reduce work duration if the employee does not attend a break?",
  "attendance.the_form_will_be_disabled_when_employee_access_can_only_presence_clock_out_when_working_hours_end_is_active":
    "The form will be disabled when employee access can only presence clock out when active working hours end is active",
  "attendance.can_employees_only_be_present_at_the_specified_time":
    "Can employees only be present at the specified time?",
  "attendance.minutes_before_attendance_clock_in":
    "Minutes before attendance clock in",
  "attendance.minutes_after_attendance_clock_out":
    "Minutes after attendance clock out",
  "attendance.if_zero_then_there_is_no_limit":
    "If zero, then there is no limit",
  "attendance.what_is_the_maximum_client_visit_per_day":
    "What is the maximum client visit per day?",
  "attendance.if_0_then_there_is_no_limit_to_client_visits_per_day":
    "If 0, then there is no limit to client visits per day.",
  "attendance.please_enter_maximum_client_visits_per_day":
    "Please enter maximum client visits per day",
  "attendance.what_is_the_maximum_duration_of_overtime_in_a_day":
    "What is the maximum duration of overtime in a day?",
  "attendance.if_0_then_there_is_no_limit_to_overtime_per_day":
    "If 0, then there is no limit to overtime per day.",
  "attendance.do_employees_have_to_be_client_visit_from_within_the_specified_gps_radius":
    "Do employees have to be client visit from within the specified GPS radius?",
  "attendance.is_approval_required_for_client_visit_outside_the_gps_radius":
    "Is approval required for client visit outside the GPS radius?",
  "attendance.time_on_device": "Time {{value}} on {{device}}",
  "attendance.location_tracking": "Location Tracking",
  "attendance.overtime_type": "Overtime Type",
  "attendance.please_select_overtime_type": "Please select overtime type",
  "attendance.presence_location": "Presence Location",
  "attendance.what_is_the_maximum_duration_of_working_hours_in_a_day":
    "What is the maximum duration of working hours in a day?",
  "attendance.if_0_then_there_is_no_limit_to_working_hours_per_day":
    "If 0, then there is no limit to working hours per day.",
  "attendance.export_daily_attendance": "Export Daily Attendance",
  "attendance.export_overtime": "Export Overtime",
  "attendance.export_detail_attendance": "Export Detail Attendance",
  "attendance.organization": "Organization",
  "attendance.in_attendance_note": "In Attendance Note",
  "attendance.in_attendance_location": "In Attendance Location",
  "attendance.in_attendance_location_url": "In Attendance Location URL",
  "attendance,in_attendance_photo": "In Attendance Photo",
  "attendance.out_attendance_note": "Out Attendance Note",
  "attendance.out_attendance_location": "Out Attendance Location",
  "attendance.out_attendance_location_url": "Out Attendance Location URL",
  "attendance,out_attendance_photo": "Out Attendance Photo",
  "attendance.break_started_note": "Break Started Note",
  "attendance.break_started_location": "Break Started Location",
  "attendance.break_started_location_url": "Break Started Location URL",
  "attendance.break_started_photo": "Break Started Photo",
  "attendance.break_ended_note": "Break Ended Note",
  "attendance.break_ended_location": "Break Ended Location",
  "attendance.break_ended_location_url": "Break Ended Location URL",
  "attendance.break_ended_photo": "Break Ended Photo",
  "attendance.export_late_after_break": "Late After Break",
  "attendance.out_attendance_early": "Out Attendance Early",
  "attendance.overtime_started_note": "Overtime Started Note",
  "attendance.overtime_started_location": "Overtime Started Location",
  "attendance.overtime_started_location_url": "Overtime Started Location URL",
  "attendance.overtime_started_photo": "Overtime Started Photo",
  "attendance.overtime_ended_note": "Overtime Ended Note",
  "attendance.overtime_ended_location": "Overtime Ended Location",
  "attendance.overtime_ended_location_url": "Overtime Ended Location URL",
  "attendance.overtime_ended_photo": "Overtime Started Photo",
  "attendance.overitme_hours": "Overtime Hours",
  "attendance.total_overtime_hours": "Total Overtime Hours",
  "attendance.import_add_overtime": "Import Add Overtime",
  "attendance.import_update_overtime": "Import Update Overtime",
  "attendance.overtime_id": "Overtime ID",
  "attendance.office_hours": "Office Hours",
  "attendance.break_hours": "Break Hours",
  "attendance.setting_export": "Setting Export",
  "attendance.attendance_location_filter_description_info":
    "This attendance location filter will only display attendance that has been done through <strong>the ESS application</strong> or <strong>Fingerprint</strong>. If the attendance is input manually, it will not appear because it does not have a record of the attendance location.",

  "attendanceLocation.office_location": "Office Location",
  "attendanceLocation.name": "Name",
  "attendanceLocation.address": "Address",
  "attendanceLocation.employee_placement": "Employee Placement",
  "attendanceLocation.employee": "Employee",
  "attendanceLocation.add_location": "Add Location",
  "attendanceLocation.location_name": "Location Name",
  "attendanceLocation.add_office_location": "Add Office Location",
  "attendanceLocation.edit_office_location": "Edit Office Location",
  "attendanceLocation.please_input_location_name":
    "Please input location name.",
  "attendanceLocation.please_input_address": "Please input address.",
  "attendanceLocation.please_input_ip_address": "Please input IP address.",
  "attendanceLocation.please_input_employee": "Please input employee.",
  "attendanceLocation.please_add_ip_address": "Please add IP address.",
  "attendanceLocation.please_select_location": "Please select location.",
  "attendanceLocation.please_input_ip": "Please input IP.",
  "attendanceLocation.latitude": "Latitude",
  "attendanceLocation.longitude": "Longitude",
  "attendanceLocation.ip_address": "IP Address",
  "attendanceLocation.ip": "IP",
  "attendanceLocation.please_add_employee_placement":
    "Please add employee placement.",
  "attendanceLocation.please_input_name": "Please input name.",
  "attendanceLocation.radius": "Radius",
  "attendanceLocation.please_input_radius": "Please input radius.",
  "attendanceLocation.are_you_sure_to_delete_office_location":
    "Are you sure to delete office location?",
  "attendanceLocation.location": "Location",
  "attendanceLocation.help_radius_info":
    "Determine the radius of your office location from the coordinates. You can set so that employees can only make attendance from within the radius. {{click_here}} for the presence validation settings.",
  "attendanceLocation.help_ip_info":
    "Assign one or more of your office internet IP addresses. You can set employees to only be present if they use an office IP address. {{click_here}} for the presence validation settings.",
  "attendanceLocation.help_employee_placement_info":
    "Determine which employees can make a presence at this office location.",
  "attendanceLocation.please_select_your_office_location_point":
    "Please select your office location point. These coordinates will be used in determining the radius tolerance when employees are absent",
  "attendanceLocation.without_archive": "Without archive",
  "attendanceLocation.with_archive": "With archive",
  "attendanceLocation.show_archive": "Show Archive",
  "attendanceLocation.select_office_location": "Select Office Location",

  "attendanceHistory.select_type": "Select type",
  "attendanceHistory.type": "Type",
  "attendanceHistory.location_status": "Location Status",
  "attendanceHistory.ip_address": "IP Address",
  "attendanceHistory.note": "Note",
  "attendanceHistory.location": "Location",
  "attendanceHistory.radius": "Radius",
  "attendanceHistory.coordinate": "Coordinate",
  "attendanceHistory.show_map": "Show Map",
  "attendanceHistory.hide_map": "Hide Map",
  "attendanceHistory.ip_status": "IP Status",
  "attendanceHistory.phone_type": "Phone Type",
  "attendanceHistory.within_the_radius": "Within Radius",
  "attendanceHistory.out_of_radius": "Out of Radius",
  "attendanceHistory.within_the_range": "Within Range",
  "attendanceHistory.out_of_range": "Out of Range",

  "attendanceApproval.title": "Presence Approval",
  "attendanceApproval.type": "Type",
  "attendanceApproval.time": "Time",
  "attendanceApproval.location": "Location",
  "attendanceApproval.description": "Description",
  "attendanceApproval.action": "Action",
  "attendanceApproval.presence": "Presence",
  "attendanceApproval.break": "Break",
  "attendanceApproval.overtime": "Overtime",
  "attendanceApproval.client_visit": "Client Visit",
  "attendanceApproval.in_the_office": "In the Office",
  "attendanceApproval.out_of_office": "Out of Office",
  "attendanceApproval.manage_presence_policy": "Manage Presence Policy",

  "clientVisit.title": "Client Visit",
  "clientVisit.name": "Name",
  "clientVisit.in": "In",
  "clientVisit.out": "Out",
  "clientVisit.duration": "Duration",
  "clientVisit.visit_in": "Visit In",
  "clientVisit.visit_out": "Visit Out",
  "clientVisit.detail_client_visit": "Detail Client Visit",
  "clientVisit.view_map": "View Map",
  "clientVisit.hide_map": "Hide Map",
  "clientVisit.manage_client_visit_policy": "Manage Client Visit Policy",
  "clientVisit.is_approval_always_required_at_the_time_of_client_visit":
    "Is approval always required at the time of client visit?",
  "clientVisit.open_google_maps": "Open Google Maps",
  "clientVisit.empty_message":
    "Client visits are used to record your team's attendance when visiting clients outside the office. <br/>You can set your visiting policy by <Link>click here</Link>.",
  "clientVisit.client_list": "Client List",
  "clientVisit.client_location": "Client Location",
  "clientVisit.export_client_visit": "Export Client Visit",
  "clientVisit.employee_id": "Employee ID",
  "clientVisit.day": "Day",
  "clientVisit.visit_started": "Visit Started",
  "clientVisit.visit_ended": "Visit Ended",
  "clientVisit.visit_started_latitude": "Latitude (Visit Started)",
  "clientVisit.visit_started_longitude": "Longitude (Visit Ended)",
  "clientVisit.visit_started_address": "Visit Started Address",
  "clientVisit.visit_started_location_url": "Visit Started Location URL",
  "clientVisit.visit_ended_latitude": "Latitude (Visit Ended)",
  "clientVisit.visit_ended_longitude": "Longitude (Visit Ended)",
  "clientVisit.visit_ended_address": "Visit Ended Address",
  "clientVisit.visit_ended_location_url": "Visit Ended Location URL",
  "clientVisit.visit_note_in": "Visit Started Note",
  "clientVisit.visit_note_out": "Visit Ended Note",

  "schedule.schedule": "Schedule",
  "schedule.work_schedule": "Work Schedule",
  "schedule.all": "All",
  "schedule.fixed_schedule": "Fixed Schedule",
  "schedule.shift_schedule": "Shift Schedule",
  "schedule.add_work_schedule": "Add Schedule",
  "schedule.edit_schedule": "Edit Schedule",
  "schedule.detail_schedule": "Detail Schedule",
  "schedule.detail": "Detail",
  "schedule.weekdays": "Weekdays",
  "schedule.holiday": "Holiday",
  "schedule.effective_date": "Effective Date",
  "schedule.status": "Status",
  "schedule.valid_at_this_time": "Valid at this time",
  "schedule.add_schedule": "Add Schedule",
  "schedule.view_calendar": "View Calendar",
  "schedule.effective_starting_date": "Effective starting date",
  "schedule.change_employee_work_schedule": "Change Employee Work Schedule",
  "schedule.list_employee": "List Employee",
  "schedule.change_the_employee_schedule_above_with_the_following_work_schedule":
    "Change the employee schedule above with the following work schedule",
  "schedule.select_schedule": "Select Schedule",
  "schedule.effective_from_the_date": "Effective from the date",
  "schedule.please_select_date": "Please select date",
  "schedule.name": "Name",
  "schedule.work_pattern_name": "Work Pattern Name",
  "schedule.select_shift": "Select Shift",
  "schedule.add_line": "Add Line",
  "schedule.effective_from_date": "Effective from date",
  "schedule.national_holidays_still_in": "National holidays still in",
  "schedule.leave_together_still_in": "Leave together still in",
  "schedule.flexible_working_hours": "Flexible Working Hours",
  "schedule.delay_tolerance": "Delay tolerance",
  "schedule.please_input_schedule_name": "Please input schedule name.",
  "schedule.please_input_effective_date": "Please input effective date.",
  "schedule.please_input_delay_tolerance": "Please input delay tolerance.",
  "schedule.please_add_shift": "Please add shift.",
  "schedule.please_select_shift": "Please select shift.",
  "schedule.shift_work_schedule": "Shift work Schedule.",
  "schedule.are_you_sure_to_delete_schedule":
    "Are you sure to delete schedule?",
  "schedule.are_you_sure_to_delete_shift_schedule":
    "Are you sure to delete shift schedule?",
  "schedule.shift_list": "Shift List",
  "schedule.still_in": "still in",
  "schedule.days_off": "days off",
  "schedule.flexible": "flexible",
  "schedule.not_flexible": "not flexible",
  "schedule.national_holidays": "National holidays",
  "schedule.joint_leave": "Joint leave",
  "schedule.late_tolerance": "Late tolerance",
  "schedule.working_hours": "Working hours",
  "schedule.minute": "minute",
  "schedule.schedule_name": "Schedule Name",
  "schedule.if_flexible_working_hours_are_set_delays_in_employee_attendance_will_be_ignored":
    "If flexible working hours are set, delays in employee attendance will be ignored.",
  "schedule.how_many_minutes_is_the_employees_late_tolerance":
    "How many minutes is the employee's late tolerance?",
  "schedule.if_the_employee_is_late_for_attendance_below_the_tolerance_then_the_delay_will_be_ignored":
    "If the employee is late for attendance below the tolerance, then the delay will be ignored.",
  "schedule.add_new_schedule": "Add New Schedule",
  "schedule.can_be_edited": "Can be edited",
  "schedule.cannot_be_edited": "Cannot be edited",
  "schedule.are_you_sure_mass_edit":
    "Are you sure edit {{count}} work schedule?",
  "schedule.total_can_be_edited_is_and_cannot_be_edited_is":
    "Total work schedule can be edited is {{editable}}, and cannot be edited is {{uneditable}}",
  "schedule.confirm_edit_work_schedule": "Confirm Edit Work Schedule",
  "schedule.work_schedule_details_cannot_be_edited":
    "Detail work schedule cannot be edited",
  "schedule.import_shift_schedule": "Import Shift Schedule",
  "schedule.shift_date": "Shift Date",
  "schedule.follow_these_steps_to_import_shift_schedule":
    "Follow these steps to import shift schedule",
  "schedule.1_download_file_template_shift_schedule":
    "1. Download file template shift schedule",
  "schedule.start_by_downloading_file_template_shift_schedule":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from shift schedule.",
  "schedule.2_copy_shift_schedule_data_into_the_template":
    "2. Copy shift schedule data into the template",
  "schedule.how_to_custom_file_template_shift_schedule":
    "Using Excel or other spreadsheet software, enter shift schedule data into the template file from Gajihub. Make sure the shift schedule data matches the header column in the template.",
  "schedule.important_import_info":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 shift schedule data can be imported at a time.<br/> When importing, Gajihub will only enter new data, no data will be deleted or updated.<br/>",
  "schedule.shift_schedule_successfully_imported":
    "{{rowCount}} shift schedule successfully imported.",
  "schedule.go_to_the_shift_schedule_list_page":
    "Go to shift schedule list page",
  "schedule.if_flexible_break_time_are_set_delays_in_employee_attendance_will_be_ignored":
    "If flexible break time are set delays in employee attendance after break time will be ignored.",
  "schedule.how_many_minutes_is_the_employees_late_tolerance_after_break_time":
    "How many minutes is the employee late tolerance after break time?",
  "schedule.if_the_employee_is_late_for_attendance_after_break_time_below_the_tolerance_then_the_delay_will_be_ignored":
    "If the employee is late for attendance after break time below the tolerance, then the delay will be ignored.",
  "schedule.select_shift_schedule": "Select Shift Schedule",

  "career.career_title": "Career",
  "career.employee_status": "Employee Status",
  "career.work_start_date": "Work Start Date",
  "career.work_started": "Work Started",
  "career.working_period": "Working Period",
  "career.organization": "Organization",
  "career.job_level": "Job Level",
  "career.job_position": "Job Position",
  "career.effective_date": "Effective Date",
  "career.add_career": "Add Career",
  "career.edit_career": "Edit Career",
  "career.terminated": "Terminated",
  "career.cancel_terminated": "Cancel Terminated",
  "career.placement": "Placement",
  "career.permanent_employee": "Permanent Employee",
  "career.interns": "Interns",
  "career.pkwt": "PKWT",
  "career.select_employee_status": "Select Employee Status",
  "career.please_select_employee_status": "Please select employee status.",
  "career.work_placement": "Work Placement",
  "career.select_work_placement": "Select Work Placement",
  "career.please_select_work_placement": "Please select work placement.",
  "career.select_organization": "Select Organization",
  "career.please_select_organization": "Please select organization.",
  "career.select_job_level": "Select Job Level",
  "career.please_select_job_level": "Please select job level.",
  "career.select_job_position": "Select Job Position",
  "career.please_select_job_position": "Please select job position.",
  "career.please_input_effective_date": "Please input effective date.",
  "career.end_employment_date": "End of Employment Date",
  "career.end_employment": "End of Employment",
  "career.please_input_date": "Please input date.",
  "career.select_month": "Select Month",
  "career.please_select_month": "Please select month.",
  "career.termination_employee": "Termination Employee",
  "career.reason_termination": "Reason Termination",
  "career.select_reason_termination": "Select Reason Termination",
  "career.please_select_reason_termination":
    "Please select reason termination.",
  "career.termination_date": "Termination Date",
  "career.please_input_termination_date": "Please input termination date",
  "career.month_after_effective": "Month after effective",
  "career.are_you_sure_to_delete_employee_career":
    "Are you sure to delete employee career?",
  "career.income_type": "Income Type",
  "career.please_select_income_type": "Please select income type.",
  "career.income_withholder": "Income Withholder",
  "career.please_select_income_withholder": "Please select income withholder.",
  "career.are_you_sure_to_cancel_terminated_employee":
    "Are you sure to cancel terminated employee?",

  "shift.add_shift": "Add Shift",
  "shift.edit_shift": "Edit Shift",
  "shift.please_input_shift_name": "Please input shift name.",
  "shift.please_input_time": "Please input time.",
  "shift.name": "Name",
  "shift.entry_hours": "Entry Hours",
  "shift.hours_start": "Hours start",
  "shift.hours_end": "Hours end",
  "shift.background_color": "Background Color",
  "shift.are_you_sure_to_delete_shift": "Are you sure to delete shift?",
  "shift.shift_updated_successfully": "Shift updated successfully",
  "shift.shift_failed_to_update": "Shift failed to update",
  "shift.break_time": "Break Time",
  "shift.break_time_is_flexible": "Break Time is Flexible",
  "shift.late_tolerance_after_break": "Late Tolerance After Break",
  "shift.type_shift": "Type Shift",
  "shift.shift_full_day": "Shift Full Day",
  "shift.shift_half_day": "Shift Half Day",
  "shift.work_location": "Work Location",

  "error.an_error_happened": "An Error Happened",
  "error.failed_to_load_data_from_server": "Failed to load data from server!",
  "error.failed_to_delete_data_from_server":
    "Failed to delete data from server!",
  "error.failed_save_data_to_server": "Failed save data to server!",
  "error.failed_update_data_to_server": "Failed update data to server!",
  "error.max_characters": "Max {{value}} characters.",
  "error.min_characters": "Min {{value}} characters.",
  "error.sorry_the_page_you_visited_does_not_exist":
    "Sorry, the page you visited does not exist.",

  "payroll.title": "Payroll",
  "payroll.payroll": "Payroll",
  "payroll.effective_date": "Effective Date",
  "payroll.ump": "UMP",
  "payroll.work_safety_guarantee": "Work Safety Guarantee",
  "payroll.life_insurance": "Life Insurance",
  "payroll.calculation_method": "Calculation Method PPH 21/26",
  "payroll.are_you_sure_to_delete_payroll": "Are you sure to delete payroll?",
  "payroll.monthly_salary": "Monthly Salary",
  "payroll.meal_allowances": "Meal Allowances",
  "payroll.income_custom": "Income Custom",
  "payroll.salary_deductions_custom": "Salary Deductions",
  "payroll.total": "Total",
  "payroll.basic_salary": "Basic Salary",
  "payroll.fixed_income": "Fixed Income",
  "payroll.fixed_deductions": "Fixed Deductions",
  "payroll.thr": "THR",
  "payroll.yearly_bonus": "Yearly Bonus",
  "payroll.payroll_history": "Payroll History",
  "payroll.salary_adjustment": "Salary Adjustment",
  "payroll.pay_slip": "Pay Slip",
  "payroll.amount_of_fixed_income_and_fixed_deductions":
    "Amount of fixed income and fixed deductions",
  "payroll.add_payroll": "Add Payroll",
  "payroll.duplicate_payroll": "Duplicate Payroll",
  "payroll.edit_payroll": "Edit Payroll",
  "payroll.select_pay_slip": "Select Pay Slip",
  "payroll.income": "Income",
  "payroll.deductions": "Deductions",
  "payroll.total_take_home_pay": "Total Take Home Pay",
  "payroll.default_settings": "Default Settings",
  "payroll.advanced_settings": "Advanced Settings",
  "payroll.work_accident_insurance_risk_level_group":
    "Work Accident Insurance Risk Level Group",
  "payroll.death_benefit_percentage": "Death Benefit Percentage",
  "payroll.overtime_pay": "Overtime Pay",
  "payroll.fixed_amount_per_hour": "Fixed amount per hour",
  "payroll.meal_allowance": "Meal Allowance",
  "payroll.per_presence": "Per presence",
  "payroll.sales_bonus": "Sales Bonus",
  "payroll.multiplier_unit_is_included_in_salary":
    "Multiplier unit is included in salary",
  "payroll.performance_bonus": "Performance Bonus",
  "payroll.total_income": "Total Income",
  "payroll.social_gathering_fee": "Social gathering fee",
  "payroll.late_charge": "Late charge",
  "payroll.marriage_friend_fees": "Marriage friend fees",
  "payroll.total_deductions": "Total Deductions",
  "payroll.add_non_fixed_slip": "Add Non Fixed Slip",
  "payroll.slip": "Slip",
  "payroll.periode": "Period",
  "payroll.employee": "Employee",
  "payroll.ready_to_pay": "Ready To Pay",
  "payroll.already_paid": "Already Paid",
  "payroll.first_period": "First Period",
  "payroll.end_of_period": "End of Period",
  "payroll.name_non_fixed_slip": "Name Non Fixed Slip",
  "payroll.detail": "Detail",
  "payroll.pay_salary": "Pay Salary",
  "payroll.change_to_ready_to_pay": "Change to Ready to Pay",
  "payroll.bank": "Bank",
  "payroll.attendance_status": "Attendance Status",
  "payroll.payment_status": "Payment Status",
  "payroll.not_ready": "Not Ready",
  "payroll.transportation_allowance": "Transportation Allowance",
  "payroll.pph_21": "PPh 21",
  "payroll.income_name": "Income Name",
  "payroll.add_thp": "Add THP",
  "payroll.taxable": "Taxable",
  "payroll.amount": "Amount",
  "payroll.cash_receipt": "Cash Receipt",
  "payroll.cash_receipt_name": "Cash Receipt Name",
  "payroll.will_be_repaid_for": "Will be repaid for",
  "payroll.cuts": "Deduction",
  "payroll.cuts_name": "Deduction Name",
  "payroll.tax_deduction": "Tax Deduction",
  "payroll.payment": "Payment",
  "payroll.choose_payment_method": "Choose payment method",
  "payroll.payment_confirmation": "Payment Confirmation",
  "payroll.once_confirmed_salary_slips_cannot_be_changed_or_deleted_again":
    "Once confirmed, salary slips cannot be changed or deleted again.",
  "payroll.total_payment": "Total Payment",
  "payroll.total_employee": "Total Employee",
  "payroll.are_you_sure_to_confirm_the_following_salary_payments":
    "Are you sure to confirm the following salary payments?",
  "payroll.success": "Success",
  "payroll.payment_confirmation_successful": "Payment confirmation successful!",
  "payroll.salary_payment_steps": "Salary payment steps",
  "payroll.payment_instructions_1":
    "Make sure your company account has been added. {{click_here}} to add.",
  "payroll.payment_instructions_2":
    "Make sure the payslip is ready to pay. Only those with Ready to Pay status will appear in the downloaded file.",
  "payroll.payment_instructions_3":
    "Download the salary list according to the bank of each employee.",
  "payroll.payment_instructions_4":
    "Make payroll payments to their respective banks.",
  "payroll.payment_instructions_5":
    "Return to this page, and click the Confirm button.",
  "payroll.please_input_effective_date": "Please input effective date.",
  "payroll.salary_slip": "Salary Slip",
  "payroll.add_salary_slip": "Add Salary Slip",
  "payroll.edit_salary_slip": "Edit Salary Slip",
  "payroll.name": "Name",
  "payroll.salary_slip_name": "Salary Slip Name",
  "payroll.please_input_salary_slip_name": "Please input salary slip name.",
  "payroll.period": "Period",
  "payroll.period_started": "Period Started",
  "payroll.period_started_on_date": "Period Started On Date",
  "payroll.please_select_period_started": "Please select period started.",
  "payroll.duration_period": "Duration Period",
  "payroll.please_select_period": "Please select period.",
  "payroll.please_input_count_days": "Please input count days.",
  "payroll.please_select_duration_period": "Please select duration period.",
  "payroll.number_of_days_in_period": "Number of Days In Period",
  "payroll.please_select_number_of_days_in_period":
    "Please select number of days in period.",
  "payroll.previous_slip_date": "Previous Slip Date",
  "payroll.please_input_previous_slip_date": "Please input previous slip date.",
  "payroll.for_the_income_component_depending_on_attendance_how_many_days_of_the_last_attendance_count":
    "For the income component depending on attendance, how many days of the last attendance count",
  "payroll.days_before_the_end_of_the_period":
    "days before the end of the period",
  "payroll.select_the_income_component_and_the_discount_component_for_this_payslip":
    "Select the income component and the discount component for this payslip",
  "payroll.income_component": "Income Component",
  "payroll.cut_component": "Cut Component",
  "payroll.add_income_component": "Add Income Component",
  "payroll.edit_income_component": "Edit Income Component",
  "payroll.detail_income_component": "Detail Income Component",
  "payroll.add_cut_component": "Add Cut Component",
  "payroll.edit_cut_component": "Edit Cut Component",
  "payroll.detail_cut_component": "Detail Cut Component",
  "payroll.please_select_income_component_or_cut_component":
    "Please select income component or cut component.",
  "payroll.input_count_days": "Input count days",
  "payroll.component_type": "Component Type",
  "payroll.please_select_component_type": "Please select component type.",
  "payroll.please_input_name": "Please input name.",
  "payroll.taxable_income": "Taxable Income",
  "payroll.tax_category": "Tax Category",
  "payroll.please_select_tax_category": "Please select tax category.",
  "payroll.tax_reduction": "Tax Reduction",
  "payroll.are_you_sure_to_delete_income_component":
    "Are you sure to delete income component?",
  "payroll.are_you_sure_to_delete_cut_component":
    "Are you sure to delete cut component?",
  "payroll.are_you_sure_to_delete_salary_slip":
    "Are you sure to delete salary slip?",
  "payroll.please_add": "Please add.",
  "payroll.please_add_salary_slip": "Please add salary slip.",
  "payroll.input_every_time_you_make_payroll":
    "Input every time you make payroll",
  "payroll.please_input": "Please input",
  "payroll.per_hour": "per hour",
  "payroll.please_select_work_accident_insurance_risk_level_group":
    "Please select work accident insurance risk level group (JKK).",
  "payroll.please_select_death_benefit_percentage":
    "Please select death benefit percentage (JKM).",
  "payroll.please_select_calculation_method":
    "Please select calculation method PPH 21/26.",
  "payroll.amount_fixed_income_and_fixed_deductions":
    "Amount of fixed income and fixed deductions",
  "payroll.please_select_salary_slip": "Please select salary slip.",
  "payroll.please_input_first_period": "Please input first period.",
  "payroll.please_input_end_period": "Please input end period.",
  "payroll.attendance": "Attendance",
  "payroll.please_input_income_name": "Please input income name.",
  "payroll.salary_history": "Salary History",
  "payroll.status": "Status",
  "payroll.not_ready_pay": "Not Ready Pay",
  "payroll.failed_to_download_file": "Failed to download file",
  "payroll.view_slip": "View Slip",
  "payroll.split_salary_slip": "Split Salary Slip",
  "payroll.cancel_split_salary_slip": "Cancel Split Salary Slip",
  "payroll.are_you_sure_to_cancel_split_salary_slip":
    "Are you sure to cancel split salary slip?",
  "payroll.latest_salary_slip": "Latest Salary Slip",
  "payroll.please_choose_a_payslip_that_has_a_basic_salary":
    "Please choose a payslip that has a basic salary",
  "payroll.view_employee_details": "View Employee Details",
  "payroll.click_here_to_add_a_new_pay_slip":
    "Click here to add a new pay slip.",
  "payroll.borne_by_the_company": "borne by the company",
  "payroll.please_select": "Please select <span>{{type}}</span>",
  "payroll.late_charge_per_minutes_description":
    "This deduction is taken based on employees <strong>Late</strong> per minutes",
  "payroll.late_charge_description":
    "This deduction is taken based on employees <strong>Late</strong> duration in the presence",
  "payroll.late_after_break_charge_description":
    "This deduction is taken based on employees <strong>Late</strong> duration after break",
  "payroll.deduction_unpaid_leave_description":
    "This deduction was taken based on the number of <strong>Unpaid Leave</strong> from the proposed and approved employee",
  "payroll.deduction_absence_description":
    "This deduction is taken based on the amount of <strong>Absence</strong> or <strong>Absent Status</strong> of employees in the presence",
  "payroll.the_payroll_effective_date_selected_cannot_be_less_than_the_current_effective_employee_career_date":
    "<strong>The payroll effective date</strong> selected cannot be less than the current employee <strong>effective career date</strong>",
  "payroll.import": "Import Payroll",
  "payroll.follow_these_steps_to_import_payroll":
    "Follow these steps to import payroll",
  "payroll.1_download_file_template_payroll":
    "1. Download file template payroll",
  "payroll.start_by_downloading_file_template_payroll":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from payroll.",
  "payroll.2_copy_payroll_data_into_the_template":
    "2. Copy payroll data into the template",
  "payroll.how_to_custom_file_template_payroll":
    "Using Excel or other spreadsheet software, enter payroll data into the template file from Gajihub. Make sure the payroll data matches the header column in the template.",
  "payroll.important_import_info":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 payroll data can be imported at a time.<br/> When importing, Gajihub will only enter new data, no data will be deleted or updated.<br/>",
  "payroll.payroll_successfully_imported":
    "{{rowCount}} payroll successfully imported.",
  "payroll.go_to_the_payroll_list_page": "Go to payroll list page",
  "payroll.component": "Component",
  "payroll.note": "Note",
  "payroll.input_note": "Input note",
  "payroll.fixed_allowance": "Fixed Allowance",
  "payroll.total_payroll_payment_is_empty_please_add_payroll_for_your_employees_first":
    "Total payroll payment is empty, please add payroll for your employees first.",
  "payroll.based_on_attendance_status": "Based on Attendance Status",
  "payroll.use_in_the_next_period": "Use in the next period",
  "payroll.export_tax_calculation": "Export Tax Calculation",
  "payroll.export_prorate_calculation": "Export Prorate Calculation",
  "payroll.payroll_tax_calculation": "Payroll Tax Calculation",
  "payroll.payroll_prorate_calculation": "Payroll Prorate Calculation",
  "payroll.overtime_calculation_method": "Overtime Calculation Method",
  "payroll.fixed_amount_of_overtime_pay_per_hour":
    "Fixed Amount of Overtime Pay Per Hour",
  "payroll.the_method_used_for_calculating_employee_overtime_pay":
    "The method used for calculating employee overtime pay",
  "payroll.fixed_nominal_amount_of_employee_overtime_pay":
    "Fixed Nominal Amount of Employee Overtime Pay",
  "payroll.please_input_fixed_amount_of_overtime_pay_per_hour":
    "Please input fixed amount of overtime pay per hour.",
  "payroll.formula": "Formula",
  "payroll.manual": "Manual",
  "payroll.dynamic": "Dynamic",
  "payroll.variabel": "Variabel",
  "payroll.operator": "Operator",
  "payroll.numbers": "Numbers",
  "payroll.edit_formula": "Edit Formula",
  "payroll.criteria": "Criteria",
  "payroll.input": "Input",
  "payroll.output": "Output",
  "payroll.please_add_input": "Please add input.",
  "payroll.please_add_criteria": "Please add criteria.",
  "payroll.cannot_less_than_1": "Cannot less than 1.",
  "payroll.import_manual_payroll_component": "Import Manual Payroll Component",
  "payroll.follow_these_steps_to_import_manual_payroll_components":
    "Follow these steps to import manual payroll components",
  "payroll.1_download_file_template_manual_payroll_component":
    "1. Download file template manual payroll component",
  "payroll.start_by_downloading_file_template_manual_payroll_component":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from manual payroll component.",
  "payroll.2_copy_manual_payroll_component_data_into_the_template":
    "2. Copy manual payroll component data into the template",
  "payroll.how_to_custom_file_template_manual_payroll_component":
    "Using Excel or other spreadsheet software, enter manual payroll component data into the template file from Gajihub. Make sure the manual payroll component data matches the header column in the template.",
  "payroll.important_import_info_manual_payroll_component":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 manual payroll component data can be imported at a time.<br/> When importing, Gajihub will only enter new data, no data will be deleted or updated.<br/>",
  "payroll.component_name": "Component Name",
  "payroll.tax_type": "Tax Type",
  "payroll.sustainable": "Sustainable",
  "payroll.manual_payroll_component_successfully_imported":
    "{{rowCount}} manual payroll component successfully imported",
  "payroll.visit": "Visit",
  "payroll.show_data": "Show Data",
  "payroll.at_least_one_column_display": "At least one column display",
  "payroll.manual_income_component": "Manual Income Component",
  "payroll.manual_deduction_component": "Manual Deduction Component",
  "payroll.manual_cash_receipt_component": "Manual Cash Receipt Component",
  "payroll.tax_status": "Tax Status",
  "payroll.not_taxable": "Not Taxable",
  "payroll.not_deducted_by_tax": "Not Deducted by Tax",
  "payroll.tax": "Tax",
  "payroll.import_payroll_notes": "Import Payroll Notes",
  "payroll.follow_these_steps_to_import_payroll_note":
    "Follow these steps to import payroll note",
  "payroll.1_download_file_template_payroll_note":
    "1. Download file template payroll note",
  "payroll.start_by_downloading_file_template_payroll_note":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from payroll note.",
  "payroll.2_copy_payroll_note_data_into_the_template":
    "2. Copy payroll note data into the template",
  "payroll.how_to_custom_file_template_payroll_note":
    "Using Excel or other spreadsheet software, enter payroll note data into the template file from Gajihub. Make sure the payroll note data matches the header column in the template.",
  "payroll.important_import_info_payroll_note":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 payroll note data can be imported at a time.<br/> When importing, Gajihub will only enter new data, no data will be deleted or updated.<br/>",
  "payroll.payroll_note_successfully_imported":
    "{{rowCount}} payroll note successfully imported",
  "payroll.per_day": "per day",
  "payroll.sort": "Sort",
  "payroll.sort_income_payroll_components": "Sort Income Payroll Components",
  "payroll.sort_deduction_payroll_components":
    "Sort Deduction Payroll Components",
  "payroll.the_selected_payroll_component_has_not_been_saved":
    "The selected payroll component has not been saved",
  "payroll.the_payroll_components_that_can_be_sorted_are_only_the_payroll_components_that_have_been_selected_and_saved_on_the_salary_slip":
    "The payroll components that can be sorted are only the payroll components that have been selected and saved on the salary slip.",
  "payroll.download_salary_slip": "Download Salary Slip",
  "payroll.with_attachments": "With Attachments",
  "payroll.multi_payroll_payment": "Multi Payroll Payment",
  "payroll.employees_use_old_age_insurance": "Employees Use Old Age Insurance",
  "payroll.percentage_of_old_age_Insurance_covered_by_the_company":
    "Percentage of Old Age Insurance Covered by the Company",
  "payroll.employees_use_pension_protection":
    "Employees Use Pension Protection",
  "payroll.percentage_of_pension_protection_covered_by_the_company":
    "Percentage of Pension Protection Covered by the Company",
  "payroll.percentage_of_bpjs_healthcare_covered_by_the_company":
    "Percentage of BPJS Healthcare Covered by the Company",
  "payroll.edit_overtime": "Edit Overtime",
  "payroll.overtime_type": "Overtime Type",
  "payroll.select_overtime_type": "Select overtime type",
  "payroll.excess_or_less_than_the_duration_will_be_distributed_to_overtime_with_the_selected_type":
    "Excess or less than the duration will be distributed to overtime with the selected type.",
  "payroll.are_you_sure_to_reset_overtime": "Are you sure to reset overtime?",
  "payroll.jht_covered_by_the_company_is_calculated_as_pph_21":
    "Old Age Protection (JHT) covered by the company is calculated as PPh 21",
  "payroll.mass_salary_slip_download": "Mass Salary Slip Download",
  "payroll.the_download_results_will_be_sent_to_your_email_address":
    "The download results will be sent to your email address.",
  "payroll.download_success": "Download Success",
  "payroll.valid_for_the_day": "Valid For The Day",
  "payroll.download_payroll_export": "Download Payroll Export {{value}}",
  "payroll.confirmation_of_salary_payment_via_payment_connect_is_successful":
    "Confirmation of salary payment via payment connect is successful.",
  "payroll.please_confirm_on_each_payment_connect":
    "Please confirm on each payment connect!",

  "cashReceipt.title": "Cash Receipt",
  "cashReceipt.balance": "Balance",
  "cashReceipt.history": "History",
  "cashReceipt.payment": "Payment",
  "cashReceipt.add_cash_receipt": "Add Cash Receipt",
  "cashReceipt.edit_cash_receipt": "Edit Cash Receipt",
  "cashReceipt.detail_cash_receipt": "Detail Cash Receipt",
  "cashReceipt.name": "Name",
  "cashReceipt.unpaid_cash_receipt": "Unpaid Cash Receipt",
  "cashReceipt.last_payment": "Last Payment",
  "cashReceipt.last_payment_date": "Last Payment Date",
  "cashReceipt.description": "Description",
  "cashReceipt.cash_receipt_description": "Cash Receipt Description",
  "cashReceipt.approved_or_rejected_description":
    "Approved/Rejected Description",
  "cashReceipt.total_cash_receipt": "Total Cash Receipt",
  "cashReceipt.unpaid": "Unpaid",
  "cashReceipt.cash_receipt_date": "Cash Receipt Date",
  "cashReceipt.due_date": "Due Date",
  "cashReceipt.term": "Term",
  "cashReceipt.status": "Status",
  "cashReceipt.date": "Date",
  "cashReceipt.please_input": "Please input <span>{{type}}</span>",
  "cashReceipt.please_select": "Please select <span>{{type}}</span>",
  "cashReceipt.select_term": "Select Term",
  "cashReceipt.add_payment": "Add Payment",
  "cashReceipt.edit_payment": "Edit Payment",
  "cashReceipt.total_payment": "Total Payment",
  "cashReceipt.employee": "Employee",
  "cashReceipt.are_you_sure_to_delete_cash_receipt":
    "Are you sure to delete cash receipt?",
  "cashReceipt.please_click_here_to_add_bank_account_before_creating_cash_receipt":
    "Please <Link><bold>click here</bold></Link> to add bank account before <span>{{type}}</span>.",
  "cashReceipt.please_click_here_to_edit_plafon_before_creating_cash_receipt":
    "Please <Link><bold>click here</bold></Link> to edit plafon before <span>{{type}}</span>.",
  "cashReceipt.can_employees_apply_for_cash_receipts_from_ess":
    "Can employees apply for cash receipts from ESS?",
  "cashReceipt.employee_cash_receipt_plafon": "Employee cash receipt plafon",
  "cashReceipt.maximum_cash_receipt": "Maximum Cash Receipt",
  "cashReceipt.cash_receipt_successfully_created":
    "Cash Receipt Successfully Created",
  "cashReceipt.cash_receipt_submission_approved":
    "Cash Receipt Submission Approved",
  "cashReceipt.cash_receipt_submission_rejected":
    "Cash Receipt Submission Rejected",
  "cashReceipt.the_cash_receipt_you_created_is_in_the_approved_tab":
    "The cash receipt you created is in the approved tab",
  "cashReceipt.cash_receipt_that_you_approve_moved_to_the_approved_tab_section":
    "Cash receipt that you approve moved to the approved tab section",
  "cashReceipt.cash_receipt_that_you_rejected_moved_to_the_rejected_tab_section":
    "Cash receipt that you approve moved to the rejected tab section",
  "cashReceipt.cash_receipt_payment": "Cash Receipt Payment",
  "cashReceipt.cash_receipt_pay": "Cash Receipt Pay",
  "cashReceipt.date_approved": "Date Approved",
  "cashReceipt.date_rejected": "Date Rejected",
  "cashReceipt.import_cash_receipt": "Import Cash Receipt",
  "cashReceipt.follow_these_steps_to_import_cash_receipt":
    "Follow these steps to import cash receipt",
  "cashReceipt.1_download_file_template_cash_receipt":
    "1. Download file template cash receipt",
  "cashReceipt.start_by_downloading_file_template_cash_receipt":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from cash receipt.",
  "cashReceipt.2_copy_cash_receipt_data_into_the_template":
    "2. Copy cash receipt data into the template",
  "cashReceipt.how_to_custom_file_template_cash_receipt":
    "Using Excel or other spreadsheet software, insert cash receipt data into the template file from Gajihub. Make sure the cash receipt data matches the header column in the template.",
  "cashReceipt.important_import_info":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 cash receipts data can be imported at a time.<br/> When importing, Gajihub will only enter new data, no data will be deleted or updated.<br/>",
  "cashReceipt.cash_receipt_successfully_imported":
    "{{rowCount}} cash receipt successfully imported.",
  "cashReceipt.go_to_the_cash_receipt_list_page":
    "Go to cash receipt list page",
  "cashReceipt.submission_date": "Submission Date",
  "cashReceipt.payment_date": "Payment Date",
  "cashReceipt.import_cash_receipt_plafon": "Import Cash Receipt Plafon",
  "cashReceipt.follow_these_steps_to_import_cash_receipt_plafon":
    "Follow these steps to import cash receipt plafon",
  "cashReceipt.1_download_file_template_cash_receipt_plafon":
    "1. Download file template cash receipt plafon",
  "cashReceipt.start_by_downloading_file_template_cash_receipt_plafon":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from cash receipt plafon.",
  "cashReceipt.2_copy_cash_receipt_plafon_data_into_the_template":
    "2. Copy cash receipt plafon data into the template",
  "cashReceipt.how_to_custom_file_template_cash_receipt_plafon":
    "Using Excel or other spreadsheet software, insert cash receipt plafon data into the template file from Gajihub. Make sure the cash receipt plafon data matches the header column in the template.",
  "cashReceipt.important_import_info_cash_receipt_plafon":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 cash receipts plafon data can be imported at a time.<br/> When importing, Gajihub will only enter new data, no data will be deleted or updated.<br/>",
  "cashReceipt.cash_receipt_plafon_successfully_imported":
    "{{rowCount}} cash receipt plafon successfully imported.",
  "cashReceipt.go_to_the_cash_receipt_plafon_list_page":
    "Go to cash receipt plafon list page",
  "cashReceipt.payment_start_date": "Payment Start Date",
  "cashReceipt.types_of_cash_advance_installment_payments_in_payroll":
    "Types of cash receipt installment payments in payroll",
  "cashReceipt.enable_application_terms_of_cash_receipt_submission":
    "Enable application terms of cash receipt submission",
  "cashReceipt.maximum_terms_cash_receipt_submission":
    "Maximum terms cash receipt submission",
  "cashReceipt.pay_cash_receipt_installments": "Pay Cash Receipt Installments",
  "cashReceipt.mass_pay_cash_receipt_installments":
    "Mass Pay Cash Receipt Installments",
  "cashReceipt.pay_cash_receipt": "Pay Cash Receipt",
  "cashReceipt.mass_pay_cash_receipt": "Mass Pay Cash Receipt",
  "cashReceipt.selected_cash_receipt_with_approved_status":
    "Selected cash receipt with approved status",
  "cashReceipt.selected_cash_receipt_with_paid_status":
    "Selected cash receipt with paid status",
  "cashReceipt.nominal": "Nominal",

  "reimbursement.title": "Reimbursement",
  "reimbursement.add_reimburse": "Add Reimbursement",
  "reimbursement.edit_reimburse": "Edit Reimbursement",
  "reimbursement.detail_reimburse": "Detail Reimbursement",
  "reimbursement.amount_of_money": "Amount of Money",
  "reimbursement.please_input": "Please input <span>{{type}}</span>",
  "reimbursement.please_select": "Please select <span>{{type}}</span>",
  "reimbursement.reimbursement_usage_details": "Reimburse Usage Details",
  "reimbursement.reimbursement_applicant": "Reimbursement Applicant",
  "reimbursement.payment": "Payment",
  "reimbursement.paid": "Paid",
  "reimbursement.add_payment": "Add Payment",
  "reimbursement.pay_reimburse": "Pay Reimbursement",
  "reimbursement.date_payment": "Date Payment",
  "reimbursement.total_payment": "Total Payment",
  "reimbursement.are_you_sure_to_delete_reimbursement":
    "Are you sure to delete reimbursement?",
  "reimbursement.from": "From",
  "reimbursement.to": "To",
  "reimbursement.bank_account": "Bank Account",
  "reimbursement.employee_account": "Employee Account",
  "reimbursement.payment_method": "Payment Method",
  "reimbursement.cash": "Cash",
  "reimbursement.non_cash": "Non-Cash",
  "reimbursement.reimbursement_settings": "Reimbursement Settings",
  "reimbursement.employee_reimbursement_plafon":
    "Employee reimbursement plafon",
  "reimbursement.maximum_reimbursement": "Maximum Reimbursement",
  "reimbursement.plafon_type": "Plafon Type",

  "timeline.title": "Timeline",
  "timeline.announcement": "Announcement",
  "timeline.add_timeline": "Add Timeline",
  "timeline.add_announcement": "Add Announcement",
  "timeline.edit_announcement": "Edit Announcement",
  "timeline.time": "Time",
  "timeline.text": "Text",
  "timeline.read": "Read",
  "timeline.body_timeline": "Timeline Content",
  "timeline.body_announcement": "Announcement Content",
  "timeline.please_input_content": "Please input content.",
  "timeline.please_input_body_announcement":
    "Please input announcement content.",
  "timeline.are_you_sure_to_add_this_announcement":
    "Are you sure to add this announcement?",
  "timeline.when_you_click_sure_the_announcement_will_immediately_be_read_by_all_targeted_employees":
    "When you click Sure, the announcement will immediately be read by all targeted employees.",
  "timeline.sure": "Sure",
  "timeline.comments": "Comments",
  "timeline.setting_leave_on_timeline":
    "Are notifications of leave permission displayed on the timeline?",
  "timeline.setting_birthday_on_timeline":
    "Are birthday notifications displayed on the timeline?",
  "timeline.send_to": "Send To",
  "timeline.are_you_sure_pin_this_announcement":
    "Are you sure pin this announcement?",
  "timeline.are_you_sure_unpin_this_announcement":
    "Are you sure unpin this announcement?",

  "thr.title": "THR",
  "thr.how_many_min_working_month":
    "How many minimum working month of employee to get THR?",
  "thr.how_much_thr_each_employee": "How much THR for each employee?",
  "thr.time": "time",
  "thr.times": "times",
  "thr.employee_under_1_year_how_thr":
    "For employee with under 1 year of working, how to calculate the THR?",
  "thr.enable_progressive_thr_calculation":
    "Enable progressive THR calculation",
  "thr.minimum_working_duration": "Minimum working duration",
  "thr.thr_amount": "THR amount",
  "thr.add_row": "Add Row",
  "thr.please_input": "Please input",
  "thr.min_month": "Min {{min}} month",
  "thr.max_month": "Max {{max}} month",
  "thr.max": "Max {{max}}",
  "thr.working_time_has_been_used": "Working time has been used",
  "thr.set_different_thr_amounts_based_on_length_of_service":
    "Set different THR amounts based on length of service",

  "leaveAllowance.title": "Leave Allowance",
  "leaveAllowance.activate_for_employee":
    "Activate leave allowance for employee?",
  "leaveAllowance.how_much_each_employee":
    "How much leave allowance for each employee?",
  "leaveAllowance.is_it_with_tax": "Is leave allowance taxable?",
  "leaveAllowance.what_tax_category":
    "What is the tax catogory for leave allowance?",
  "leaveAllowance.cannot_less_than_0": "Cannot less than 0.",
  "leaveAllowance.description":
    "Leave allowance is an allowance that is paid once a year on each date the employee joins the company.",

  "overtimePay.title": "Overtime Pay",
  "overtimePay.activate_for_employee": "Activate overtime pay for employee?",
  "overtimePay.how_company_calculate_overtime":
    "How company calculate overtime pay?",
  "overtimePay.total_per_hour": "Total per hour",
  "overtimePay.total_per_day": "Total per day",
  "overtimePay.173_description":
    "The number 173 is the average number of hours worked by employees in one month.<br/>Calculation details:<br/>1 year = 52 weeks. The average month is 52 weeks/12 months = 4.3 weeks.<br/>Total working hours of employees per week = 40 hours.<br/>Total working hours of employees 1 month = 40 hours/week X 4.33 weeks = 173",

  "proRate.title": "Pro Rate",
  "proRate.how_calculate_salary_promoted_employee":
    "How to calculate salary for promoted employee in the middle of payroll period?",
  "proRate.how_calculate_salary_resigned_employee":
    "How to calculate salary for resigned employee in the middle of payroll period?",
  "proRate.how_calculate_pro_rate":
    "How to calculate pro rate in your company?",
  "proRate.how_is_the_salary_calculation_for_employees_who_enter_or_leave_in_the_middle_of_the_payroll_period":
    "How is the salary calculation for employees who enter or leave in the middle of the payroll period?",
  "proRate.select_the_working_days_in_your_company":
    "Select the working days in your company",
  "proRate.do_national_holidays_collective_leave_and_non_holiday_events_reduce_the_prorate":
    "Do national holidays, collective leave and non-holiday events reduce the prorate?",

  "tax.title": "Tax",
  "tax.activate_pph21/26_tax": "Activate PPH 21/26 tax?",
  "tax.npwp_withholding": "NPWP Withholding",
  "tax.add_npwp_withholding": "Add NPWP Withholding",
  "tax.edit_npwp_withholding": "Edit NPWP Withholding",
  "tax.default": "Default",
  "tax.name": "Name",
  "tax.npwp": "NPWP",
  "tax.only_alphanumeric_allowed": "Only alphanumeric allowed.",
  "tax.please_input_name": "Please input name.",
  "tax.please_input_npwp": "Please input npwp.",
  "tax.npwp_business_entity": "NPWP Business Entity",
  "tax.npwp_leader_or_authorization": "NPWP Leader / Authorization",
  "tax.are_you_sure_to_delete_npwp_withholding":
    "Are you sure to delete NPWP withholding?",
  "tax.set_as_default": "Set as default",
  "tax.npwp_company": "NPWP Company",
  "tax.leaders_or_those_who_have_the_power_to_sign_the_spt":
    "Leaders or those who have authority to sign the SPT",
  "tax.help_tax_activated":
    "If the PPH tax is activated, then the payslip will automatically calculate the tax component.",
  "tax.help_npwp_withholder":
    "Enter the NPWP of the Cutter in your company. If there is more than one withholding NPWP, you can specify that each employee will be deducted using a different withholding NPWP from the employee detail page.",
  "tax.help_calculation_method":
    "Nett, is a tax deduction method in which the company bears the taxes of its employees.<br/>Gross, is a tax deduction method in which employees bear the amount of their own income tax.<br/>Gross up, is a tax deduction method in which the company provides tax allowances in the amount equal to the amount of tax withheld from employees.<br/><br/>This setting will be applied by default to all employees. If there are employees who will use a different calculation method, you can set it on the employee detail page.",

  "bpjs.title": "BPJS",
  "bpjs.bpjs_of_employement": "BPJS of Employement",
  "bpjs.bpjs_healthcare": "BPJS Healthcare",
  "bpjs.setup_bpjs_of_employement": "Setup BPJS of Employement",
  "bpjs.edit_setup_bpjs_of_employement": "Edit Setup BPJS of Employement",
  "bpjs.setup_bpjs_healthcare": "Setup BPJS Healthcare",
  "bpjs.edit_setup_bpjs_healthcare": "Edit Setup BPJS Healthcare",
  "bpjs.business_entity_code": "Business Entity Code",
  "bpjs.bpjs_dues_multiplier": "BPJS Dues Multiplier",
  "bpjs.percentage_contributions_borne_by_the_company":
    "Percentage of Contributions Borne by the Company",
  "bpjs.date_started": "Date Started",
  "bpjs.please_input_business_entity_code":
    "Please input business entity code.",
  "bpjs.please_select_bpjs_dues_multiplier":
    "Please select BPJS dues multiplier.",
  "bpjs.please_input_percentage_contributions_borne_by_the_company":
    "Please input percentage of contributions borne by the company.",
  "bpjs.please_input_date_started": "Please input date started.",
  "bpjs.max_100": "Max 100",
  "bpjs.min_0": "Min 0",
  "bpjs.percentage": "Persentage",
  "bpjs.percentage_personnel_dependents": "Percentage of Personnel Dependents",
  "bpjs.are_you_sure_to_non_activate_bpjs_healthcare":
    "Are you sure to non activate BPJS Healthcare?",
  "bpjs.are_you_sure_to_non_activate_bpjs_employement":
    "Are you sure to non activate BPJS of Employement?",
  "bpjs.general_information": "General Information",
  "bpjs.old_age_protection": "Old Age Protection",
  "bpjs.pension_protection": "Pension Protection",
  "bpjs.company_registration_number": "Company Registration Number",
  "bpjs.please_input_company_registration_number":
    "Please input company registration number.",
  "bpjs.work_accident_insurance_risk_level_group":
    "Work Accident Insurance Risk Level Group (JKK)",
  "bpjs.death_benefit_percentage": "Death Benefit Percentage (JKM)",
  "bpjs.please_select": "Please select.",
  "bpjs.employee_use_old_age_protection":
    "Employee Use Old Age Protection (JHT)",
  "bpjs.old_age_protection_company_responsibilities":
    "Old Age Protection (JHT) Company Responsibilities",
  "bpjs.old_age_protection_employee_responsibilities":
    "Old Age Protection (JHT) Employee Responsibilities",
  "bpjs.jht_company_responsibilities_calculation_pph21":
    "JHT Company Responsibilities Calculation PPh 21?",
  "bpjs.does_the_company_use_pension_insurance":
    "Does the Company Use Pension Insurance?",
  "bpjs.does_the_company_use_old_age_protection":
    "Does the Company Use Old Age Protection?",
  "bpjs.employees_who_are_foreign_nationals_are_counted_as_pension_insurance":
    "Employees Who are Foreign Nationals are Counted as Pension Insurance?",
  "bpjs.employees_with_age_more_than_57_years_are_counted_as_pension_insurance":
    "Employees With Age > 57 Years are Counted as Pension Insurance?",
  "bpjs.company_responsibilities_pension_insurance_is_calculated_pph_21":
    "Company Responsibilities Pension Insurance is Calculated PPh 21?",
  "bpjs.employee_use_pension_insurance": "Employee Use Pension Insurance",
  "bpjs.company_responsibilities_pension_insurance":
    "Company Responsibilities Pension Insurance",
  "bpjs.employee_responsibilities_pension_insurance":
    "Employee Responsibilities Pension Insurance",
  "bpjs.please_select_company_responsibilities_pension_insurance":
    "Please select company responsibilities pension insurance.",
  "bpjs.max_value_pension_insurance_multiplier":
    "Max Value Pension Insurance Multiplier",
  "bpjs.max_value_bpjs_healthcare_multiplier":
    "Max Value BPJS Healthcare Multiplier",
  "bpjs.description_jht_company_responsibilities":
    "5,7 reduced JHT covered by company responsiblities",
  "bpjs.desctiption_jp_company_responsibilities":
    "3 reduced JP by employee responsibilities",
  "bpjs.the_number_required_by_the_company_to_register_its_employees_in_the_bpjs_program":
    "The number required by the company to register its employees in the BPJS program",
  "bpjs.the_basis_of_the_bpjs_calculation_multiplier_is_the_employees_wages_namely_the_basic_salary_and_fixed_allowances":
    "The basis of the BPJS calculation multiplier is the employee's wages, namely the basic salary and fixed allowances",
  "bpjs.the_higher_the_level_of_risk_in_work_the_greater_the_jkk_contribution_that_must_be_paid_every_month":
    "The higher the level of risk in work, the greater the JKK contribution that must be paid every month",
  "bpjs.jkm_contributions_are_fully_borne_by_the_company_these_contributions_provide_cash_benefits_to_the_heirs_when_the_participant_dies":
    "JKM contributions are fully borne by the company, these contributions provide cash benefits to the heirs when the participant dies",
  "bpjs.percentage_of_old_age_security_contributions_borne_by_the_company":
    "Percentage of Old Age Security (JHT) contributions borne by the company",
  "bpjs.jht_is_calculated_with_pph_21_then":
    "JHT is calculated with PPH 21 then?",
  "bpjs.the_percentage_of_jht_contributions_by_employees_responsibilities":
    "The percentage of JHT contributions by employees responsibilities",
  "bpjs.the_amount_of_the_old_age_protection_guarantee_contribution_for_wage_recipients_income":
    "The amount of the Old Age Protection (JHT) contribution for wage recipients is 5.7% of income",
  "bpjs.the_amount_of_the_pension_guarantee_jp_contribution_for_wage_recipients_income":
    "The amount of the Pension Guarantee (JP) contribution for wage recipients is 3% of income",
  "bpjs.employees_with_foreign_nationality_are_counted_or_not_counted_for_their_pension_insurance":
    "employees with foreign nationality are counted or not counted for their pension insurance",
  "bpjs.employees_who_are_more_than_57_years_old_are_counted_or_not_their_pension_benefits_are_calculated":
    "Employees who are more than 57 years old are counted or not their pension benefits are calculated",
  "bpjs.jp_is_calculated_with_pph_21_then":
    "JP is calculated with PPH 21 then?",
  "bpjs.the_percentage_of_the_pension_insurance_contribution_borne_by_the_company":
    "The percentage of the pension insurance contribution borne by the company",
  "bpjs.the_percentage_of_pension_insurance_contributions_borne_by_employees":
    "The percentage of pension insurance contributions borne by employees",
  "bpjs.code_used_to_register_several_employees_in_the_bpjs_health_program":
    "Code used to register several employees in the BPJS health program",
  "bpjs.the_percentage_of_contributions_borne_by_the_company":
    "The percentage of contributions borne by the company",
  "bpjs.calculation_of_bpjs_contributions_using_basic_salary_or_basic_salary_fixed_allowance":
    "Calculation of BPJS contributions using basic salary or basic salary + fixed allowance?",
  "bpjs.is_the_jht_contribution_borne_by_the_company_counted_as_taxable_income":
    "Is the JHT contribution borne by the company counted as taxable income?",
  "bpjs.what_percentage_of_jht_contributions_are_borne_by_employees":
    "What percentage of JHT contributions are borne by employees?",
  "bpjs.do_employees_with_foreign_citizenship_count_towards_their_retirement_benefits_or_not":
    "Do employees with foreign citizenship count towards their retirement benefits or not?",
  "bpjs.are_employees_over_the_age_of_57_years_counted_or_not_counted_for_their_retirement_benefits":
    "Are employees over the age of 57 years counted or not counted for their retirement benefits?",
  "bpjs.is_the_pension_security_contribution_borne_by_the_company_counted_as_taxable_income":
    "Is the Pension Security contribution borne by the company counted as taxable income?",
  "bpjs.what_percentage_of_the_pension_guarantee_contribution_is_borne_by_the_company":
    "What percentage of the Pension Guarantee contribution is borne by the company?",
  "bpjs.what_percentage_of_the_pension_security_contribution_is_borne_by_the_employee":
    "What percentage of the Pension Security contribution is borne by the employee?",
  "bpjs.are_bpjs_contributions_calculated_based_on_basic_salary_or_basic_salary_plus_fixed_allowance":
    "Are bpjs contributions calculated based on basic salary or basic salary + fixed allowance?",
  "bpjs.what_percentage_of_contributions_is_borne_by_the_company":
    "What percentage of contributions is borne by the company?",
  "bpjs.please_input_bpjs_social_security_effective_date":
    "Please input BPJS Social Security effective date",
  "bpjs.please_input_bpjs_healthcare_effective_date":
    "Please input BPJS Healthcare effective date",
  "bpjs.the_start_date_of_calculating_bpjs_membership_in_the_company_the_company_will_calculate_the_discount_based_on_that_date":
    "The start date of calculating BPJS membership in the company, the company will calculate the discount based on that date",
  "bpjs.when_did_this_bpjs_regulation_come_into_effect_in_your_company":
    "When did this BPJS regulation come into effect in your company?",
  "bpjs.setting_bpjs": "BPJS Setting",
  "bpjs.office_domicile_as_the_basis_for_calculating_ump_bpjs":
    "Office domicile as the basis for calculating UMP BPJS",
  "bpjs.please_select_domicile": "Please select domicile",
  "bpjs.the_applicable_provincial_minimum_wage_in_your_company":
    "The applicable Provincial Minimum Wage (UMP) in your company",
  "bpjs.history_bpjs_of_employement": "History BPJS of Employement",
  "bpjs.history_bpjs_healthcare": "History BPJS Healthcare",
  "bpjs.status_applied": "Status Applied (Yes/No)",
  "bpjs.bpjs_employement_dues_multiplier": "BPJS Employement Dues Multiplier",
  "bpjs.bpjs_healthcare_dues_multiplier": "BPJS Healthcare Dues Multiplier",
  "bpjs.calculation_of_bpjs_employement_contributions_using_basic_salary_or_basic_salary_fixed_allowance":
    "Calculation of BPJS Employement contributions using basic salary or basic salary + fixed allowance?",
  "bpjs.calculation_of_bpjs_healthcare_contributions_using_basic_salary_or_basic_salary_fixed_allowance":
    "Calculation of BPJS Healthcare contributions using basic salary or basic salary + fixed allowance?",
  "bpjs.bpjs_employement_multiplier": "BPJS Employement Multiplier",
  "bpjs.bpjs_healthcare_multiplier": "BPJS Healthcare Multiplier",
  "bpjs.type_of_overtime_pay_calculation": "Type of Overtime Pay Calculation",
  "bpjs.total_calculation_of_overtime_pay_per_hour":
    "Total Calculation of Overtime Pay per Hour",
  "bpjs.bpjs_multiplier_per_employee": "BPJS Multiplier per Employee",
  "bpjs.bpjs_multiplier_uses_ump_per_employee":
    "BPJS Multiplier Uses Provincial Minimum Wage (UMP) per Employee",
  "bpjs.another_multiplier_base": "Another Multiplier Base",
  "bpjs.setting_bpjs_multiplier_employee_help_text_1":
    "If the employee UMP is not set (or the employee is not added here), the default will be the Jakarta UMP",
  "bpjs.setting_bpjs_multiplier_employee_help_text_2":
    "If other multiplier bases are not set (or employees are not added here), the default multiplier bases will be used",
  "bpjs.bpjs_healthcare_is_covered_by_the_company":
    "BPJS Healthcare is Covered by the Company",
  "bpjs.import_bpjs_multiplier_per_employee":
    "Import BPJS Multiplier per Employee",
  "bpjs.follow_these_steps_to_import_bpjs_multiplier_per_employee":
    "Follow these steps to import BPJS multiplier per employee",
  "bpjs.1_download_file_template_bpjs_multiplier_per_employee":
    "1. Download file template BPJS multiplier per employee",
  "bpjs.start_by_downloading_file_template_bpjs_multiplier_per_employee":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from BPJS multiplier per employee.",
  "bpjs.2_copy_bpjs_multiplier_per_employee_data_into_the_template":
    "2. Copy BPJS multiplier per employee data into the template",
  "bpjs.how_to_custom_file_template_bpjs_multiplier_per_employee":
    "Using Excel or other spreadsheet software, enter BPJS multiplier per employee data into the template file from Gajihub. Make sure the BPJS multiplier per employee data matches the header column in the template.",
  "bpjs.important_import_info_bpjs_multiplier_per_employee":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 BPJS multiplier per employee data can be imported at a time.<br/> When importing, Gajihub will only enter new data, no data will be deleted or updated.<br/>",
  "bpjs.bpjs_multiplier_per_employee_successfully_imported":
    "{{rowCount}} BPJS multiplier per employee successfully imported",
  "bpjs.go_to_the_bpjs_settings_page": "Go to the BPJS settings page",
  "bpjs.other_multipliers_bpjs_employment": "Other Multipliers BPJS Employment",
  "bpjs.other_multipliers_bpjs_healthcare": "Other Multipliers BPJS Healthcare",

  "unpaidLeave.title": "Unpaid Leave",
  "unpaidLeave.how_is_the_unpaid_leave_discount_calculated":
    "How is the unpaid leave discount calculated?",
  "unpaidLeave.unpaid_leave_discount_calculated":
    "Unpaid leave discount calculated",
  "unpaidLeave.how_to_calculate_the day_divisor_of_unpaid_leave_in_your_company":
    "How to calculate the day divisor of unpaid leave in your company?",
  "unpaidLeave.number_of_rupiah_pieces_per_day":
    "Number of rupiah pieces per day",
  "unpaidLeave.cannot_less_than_1": "Cannot less than 1.",
  "unpaidLeave.cannot_more_than_31": "Cannot more than 31.",

  "grading.title": "Grading",
  "grading.add_grading": "Add Grading",
  "grading.add_system_grading": "Add System Grading",
  "grading.edit_system_grading": "Edit System Grading",
  "grading.method": "Method",
  "grading.base_salary": "Base Salary",
  "grading.effective_date": "Effective Date",
  "grading.system_grading_name": "System Grading Name",
  "grading.please_input_system_grading_name":
    "Please input system grading name.",
  "grading.started_date": "Started Date",
  "grading.please_input_date": "Please input date.",
  "grading.method_grading": "Method Grading",
  "grading.please_select_method_grading": "Please select method grading.",
  "grading.please_select_base_salary": "Please select base salary.",
  "grading.grade_number": "Grade Number",
  "grading.please_select_grade_number": "Please select grade number.",
  "grading.name": "Name",
  "grading.save_and_activate": "Save & Activate",
  "grading.grade": "Grade",
  "grading.job_group": "Job Group",
  "grading.range": "Range",
  "grading.minimum_salary": "Minimum Salary",
  "grading.middle_value": "Middle Value",
  "grading.maximum_salary": "Maximum Salary",
  "grading.please_select": "Please select.",
  "grading.please_input": "Please input.",
  "grading.effective_starting_date": "Effective starting date",
  "grading.are_you_sure_to_delete_this_grading":
    "Are you sure to delete this grading?",

  "company.company": "Company",
  "company.company_info": "Company Info",
  "company.organizational_structure": "Organizational Structure",
  "company.job_title": "Job Title",
  "company.add_job_title": "Add Job Title",
  "company.edit_job_title": "Edit Job Title",
  "company.position": "Position",
  "company.delete_job_title": "Delete Job Title",
  "company.select_job_title": "Select Job Title",
  "company.add_subordinates": "Add Subordinates",
  "company.add_organization": "Add Organization",
  "company.add_sub_organization": "Add Sub Organization",
  "company.edit_organization": "Edit Organization",
  "company.please_input_position_name": "Please input position name.",
  "company.please_input_job_level_name": "Please input job level name.",
  "company.please_input_organization_name": "Please input organization name.",
  "company.are_you_sure_to_delete": "are you sure to delete?",
  "company.edit_position": "Edit Position",
  "company.add_position": "Add Position",
  "company.parent": "Parent",
  "company.select_parent": "Select Parent",
  "company.please_select_parent": "Please select parent.",
  "company.success_message_title": "Your company was created successfully.",
  "company.success_message_subtitle":
    "Please click the button below to continue.",
  "company.title": "Title",
  "company.company_policy": "Company Policy",
  "company.add_policy": "Add Policy",
  "company.edit_policy": "Edit Policy",
  "company.contents_of_rules": "Contents of Rules",
  "company.please_input_title": "Please input title.",
  "company.please_input_contents_of_rules": "Please input contents of rules.",
  "company.deletable_job_title": "Deletable job title",
  "company.not_yet_used_as_a_career_employee":
    "Not yet used as a career employee.",
  "company.not_yet_used_as_a_approval_rule":
    "Not yet used as a career approval rule.",
  "company.never_deleted": "Never deleted.",
  "company.cannot_be_deleted_if_it_is_still_registered_in_your_employees_career":
    "{{type}} cannot be deleted if it is still registered in your employee's career",

  "property.property": "Property",
  "property.company": "Company",
  "property.property_details": "Property Details",
  "property.company_details": "Company Details",
  "property.tax_details": "Tax Details",
  "property.system_settings": "System Settings",
  "property.property_logo": "Property Logo",
  "property.company_logo": "Company Logo",
  "property.error_cannot_update_tax_details": "Cannot update tax details.",
  "property.property_details_successfully_updated":
    "Property details successfully updated.",
  "property.company_details_successfully_updated":
    "Company details successfully updated.",
  "property.error_cannot_update_property_details":
    "Cannot update property details.",
  "property.error_cannot_update_company_details":
    "Cannot update company details.",
  "property.system_details_successfully_updated":
    "System details successfully updated.",
  "property.error_cannot_update_system_details":
    "Cannot update system details.",
  "property.tax_details_successfully_updated":
    "Tax details successfully updated.",
  "property.property_name": "Name",
  "property.company_name": "Name",
  "property.please_input_the_property_name": "Please input the property name.",
  "property.please_input_the_company_name": "Please input the company name.",
  "property.max_100_characters": "Max 100 characters.",
  "property.only_alphanumeric_allowed": "Only alphanumeric allowed.",
  "property.email_address": "Emai Address",
  "property.invalid_email_address_format": "Invalid email address format.",
  "property.phone_number": "Phone Number",
  "property.max_20_characters": "Max 20 characters.",
  "property.only_number_allowed": "Only number allowed.",
  "property.address": "Billing Address",
  "property.address_delivery": "Delivery Address",
  "property.country": "Country",
  "property.tax_number": "Tax Number",
  "property.please_select_the_country": "Please select the country.",
  "property.select_country": "Select country",
  "property.arrival_time": "Arrival Time",
  "property.departure_time": "Departure Time",
  "property.image_file_size_maximal": "Image file size maximal",
  "property.language": "Language",
  "property.select_time_region": "Select time region",
  "property.timezone": "Timezone",
  "property.please_select_the_language": "Please select the language.",
  "property.select_timezone": "Select timezone",
  "property.please_select-the_timezone": "Please select the timezone.",
  "property.date_format": "Date Format",
  "property.please_select_the_date_format": "Please select the date format.",
  "property.select_date_format": "Select date format",
  "property.time_format": "Time Format",
  "property.please_select_the_time_format": "Please select the time format.",
  "property.select_time_format": "Select time format",
  "property.Currency_Format": "Currency Format",
  "property.please_select_the_currency_format":
    "Please select the currency format.",
  "property.select_currency_format": "Select currency format",
  "property.country_tax_identifier_name": "Country Tax Identifier Name",
  "property.company_tax_number": "Company Tax Number",
  "property.tax_name": "Tax Name",
  "property.tax_rate": "Tax Rate",
  "property.image_file_dimension_maximal": "Image file dimension maximal",
  "property.click_or_drag_file_to_this_area_to_upload":
    "Click or drag file to this area to upload",
  "property.preview": "Preview",
  "property.file_uploaded_successfully": "file uploaded successfully.",
  "property.file_upload_failed": "file upload failed.",
  "property.you_can_only_upload_jpg_or_png_file_":
    "You can only upload JPG/PNG file.",
  "property.image_must_smaller_than": "Image must smaller than",
  "property.image_width_must_smaller_than": "Image width must smaller than",
  "property.image_height_must_smaller_than": "Image height must smaller than",
  "property.currency": "Currency",
  "property.please_select_the_currency": "Please select the currency.",
  "property.select_currency": "Select currency",
  "property.number_after_comma": "Number after comma",
  "property.please_select_the_number_after_comma":
    "Please select number after comma.",
  "property.select_number_after_comma": "Select number after comma",
  "property.upload_failed": "Upload failed",
  "property.start_using_gajihub": "Start Using Gajihub",
  "property.theme_colors": "Theme Colors",
  "property.enable_theme_color_settings": "Enable Theme Color Settings",
  "property.reset_theme_color_to_default": "Reset default color",

  "approval.approval_title": "Approval",
  "approval.edit_approval_title": "Edit Approval",
  "approval.add_rule_approval": "Add Rule Approval",
  "approval.rule_name": "Rule Name",
  "approval.applies_at": "Applies At",
  "approval.applies_to": "Applies To",
  "approval.approval_flow": "Approval Flow",
  "approval.are_you_sure_to_delete_rule_approval":
    "Are you sure to delete rule approval?",
  "approval.please_input_rule_name": "Please input rule name.",
  "approval.annual_leave": "Annual Leave",
  "approval.special_leave": "Special Leave",
  "approval.cash_receipt": "Cash Receipt",
  "approval.reimbursement": "Reimbursement",
  "approval.sick": "Sick",
  "approval.permission": "Permission",
  "approval.unpaid_leave": "Unpaid Leave",
  "approval.overtime": "Overtime",
  "approval.personal_data_change": "Personal Data Change",
  "approval.all_employee": "All Employee",
  "approval.job_position": "Job Position",
  "approval.job_level": "Job Level",
  "approval.structure": "Structure",
  "approval.employee": "Employee",
  "approval.select_category": "Select Category",
  "approval.select": "Select",
  "approval.please_select": "Please select.",
  "approval.please_add": "Please add.",
  "approval.waiting_approval": "Waiting Approval",
  "approval.approved": "Approved",
  "approval.approve": "Approve",
  "approval.rejected": "Rejected",
  "approval.reject": "Reject",
  "approval.type": "Type",
  "approval.approval_progress": "Approval progress",
  "approval.submission_date": "Submission Date",
  "approval.approval_date": "Approval Date",
  "approval.rejection_date": "Rejection Date",
  "approval.reason_for_approval": "Reason Approval",
  "approval.reason_for_rejection": "Reason Rejection",
  "approval.are_you_sure_approve_to_these_requests":
    "Are you sure approve to these {{count}} requests?",
  "approval.are_you_sure_reject_to_these_requests":
    "Are you sure reject to these {{count}} requests?",
  "approval.confirm_approval": "Confirm Approval",
  "approval.confirm_rejection": "Confirm Rejection",
  "approval.description": "Description",
  "approval.manage_approval_policy": "Manage Approval Policy",
  "approval.detail_approval_rule": "Detail Approval Rule",
  "approval.detail": "Detail",
  "approval.approval_of_permission_or_leave_by_superior":
    "Approval of permission / leave by superior",
  "approval.days_approval_by_superior": "days approval by superior",

  "calendar.title": "Calendar",
  "calendar.add_event": "Add Event",
  "calendar.edit_event": "Edit Event",
  "calendar.delete_calendar": "Delete Calendar",
  "calendar.event_name": "Event Name",
  "calendar.date": "Date",
  "calendar.type": "Type",
  "calendar.please_input_event_name": "Please input event name.",
  "calendar.please_input_date": "Please input date.",
  "calendar.please_select_type": "Please select type.",
  "calendar.select_calendar": "Select Calendar",
  "calendar.are_you_sure_to_delete_event_calendar":
    "Are you sure to delete event calendar?",
  "calendar.note_description":
    "Write down your company calendar, including joint leave, national holidays, and internal company events. Public holidays and leave on this calendar will also affect attendance calculations.",

  "profile.profile": "Profile",
  "profile.image_profile": "Profile Image",
  "profile.my_profile": "My Profile",
  "profile.old_password": "Old Password",
  "profile.please_input_the_old_password": "Please input the old password.",
  "profile.new_password": "New Password",
  "profile.please_input_the_new_password": "Please input the new password.",
  "profile.max_100_characters": "Max 100 characters.",
  "profile.confirm_new_password": "Confirm New Password",
  "profile.please_input_the_confirm_new_password":
    "Please input the confirm new password.",
  "profile.email_or_username": "Email / Username",
  "profile.please_input_the_email/username": "Please input the email/username.",
  "profile.the_input_is_not_valid-e-mail": "The input is not valid E-mail.",
  "profile.full_name": "Full Name",
  "profile.please_input_the_full_name": "Please input the full name.",
  "profile.0nly_alphanumeric_allowed": "Only alphanumeric allowed.",
  "profile.phone_number": "Phone Number",
  "profile.please_input_the_phone_number": "Please input the phone number.",
  "profile.max_20_characters": "Max 20 characters.",
  "profile.only_number_allowed": "Only number allowed.",
  "profile.user_profile_successfully_updated":
    "User profile successfully updated.",
  "profile.error_cannot_update_user_profile": "Cannot update user profile.",
  "profile.user_password_successfully_updated":
    "User password successfully updated.",
  "profile.error_cannot_update_user_password": "Cannot update user password.",
  "profile.change_password": "Change Password",
  "profile.two_passwords_that_you_enter_is_inconsistent_":
    "Two passwords that you enter is inconsistent.",
  "profile.password_atleast_8_characters_atleast_1_uppercase_and_atleast_1_digit":
    "Password atleast 8 characters, atleast 1 uppercase and atleast 1 digit.",
  "profile.password_atleast_6_characters": "Password atleast 6 characters.",
  "profile.logout_from_all_device": "Logout from all device",
  "profile.cannot_logout_from_all_device": "Cannot logout from all device",

  "roles.add_role": "Add Role",
  "roles.delete_role": "Delete Role",
  "roles.select_role_to_delete": "Select Role to Delete",
  "roles.please_select_a_role": "Please select a role.",
  "roles.select_role": "Select role",
  "roles.edit_role": "Edit Role",
  "roles.role": "Role",
  "roles.roles": "Roles",
  "roles.please_input_the_role_name": "Please input the role name.",
  "roles.max_100_characters": "Max 100 characters.",
  "roles.superadmin": "Superadmin",
  "roles.error_cannot_get_roles": "Cannot get Roles.",
  "roles.successfully_added": "successfully added.",
  "roles.error_cannot_add": "Cannot add",
  "roles.role_successfully_updated": "Role successfully updated.",
  "roles.error_cannot_update_role": "Cannot update role.",
  "roles.successfully_deleted": "successfully deleted.",
  "roles.error_cannot_delete": "Cannot delete",
  "roles.role_moved_successfully": "Role moved successfully.",
  "roles.error_cannot_move_the_channel": "Cannot move the channel.",
  "roles.search_by_role_name": "Search by role name",
  "roles.name": "Role name",
  "roles.invoices_payment": "Payment",
  "roles.orders_payment": "Payment",
  "roles.purchase_invoices_payment": "Payment",
  "roles.purchase_orders_payment": "Payment",
  "roles.product_show_purchase_price": "Show Purchase Price",
  "roles.product_show_sale_price": "Show Sale Price",
  "roles.product_show_hpp": "Show COGS",
  "roles.inventory_show_hpp": "Show Price",
  "roles.reports_finance": "Finance",
  "roles.reports_accounting": "Accounting",
  "roles.reports_sales": "Sales",
  "roles.reports_purchases": "Purchases",
  "roles.reports_expenses": "Expenses",
  "roles.reports_taxes": "Taxes",
  "roles.reports_inventory": "Inventory",
  "roles.reports_fixed_assets": "Fixed Assets",
  "roles.reports_budget": "Budget",
  "roles.reports_consolidation": "Consolidation",
  "roles.apikeys": "API Keys",
  "roles.sales_other_user_access": "Other User Access",
  "roles.purchase_other_user_access": "Other User Access",
  "roles.expenses_other_user_access": "Other User Access",
  "roles.product_show_manufacture_raw": "Show Manufacture Raw Materials",
  "roles.are_you_sure_to_delete_role": "Are you sure to delete role?",
  "roles.employee": "Employee",
  "roles.admin": "Admin",

  "companyAccount.title": "Company Account",
  "companyAccount.are_you_sure_to_delete_company_account":
    "Are you sure to delete company account?",
  "companyAccount.add_account": "Add Account",
  "companyAccount.add_company_account": "Add company account",
  "companyAccount.edit_company_account": "Edit company account",
  "companyAccount.bank_name": "Bank Name",
  "companyAccount.bank_account_number": "Bank Account Number",
  "companyAccount.account_holders_name": "Account Holders Name",
  "companyAccount.bank_branch_office": "Bank Branch Office",
  "companyAccount.please_select_bank_name": "Please select bank name.",
  "companyAccount.please_input_bank_account_number":
    "Please input bank account number.",
  "companyAccount.please_input_account_holders_name":
    "Please input account holders name.",
  "companyAccount.please_input_bank_branch_office":
    "Please input bank branch office.",
  "companyAccount.only_number_allowed": "Only number allowed.",
  "companyAccount.note_description":
    "Please enter the bank account number that your company uses to pay salaries to employees. It is recommended to use one account to payroll to all employees.<br/><br/>Currently the banks supported by GajiHub are BCA, Bank Mandiri, Bank CIMB, BRI, BNI, Permata, Bank National Nobu, DBS and Bank BTPN.<br/><br/>Specifically for Bank Mandiri, BRI, DBS, Bank BTPN, and Bank CIMB you can payroll to a different bank account. Apart from these 5 banks, you can only make payroll payments to the same bank accounts.",

  "report.report_title": "Report",
  "report.payroll_summary": "Payroll Summary",
  "report.payroll_summary_per_organization": "Payroll Summary per Organization",
  "report.payroll_summary_per_component": "Payroll Summary per Component",
  "report.payroll_payment": "Payroll Payment",
  "report.permanent_employee_tax_data_reference":
    "Permanent Employee Tax Data Reference",
  "report.proof_of_withholding_annual_permanent_employee_tax":
    "Proof of Withholding Annual Permanent Employee Tax",
  "report.proof_of_withholding_annual_freelance_employee_tax":
    "Proof of Withholding of Annual Freelance Employee Tax",
  "report.permanent_employee_monthly_tax": "Permanent Employee Monthly Tax",

  "reportTax.report_espt_pph21": "Report e-SPT PPH 21/26",
  "reportTax.please_select": "Please select",
  "reportTax.tax_period": "Tax Period",
  "reportTax.tax_year": "Tax Year",
  "reportTax.correction": "Correction",
  "reportTax.npwp": "NPWP",
  "reportTax.tax_code": "Tax Code",
  "reportTax.gross_amount": "Gross Amount",
  "reportTax.pph_amount": "PPH Amount",
  "reportTax.country_code": "Country Code",
  "reportTax.deduction_proof_number": "Deduction Proof Number",
  "reportTax.nik": "NIK",
  "reportTax.address": "Address",
  "reportTax.wp_foreign": "WP Foreign",
  "reportTax.dpp_amount": "DPP Amount",
  "reportTax.without_npwp": "Without NPWP",
  "reportTax.rate": "Rate",
  "reportTax.withholder_npwp": "Withholder NPWP",
  "reportTax.withholder_name": "Withholder Name",
  "reportTax.deduction_proof_date": "Deduction Proof Date",
  "reportTax.this_process_requires_a_stable_internet_connection":
    "This process requires a stable internet connection",
  "reportTax.we_re_preparing_your_file_recap":
    "We're preparing your file {{type}} recap....",
  "reportTax.ptkp_status": "PTKP Status",
  "reportTax.job_position_name": "Job Position Name",
  "reportTax.initial_acquisition_period": "Initial Acquisition Period",
  "reportTax.final_acquisition_period": "Final Acquisition Period",
  "reportTax.total_dependents": "Total Dependents",
  "reportTax.move_status": "Move Status",
  "reportTax.amount": "Amount",
  "reportTax.download_form_a1": "Download Form A1",

  "reportPayroll.salary_summary": "Salary Summary",
  "reportPayroll.detail_salary_summary": "Detail Salary Summary",
  "reportPayroll.basic_salary": "Basic Salary",
  "reportPayroll.total_deduction": "Total Deduction",
  "reportPayroll.total_allowance": "Total Allowance",
  "reportPayroll.take_home_pay": "Take Home Pay",
  "reportPayroll.period": "Period",
  "reportPayroll.salary_payment_recap": "Salary Payment Recap",
  "reportPayroll.salary_payment": "Salary Payment",
  "reportPayroll.slip_count": "Slip Count",
  "reportPayroll.total": "Total",
  "reportPayroll.select_level": "Select Level",
  "reportPayroll.organization": "Organization",

  "reportBpjs.report_bpjs_employement": "BPJS Employement Report",
  "reportBpjs.report_bpjs_healthcare": "BPJS Healthcare Report",
  "reportBpjs.number_participant": "No. Participant",
  "reportBpjs.income_amount": "Income Amount",
  "reportBpjs.multiplier_base": "Multiplier Base",
  "reportBpjs.jkk_dues": "JKK Dues",
  "reportBpjs.jkm_dues": "JKM Dues",
  "reportBpjs.jht_dues_paid_by_the_company": "JHT Dues Paid by the Company",
  "reportBpjs.jht_dues_paid_by_the_personnel": "JHT Dues Paid by the Personnel",
  "reportBpjs.jp_dues_paid_by_the_company": "JP Dues Paid by the Company",
  "reportBpjs.jp_dues_paid_by_the_personnel": "JP Dues Paid by the Personnel",
  "reportBpjs.bpjs_healthcare_dues_paid_by_the_company":
    "BPJS Healthcare Dues Paid by the Company",
  "reportBpjs.bpjs_healthcare_dues_paid_by_the_personnel":
    "BPJS Helathcare Dues Paid by the Personnel",
  "reportBpjs.percentage": "Percentage",

  "settings.data_not_found": "Data not found",
  "settings.reset": "Reset",
  "settings.settings": "Settings",
  "settings.company": "Company's data and logo",
  "settings.users": "Invite staff and colleagues to use Gajihub",
  "settings.roles": "Manage users role",
  "settings.profile": "Manage profile and change password",
  "settings.payroll": "Manage payment employee",
  "settings.schedule": "Manage schedule employee",
  "settings.billing": "Upgrade and renew plan",
  "settings.businessflow": "Manage your business flow",
  "settings.setup": "Remove demo data and start from scratch",
  "settings.account_mapping": "Accounting software integration settings",
  "settings.calendar": "Manage calendar of holidays and company events",
  "settings.approval_rule": "Manage submission approval flow",
  "settings.annual_leave": "Manage employee annual leave",
  "settings.special_leave": "Manage employee special leave",
  "settings.attendance_validation": "Set employee attendance validation",
  "settings.attendance_location": "Employee attendance location",
  "settings.mobile_menu": "Select the menu displayed in the application",
  "settings.setting_template":
    "Set email and mobile app notification templates",
  "settings.apps": "Connect your Apps with API Gajihub",
  "settings.setting_layout": "Manage salary slip layout",
  "settings.setting_audit": "Watch data log",
  "settings.cash_receipt": "Manage Cash Receipt for Employees",
  "settings.reimbursement": "Manage Reimbursement for Employees",
  "settings.sick_leave": "Manage employee sick leave",
  "settings.location_tracking_rule": "Manage employee location tracking rule",
  "settings.setting_overtime": "Manage overtime employee",
  "settings.setting_automatic_sequencing": "Manage automatic sequencing",
  "settings.setting_fingerprint_connect": "Manage fingerprint connect",
  "settings.sso_setting": "Login to Gajihub with just one account",
  "settings.setting_task": "Manage task employee",
  "settings.setting_payment_connect": "Accept payment from VA",

  "breadcrumb.finance": "Finance",
  "breadcrumb.dashboard": "Dashboard",
  "breadcrumb.presence": "Presence",
  "breadcrumb.accountancy": "Accountancy",
  "breadcrumb.inventory": "Inventory",
  "breadcrumb.settings": "Settings",
  "breadcrumb.payroll": "Payroll",
  "breadcrumb.leave_and_permission": "Leave and Permission",
  "breadcrumb.annual_leave": "Annual Leave",
  "breadcrumb.special_leave": "Special Leave",
  "breadcrumb.work_schedule": "Work Schedule",
  "breadcrumb.approval": "Approval",
  "breadcrumb.detail": "Detail",
  "breadcrumb.404": "404 Not Found",

  "dashboard.hello_name": "Halo {{name}},",
  "dashboard.schedule_a_demo_and_consult":
    "Let's schedule a free online demo and consultation with superior Gajihub team.",
  "dashboard.demo_online_with_zoom":
    "The demo will be conducted online using Zoom 1 on 1 for 45 minutes.",
  "dashboard.age": "Age",
  "dashboard.religion": "Religion",
  "dashboard.education": "Education",
  "dashboard.gender": "Gender",
  "dashboard.marital_status": "Maritial Status",
  "dashboard.number_of_employee_per_month": "Number of Employee per Month",
  "dashboard.number_of_employees_per_organization":
    "Number of Employee per Organization",
  "dashboard.new": "New",
  "dashboard.active": "Active",
  "dashboard.quit_work": "Quit Work",
  "dashboard.years_of_work": "Years of Work",
  "dashboard.total_salary_of_employee": "Total Salary of Employee",
  "dashboard.total_salary_this_month": "Total Salary This Month",
  "dashboard.average_salary_this_month": "Average Salary This Month",
  "dashboard.from_the_previous_month": "from the previous month",
  "dashboard.salary_per_organization": "Salary Per Organization",
  "dashboard.amount": "Amount",
  "dashboard.total": "Total",
  "dashboard.interval": "Interval",
  "dashboard.employee_status": "Total Employee Based On Status",
  "dashboard.total_personalia_based_on_salary":
    "Total Personalia Based On Salary",
  "dashboard.custom": "Custom",
  "dashboard.total_salary": "Total Salary {{daterange}}",
  "dashboard.average_salary": "Average Salary {{daterange}}",
  "dashboard.from_the_previous": "from the previous {{daterange}}",
  "dashboard.total_salary_per_component": "Salary per Component",
  "dashboard.level_organization": "Level Organization",
  "dashboard.number_of_attendance_status_employee":
    "Employee Attendance Status",
  "dashboard.attendance": "Employee Attendance",
  "dashboard.list_of_late_employees": "Late",
  "dashboard.list_of_employees_leave": "Leave & Permission",
  "dashboard.edit": "Edit Dashboard",
  "dashboard.exit": "Exit",
  "dashboard.show": "Show",
  "dashboard.hide": "Hide",
  "dashboard.employee_of_attendance_rating_statistics":
    "Employee of Attendance Statistics",
  "dashboard.most_late_employees": "Most Late Employees",
  "dashboard.most_attendance_without_late_employees":
    "Most Attendance Without Late Employees",
  "dashboard.most_attendance_with_late_employees":
    "Most Attendance With Late Employees",
  "dashboard.most_leave_employees": "Most Leave Employees",
  "dashboard.type": "Type",
  "dashboard.number_of_days": "Number of Days",
  "dashboard.employee_termination": "Employee's End of Employment",

  "businessFlow.businessFlow": "Settings Business Flow",
  "businessFlow.lang_date_currency": "Language, Date, & Currency",
  "businessFlow.app_lang": "Application language",
  "businessFlow.app_lang_desc":
    "Default language for every staff in your company",
  "businessFlow.time_zone": "Time zone",
  "businessFlow.time_zone_desc": "Time zone location of your company",
  "businessFlow.date_format": "Date format",
  "businessFlow.date_format_desc": "Date display in Gajihub app",
  "businessFlow.time_format": "Time format",
  "businessFlow.time_format_desc": "Hour and minute display in Gajihub app",
  "businessFlow.number_after_comma": "Number after comma",
  "businessFlow.number_after_comma_desc":
    "How much number behind comma showed in Gajihub app?",
  "businessFlow.currency_format": "Currency format",
  "businessFlow.currency_format_desc": "Currency display in Gajihub app",
  "businessFlow.activate_multi_currency": "Activate multi currency feature",
  "businessFlow.activate_multi_currency_desc":
    "Does your company use more than one currency?",
  "businessFlow.base_currency": "Base currency",
  "businessFlow.base_currency_desc":
    "Currency used as main currency in your company",
  "businessFlow.show_currency_symbol": "Show currency symbol",
  "businessFlow.show_currency_symbol_desc":
    "Will the currency symbol be displayed on the interface?",

  "setup.you_are_using_a_demo_of_gajihub_at_the_moment":
    "You are using a demo of gajihub at the moment.",
  "setup.slide_button_below_to_clear_the_test_data_and_start_setting_up_your_property":
    "Geser button below to clear the test data and start setting up your company",
  "setup.slide_the_button_below_to_clear_demo_data_all_data_will_be_deleted_and_cannot_be_recovered":
    "Slide the button below to clear demo data. All data will be deleted and cannot be recovered.",
  "setup.are_you_sure_that_you_want_to_clear_demo_":
    "Are you sure to clear demo data?",
  "setup.this_will_remove_all_rooms_room_types_rates_and_bookings":
    "This will remove all data, and cannot be undone.",
  "setup.horee": "Yeaah!",
  "setup.setup_complete":
    "Demo data has been deleted and setup is complete, now you can start using Gajihub.",
  "setup.go_to_dashboard": "Go to Dashboard",
  "setup.demo":
    "The data presented here is dummy data. When ready, {{setup}} to reset the data.",
  "setup.demo_link": "click here",
  "setup.swipe_to_clear_all_data": "Swipe to clear all data",
  "setup.demo_data_deleted_successfully": "Demo data deleted successfully",
  "setup.dummy_data_has_been_deleted_please_start_filling_in_your_employee_data":
    "Dummy data has been deleted. Please start filling in your employee data.",
  "setup.deleting": "Deleting",
  "setup.failed_to_delete_data": "Failed to Delete Data",

  "accountancy.hooray_the_gajiHub_account_has_been_successfully_connected_to_the_kledo_accounting_software":
    "Yeaah!, The GajiHub account has been successfully connected to the Kledo Accounting Software.",
  "accountancy.go_to_kledo": "Go to Kledo",
  "accountancy.gajihub_is_now_automatically_integrated_with_kledo_accounting_software_this_feature_is_available_for_free":
    "Gajihub is now automatically integrated with <strong>Kledo</strong> Accounting Software. This feature is available for <strong>free</strong>.",
  "accountancy.what_benefits_will_you_get_with_this_integration":
    "What benefits will you get with this integration ?",
  "accountancy.employee_payroll_is_automatically_recorded_in_kledo":
    "Employee payroll is automatically recorded in Kledo.",
  "accountancy.employee_payables_and_installment_payments_are_automatically_recorded_to_the_kledo_accounting_software":
    "Employee payables and installment payments are automatically recorded to the Kledo accounting software.",
  "accountancy.employees_at_gajiHub_are_also_automatically_registered_as_employees_at_kledo":
    "Employees at GajiHub are also automatically registered as employees at Kledo.",
  "accountancy.username_password_to_login_to_gajiHub_can_be_used_to_login_to_kledo":
    "Username password to login to GajiHub can be used to login to Kledo.",
  "accountancy.office_inventory_at_gajiHub_is_recorded_in_kledo_automatically":
    "Office inventory at GajiHub is recorded in Kledo automatically.",
  "accountancy.click_the_button_below_to_start_the_integration":
    "Click the button below to start the integration.",
  "accountancy.open_kledo_app": "Open Kledo App",
  "accountancy.integrate_with_kledo": "Integrate with Kledo",
  "accountancy.please_wait": "Please wait a minutes!",
  "accountancy.integrating_kledo_with_gajihub":
    "Integrating Gajihub with Kledo...",

  "accountMapping.account_mapping": "Account Mapping",
  "accountMapping.enable_gajihub_and_kledo_integration_each_payslip_will_automatically_input_the_accounting_journaling_in_kledo":
    "Enable Gajihub & Kledo integration? Each payslip will automatically input the accounting journaling in Kledo.",
  "accountMapping.coa_account_default_salary_fee":
    "COA account default salary fee",
  "accountMapping.by_default_salary_costs_will_be_recorded_in_this_coa_account":
    "By default, salary costs will be recorded in this COA account.",
  "accountMapping.enter_bank_payment_journal_when_making_payroll":
    "Enter bank payment journal when making payroll?",
  "accountMapping.payroll_bank_default_coa_account":
    "Payroll bank default COA account",
  "accountMapping.when_making_payroll_by_default_it_will_be_recorded_as_payment_from_this_bank_account":
    "When making payroll, by default it will be recorded as payment from this bank account.",
  "accountMapping.advanced_settings": "Advanced Settings",
  "accountMapping.coa_account_mapping_per_salary_component":
    "COA account mapping per salary component",
  "accountMapping.payments_per_bank_coa_account_mapping":
    "Payments per bank COA account mapping",
  "accountMapping.please_select_account": "Please select account",
  "accountMapping.coa_account_default_current_liabilities":
    "COA account default current liabilities",
  "accountMapping.by_default_current_liabilities_will_be_recorded_in_this_coa_account_for_example_taxes_payable_bpjs_debt_and_so_on":
    "By default, current liabilities will be recorded in this COA account. For example, Taxes Payable, BPJS Debt and so on",
  "accountMapping.payable_coa_account": "Payable COA Account",
  "accountMapping.when_making_payroll_payments_payroll_payables_are_recorded_in_this_coa_account":
    "When making payroll payments, payroll payables are recorded in this COA account",
  "accountMapping.leave_allowance": "Leave Allowance",
  "accountMapping.tax_payable": "Tax Payable",
  "accountMapping.coa_account_employee_cash_receipt":
    "COA account employee cash receipt",
  "accountMapping.coa_account_employee_reimbursement":
    "COA account employee reimbursement",
  "accountMapping.activate_custom_coa_every_time_a_reimbursement_is_made":
    "Activate custom COA every time a reimbursement is made?",
  "accountMapping.activate_custom_coa_reimbursement_description":
    "When activated, you will be directed to select a COA account when making a reimbursement. If not then by default it will use the COA account selected in the settings",
  "accountMapping.select_account_mapping": "Select account mapping",
  "accountMapping.please_select_account_mapping":
    "Please select account mapping.",
  "accountMapping.employee_reimbursement_cash_payment_coa_account":
    "Employee reimbursement cash payment COA account",
  "accountMapping.tag_settings": "Tag Settings",
  "accountMapping.select_tag": "Select tag",
  "accountMapping.the_tag_is_equated_to_the_employees_office_location":
    "The Tag is Equated To the Employee's Office Location",
  "accountMapping.type_of_salary_payment_transaction_date":
    "Type of salary payment transaction date",

  "mobileMenu.application_menu": "Application Menu",
  "mobileMenu.select_menu_displayed_on_the_ess_app":
    "Select the menu displayed on the ESS app",
  "mobileMenu.select_the_displayed_shortcut_menu":
    "Select the displayed shortcut menu",
  "mobileMenu.select_the_features_displayed_on_the_application_dashboard":
    "Select the features displayed on the application dashboard",
  "mobileMenu.select_the_bottom_menu_that_appears_in_the_application":
    "Select the bottom menu that appears in the application",
  "mobileMenu.attendance": "Attendance",
  "mobileMenu.leave": "Leaves",
  "mobileMenu.salary": "Salary",
  "mobileMenu.calendar": "Calendar",
  "mobileMenu.company": "Company",
  "mobileMenu.schedule": "Schedule",
  "mobileMenu.presence_in": "Presence In",
  "mobileMenu.presence_out": "Presence Out",
  "mobileMenu.break_start": "Break Start",
  "mobileMenu.break_end": "Break End",
  "mobileMenu.visit_in": "Visit In",
  "mobileMenu.visit_out": "Visit Out",
  "mobileMenu.overtime_in": "Overtime Started",
  "mobileMenu.overtime_out": "Overtime Ended",
  "mobileMenu.timeline": "Timeline",
  "mobileMenu.statistic": "Statistic",
  "mobileMenu.employee_attendance_history": "Employee Attendance History",
  "mobileMenu.show_menu_employee_attendance_history":
    "Show menu employee attendance history",
  "mobileMenu.select_the_roles_that_can_access_menu_employee_attendance_history":
    "Select the roles that can access menu employee attendance history",
  "mobileMenu.select_roles": "Select roles",
  "mobileMenu.if_a_role_is_not_filled_then_a_menu_will_appear_for_all_employees":
    "If the role is not filled, the employee absence history menu will appear for all employees.",
  "mobileMenu.task": "Task",

  "billing.title": "Billing",
  "billing.account_expired": "Your account has expired, please renew now",
  "billing.subscription_plan": "Subscription Plan",
  "billing.active_until": "Active until",
  "billing.forever": "Forever",
  "billing.addon": "Addon",
  "billing.available_in_package_pro_and_elite":
    "Available in package Pro & Elite",
  "billing.this_feature_is_available_in_the_package_pro_and_elite":
    "This feature is available in the package Pro & Elite",
  "billing.order_date": "Order Date",
  "billing.upgrade": "Upgrade",
  "billing.upgrade_plan": "Upgrade Plan",
  "billing.renew_plan": "Renew Plan",
  "billing.select_plan": "Select a plan",
  "billing.please_select_a_plan": "Please select a plan",
  "billing.select_contract_duration": "Select the contract duration",
  "billing.please_select_the_contract_duration":
    "Please select the contract duration",
  "billing.get_25_discount_for_1_year_payment_and_for_6_months":
    "Get 25% discount for 1 year payment and 2% discount for 6 months payment",
  "billing.get_30_discount_for_2_year_payment":
    "Get 30% discount for 2 years payment",
  "billing.total_bill": "Total bill",
  "billing.upgrade_now": "Upgrade Now",
  "billing.renew_now": "Renew Now",
  "billing.please_enter_number_of_employee": "Please enter number of employee",
  "billing.token_required_to_make_payment":
    "Token is required to make a payment",
  "billing.payment_success": "Payment Succees",
  "billing.payment_pending": "Waiting for Payment",
  "billing.your_account_wiil_be_active_after_payment":
    "Your account will be active after payment. If you have made a payment, please click the following button. Thank You.",
  "billing.your_account_activated_thanks":
    "Your account has been activated. Thank You.",
  "billing.payment_failed": "Payment Failed",
  "billing.please_contact_gajihub_if_difficulity_on_payment":
    "Please contact tim Hebat Gajihub if you have a difficulity on payment.",
  "billing.thankyou": "Thankyou",
  "billing.bill_email_has_been_sent_to_your_email":
    "Bill email has been sent to your email address.",
  "billing.please_make_a_payment_of": "Please make a payment of",
  "billing.by_click_button_below": "by clicking button below",
  "billing.pay_now": "Pay Now",
  "billing.back_to_billing": "Back to Billing",
  "billing.description": "Description",
  "billing.period": "Period",
  "billing.payment_date": "Payment Date",
  "billing.total": "Total",
  "billing.status": "Status",
  "billing.pay": "Pay",
  "billing.payment_history": "Payment History",
  "billing.your_free_trial_active":
    "Your Free Trial account has been activated.",
  "billing.account_will_active_until": "The account will be active until",
  "billing.you_are_currently_using_dummy_data_please_clear_your_data_first_before_upgrading":
    "You are currently using dummy data, please clear your data first before upgrading. {{click_here}} to clear data",
  "billing.unlimited": "Unlimited",
  "billing.the_number_of_employees_has_exceeded_the_maximum_limit_for_your_company_account":
    "The number of employees has exceeded the maximum limit for your company account.",
  "billing.available_in_elite_package": "Available in Elite package",
  "billing.this_feature_is_available_in_the_package_elite":
    "This feature is available in the package Elite",
  "billing.please_enter_the_number_of_additional_employees":
    "Please enter the number of additional employees",
  "billing.current_number_of_employees": "Current number of employees",
  "billing.number_of_employees_after_addition":
    "Number of employees after addition",
  "billing.order_now": "Order Now",
  "billing.add_addon": "Add Addon",
  "billing.hide_addon": "Hide Addon",
  "billing.location": "Location",
  "billing.calculation_details": "Calculation Details",
  "billing.produk": "Product",
  "billing.amount": "Amount",
  "billing.contract": "Contract",
  "billing.per_month": "Per Month",
  "billing.subtotal": "Subtotal",
  "billing.save": "Save",
  "billing.terms_of_use_and_privacy_policy":
    "By clicking the button above, you agree to our <LinkA>Terms of Use</LinkA> and confirm that you have read our <LinkB>Privacy Policy</LinkB>.",

  "referral.referral": "Referral",
  "referral.read_more": "Read more >>",
  "referral.referral_history": "Referral History",
  "referral.your_referral_code": "Your Referral Code",
  "referral.company": "Company",
  "referral.code": "Code",
  "referral.copy_code": "Copy Code",
  "referral.add_code": "Tambah Code",
  "referral.register": "Register",
  "referral.paid": "Paid",
  "referral.transfered": "Transfered",
  "referral.approved": "Approved",
  "referral.lead": "Lead",
  "referral.cannot_get_referral": "Cannot get referral",
  "referral.cannot_add_referral": "Cannot add referral",
  "referral.cannot_delete_referral": "Cannot delete referral",
  "referral.invite_your_friends": "Invite Your Friends to Use Gajihub",
  "referral.get_millions_per_month": "Get millions per month",
  "referral.share_your_referral_code": "Share your referral code",
  "referral.share_via_sosmed_or_anything":
    "Share via sosmed, email, whatsapp, or anything to your friends",
  "referral.discount_for_your_friends": "{{value}}% Discount for your friends",
  "referral.your_friends_will_get_discount":
    "Your friends will get {{value}}% discount if register for 1 year",
  "referral.reward_per_referral": "Reward 50% per referral",
  "referral.get_reward_per_referral":
    "Get a reward of 50% of sales with a maximum of IDR 150.000,- per referral",
  "referral.please_input_code": "Please input code.",
  "referral.code_has_been_copied": "Referral code has been copied.",

  "attendance.check_out_time_must_be_after_check_in_time":
    "Check out time must be after check in time!",
  "attendance.break_in_time_must_be_after_check_in_time":
    "Break in time must be after check in time!",
  "attendance.break_end_time_must_after_break_start_time":
    "Break end time must be after break start time!",
  "attendance.radius_from_the_office":
    "{{radius}} meters radius from the office ({{inTheOffice}})",
  "attendance.in_the_office": "in the office",
  "attendance.outside_the_office": "outside the office",
  "attendance.the_time_must_before_out_time": "The time must before out time!",
  "attendance.the_time_must_after_in_time": "The time must after in time!",
  "attendance.check_in_time_must_before_check_out_time":
    "Check in time must before check out time!",
  "attendance.break_in_time_must_be_before_break_ended_time":
    "Break in time must be before break end time!",
  "attendance.overtime_started_time_must_before_overtime_ended_time":
    "Overtime in must before overtime out!",
  "attendance.overtime_ended_time_must_after_overtime_started_time":
    "Overtime out must after overtime in!",

  "schedule.schedule_changes_will_be_applied_to_all_employees_who_use_this_schedule":
    "Schedule changes will be applied to all employees who use this schedule.",
  "schedule.this_change_may_cause_a_change_in_the_employees_attendance_status":
    "This change may cause a change in the employee's attendance status.",
  "schedule.are_u_sure_to_change_this_work_schedule":
    "Are u sure to change this work schedule ?",
  "schedule.drag_an_drop_shift_below_into_the_calendar_table_on_the_left":
    "Drag and drop shift below into the calendar table on the left.",
  "schedule.last_week": "Last week",
  "schedule.next_week": "Next week",
  "schedule.click_here_to_assign_work_schedules_here_to_each_employee":
    "Click here to apply work schedules here to each employee.",

  "attendanceApproval.employee_presence_that_requires_admin_approval_will_be_entered_in_this_table":
    "Employee presence that requires admin approval will be entered in this table.",
  "attendanceApproval.employees_can_make_attendance_from_the_ess_gaihub_application_click_here_to_install_the_android_and_ios_version":
    "Employees can make attendance from the ESS GajiHub application, {{link_android}} to install the Android version or {{link_ios}} iOS version.",
  "attendanceApproval.you_can_set_attendance_criteria_that_require_approval_through_the_settings_menu_here":
    "You can set presence criteria that require approval {{link_presence_application_validation_setting}} via settings menu.",
  "attendanceApproval.presence_application_validation_setting":
    "Presence Application Validation Settings",
  "attendanceApproval.in_here": "in here",
  "attendanceApproval.approval_employee_attendance_will_go_to_this_table":
    "<p>Approved employee attendance will be entered in this table.</p><p> Not all attendance requires approval, you can set attendance criteria that require approval {{link_presence_application_validation_setting}} via the settings menu.</p>",
  "attendanceApproval.the_employee_attendance_that_you_decline_will_go_to_this_table":
    "<p>Employee attendance that you reject will be entered in this table.</p><p> You can set attendance criteria that require approval {{link_presence_application_validation_setting}} via settings menu.</p>",
  "leaves.this_table_will_display__a_list_of_request_for_annual_leave_from_your_employees":
    "<p>This table will display a list of requests for annual leave from your employees.</p><p> Your employees can apply for annual leave through the ESS Gajihub application, {{link_android}} to install the Android version, {{link_ios}} for the iOS version. </p><p> You need to approve all leave applications before deducting the employee's leave balance.</p>",
  "leaves.special_leave_is_leave_for_employees_who_have_urgent_needs":
    "<p>Special leave is leave for employees who have urgent needs, for example maternity leave, funeral leave and so on.</p><p>You can set the specific types of leave allowed by the company and the maximum duration {{link_leaves}}.</p><p> Special leave requests submitted by your employees through the ESS GajiHub application will appear on this page, and you can then approve or reject them.</p><p> Employees who are approved for leave in particular will continue to receive a salary from the company.</p><p> {{link_android}} to install the Android version of ESS, and {{link_ios}} for the iOS version.</p>",
  "leaves.your_employees_can_apply_for_sick_leave_via_ess_gajihub":
    "<p>Your employees can apply for sick leave via ESS GajiHub.</p><p> Then you can approve or reject the sick leave application.</p><p> Employees whose sick leave is approved will still receive a salary from the company.</p>",
  "leaves.your_employees_can_apply_for_a_permit_via_ess_gajihub":
    "<p>Your employees can apply for a permit via ESS SalaryHub.</p><p> The permits referred to here are permits other than sick leave, and other than those that can be categorized as special leave.</p>",
  "leaves.unpaid_leave_is_a_permission_not_to_come_to_work_and_the_employee_will_not_receive_a_salary_during_this_absence":
    "<p>Unpaid Leave is a permission not to come to work and the employee will not receive a salary during this absence.</p>",

  "approval.all_submission_from_employees_that_require_you_approval_will_appear_here":
    "<p>All submissions from employees that require your approval will appear here.</p><p> This includes applying for leave, permits, sickness and so on. You can reject or approve the submission.</p>",
  "approval.submissions_from_approved_employees_will_be_displayed_here":
    "<p>Submissions from approved employees will be displayed here. You can set the approval flow {{link_setting}}.</p>",
  "approval.submission_from_employee_who_have_been_rejected_will_be_displayed_here":
    "Submissions from employees who have been rejected will be displayed here. You can set the approval flow {{link_setting}}.",
  "timeline.timeline_is_your_company_internal_socialmedia":
    "<p>Timeline is your company's internal social media.</p><p> All employees can interact with each other through the ESS GajiHub application.</p><p> This will make the work aura more exciting and vibrant.</p<p> You can still hide timeline posts from employees you deem inappropriate.</p>",
  "announcement.announcements_will_be_sent_to_all_employees_via_the_ess_gajihub_application":
    "<p>Announcements will be sent to all employees via the ESS GajiHub application.</p><p> You will get a report on which employees have read the announcement.</p>",
  "company.company_regulations_will_be_displayed_on_the_ess_gajihub_application":
    "<p>Company regulations will be displayed on the ESS GajiHub application.</p><p> All employees will be able to read company regulations, thereby making your company more transparent to all employees.</p>",

  "mobileMenu.approval": "Approval",
  "mobileMenu.teams": "Teams",
  "mobileMenu.cash_receipt": "Cash Receipt",

  "template.notification_templates": "Notification Templates",
  "template.email": "Email",
  "template.whatsapp": "Whatsapp",
  "template.mobile_apps": "Mobile Apps",
  "template.edit_email_template": "Edit Email Template",
  "template.edit_whatsapp_template": "Edit Whatsapp Template",
  "template.edit_mobile_apps_notification_template":
    "Edit Mobile Apps Notification Template ",
  "template.subject": "Subject",
  "template.message": "Message",
  "template.please_input": "Please input <span>{{type}}</span>",
  "template.revert_to_default": "Revert to Default",
  "template.insert_tags": "Insert Tags",
  "template.are_you_sure_revert_to_default": "Are you sure revert to default?",
  "template.off": "Off",
  "template.on": "On",

  "layoutSalarySlip.title": "Layout Salary Slip",
  "layoutSalarySlip.personal_and_secret": "Personal and Secret",
  "layoutSalarySlip.resize_logo": "Resize Logo",
  "layoutSalarySlip.resize_logo_footer": "Resize Logo Footer",
  "layoutSalarySlip.settings": "Settings",
  "layoutSalarySlip.show_full_address": "Show Full Address (footer)",
  "layoutSalarySlip.show_leave": "Show leave",
  "layoutSalarySlip.back_to_default_settings": "Back to Default Settings",
  "layoutSalarySlip.are_you_sure_to_remove_the_company_logo":
    "Are you sure to remove the company logo?",
  "layoutSalarySlip.font_size": "Font Size",
  "layoutSalarySlip.show_logo": "Show Logo",
  "layoutSalarySlip.show_header": 'Show Text "Personal and Secret"',
  "layoutSalarySlip.show_company_name": "Show Company Name",
  "layoutSalarySlip.show_employee_id": "Show Employee ID",
  "layoutSalarySlip.show_ptkp_status": "Show PTKP Status",
  "layoutSalarySlip.show_date_joined": "Show Date Joined",
  "layoutSalarySlip.show_take_home_pay": "Show Take Home Pay",
  "layoutSalarySlip.show_leave_acrued": "Show Leave Acrued",
  "layoutSalarySlip.show_leave_used": "Show Leave Used",
  "layoutSalarySlip.show_leave_balance": "Show Leave Balance",
  "layoutSalarySlip.show_note": "Show Note",
  "layoutSalarySlip.show_unpaid_cash_receipt": "Show Unpaid Cash Receipt",
  "layoutSalarySlip.show_bpjs": "Show BPJS",
  "layoutSalarySlip.preview_salary_slip": "Preview Salary Slip",
  "layoutSalarySlip.show_attendance_summary": "Show Attendance Summary",
  "layoutSalarySlip.show_attendance_summary_working_days":
    "Show Attendance Summary Working Days",
  "layoutSalarySlip.show_attendance_summary_day_off":
    "Show Attendance Summary Day Off",
  "layoutSalarySlip.show_attendance_summary_present_at_working_day":
    "Show Attendance Summary Present at Working Day",
  "layoutSalarySlip.show_attendance_summary_national_holiday":
    "Show Attendance Summary National Holiday",
  "layoutSalarySlip.show_attendance_summary_joint_holiday":
    "Show Attendance Summary Joint Holiday",
  "layoutSalarySlip.show_attendance_summary_non_holiday_event":
    "Show Attendance Summary Non Holiday Event",
  "layoutSalarySlip.show_signature": "Show Signature",
  "layoutSalarySlip.show_signature_on_right": "Show Signature on Right",
  "layoutSalarySlip.show_signature_on_left": "Show Signature on Left",
  "layoutSalarySlip.show_signature_in_the_middle":
    "Show Signature in the Middle",
  "layoutSalarySlip.signature": "Signature",
  "layoutSalarySlip.direct_signature": "Direct Signature",
  "layoutSalarySlip.upload_signature": "Upload Signature",
  "layoutSalarySlip.are_you_sure_to_remove_the_signature":
    "Are you sure to remove the signature?",
  "layoutSalarySlip.please_enter_a_signature": "Please enter a signature.",
  "layoutSalarySlip.show_late_total": "Show Late Total",
  "layoutSalarySlip.show_attendance_summary_no_status":
    "Show Total Attendance Summary No Status",
  "layoutSalarySlip.show_attendance_summary_status_present":
    "Show Total Attendance Summary Status Present",
  "layoutSalarySlip.show_attendance_summary_status_present_at_working_day":
    "Show Total Attendance Summary Status Present at Working Day",
  "layoutSalarySlip.show_attendance_summary_status_present_at_non_working_day":
    "Show Total Attendance Summary Status Present at Non Working Day",
  "layoutSalarySlip.show_attendance_summary_status_absent":
    "Show Total Attendance Summary Status Absent",
  "layoutSalarySlip.show_attendance_summary_status_non_working_day":
    "Show Total Attendance Summary Status Non Working Day",
  "layoutSalarySlip.show_attendance_summary_status_out_of_office_duty":
    "Show Total Attendance Summary Status Out of Office Duty",
  "layoutSalarySlip.show_attendance_summary_status_sick":
    "Show Total Attendance Summary Status Sick ",
  "layoutSalarySlip.show_attendance_summary_status_permit":
    "Show Total Attendance Summary Status Permit",
  "layoutSalarySlip.show_attendance_summary_status_leave":
    "Show Total Attendance Summary Status Leave",
  "layoutSalarySlip.show_attendance_summary_status_half_day_leave":
    "Show Total Attendance Summary Status Half Day Leave",
  "layoutSalarySlip.show_attendance_summary_status_unpaid_leave":
    "Show Total Attendance Summary Status Unpaid Leave",
  "layoutSalarySlip.show_attendance_summary_status_joint_holiday":
    "Show Total Attendance Summary Status Joint Holiday",
  "layoutSalarySlip.show_component_multiplier_details":
    "Show Components Multiplier Detail",
  "layoutSalarySlip.automatically_attach_pph_tax_calculation":
    "Automatically Attach PPh Tax Calculation",
  "layoutSalarySlip.show_attendance_summary_working_hours":
    "Show Total Attendance Summary Working Hours",
  "layoutSalarySlip.show_attendance_summary_office_hours":
    "Show Total Attendance Summary Office Hours",

  "appSetting.apps": "Apps",
  "appSetting.date_created": "Date Created",
  "appSetting.app_name": "App Name",
  "appSetting.client_id": "Client ID",
  "appSetting.client_id_has_been_copied": "Client ID has been copied",
  "appSetting.secret_has_been_copied": "Secret has been copied",
  "appSetting.endpoint_has_been_copied": "API Endpoint URL has been copied",
  "appSetting.documentation_has_been_copied":
    "API Documentation has been copied",
  "appSetting.api_documentation_endpoint": "API Documentation Endpoint",
  "appSetting.please_input_name": "Please input app name",
  "appSetting.please_redirect_uri": "Please input redirect URI",
  "appSetting.please_valid_uri": "Please input valid URL",
  "appSetting.error_cannot_get": "Cannot get apps",
  "appSetting.error_cannot_create": "Cannot create apps",
  "appSetting.error_cannot_edit": "Cannot edit apps",
  "appSetting.error_cannot_delete": "Cannot delete apps",
  "appSetting.app_has_been_created_make_sure_save_secret":
    "New app has been created, please please save secret code below before going to another page.",
  "appSetting.secret_code_just_shown_once":
    "Secret code just shown once. Save this code, because after this you cannot see this secret code again.",
  "appSetting.integrate_your_app_with_gajihub":
    "Integrate your application with Gajihub",
  "appSetting.now_app_can_be_integrated_with_gajihub":
    "Now your application, website, or software can be integrated with Gajihub.",
  "appSetting.for_more_details_lets_see_the_video":
    "For more details, lets watch the video",
  "appSetting.watch_video": "Watch video",
  "appSetting.add_apps": "Add Apps",
  "appSetting.edit_apps": "Edit Apps",
  "appSetting.detail_apps": "Detail Apps",
  "appSetting.are_you_sure_to_delete": "Are you sure to delete?",

  "table.data_empty": "Data Empty",
  "table.data_x_empty": "{{name}} Data Empty",
  "table.lets_create_your_first_x": "Lets create your first {{name}}!",

  "mobileMenu.reimbursement": "Reimbursement",

  "audit.title": "Audit",
  "audit.by": "by",
  "audit.show_audit": "Show Audit",
  "audit.last_modified_by": "Last modified by",
  "audit.on": "on",

  "faqs.whats_your_question": "What's your Question?",
  "faqs.most_popular": "Most Popular",
  "faqs.most_help": "Most Help",
  "faqs.search_result": "Search result {{value}}",
  "faqs.title_by_category": "By {{value}} Category",
  "faqs.title_by_tag": "By {{value}} Tag",
  "faqs.was_this_answer_helpful": "Was this answer helpful?",
  "faqs.last_update": "Last Update",
  "faqs.copy_raw_text": "FAQ copied successfully",
  "faqs.category": "Category",

  "journal.view_journal_entries": "View journal entries",
  "journal.journal_report": "Journal Report",
  "journal.account": "Account",
  "journal.debit": "Debit",
  "journal.credit": "Credit",

  "client.title": "Client",
  "client.add_client": "Add Client",
  "client.edit_client": "Edit Client",
  "client.company": "Company",
  "client.location": "Location",
  "client.look_on_the_map": "Look on the map",
  "client.client_name": "Client Name",
  "client.please_input": "Please input {{value}}.",
  "client.personal_information": "Personal Information",
  "client.location_name": "Location Name",
  "client.select_address_via_map": "Select address via map",
  "client.are_you_sure_to_delete_client": "Are you sure to delete client?",
  "client.empty_message":
    "By recording your client's location here, you can arrange so that the team can only make client visits from the radius of the client's location that has been recorded.<br /> <Link>Click here</Link> to set your client visit policy!",
  "client.import_client": "Import Client",
  "client.follow_these_steps_to_import_client":
    "Follow these steps to import client",
  "client.1_download_file_template_client": "1. Download file template client",
  "client.start_by_downloading_file_template_client":
    "Get started by downloading an Excel template file. This file has header fields that Gajihub needs to import details from client.",
  "client.2_copy_client_data_into_the_template":
    "2. Copy client data into the template",
  "client.how_to_custom_file_template_client":
    "Using Excel or other spreadsheet software, insert client data into the template file from Gajihub. Make sure the client data matches the header column in the template.",
  "client.important_import_info":
    "Don't change the column headers in the template. This is required for the import to work.<br/> A maximum of 1,000 clients data can be imported at a time.<br/> When importing, Gajihub will only enter new data, no data will be deleted or updated.<br/>",
  "client.client_successfully_imported":
    "{{rowCount}} clients successfully imported.",
  "client.go_to_the_client_list_page": "Go to client list page",

  "mobileSetting.notifications_use_company_settings":
    "Notifications use company settings",
  "mobileSetting.cash_receipt": "Cash Receipt",
  "mobileSetting.company_announcement": "Company Announcement",
  "mobileSetting.friends_birthday": "Friends Birthday",
  "mobileSetting.presence": "Presence",
  "mobileSetting.exit_presence": "Exit Presence",
  "mobileSetting.break": "Break",
  "mobileSetting.after_break": "After Break",
  "mobileSetting.late_presence": "Late Presence",
  "mobileSetting.receive_salary": "Receive Salary",
  "mobileSetting.update_timeline": "Update Timeline",
  "mobileSetting.leave": "Leave",
  "mobileSetting.approval": "Approval",
  "mobileSetting.reimburse": "Reimburse",
  "mobileSetting.calendar": "Calendar",
  "mobileSetting.teams_use_company_settings": "Teams use company settings",
  "mobileSetting.task": "Task",
  "mobiieSetting.task_activity": "Task Activity",

  "settingOvertime.overtime_schedule": "Overtime Schedule",
  "settingOvertime.add_overtime_schedule": "Add Overtime Schedule",
  "settingOvertime.edit_overtime_schedule": "Edit Overtime Schedule",
  "settingOvertime.detail_overtime_schedule": "Detail Overtime Schedule",
  "settingOvertime.overtime_type": "Overtime Type",
  "settingOvertime.overtime_time": "Overtime Time",
  "settingOvertime.type": "Type",
  "settingOvertime.calculation": "Calculation",
  "settingOvertime.times": "Times",
  "settingOvertime.wages": "Wages",
  "settingOvertime.overtime_wages": "Overtime Wages",
  "settingOvertime.overtime_name": "Overtime Name",
  "settingOvertime.please_input": "Please input {{value}}.",
  "settingOvertime.please_select": "Please select {{value}}.",
  "settingOvertime.total_wages": "Total Wages",
  "settingOvertime.detail_overtime": "Detail Overtime",
  "settingOvertime.actual_overtime_hours": "Actual Overtime Hours",
  "settingOvertime.overtime_conversion_hours": "Overtime Conversion Hours",
  "settingOvertime.change_overtime_durations": "Change Overtime Durations",
  "settingOvertime.actual_hours": "Actual Hours",
  "settingOvertime.conversion_hours": "Conversion Hours",
  "settingOvertime.setting_overtime": "Setting Overtime",
  "settingOvertime.add_setting_overtime": "Add Setting Overtime",
  "settingOvertime.edit_setting_overtime": "Edit Setting Overtime",
  "settingOvertime.detail_setting_overtime": "Detail Setting Overtime",
  "settingOvertime.tooltip_calculation":
    "Overtime wages will be calculated divided by the amount per hour that has been set. For example, it is set per 2 hours with a salary of 50.000 then 50.000/2 = 25.000",
  "settingOvertime.tooltip_time":
    "Distribution of overtime. Morning (00:00 - 08:00), Afternoon (08:00 - 16:00), Evening (16:00 - 23:59)",
  "settingOvertime.click_here_to_see_the_applied_overtime_settings":
    "Click here to see the applied overtime settings",
  "settingOvertime.settings_have_been_used": "Setting has been used",

  "tracking.title": "Tracking",
  "tracking.location_records": "Location Records",
  "tracking.active_employees": "Active Employees",
  "tracking.are_you_sure_to_enable_location_tracking":
    "Are you sure to enable location tracking?",
  "tracking.location_tracking_interval": "Location tracking interval",
  "tracking.location_tracking_break_duration":
    "Location tracking break duration",
  "tracking.manage_location_tracking": "Manage Location Tracking",
  "tracking.see_tracking": "See Tracking",
  "tracking.travel_distance": "Travel Distance",
  "tracking.detail_tracking": "Tracking Detail",
  "tracking.starting_location": "Starting location",
  "tracking.current_location": "Current location",
  "tracking.records": "Records {{value}}",
  "tracking.the_current_location_will_be_updated_according_to_the_selected_time_interval":
    "The current location will be updated according to the selected time interval.",
  "tracking.the_duration_for_live_tracking_to_be active_again_before_the_break_is_over":
    "The duration for live tracking to be active again before the break is over.",
  "tracking.this_feature_is_only_available_on_the_android_version_of_ess_does_not_support_the_ios_version":
    "This feature is only available on the Android version of ESS, does not support the iOS version.",
  "tracking.default_map_view": "Default Map View",
  "tracking.satellite_map_view": "Satellite Map View",

  "automaticSequencing.title": "Penomoran Otomatis",
  "automaticSequencing.define_the_number_to_be_used":
    "Determine the number used to create the numbering. The number below will be automatically added every time a new document is created.",
  "automaticSequencing.edit_number": "Edit Number",
  "automaticSequencing.number_format": "Number Format",
  "automaticSequencing.automatic_numbering_output_example":
    "Automatic Numbering Output Example",
  "automaticSequencing.current_number": "Current Number",
  "automaticSequencing.reset_number_every": "Reset Number Every",
  "automaticSequencing.never_reset": "Never reset",
  "automaticSequencing.every_month": "Every month",
  "automaticSequencing.every_year": "Every year",
  "automaticSequencing.on_date": "On date",
  "automaticSequencing.month": "Month",
  "automaticSequencing.please_input": "Please input the {{value}}",
  "automaticSequencing.add_numbering_code": "Add Numbering Code",
  "automaticSequencing.the_last_reset_number":
    "The last reset was on {{date}}, [<Link>click here</Link>] to view the log",
  "automaticSequencing.title_setting":
    "The number is locked so that it cannot be changed",
  "automaticSequencing.description_setting":
    "If it is active, the number will follow the Gajihub system, it cannot be changed by the user.",
  "automaticSequencing.number": "Number",
  "automaticSequencing.please_enter_the_current_number":
    "Please enter the current number",
  "automaticSequencing.tooltip_number":
    "The number will be automatically generated by the system, but you can also change it here. You can set this number format <Link>here</Link>.",
  "automaticSequencing.the_number_is_already_in_use_click_here_to_get_a_new_number":
    "The number is already in use, <Link>click here</Link> to get a new number.",

  "fingerprint.fingerprint_connect": "Fingerprint Connect",
  "fingerprint.add": "Add",
  "fingerprint.fingerprint_machine": "Fingerprint Machine",
  "fingerprint.machine_name": "Machine Name",
  "fingerprint.machine_id": "Machine ID",
  "fingerprint.office_location": "Office Location",
  "fingerprint.created": "Created",
  "fingerprint.status": "Status",
  "fingerprint.add_fingerprint_connect": "Add Fingerprint Connect",
  "fingerprint.edit_fingerprint_connect": "Edit Fingerprint Connect",
  "fingerprint.select_fingerprint_machine": "Select Fingerprint Machine",
  "fingerprint.description_select_fingerprint_machine":
    "By using Fingerprint Connect, employee attendance data will automatically enter the GajiHub system, cool, right? Let's connect your fingerprint with GajiHub.",
  "fingerprint.select_the_fingerprint_device_that_will_beconnected_to_gajiHub":
    "Select the fingerprint device that will be connected to GajiHub.",
  "fingerprint.step_machine_id":
    "The Machine ID can be seen on the <bold>Fingerspot</bold> machine in Menu -> Options -> Network -> Machine Number",
  "fingerprint.select_device": "Select Device",
  "fingerprint.please_input": "Please input {{value}}",
  "fingerprint.input": "Input {{value}}",
  "fingerprint.add_fingerprint": "Add Fingerprint",
  "fingerprint.employee_synchronization": "Employee Synchronization",
  "fingerprint.are_you_sure_to_delete": "Are you sure to delete {{value}}?",
  "fingerprint.fingerprint_connect_synchronization":
    "Fingerprint Connect Synchronization",
  "fingerprint.pin_and_name_in_fingerprint": "PIN & Name in Fingerprint",
  "fingerprint.synchronize_employees_with_fingerprint":
    "Synchronize employees with fingerprint",
  "fingerprint.reload_option":
    "Reloading will delete all previously fetch users data",
  "fingerprint.update_option":
    "The update will fetch in users data that doesn't exist yet and update existing users data",
  "fingerprint.integration_instructions": "Integration Instructions",
  "fingerprint.network_setup_on_the_machine": "Network setup on the machine",
  "fingerprint.network_setup_on_the_machine_description":
    "<ul><li>Go to Menu -> Options -> Network.</li><li>Connect the machine to WIFI (until the wifi signal indicator appears).</li><li>Set the machine in internet mode, not local (until the cloud indicator appears).</li><li>Set the server value to `fdevice.com` with port `9002`.</li></ul>",
  "fingerprint.setting_the_presence_type_on_the_machine":
    "Setting the presence type on the machine",
  "fingerprint.setting_the_presence_type_on_the_machine_description":
    "<ol><li>Go to Menu -> Options -> Device -> Att Status.</li><li>Activate Att Status if it is not already active, in the top option.</li><li>Set the options below to match the attendance type on GajiHub, so the order is like this :<ul><li>in (0)</li><li>out (1)</li><li>break (2)</li><li>back (3)</li><li>overtime (4)</li><li>overtime out (5)</li><li>visit (6)</li><li>visit out (7)</li></ul></li><li>Labels can be changed according to taste.</li></ol>",
  "fingerprint.integration_flow_in_gajiHub": "Integration flow in GajiHub",
  "fingerprint.integration_flow_in_gajiHub_description":
    "<ol><li>Add integration with Fingerspot by filling in:<ul><li>Machine ID (obtained from Menu -> Options -> Network -> Machine No)</li><li>Machine Name</li><li>Office Location</li></ul></li><li>All employees at the office location are connected to the machine.</li></ol><strong>For new fingerspot users</strong><ol><li>All employees are registered to the machine. Users who log in to the machine will have the same `pin` as their employee `ID`.</li><li>Employees who have registered on the machine scan their fingerprints on the machine according to their PIN.</li></ol><strong>For old fingerspot users</strong><ol><li>Pull the user data registered on the machine.</li><li>Connect employees with users who have been withdrawn from the machine one by one.</li></ol><span>Manual synchronization or presence synchronization that has been carried out on the previous machine can be done via the fingerprint connect synchronization button in question.</span>",
  "fingerprint.employee_synchronization_list": "Employee Synchronization List",
  "fingerprint.please_wait_a_few_moments_before_you_can_see_the_update_or_update_of_the_entire_user_list":
    "Please wait a few moments before you can see the update of the entire user list",
  "fingerprint.attendance_synchronization": "Attendance Synchronization",
  "fingerprint.attendance_date": "Attendance Date",
  "fingerprint.please_wait_a_few_moments_before_you_can_see_the_attendance_synchronization_results":
    "Please wait a few moments before you can see the attendance synchronization results.",
  "fingerprint.active": "Active",
  "fingerprint.not_active": "Not Active",
  "fingerprint.error": "Error",
  "fingerprint.fetch_users": "Fetch Users",
  "fingerprint.add_users": "Add Users",
  "fingerprint.fetch_users_from_the_fingerspot_machine":
    "Fetch users from the Fingerspot machine",
  "fingerprint.add_users_into_the_fingerspot_machine":
    "Add users into the fingerspot machine",
  "fingerprint.add_users_info":
    "Select the employee who will be entered into the fingerprint machine. The results generated by the user in the fingerprint machine have the same pin as the employee ID.",
  "fingerprint.reload": "Reload",
  "fingerprint.activation": "Activation",
  "fingerprint.activation_description":
    "Contact GajiHub customer service to activate fingerprint connect.",

  "sso.login_with_sso": "Login with SSO",
  "sso.continue_with_sso": "Continue with SSO",
  "sso.login_sso_desc":
    "Your account has Single Sign-On enabled. Continue with SSO <br /> to access your Gajihub account.",
  "sso.back_to_login": "Back to login",
  "sso.sso_setting": "SSO Settings",
  "sso.domain_verification": "Domain Verification",
  "sso.domain_verification_successful": "Domain Verification Successful",
  "sso.sso_configuration": "SSO Configuration",
  "sso.add_domain": "Add Domain",
  "sso.add_domain_desc":
    "Please enter your email address that contains your company domain name. For example, myname@companyname.com",
  "users.the_input_is_not_valid_email_": "Please enter a valid email.",
  "sso.next_step": "Next",
  "sso.domain_successfully_verified":
    "Congratulations! The domain {{domainName}} has been successfully verified.",
  "sso.verify_domain_desc":
    "A verification code has been sent to your email address. Please enter the verification code in the form below.",
  "sso.please_input_your_code": "Please enter your code",
  "sso.resend_code": "Resend code",
  "sso.verification": "Verification",
  "sso.verified": "Verified",
  "sso.pending_verification": "Pending Verification",
  "sso.sso_configuration_service_provider_desc":
    "Please enter the SP Entity ID and SP ACS URL below into your identity provider's administration page.",
  "sso.sso_configuration_identity_provider_desc":
    "Get the IDP Entity ID and SAML Login URL from your identity provider, then enter them into the form below.",
  "sso.sso_please_enter_idp_entity_id": "Please enter the IDP Entity ID",
  "sso.sso_please_enter_saml_login_url": "Please enter the SAML Login URL",
  "sso.faq_question_what_is_sso": "What is Single Sign-On (SSO)?",
  "sso.faq_answer_what_is_sso":
    "SSO allows users to log in to multiple applications and websites using a single identity provider. This way, users don't need to remember multiple usernames and passwords for each application or website.",
  "sso.faq_question_how_does_sso_work": "How does SSO work?",
  "sso.faq_answer_1_how_does_sso_work":
    "The user attempts to log in to Gajihub using SSO.",
  "sso.faq_answer_2_how_does_sso_work":
    "Gajihub sends the user's data to the Identity Provider.",
  "sso.faq_answer_3_how_does_sso_work":
    "The Identity Provider checks the user's credentials.",
  "sso.faq_answer_4_how_does_sso_work":
    "The Identity Provider sends a response to Gajihub, confirming that the user is allowed to log in.",
  "sso.faq_answer_5_how_does_sso_work":
    "Gajihub receives this information from the Identity Provider and logs the user into Gajihub.",
  "sso.faq_question_how_to_setup_sso_in_gajihub":
    "How do I set up SSO in Gajihub?",
  "sso.faq_answer_how_to_setup_sso_in_gajihub":
    "Click {{click_here}} for a tutorial on how to set up SSO in Gajihub.",
  "sso.here": "here",

  "businessFlow.system": "System",
  "businessFlow.sso": "Enable login with SSO?",
  "businessFlow.sso_desc": "Do you use SSO for login?",

  "task.title": "Task",
  "task.add_task": "Add Task",
  "task.edit_task": "Edit Task",
  "task.duplicate_task": "Duplicate Task",
  "task.form_title": "Title",
  "task.detail": "Detail",
  "task.input": "Input {{value}}",
  "task.select": "Select {{value}}",
  "task.please_input": "Please input {{value}}",
  "task.please_select": "Please select {{value}}",
  "task.time_started": "Time Started",
  "task.deadline": "Deadline",
  "task.location": "Location",
  "task.select_location_via_map": "Select location via map",
  "task.client": "Client",
  "task.assigned_to": "Assigned to:",
  "task.add_employee": "Add Employee",
  "task.edit_employee": "Edit Employee",
  "task.status": "Status",
  "task.priorities": "Priorities",
  "task.weight": "Weight",
  "task.delete_task": "Delete Task",
  "task.cancel_delete": "Cancel delete",
  "task.multiple_delete_confirm":
    "Are you sure you want to delete this task?, you can't restore it afterwards.",
  "task.drag_and_drop_task_here": "Drag and drop task here",
  "task.sort_type": "Sort Type",
  "task.sort_by": "Sort By",
  "task.activate_task_integration_with_payroll":
    "Activate task integration with payroll",
  "task.activate_task_integration_with_client_visit":
    "Are the tasks specified by the client automatically connected to the client's visit attendance?",
  "task.archived": "Archived",
  "task.activities": "Activities",
  "task.show_activities": "Show Activities",
  "task.hide_activities": "Hide Activities",
  "task.content": "Content",
  "task.comment": "Comment",
  "task.write_comment": "Write a comment...",
  "task.edit_status": "Edit Status",
  "task.the_task_has_exceeded_the_deadline":
    "The task has exceeded the deadline",
  "task.change_status": "{{activity}} to {{result}}",
  "task.activity_client_visit": "{{activity}} at {{datetime}}",

  "paymentConnect.title": "Payment Connect",
  "paymentConnect.what_is_payment_gateway": "What is Payment Gateway?",
  "paymentConnect.payment_gateway_description":
    "By using a payment gateway, you can make payroll payments that you set up in your company through various payment vendors, such as Midtrans and Xendit. Later, payroll payments will be sent to the payment vendor you choose and will be approved by the related vendor, then the payment status in GajiHub will be <strong>paid</strong>.",
  "paymentConnect.what_happens_when_my_customers_pay_through_a_payment_gateway":
    "What happens when my customers pay through a Payment Gateway?",
  "paymentConnect.customers_pay_through_a_payment_gateway_description":
    "When payment is made, your payroll will automatically update its payment status. So you don't need to bother making payments through the relevant bank.",
  "paymentConnect.does_gajiHub_charge_a_fee_for_using_the_payment_gateway":
    "Does GajiHub charge a fee for using the Payment Gateway?",
  "paymentConnect.charge_a_fee_for_using_the_payment_gateway_description":
    "No. However, the payment gateway provider will charge a fee per transaction. For more detailed information, you can ask Midtrans or Xendit directly.",
  "paymentConnect.how_to_setup_it": "How to setup it?",
  "paymentConnect.setup_description":
    "Click the following link:<br/><ul><li><Link1>Midtrans</Link1></li><li><Link2>Xendit</Link2></li></ul>",
  "paymentConnect.detail": "Detail",
  "paymentConnect.activate_payments_via": "Activate payments via {{value}}",
  "paymentConnect.midtrans_api_key_help_text":
    "Copy the API Key from your Midtrans account, via the menu Settings >> Access Keys >> Server Key",
  "paymentConnect.xendit_api_key_help_text":
    "Copy the API Key from your Xendit account, via the menu Settings >> Developer >> API keys >> Create new secret key >> Secret API Key",
  "paymentConnect.token_callback_help_text":
    "Click the copy URL button below, then enter it into {{value}} account",
  "paymentConnect.copy": "Copy",
  "paymentConnect.success_copy_url_callback":
    "The callback url was copied successfully",
  "paymentConnect.connection_test": "Connection Test",
};

const data = {
  locale: "en-US",
  antdData,
  messages,
};

export default data;
