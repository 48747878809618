import axios from "utils/axios";
import { stringify } from "query-string";

export const getBillingTransactions = (params) => {
  const query = stringify(params);
  return axios.get(`/app/billingTransactions/pagination?${query}`);
};

export const getBillings = () => {
  return axios.get(`/app/billings`);
};

export const getBillingUpgrade = () => {
  return axios.get(`/app/billings/upgrade`);
};

export const getBillingRenew = () => {
  return axios.get(`/app/billings/renew`);
};

export const getBillingQuantity = () => {
  return axios.get(`/app/billings/quantity`);
};

export const getBillingUpgradeAmount = (params) => {
  return axios.get(`/app/billings/upgrade/amount`, { params });
};

export const getBillingRenewAmount = (params) => {
  return axios.get(`/app/billings/renew/amount`, { params });
};

export const getBillingQuantityAmount = (params) => {
  const query = stringify(params);
  return axios.get(`/app/billings/quantity/amount?${query}`);
};

export const getBillingAddonAmount = (params) => {
  return axios.get(`/app/billings/addons/amount`, { params });
};
export const getBillingToken = (data) => {
  return axios.get(`/app/billingTransactions/${data?.id}/checkout`);
};

export const getBillingAddons = () => {
  return axios.get(`/app/billings/addons`);
};

export const upgradeBillingPlan = (data) => {
  return axios.post(`/app/billings/upgrade`, data);
};

export const upgradeBillingQuantity = (data) => {
  return axios.post(`/app/billings/quantity`, data);
};

export const renewBillingPlan = (data) => {
  return axios.post(`/app/billings/renew`, data);
};

export const orderBillingAddon = (data) => {
  return axios.post(`/app/billings/addons`, data);
};

export const deleteBillingTransaction = (id) => {
  return axios.delete(`/app/billingTransactions/${id}`);
};
