import antdData from "antd/lib/locale/id_ID";

const messages = {
  "topBar.issuesHistory": "Issues History",
  "topBar.projectManagement": "Project Management",
  "topBar.typeToSearch": "Ketik untuk mencari...",
  "topBar.buyNow": "Beli Sekarang $24",
  "topBar.bitcoin": "Bitcoin",
  "topBar.profileMenu.hello": "Halo",
  "topBar.profileMenu.billingPlan": "Billing Plan",
  "topBar.profileMenu.role": "Role",
  "topBar.profileMenu.email": "Email",
  "topBar.profileMenu.phone": "Telp",
  "topBar.profileMenu.editProfile": "Ubah Profil",
  "topBar.profileMenu.logout": "Keluar",
  "topBar.billing.plan": "Paket",
  "topBar.billing.forever": "Selamanya",
  "topBar.billing.active_until": "Aktif s/d",
  "topBar.change_language": "Ubah Bahasa",
  "topBar.data_changes_timeline": "Timeline Perubahan Data",
  "topBar.latest_gajihub_update": "Update Gajihub Terbaru",

  "notification.a_new_version_is_available": "Versi terbaru tersedia!",
  "notification.please_wait_prepare_to_reload_page":
    "Silakan tunggu, sistem akan melakukan pemasangan update dalam {{countDown}} detik. {{errorAutoReloadMessage}}",
  "notification.autoreload_failed": "Pemasangan otomatis gagal.",
  "notification.reload_immediately": "Pasangkan langsung",
  "notification.login_expired": "Sesi login Anda sudah berakhir!",
  "notification.login_google_failure": "Login dengan google gagal!",
  "notification.gajihub_is_now_on_google_play":
    "Gajihub kini tersedia di Google Play",
  "notification.gajihub_is_now_on_app_store":
    "Gajihub kini tersedia di App Store",
  "notification.install_now": "Install Sekarang",
  "notification.later": "Nanti Saja",

  "tooltip.hidden": "Sembunyikan",

  "queue.data_is_waiting_to_be_processed":
    "{{value}} data sedang menunggu diproses...",

  "attachment.open_document": "Buka Dokumen",
  "attachment.open_gallery": "Buka Gallery",
  "attachment.take_photo": "Ambil Foto",
  "attachment.maximum_file_size": "Ukuran maksimal file {{value}} MB",
  "attachment.upload_failed": "Upload gagal",
  "attachment.delete_attachment": "Hapus lampiran",

  "validation.min": "Min {{value}}",
  "validation.max": "Maks {{value}}",

  "button.save": "Simpan",
  "button.save_add_new": "Simpan & Tambah Baru",
  "button.cancel": "Batal",
  "button.add": "Tambah",
  "button.duplicate": "Duplikat",
  "button.import": "Import",
  "button.export": "Ekspor",
  "button.print": "Print",
  "button.filter": "Filter",
  "button.clear_filter": "Hapus Filter",
  "button.delete": "Hapus",
  "button.reset": "Reset",
  "button.edit": "Ubah",
  "button.next": "Selanjutnya",
  "button.back": "Kembali",
  "button.approve": "Setujui",
  "button.reject": "Tolak",
  "button.yes": "Ya",
  "button.no": "Tidak",
  "button.download": "Download",
  "button.confirmation": "Konfirmasi",
  "button.activate": "Aktifkan",
  "button.non_activate": "Non Aktifkan",
  "button.detail": "Detil",
  "button.add_line": "Tambah Baris",
  "button.select_all": "Pilih Semua",
  "button.deselect_all": "Hapus Semua",
  "button.schedule_now": "Jadwalkan Sekarang",
  "button.hello_can_i_help": "Halo, ada yang bisa saya bantu?",
  "button.close": "Tutup",
  "button.slide_to_confirm": "Geser untuk konfirmasi",
  "button.confirmed": "Dikonfirmasi!",
  "button.click_here": "Klik di sini",
  "button.upgrade": "Upgrade",
  "button.renew": "Perpanjang",
  "button.copy_to_clipboard": "Salin Link ke Clipboard",
  "button.archive": "Arsip",
  "button.unarchive": "Batalkan Arsip",
  "button.copy_link": "Salin Link",
  "button.upload": "Upload",
  "button.send": "Kirim",
  "button.search": "Cari",

  "message.cannot_update_data": "Data tidak dapat diubah.",
  "message.cannot_return_data": "Data tidak dapat di kembalikan.",
  "message.link_has_been_copied": "Link berhasil disalin.",
  "message.on": "di",
  "message.no_data": "Tidak ada data {{value}}",

  "popconfirm.title.delete": "Apakah Anda yakin untuk menghapus?",
  "popconfirm.title.archive": "Apakah Anda yakin untuk mengarsipkan?",
  "popconfirm.title.unarchive": "Apakah Anda yakin untuk batalkan arsip?",
  "popconfirm.title.reset": "Apakah Anda yakin untuk mengatur ulang?",

  "input.search": "Cari",
  "input.other": "Lainnya",
  "input.all": "Semua",
  "input.select_year": "Pilih tahun",
  "input.select_month": "Pilih bulan",
  "input.cannot_be_empty": "Tidak boleh kosong",

  "date.today": "Hari Ini",
  "date.yesterday": "Kemarin",
  "date.week": "Minggu",
  "date.this_week": "Minggu Ini",
  "date.last_week": "Minggu Lalu",
  "date.this_month": "Bulan Ini",
  "date.last_month": "Bulan Lalu",
  "date.last_7_days": "7 Hari Lalu",
  "date.last_30_days": "30 Hari Lalu",
  "date.this_year": "Tahun Ini",
  "date.last_year": "Tahun Lalu",
  "date.daily": "Harian",
  "date.monthly": "Bulanan",
  "date.yearly": "Tahunan",
  "date.year": "Tahun",
  "date.month": "Bulan",
  "date.day": "Hari",
  "date.days": "Hari",
  "date.date": "Tanggal",
  "date.hour": "Jam",
  "date.hours": "Jam",
  "date.minute": "Menit",
  "date.minutes": "Menit",
  "date.time": "Waktu",
  "date.date_range": "Rentang Tanggal",

  "preview.image_preview": "Pratinjau Gambar",
  "preview.failed_to_load_image": "Gagal memuat gambar",

  "nps.how_likely_you_recommend":
    "Seberapa besar kemungkinan Anda akan merekomendasikan Gajihub kepada teman atau rekan kerja?",
  "nps.not_likely": "Sama sekali tidak mungkin",
  "nps.very_likely": "Sangat mungkin",
  "nps.please_mention_another":
    "Mohon sebutkan hal lain yang ingin Anda sampaikan melalui kotak komentar di bawah",
  "nps.cannot_hide_nps": "Tidak dapat menutup NPS",
  "nps.cannot_send_nps": "Tidak dapat mengirim NPS",
  "nps.thank_you_your_input":
    "Terima kasih, masukan Anda sangat berarti. Kami mempelajari setiap masukan yang masuk.",

  "users.remember_me": "Ingat saya",
  "users.forgot_password_": "lupa password?",
  "users.welcome_to2": "Selamat datang di ",
  "users.login": "Masuk",
  "users.login_with_google": "Sign in dengan Google",
  "users.login_failed": "Login Gagal",
  "users.please_log_in": "Silakan masuk",
  "users.you_have_logged_out_automatically":
    "Anda sudah terlogout secara otomatis, silakan login kembali untuk melanjutkan.",
  "users.email_is_required": "Email harus diisi",
  "users.email_is_not_a_valid_email": "Email bukan email yang valid",
  "users.email": "Email",
  "users.password": "Password",
  "users.please_input_your_password": "Silakan masukkan password Anda",
  "users.please_input_your_email_address": "Silakan masukkan alamat email Anda",
  "users.hello": "Halo",
  "users.please_select_company_to_proceed":
    "silakan pilih perusahaan untuk melanjutkan",
  "users.leave_page": "Pindah halaman?",
  "users.changes_that_you_made_may_not_be_saved":
    "Perubahan yang anda buat mungkin tidak disimpan.",
  "users.registration_completed": "Pendaftaran berhasil!",
  "users.password_has_been_sent_to_email_sms":
    "Password telah dikirim ke email dan sms mu, silakan cek ya",
  "users.users": "Pengguna",
  "users.name": "Nama Lengkap",
  "users.roles": "Peran",
  "users.search_by_name_or_email": "Cari berdasarkan nama atau email",
  "users.search_by_name": "Cari berdasarkan nama",
  "users.pending_invitation": "(Undangan tertunda)",
  "users.add_user": "Tambah pengguna",
  "users.delete_user": "Hapus pengguna",
  "users.edit_user": "Ubah Pengguna",
  "users.please_input_the_email_address": "Silakan masukkan alamat email.",
  "users.max_100_characters": "Maks 100 karakter.",
  "users.invalid_email_address_format": "Format alamat email tidak valid.",
  "users.please_select_a_role": "Silakan pilih peran.",
  "users.select_roles": "Pilih peran",
  "users.information": "Informasi",
  "users.information_invitation_email":
    "Email sudah dikirim ke {{email}}. Silakan minta kolega Anda untuk membuka email dan mengikuti instruksi berikutnya untuk mengaktifkan akun. Jika email tidak diterima, silakan cek folder bulk dan folder spam.",
  "users.only_displays_users_other_than_you":
    "Hanya menampilkan pengguna selain Anda",
  "users.please_select_a_user": "Silakan pilih pengguna.",
  "users.select_user": "Pilih pengguna",
  "users.select_user_to_delete": "Pilih Pengguna untuk Dihapus",
  "users.two_passwords_that_you_enter_is_inconsistent_":
    "Dua password yang Anda masukkan tidak konsisten.",
  "users.register_now": "Daftar sekarang",
  "users.restore_password": "Pulihkan Password",
  "users.enter_the_email_address_associated_with_your_account_and_well_email_you_a_link_to_reset_your_password":
    "Masukkan alamat email yang dikaitkan dengan akun Anda, dan kami akan mengirimi Anda email tautan untuk mereset password Anda ",
  "users.back_to_login": "Kembali untuk Login",
  "users.send_reset_link": "Kirim Reset Link",
  "users.please_check_your_email_inbox_and_follow_the_instruction_to_reset_your_password_if_email_hasnt_received_please_check_the_bulk_and_spam_folder":
    "Silakan periksa kotak masuk email Anda dan ikuti instruksi untuk mereset password Anda. Jika email belum diterima, silakan periksa folder bulk dan spam",
  "users.reset_password": "Setel Ulang Password",
  "users.request_new_password_reset": "Minta reset password lagi",
  "users.new_password": "password baru",
  "users.invalid_password_reset_token": "Token tidak valid",
  "users.your_password_reset_token_is_invalid_please_check_it_again_and_reload_the_page":
    "Token atur ulang password Anda tidak valid, periksa lagi dan muat ulang halaman",
  "users.confirm_new_password": "Konfirmasi password baru",
  "users.please_input_new_password": "Silakan masukkan Password Baru",
  "users.status": "Status",
  "users.add_as_employee": "Tambah Sebagai Karyawan",
  "users.employee": "Karyawan",
  "users.email_is_not_registered_with_gajihub":
    "Email tidak terdaftar di GajiHub.",
  "users.please_fill_the_register_form": "Silakan isi formulir pendaftaran",
  "users.invalid_invitation_token": "Token undangan tidak valid",
  "users.your_invitation_token_is_invalid_please_check_it_again_and_reload_the_page":
    "Silakan periksa lagi dan muat ulang halaman",
  "users.you_are_invited_to_join": "Anda diundang untuk bergabung di",
  "users.do_you_agree_to_join": "Apakah Anda bersedia bergabung di",
  "users.please_input_your_name": "Silakan masukkan name.",
  "users.phone_number": "Nomor telepon",
  "users.phone_number_is_required": "Nomor Telepon harus diisi",
  "users.no_phone_number_worng": "Nomor telepon salah",
  "users.phone_number_must_be_at_least_5_characters":
    "Nomor Telepon minimal 5 karakter",
  "users.phone_number_cannot_be_longer_than_20_characters":
    "Nomor Telepon tidak boleh lebih dari 20 karakter",
  "users.please_input_your_phone": "Silakan masukkan no ponsel Anda",
  "users.activate": "Mengaktifkan",
  "users.waiting_for_confirmation": "Menunggu Konfirmasi",
  "users.resending_help":
    "Untuk mengkonfirmasi, silakan buka inbox email, dan klik link yang dikirim oleh Gajihub.",
  "users.resending": "Kirim Ulang",
  "users.success_resending": "Sukses Mengirim Undangan",
  "users.success_resending_desc":
    "Undangan berhasil dikirim ulang ke email {{email}}. Silakan buka inbox email Anda, dan klik link yang telah dikirm oleh Gajihub.",
  "users.you_have_registered_on_gajiHub_with_your_username":
    "Kamu sudah terdaftar di GajiHub dengan username",
  "users.click_the_button_below_to_log_in_to_gajiHub":
    "Klik tombol dibawah untuk login ke GajiHub",
  "users.info_is_only_has_role_employee":
    "Anda login sebagai karyawan.<br/> Untuk melanjutkan menggunakan aplikasi gajihub silakan pilih opsi berikut:",
  "users.login_to_the_ess_application": "Login di Aplikasi ESS",

  "companies.company_list": "Daftar Perusahaan",
  "companies.add_company": "Tambah Perusahaan",
  "companies.created_on": "Dibuat",
  "companies.as": "Sebagai",
  "companies.are_you_sure_to_delete_this_company":
    "Apakah Anda yakin untuk menghapus perusahaan ini?<br />Setelah dihapus, data tidak dapat dikembalikan",
  "companies.choose_package": "Pilih Paket",
  "companies.full_name": "Nama Lengkap",
  "companies.please_input_full_name": "Silakan masukkan Nama Lengkap",
  "companies.company_name": "Nama Perusahaan",
  "companies.please_input_company_name": "Silakan masukkan Nama Perusahaan",
  "companies.role_on_company": "Jabatan di Perusahaan Sebagai",
  "companies.please_input_role_on_company":
    "Silakan masukkan Jabatan di Perusahaan",
  "companies.number_of_employee": "Jumlah Karyawan",
  "companies.please_input_number_of_employee":
    "Silakan masukkan Jumlah Karyawan",
  "companies.please_input_email": "Silakan masukkan email",
  "companies.please_input_phone_number": "Silakan masukkan nomor telepon",
  "companies.phone_number_must_08": "Nomor telepon harus berawalan 08",
  "companies.next_per_month": "Selanjutnya {price}/bln",
  "companies.free_forever": "Gratis Selamanya",
  "companies.max_characters": "Maks {{value}} karakter.",
  "companies.min_characters": "Min {{value}} karakter.",
  "companies.select_company": "Pilih Perusahaan",

  "employee.title": "Karyawan",
  "employee.add_employee": "Tambah Karyawan",
  "employee.add_submission": "Tambah Pengajuan",
  "employee.import_add_employee": "Import Tambah Karyawan",
  "employee.import_update_employee": "Import Update Karyawan",
  "employee.date": "Tanggal",
  "employee.name": "Nama",
  "employee.organization": "Organisasi",
  "employee.job_title": "Job Title",
  "employee.job_level": "Pangkat",
  "employee.status": "Status",
  "employee.email": "Email",
  "employee.telp": "Telp",
  "employee.personal": "Personal",
  "employee.staffing": "Kepegawaian",
  "employee.attendance": "Kehadiran",
  "employee.attendance_history": "Histori Kehadiran",
  "employee.calendar": "Kelender",
  "employee.permission_and_leave": "Izin & Cuti",
  "employee.overtime": "Lembur",
  "employee.business_trip": "Perjalanan Bisnis",
  "employee.career": "Karir",
  "employee.payroll": "Payroll",
  "employee.finance": "Keuangan",
  "employee.loan": "Pinjaman",
  "employee.reimburse": "Reimburse",
  "employee.cash_receipt": "Kasbon",
  "employee.inventory": "Inventaris",
  "employee.all": "Semua",
  "employee.approved": "Disetujui",
  "employee.waiting_for_approval": "Menunggu Persetujuan",
  "employee.rejected": "Ditolak",
  "employee.submission_date": "Tanggal Pengajuan",
  "employee.number_of_days": "Jumlah Hari",
  "employee.leave_date": "Tanggal Cuti",
  "employee.approval": "Persetujuan",
  "employee.leave_type": "Tipe Cuti",
  "employee.available_leave": "Sisa Cuti Tersedia",
  "employee.leave_taken": "Cuti Diambil",
  "employee.mass_leave": "Cuti Bersama",
  "employee.remaining_leave_this_periode": "Sisa Cuti Periode Ini",
  "employee.total_leave_days_this_month": "Total Hari Cuti Bulan Ini",
  "employee.total_leave_days_this_year": "Total Hari Cuti Tahun Ini",
  "employee.total_sick_days_this_month": "Total Hari Sakit Bulan Ini",
  "employee.total_sick_days_this_year": "Total Hari Sakit Tahun Ini",
  "employee.total_permit_days_this_month": "Total Hari Izin Bulan Ini",
  "employee.total_permit_days_this_year": "Total Hari Izin Tahun Ini",
  "employee.total_number_of_days_this_month": "Total Jumlah Hari Bulan Ini",
  "employee.total_number_of_days_this_year": "Total Jumlah Hari Tahun Ini",
  "employee.sick_leave_date": "Tanggal Izin Sakit",
  "employee.permission_date": "Tanggal Izin",
  "employee.annual_leave": "Cuti Tahunan",
  "employee.special_leave": "Cuti Khusus",
  "employee.sick": "Sakit",
  "employee.permission": "Izin",
  "employee.unpaid_leave": "Unpaid Leave",
  "employee.drag_file_to_upload_photo": "Drag file untuk mengupload foto",
  "employee.file_type": "Tipe File",
  "employee.employee_id": "ID Karyawan",
  "employee.full_name": "Nama Lengkap",
  "employee.date_of_birth": "Tanggal Lahir",
  "employee.place_of_birth": "Tempat Lahir",
  "employee.gender": "Jenis Kelamin",
  "employee.male": "Laki - laki",
  "employee.female": "Perempuan",
  "employee.maritial_status": "Status Perkawinan",
  "employee.married": "Menikah",
  "employee.not_married": "Belum Menikah",
  "employee.blood_type": "Golongan Darah",
  "employee.religion": "Agama",
  "employee.nationality": "Kewarganegaraan",
  "employee.personal_information": "Informasi Pribadi",
  "employee.contact_information": "Informasi Kontak",
  "employee.contact": "Kontak",
  "employee.no_hp": "No. HP",
  "employee.no_phone": "No. Telepon",
  "employee.address": "Alamat",
  "employee.identity_card_address": "Alamat Kartu Identitas",
  "employee.residence_address": "Alamat Domisili",
  "employee.residence_country": "Negara Domisili",
  "employee.residence_province": "Provinsi Domisili",
  "employee.residence_city": "Kota Domisili",
  "employee.emergency_contact_name": "Nama Kontak Darurat",
  "employee.emergency_contact": "Kontak Darurat",
  "employee.emergency_phone": "Telepon Darurat",
  "employee.last_education": "Pendidikan Terakhir",
  "employee.last_education_level": "Jenjang Pendidikan Terakhir",
  "employee.educational_institution_name": "Nama Institusi Pendidikan",
  "employee.study_program": "Program Studi",
  "employee.elementary_school": "SD",
  "employee.junior_high_school": "SMP",
  "employee.senior_high_school": "SMA",
  "employee.bachelor_degree": "S1",
  "employee.attachment": "Lampiran",
  "employee.employee_status": "Status Karyawan",
  "employee.join_date": "Tanggal Bergabung",
  "employee.resign_date": "Tanggal Resign",
  "employee.job_position": "Jabatan",
  "employee.schedule": "Jadwal",
  "employee.select_schedule": "Pilih Jadwal",
  "employee.salary": "Gaji",
  "employee.base_salary": "Gaji Dasar",
  "employee.effective_date": "Tanggal Efektif",
  "employee.account_number": "Nomor Rekening",
  "employee.bank": "Bank",
  "employee.branch": "Cabang",
  "employee.owner_name": "Nama Pemilik",
  "employee.NPWP": "NPWP",
  "employee.NPWP_number": "Nomor NPWP",
  "employee.BPJS": "BPJS",
  "employee.No_KPJ_BPJS_Ketenagakerjaan": "No KPJ BPJS Ketenagakerjaan",
  "employee.No_BPJS_Ketenagakerjaan": "No BPJS Ketenagakerjaan",
  "employee.No_BPJS_Kesehatan": "No BPJS Kesehatan",
  "employee.add_annual_leave_application": "Tambah Pengajuan Cuti Tahunan",
  "employee.add_special_leave_application": "Tambah Pengajuan Cuti Khusus",
  "employee.add_sick_leave_application": "Tambah Pengajuan Izin Sakit",
  "employee.add_permission_application": "Tambah Pengajuan Izin",
  "employee.add_unpaid_leave_application": "Tambah Pengajuan Unpaid Leave",
  "employee.edit_annual_leave_application": "Ubah Pengajuan Cuti Tahunan",
  "employee.edit_special_leave_application": "Ubah Pengajuan Cuti Khusus",
  "employee.edit_sick_leave_application": "Ubah Pengajuan Izin Sakit",
  "employee.edit_permission_application": "Ubah Pengajuan Izin",
  "employee.edit_unpaid_leave_application": "Ubah Pengajuan Unpaid Leave",
  "employee.employee_name": "Nama Karyawan",
  "employee.select_employee": "Pilih Karyawan",
  "employee.days": "Hari",
  "employee.day": "Hari",
  "employee.max": "Maks",
  "employee.full_day_leave_date": "Tanggal Cuti Seharian Penuh",
  "employee.half_day_leave_date": "Tanggal Cuti Setengah Hari",
  "employee.half_day_permission_date": "Tanggal Izin Setengah Hari",
  "employee.description": "Keterangan",
  "employee.leave_description": "Keterangan Cuti",
  "employee.approved_or_rejected_description":
    "Keterangan Persetujuan / Penolakan",
  "employee.type_of_leave": "Jenis Cuti",
  "employee.identity": "Identitas",
  "employee.place_date_of_birth": "Tempat, Tgl Lahir",
  "employee.taxpayer_status": "Status Wajib Pajak",
  "employee.bank_account": "Rekening Bank",
  "employee.are_you_sure_to_delete_employee":
    "Apakah anda yakin untuk menghapus Karyawan?",
  "employee.country": "Negara",
  "employee.province": "Provinsi",
  "employee.city": "Kota",
  "employee.identity_card_types": "Tipe Kartu Identitas",
  "employee.id_identity_card": "ID Kartu Identitas",
  "employee.please_select": "Silakan pilih",
  "employee.please_input": "Silakan masukkan",
  "employee.email_not_valid": "Email tidak valid",
  "employee.please_add_bank_account": "Silakan menambahkan Nomor Rekening",
  "employee.citizen": "Warga",
  "employee.working_period": "Masa Kerja",
  "employee.edit_personal_identity": "Ubah Informasi Pribadi",
  "employee.edit_personal_contact": "Ubah Informasi Kontak",
  "employee.edit_personal_education": "Ubah Pendidikan Terakhir",
  "employee.edit_attachment": "Ubah Lampiran",
  "employee.edit_working_period": "Ubah Masa Kerja",
  "employee.edit_npwp": "Ubah NPWP",
  "employee.edit_bpjs": "Ubah BPJS",
  "employee.edit_bank_account": "Ubah Akun Bank",
  "employee.end_after": "Berakhir Setelah",
  "employee.select_duration": "Pilih Durasi",
  "employee.npwp_withholder": "NPWP Pemotong",
  "employee.reason_for_status_change_npwp_status":
    "Alasan Perubahan Status NPWP",
  "employee.information_detail_employee_change_status_npwp":
    "Status NPWP pajak personalia ini telah diganti oleh {{name}} pada tanggal {{date}}, menjadi {{status}} dan akan berlaku di periode selanjutnya {{year}}",
  "employee.not_active": "Tidak Aktif",
  "employee.active": "Aktif",
  "employee.terminated_date": "Tanggal Berhenti Kerja",
  "employee.terminated_reason": "Alasan Berhenti Kerja",
  "employee.login_ess": "Login ESS",
  "employee.last_activity": "Aktivitas Terakhir",
  "employee.havent_used_ess_yet": "Belum menggunakan ESS",
  "employee.last_logged_in": "Terakhir login pada",
  "employee.user_name": "Nama User",
  "employee.login_email": "Email Login",
  "employee.role": "Peran",
  "employee.havent_started_working_yet": "Belum mulai bekerja",
  "employee.the_emergency_telephone_number_cannot_be_the_same_as_the_main_telephone_number":
    "Telepon darurat tidak boleh sama dengan no telepon utama.",
  "employee.enter_the_15_digit_npwp": "Masukkan 15 digit NPWP",
  "employee.enter_the_15_or_16_digit_npwp": "Masukkan 15 atau 16 digit NPWP",
  "employee.enter_your_nik_npwp": "Masukkan NIK/NPWP Anda",
  "employee.attendance_daily": "Harian",
  "employee.title_attendance_daily": "Kehadiran Harian",
  "employee.sorry_employee_information_details_not_found":
    "Maaf, Detil Informasi Karyawan Tidak Ditemukan.",
  "employee.back_to_list_employee": "Kembali ke daftar karyawan",
  "employee.only_number_allowed": "Hanya angka yang diizinkan",
  "employee.import_employee": "Import Karyawan",
  "employee.upload_file": "Upload File",
  "employee.check_data": "Cek Data",
  "employee.import_in_progress": "Import Sedang di Proses",
  "employee.import_done": "Import Berhasil",
  "employee.follow_these_steps_to_import_employee":
    "Ikuti langkah berikut untuk mengimport karyawan",
  "employee.follow_these_steps_to_import_update_employee":
    "Ikuti langkah berikut untuk mengimport update karyawan",
  "employee.1_download_file_template_employee":
    "1. Download file template karyawan",
  "employee.start_by_downloading_file_template_employee":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari karyawan.",
  "employee.download_file_template": "Download File Template",
  "employee.download_file_example": "Download File Contoh",
  "employee.2_copy_employee_data_into_the_template":
    "2. Salin data karyawan ke dalam template",
  "employee.how_to_custom_file_template_employee":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data karyawan ke dalam file template dari Gajihub. Pastikan data karyawan sesuai dengan kolom header yang ada di template.",
  "employee.important": "Penting",
  "employee.important_import_info":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data karyawan dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.<br/>",
  "employee.3_upload_the_updated_template_here":
    "3. Upload template yang sudah di update ke sini",
  "employee.save_the_updated_template_file":
    "Simpan file template yang sudah di update",
  "employee.drop_the_file_to_upload": "Drag file di sini untuk upload",
  "employee.please_check_the_imported_data": "Silakan cek data yang di import",
  "employee.how_to_action_import_data":
    'Jika data yang di impor sesuai dengan yang kamu harapkan, silakan klik tombol "Import Sekarang".',
  "employee.valid": "Valid",
  "employee.invalid": "Invalid",
  "employee.total": "Total",
  "employee.country_name": "Nama Negara",
  "employee.mobile_number": "Nomor HP",
  "employee.telephone_number": "Nomor Telepon",
  "employee.work_placement": "Penempatan Kerja",
  "employee.npwp_status": "Status NPWP",
  "employee.npwp_number": "Nomor NPWP",
  "employee.import_now": "Import Sekarang",
  "employee.import_employee_is_still_in_progress": "Import masih dalam proses",
  "employee.its_ok_to_close_this_page": `Tidak apa-apa untuk menutup halaman ini atau mengimport file lain. Saat import selesai, Anda akan diberi tahu melalui email`,
  "employee.row_imported": "{{imported}} dari {{total}} baris diproses",
  "employee.import_another_file": "Import File Lain",
  "employee.go_to_the_employee_list_page": "Ke halaman daftar karyawan",
  "employee.employee_successfully_imported":
    "{{rowCount}} karyawan berhasil di import.",
  "employee.show_detail": "Tampilkan Detil",
  "employee.hide_detail": "Sembunyikan Detil",
  "employee.date_started_work_information":
    "Maksimal tanggal yang bisa dipilih adalah tanggal berdasarkan <span>tanggal aktif dari karir pertama</span>. Jika belum memiliki karir maka tanggal yang bisa dipilih adalah tanggal kapanpun",
  "employee.employee_termination_reminder": "Pengingat Masa Akhir Karyawan",
  "employee.type_reminder_duration_employee_termination":
    "Tipe durasi pengingat masa akhir karyawan",
  "employee.reminder_duration_employee_termination":
    "Durasi pengingat masa akhir karyawan",
  "employee.reminder_duration_employee_termination_info":
    "Gajihub akan secara otomatis mengirimkan pengingat kepada admin saat masa kontrak salah satu karyawan akan segera berakhir.",
  "embloyee.how_long_before_the_contract_expires_a_reminder_email_is_sent":
    "Berapa lama sebelum kontrak berakhir email pengingat dikirim?",
  "employee.end_of_employment_period": "Masa Akhir Kerja",
  "employee.reset_and_send_password": "Reset & Kirim Password",
  "employee.success_reset_password": "Sukses Reset Password",
  "employee.success_reset_password_desc":
    "Reset password berhasil dikirim ke whatsapp dan email karyawan. Silakan buka inbox whatsapp dan email Anda.",
  "employee.residence_address_is_the_same_as_the_identity_card_address":
    "Alamat domisili sama dengan alamat kartu identitas",
  "employee.is_the_data_to_be_deleted_confirmed":
    "Apakah data yang akan dihapus sudah dipastikan?",
  "employee.deleted_employee_data_cannot_be_restored_continue":
    "Data karyawan yang terhapus <span>tidak dapat dikembalikan</span>, lanjutkan?",
  "employee.rehire": "Rekrut Kembali",
  "employee.rehire_employee": "Rekrut Kembali Karyawan",
  "employee.employee_has_been_rehired": "Karyawan telah direkrut kembali",
  "employee.export_employee_detail": "Ekspor Detil Karyawan",
  "employee.select": "Pilih {{value}}",
  "employee.export_employee": "Ekspor Karyawan",
  "employee.end_of_employment": "Akhir Kerja",
  "employee.id_identity": "ID Identitas",
  "employee.bpjs_of_employement": "BPJS Ketenagakerjaan",
  "employee.bpjs_healthcare": "BPJS Kesehatan",
  "employee.working_status": "Status Kerja",

  "leave.leave_and_permission": "Izin & Cuti",
  "leave.see_remaining_leave": "Lihat Sisa Cuti",
  "leave.approval_progress": "Progress Persetujuan",
  "leave.annual_leave_application": "Pengajuan Cuti Tahunan",
  "leave.special_leave_application": "Pengajuan Cuti Khusus",
  "leave.sick_leave_application": "Pengajuan Izin Sakit",
  "leave.permission_application": "Pengajuan Izin",
  "leave.unpaid_leave_application": "Pengajuan Unpaid Leave",
  "leave.view_submissions": "Lihat Pengajuan",
  "leave.leave_allowance": "Jatah Cuti",
  "leave.leave_taken": "Cuti Diambil",
  "leave.carry_forward": "Carry Forward",
  "leave.carry_forward_taken": "Carry Forward Diambil",
  "leave.total_taken": "Total Diambil",
  "leave.remaining_leave": "Sisa Cuti",
  "leave.annual_leave": "Cuti Tahunan",
  "leave.special_leave": "Cuti Khusus",
  "leave.date_of_entry": "Tanggal Masuk",
  "leave.same": "Sama",
  "leave.at_once": "Sekaligus",
  "leave.per_month": "PerBulan",
  "leave.base_salary": "Gaji Pokok",
  "leave.base_and_allowances": "Pokok + Tunjangan",
  "leave.does_the_leave_period_match_the_employee_date_of_entry_or_is_it_the_same_for_all_employees":
    "Apakah periode cuti sesuai tanggal masuk karyawan atau sama untuk semua karyawan?",
  "leave.what_date_does_the_leave_period_reset_every":
    "Periode cuti di-reset setiap tanggal berapa?",
  "leave.how_many_months_after_work_does_the_employee_start_to_get_time_off":
    "Berapa bulan setelah bekerja karyawan mulai mendapatkan cuti?",
  "leave.how_many_days_of_leave_do_employees_get_each_year":
    "Berapa jumlah hari cuti yang didapat karyawan setiap tahunnya?",
  "leave.do_employees_get_paid_leave_at_once_at_the_beginning_or_proportionally_every_month":
    "Cuti didapat karyawan langsung sekaligus diawal atau proporsional tiap bulan?",
  "leave.can_the_remaining_leave_be_used_for_the_following_year":
    "Apakah sisa cuti bisa digunakan untuk tahun berikutnya?",
  "leave.can_the_remaining_leave_of_the_previous_year_be_cashed":
    "Apakah sisa cuti tahun sebelumnya bisa diuangkan?",
  "leave.the_rest_of_the_paid_leave_is_calculated_based_on_basic_salary_or_along_with_allowances":
    "Sisa cuti yang diuangkan dihitung berdasar gaji pokok atau beserta tunjangan?",
  "leave.does_joint_leave_cut_the_leave_ration":
    "Apakah cuti bersama memotong jatah cuti?",
  "leave.type_of_special_leave": "Jenis Cuti Khusus",
  "leave.please_input_special_leave_name": "Silakan masukkan nama cuti khusus.",
  "leave.max_day": "Maks Hari",
  "leave.please_input_max_day_leave": "Silakan masukkan maksimal hari cuti.",
  "leave.max_day_or_submission": "Maks Hari / Pengajuan",
  "leave.add_special_leave": "Tambah Cuti Khusus",
  "leave.edit_special_leave": "Ubah Cuti Khusus",
  "leave.delete_special_leave": "Hapus Cuti Khusus",
  "leave.select_special_leave": "Pilih Cuti Khusus",
  "leave.are_you_sure_to_delete_special_leave":
    "Apakah anda yakin untuk menghapus cuti khusus?",
  "leave.approval_rule": "Aturan Approval",
  "leave.approval": "Persetujuan",
  "leave.please_select_approval_rule": "Silakan pilih aturan approval.",
  "leave.please_select_employee": "Silakan pilih karyawan.",
  "leave.please_select_leave_type": "Silakan pilih jenis cuti.",
  "leave.please_input_date_request": "Silakan pilih tanggal pengajuan.",
  "leave.please_input_date_leaves": "Silakan pilih tanggal cuti.",
  "leave.please_select_approval": "Silakan pilih persetujuan.",
  "leave.are_you_sure_to_delete_leave":
    "Apakah anda yakin untuk menghapus <span>{{leave_type}}</span>?",
  "leave.unable_to_apply_for_annual_leave":
    "Tidak dapat mengajukan cuti tahunan",
  "leave.select_date": "Pilih tanggal",
  "leave.minimal_value": "Minimal {{value}}",
  "leave.archive": "Arsip",
  "leave.cancel_archive": "Batalkan Arsip",
  "leave.are_you_sure_to_cancel_archive":
    "Apakah anda yakin untuk membatalkan arsip?",
  "leave.remaining_annual_leave": "Sisa Cuti Tahunan",
  "leave.view_leave_history": "Lihat histori cuti",
  "leave.history_of_remaining_leave": "Histori Sisa Cuti",
  "leave.number_of_days": "Jumlah Hari",
  "leave.remaining_leave_for_this_period": "Sisa Cuti Periode Ini",
  "leave.remaining_leave_last_period": "Sisa Cuti Periode Lalu",
  "leave.description": "Keterangan",
  "leave.cancel_approval": "Batalkan Persetujuan",
  "leave.cancel_rejection": "Batalkan Penolakan",
  "leave.are_you_sure_to_cancel_approval":
    "Apakah anda yakin untuk membatalkan persetujuan?",
  "leave.are_you_sure_to_cancel_rejection":
    "Apakah anda yakin untuk membatalkan penolakan?",
  "leave.remaining_leave_this_period": "Sisa Cuti Periode Ini",
  "leave.this_period_leave_taken": "Cuti Periode Ini Diambil",
  "leave.last_period_leave_taken": "Cuti Periode Lalu Diambil",
  "leave.total_leave_remaining": "Total Sisa Cuti",
  "leave.total_leave_taken": "Total Cuti Diambil",
  "leave.leave_remaining": "Sisa Cuti",
  "leave.period": "Periode",
  "leave.total_sick_days_this_month": "Total hari sakit bulan ini",
  "leave.total_sick_days_this_year": "Total hari sakit tahun ini",
  "leave.total_permission_days_this_month": "Total hari izin bulan ini",
  "leave.total_permission_days_this_year": "Total hari izin tahun ini",
  "leave.total_unpaid_leave_days_this_month":
    "Total hari unpaid leave bulan ini",
  "leave.total_unpaid_leave_days_this_year":
    "Total hari unpaid leave tahun ini",
  "leave.manage_leave_policy": "Atur Kebijakan Cuti",
  "leave.manage_special_leave_policy": "Atur Kebijakan Cuti Khusus",
  "leave.does_joint_leave_count_as_debt_for_employees_who_do_not_have_leave_allowance":
    "Apakah cuti bersama terhitung sebagai hutang untuk karyawan yang belum memiliki jatah cuti?",
  "leave.leave_and_permission_type": "Jenis Izin & Cuti",
  "leave.does_the_remaining_annual_leave_for_the_past_period_have_a_limit":
    "Apakah sisa cuti tahunan periode lalu memiliki batas jumlah?",
  "leave.what_is_the_maximum_remaining_annual_leave_from_the_previous_period_that_can_be_carried_over_to_the_next_period":
    "Berapa maksimal sisa cuti tahunan periode lalu yang dapat dibawa ke periode selanjutnya?",
  "leave.does_the_remaining_annual_leave_from_the_past_period_have_a_expiration_date":
    "Apakah sisa cuti tahunan periode lalu memiliki batas waktu hangus nya?",
  "leave.how_many_months": "Berapa bulan?",
  "leave.does_annual_leave_is_calculated_as_attendance_on_the_attendance_based_component":
    "Apakah cuti tahunan dihitung hadir pada komponen berdasar kehadiran?",
  "leave.the_maximum_number_of_leave_days_that_can_be_cashed_out":
    "Jumlah maksimal hari cuti bisa diuangkan",
  "leave.number_of_working_days_per_month": "Jumlah hari kerja per bulan",
  "leave.cashable_leave_is_taxable": "Cuti diuangkan apakah kena pajak?",
  "leave.tax_category": "Kategori Pajak",
  "leave.import_remaining_annual_leave": "Import Sisa Cuti Tahunan",
  "leave.follow_these_steps_to_import_remaining_annual_leave":
    "Ikuti langkah berikut untuk mengimport sisa cuti tahunan",
  "leave.1_download_file_template_remaining_annual_leave":
    "1. Download file template sisa cuti tahunan",
  "leave.start_by_downloading_file_template_remaining_annual_leave":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari sisa cuti tahunan.",
  "leave.2_copy_remaining_annual_leave_data_into_the_template":
    "2. Salin data sisa cuti tahunan ke dalam template",
  "leave.how_to_custom_file_template_remaining_annual_leave":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data sisa cuti tahunan ke dalam file template dari Gajihub. Pastikan data sisa cuti tahunan sesuai dengan kolom header yang ada di template.",
  "leave.important_import_info_remaining_annual_leave":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data sisa cuti tahunan dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.<br/>",
  "leave.remaining_annual_leave_successfully_imported":
    "{{rowCount}} sisa cuti tahunan berhasil di import.",
  "leave.go_to_the_remaining_annual_leave_list_page":
    "Ke halaman daftar sisa cuti tahunan",
  "leave.sick_leave": "Cuti Sakit",
  "leave.are_employees_required_to_attach_files_when_applying_for_sick_leave":
    "Apakah karyawan dalam pengajuan sakitnya diwajibkan untuk melampirkan file?",
  "leave.is_the_number_of_applications_limited_to_employees_who_apply_for_sick_leave":
    "Apakah karyawan dalam pengajuan sakitnya dibatasi jumlah pengajuannya?",
  "leave.what_is_the_maximum_number_of_sick_leave_applications_permitted_each_month":
    "Berapa jumlah maksimal pengajuan sakit yang diijinkan setiap bulannya?",
  "leave.export_based_on_filters": "Ekspor Berdasarkan Filter",
  "leave.export_all_leave_and_permissions": "Ekspor Semua Izin & Cuti",
  "leave.how_many_days_before_the_first_leave_date_can_an_employee_apply_for_leave_from_the_ess_application":
    "Berapa maksimal hari sebelum tanggal cuti pertama karyawan dapat mengajukan cuti dari aplikasi ess?",
  "leave.can_apply_for_half_day_annual_leaves":
    "Apakah dapat mengajukan cuti tahunan setengah hari?",
  "leave.does_annual_leave_have_a_maximum_number_of_consecutive_days_when_submitting_an_application":
    "Apakah cuti tahunan memiliki jumlah maksimal hari berturut-turut saat melakukan pengajuan?",
  "leave.what_is_the_maximum_number_of_consecutive_leaves_when_applying":
    "Berapa jumlah maksimal cuti berturut-turut saat melakukan pengajuan?",
  "leave.how_many_days_before_the_first_leave_date_can_an_employee_apply_for_full_day_leave_from_the_ess_application":
    "Berapa maksimal hari sebelum tanggal cuti pertama karyawan dapat mengajukan cuti sehari penuh dari aplikasi ess?",
  "leave.how_many_days_before_the_first_leave_date_can_an_employee_apply_for_half_day_leave_from_the_ess_application":
    "Berapa maksimal hari sebelum tanggal cuti pertama karyawan dapat mengajukan cuti setengah hari dari aplikasi ess?",
  "leave.do_you_want_to_activate_the_automatic_addition_of_annual_leave_per_month":
    "Apakah ingin mengaktifkan penambahan jatah cuti tahunan otomatis per bulan?",
  "leave.on_what_date_is_the_monthly_annual_leave_allowance_added":
    "Setiap tanggal berapa jatah cuti tahunan bulanan ditambahkan?",
  "leave.how_much_additional_monthly_annual_leave_is_added":
    "Berapa jatah cuti tahunan bulanan yang ditambahkan?",
  "leave.on_date": "Pada tanggal",
  "leave.total_remaining_annual_leave_deducted_by_the_date_of_joint_leave":
    "Total Sisa Cuti Tahunan Yang Dipotong Oleh Tanggal Cuti Bersama",
  "leave.if_joint_leave_deducted_into_annual_leave_is_the_calculation_also_a_limitation_in_the_submission":
    "Jika cuti bersama memotong cuti tahunan, apakah kalkulasinya juga menjadi batasan dalam pengajuan?",
  "leave.summary_info_total_remaining_leave":
    "Informasi total cuti tahunan :<br/><ul><li>Total sisa cuti tahunan = {{annual_leave_current_balance}}</li><li>Total cuti bersama yang akan datang = {{next_mass_leave_count}}</li><li>Total sisa cuti tahunan jika dipotong cuti bersama = {{annual_leave_balance}}</li></ul>",
  "leave.summary_info_total_remaining_leave_with_debt":
    "Informasi total cuti tahunan :<br/><ul><li>Total sisa cuti tahunan = {{annual_leave_current_balance}}</li><li>Total sisa hutang cuti tahunan = {{annual_leave_total_current_debt_balance}}</li><li>Total cuti bersama yang akan datang = {{next_mass_leave_count}}</li><li>Total sisa cuti tahunan jika dipotong cuti bersama = {{annual_leave_balance}}</li></ul>",
  "leave.does_your_company_implement_an_annual_leave_debt_system":
    "Apakah perusahaan Anda menerapkan sistem hutang cuti tahunan?",
  "leave.maximum_number_of_days_of_annual_leave_debt":
    "Jumlah maksimal hari hutang cuti tahunan",
  "leave.implement_an_annual_leave_debt_system":
    "Menerapkan Sistem Hutang Cuti Tahunan",
  "leave.total_remaining_annual_leave_debt": "Total sisa hutang cuti tahunan",

  "attendance.attendance_data": "Data Presensi",
  "attendance.daily_attendance_data": "Data Presensi Harian",
  "attendance.daily_attendance": "Presensi Harian",
  "attendance.summary_attendance_data": "Ringkasan Data Presensi",
  "attendance.edit_attendance_employee": "Ubah Presensi Karyawan",
  "attendance.daily": "Harian",
  "attendance.summary": "Ringkasan",
  "attendance.shift": "Shift",
  "attendance.status": "Status",
  "attendance.in": "Masuk",
  "attendance.out": "Keluar",
  "attendance.out_next_day": "Keluar Hari Berikutnya",
  "attendance.overtime_duration": "Durasi Lembur",
  "attendance.break_duration": "Lama Istirahat",
  "attendance.detail_attendance": "Detil Kehadiran",
  "attendance.late": "Terlambat",
  "attendance.note": "Catatan",
  "attendance.list_employee": "Daftar Karyawan",
  "attendance.number_of_days": "Jumlah Hari",
  "attendance.weekdays": "Hari Kerja",
  "attendance.attend_weekdays": "Hadir Hari Kerja",
  "attendance.attend_not_weekdays": "Hadir Bukan Hari Kerja",
  "attendance.working_hours": "Jam Kerja",
  "attendance.overtime_hours": "Jam Lembur",
  "attendance.annual_leave": "Cuti Tahunan",
  "attendance.special_leave": "Cuti Khusus",
  "attendance.sick": "Sakit",
  "attendance.please_select_shift": "Silakan pilih shift",
  "attendance.please_select_status": "Silakan pilih status",
  "attendance.please_select_overtime_duration": "Silakan pilih lama lembur",
  "attendance.select_shift": "Pilih shift",
  "attendance.select_status": "Pilih status",
  "attendance.select_overtime_duration": "Pilih lama lembur",
  "attendance.please_input": "Silakan masukkan",
  "attendance.presence_app_validation": "Validasi Aplikasi Presensi",
  "attendance.add_setting": "Tambah Pengaturan",
  "attendance.please_select": "Silakan pilih",
  "attendance.rule_name": "Nama Aturan",
  "attendance.applies_to": "Berlaku Untuk",
  "attendance.please_add": "Silakan tambahkan",
  "attendance.setting_default": "Pengaturan Default",
  "attendance.presence": "Presensi",
  "attendance.overtime": "Lembur",
  "attendance.add_overtime": "Tambah Lembur",
  "attendance.client_visit": "Kunjungan Klien",
  "attendance.is_approval_always_required_at_the_time_of_attendance":
    "Apakah  selalu diperlukan approval saat presensi?",
  "attendance.do_employees_have_to_be_present_from_within_the_specified_gps_radius":
    "Apakah karyawan harus presensi dari dalam radius GPS yang ditentukan?",
  "attendance.is_approval_required_for_presence_outside_the_gps_radius":
    "Apakah diperlukan approval untuk presensi di luar radius GPS?",
  "attendance.do_employees_have_to_be_present_from_the_specified_ip_address":
    "Apakah karyawan harus presensi dari alamat IP yang ditentukan?",
  "attendance.is_approval_required_for_presence_outside_the_specified_ip":
    "Apakah diperlukan approval untuk presensi di luar IP yang ditentukan?",
  "attendance.do_employees_have_to_take_selfies_during_attendance":
    "Apakah karyawan harus swafoto saat presensi?",
  "attendance.are_employees_able_to_input_attendance_time_manually":
    "Apakah karyawan dapat input waktu presensi secara manual?",
  "attendance.is_approval_required_if_the_time_is_entered_manually":
    "Apakah diperlukan approval jika waktu diisi manual?",
  "attendance.is_approval_always_required_at_the_time_of_overtime":
    "Apakah  selalu diperlukan approval saat lembur?",
  "attendance.do_employees_have_to_be_overtime_from_within_the_specified_gps_radius":
    "Apakah karyawan harus lembur dari dalam radius GPS yang ditentukan?",
  "attendance.is_approval_required_for_overtime_outside_the_gps_radius":
    "Apakah diperlukan approval untuk lembur di luar radius GPS?",
  "attendance.do_employees_have_to_be_overtime_from_the_specified_ip_address":
    "Apakah karyawan harus lembur dari alamat IP yang ditentukan?",
  "attendance.is_approval_required_for_overtime_outside_the_specified_ip":
    "Apakah diperlukan approval untuk lembur di luar IP yang ditentukan?",
  "attendance.do_employees_have_to_take_selfies_during_overtime":
    "Apakah karyawan harus swafoto saat lembur?",
  "attendance.are_employees_able_to_input_overtime_time_manually":
    "Apakah karyawan dapat input waktu lembur secara manual?",
  "attendance.do_employees_have_to_take_selfies_during_client_visit":
    "Apakah karyawan harus swafoto saat kunjungan klien?",
  "attendance.are_employees_only_able_to_presence_clock_out_when_working_hours_end":
    "Apakah karyawan hanya bisa presensi keluar jika jam kerja berakhir?",
  "attendance.break_started": "Mulai Istirahat",
  "attendance.break_ended": "Selesai Istirahat",
  "attendance.break_started_next_day": "Mulai Istirahat Hari Berikutnya",
  "attendance.break_ended_next_day": "Selesai Istirahat Hari Berikutnya",
  "attendance.overtime_started": "Mulai Lembur",
  "attendance.overtime_ended": "Selesai Lembur",
  "attendance.overtime_ended_next_day": "Selesai Lembur Hari Berikutnya",
  "attendance.out_of_office_duty": "Tugas Luar Kantor",
  "attendance.non_working_day": "Bukan Hari Kerja",
  "attendance.leave": "Cuti",
  "attendance.half_day_leave": "Cuti Setengah Hari",
  "attendance.joint_leave": "Cuti Bersama",
  "attendance.absent": "Mangkir",
  "attendance.no_status": "Belum Ada Status",
  "attendance.here": "disini",
  "attendance.you_can_specify_the_office_location_here":
    "Anda dapat menentukan lokasi kantor {{click_here}}",
  "attendance.you_can_specify_the_ip_address_of_your_office_here":
    "Anda dapat menentukan IP address dari kantor Anda {{click_here}}.",
  "attendance.time_is_not_changed_manually":
    "Waktu <span>{{type}}</span> tidak diubah manual",
  "attendance.time_is_changed_manually":
    "Waktu <span>{{type}}</span> diubah manual",
  "attendance.time_before_edit":
    "Waktu <span>{{type}}</span> sebelum diedit <span>{{time}}</span>",
  "attendance.uses_office_ip":
    "<div><span>{{type}}</span> menggunakan IP kantor</div>",
  "attendance.does_not_use_office_ip":
    "<div><span>{{type}}</span> tidak menggunakan IP kantor</div>",
  "attendance.please_upgrade_to_the_pro_or_elite_plan_to_see_the_ip_addresses_used_by_employees_for_type":
    "Silakan upgrade ke paket Pro atau Elite untuk melihat alamat IP yang digunakan karyawan untuk <span>{{type}}</span>",
  "attendance.please_upgrade_to_the_pro_or_elite_package_to_see_details_of_the_devices_used_by_employees_for_type":
    "Silakan upgrade ke paket Pro atau Elite untuk melihat detail device yang digunakan karyawan untuk <span>{{type}}</span>",
  "attendance.the_plan_only_displays_photos_from_the_last_duration":
    "Paket <span>{{plan}}</span> hanya menampilkan foto <span>{{duration}}</span> terakhir.",
  "attendance.please_upgrade_plan_to_display_photos":
    "Silakan <Link>upgrade paket</Link> untuk menampilkan foto",
  "attendance.does_attendance_automatically_come_out_if_employees_forget":
    "Apakah otomatis presensi keluar jika karyawan lupa?",
  "attendance.check_out_attendance_is_automatically_carried_out_after_the_presence_employee_has_entered_for_how_many_hours":
    "Presensi keluar otomatis dilakukan setelah karyawan presensi masuk selama berapa jam?",
  "attendance.hours": "Jam",
  "attendance.do_you_always_need_notes_on_client_visits":
    "Apakah selalu membutuhkan catatan kunjungan klien?",
  "attendance.client_visit_note": "Catatan kunjungan klien",
  "attendance.export_detail": "Ekspor Detil",
  "attendance.export_summary": "Ekspor Ringkasan",
  "attendance.row": "Baris",
  "attendance.export_attendance_summary": "Ekspor Ringkasan Presensi",
  "attendance.download": "Download {{type}}",
  "attendance.import_attendance": "Import Presensi",
  "attendance.follow_these_steps_to_import_attendance":
    "Ikuti langkah berikut untuk mengimport presensi",
  "attendance.1_download_file_template_attendance":
    "1. Download file template presensi",
  "attendance.start_by_downloading_file_template_attendance":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari presensi.",
  "attendance.2_copy_attendance_data_into_the_template":
    "2. Salin data presensi ke dalam template",
  "attendance.how_to_custom_file_template_attendance":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data presensi ke dalam file template dari Gajihub. Pastikan data presensi sesuai dengan kolom header yang ada di template.",
  "attendance.important_import_info":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data presensi dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.<br/>",
  "attendance.attendance_successfully_imported":
    "{{rowCount}} presensi berhasil di import.",
  "attendance.go_to_the_attendance_list_page": "Ke halaman daftar presensi",
  "attendance.date_attendance": "Tanggal Presensi",
  "attendance.late_time": "Waktu Terlambat",
  "attendance.late_time_after_break": "Waktu Terlambat Setelah Istirahat",
  "attendance.late_duration": "Durasi Keterlambatan",
  "attendance.late_duration_after_break":
    "Durasi Keterlambatan Setelah Istirahat",
  "attendance.does_it_always_need_attendance_note":
    "Apakah selalu membutuhkan catatan presensi?",
  "attendance.late_after_break": "Terlambat Setelah Istirahat",
  "attendance.is_the_attendance_on_workdays_status_generated_from_the_employees_leaving_attendance":
    "Apakah status hadir hari kerja dihasilkan dari presensi keluar karyawan?",
  "attendance.if_yes_the_automatic_exit_presence_does_not_apply_and_if_not_the_automatic_exit_presence_appears_and_can_be_adjusted":
    "Jika <span>Ya</span>, presensi keluar otomatis tidak berlaku dan jika <span>Tidak</span> maka presensi keluar otomatis tampil dan bisa diatur",
  "attendance.is_attendance_automatically_based_on_work_arrival_time":
    "Apakah presensi keluar otomatis didasari oleh jam masuk kerja?",
  "attendance.if_it_is_not_active_then_it_is_based_on_employee_attendance":
    "Jika tidak aktif, maka didasari oleh presensi masuk karyawan.",
  "attendance.are_records_needed_when_employees_are_late_for_attendance_or_attendance_after_a_break":
    "Apakah dibutuhkan catatan saat karyawan terlambat presensi masuk atau presensi selesai istirahat?",
  "attendance.how_to_calculate_late_employee_attendance":
    "Bagaimana perhitungan keterlambatan presensi karyawan?",
  "attendance.is_attendance_clock_in_access_closed_if_an_employee_is_late":
    "Apakah akses presensi masuk ditutup jika karyawan terlambat?",
  "attendance.is_face_recognition_verification_required_for_attendance":
    "Apakah diperlukan verifikasi pengenalan wajah untuk presensi?",
  "attendance.is_face_recognition_verification_required_for_overtime":
    "Apakah diperlukan verifikasi pengenalan wajah untuk lembur?",
  "attendance.is_face_recognition_verification_required_for_client_visit":
    "Apakah diperlukan verifikasi pengenalan wajah untuk kunjungan klien?",
  "attendance.accuracy_level_of_face_recognition_verification_during_attendance":
    "Level akurasi verifikasi pengenalan wajah saat presensi",
  "attendance.accuracy_level_of_face_recognition_verification_during_overtime":
    "Level akurasi verifikasi pengenalan wajah saat lembur",
  "attendance.accuracy_level_of_face_recognition_verification_during_client_visit":
    "Level akurasi verifikasi pengenalan wajah saat kunjungan klien",
  "attendance.low": "Rendah",
  "attendance.medium": "Sedang",
  "attendance.high": "Tinggi",
  "attendance.import_overtime": "Import Lembur",
  "attendance.follow_these_steps_to_import_overtime":
    "Ikuti langkah berikut untuk mengimport lembur",
  "attendance.1_download_file_template_overtime":
    "1. Download file template lembur",
  "attendance.start_by_downloading_file_template_overtime":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari lembur.",
  "attendance.2_copy_overtime_data_into_the_template":
    "2. Salin data lembur ke dalam template",
  "attendance.how_to_custom_file_template_overtime":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data lembur ke dalam file template dari Gajihub. Pastikan data lembur sesuai dengan kolom header yang ada di template.",
  "attendance.important_import_overtime_info":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data lembur dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.<br/>",
  "attendance.overtime_successfully_imported":
    "{{rowCount}} lembur berhasil di import.",
  "attendance.follow_these_steps_to_import_update_overtime":
    "Ikuti langkah berikut untuk mengimport update lembur",
  "attendance.1_download_file_template_update_overtime":
    "1. Download file template update lembur",
  "attendance.start_by_downloading_file_template_update_overtime":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari update lembur.",
  "attendance.2_copy_update_overtime_data_into_the_template":
    "2. Salin data update lembur ke dalam template",
  "attendance.how_to_custom_file_template_update_overtime":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data update lembur ke dalam file template dari Gajihub. Pastikan data update lembur sesuai dengan kolom header yang ada di template.",
  "attendance.important_import_update_overtime_info":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data update lembur dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan update data, tidak ada data yang dihapus atau ditambahkan.<br/>",
  "attendance.update_overtime_successfully_imported":
    "{{rowCount}} update lembur berhasil di import.",
  "attendance.date_overtime": "Tanggal Lembur",
  "attendance.overtime_clock_in": "Jam Lembur Masuk",
  "attendance.overtime_clock_out": "Jam Lembur Keluar",
  "attendance.the_form_will_be_disabled_when_attendance_clock_in_access_is_closed_if_the_employee_is_late_for_being_active":
    "Form akan disabled saat akses presensi masuk ditutup jika karyawan terlambat aktif",
  "attendance.can_attendance_only_be_carried_out_at_the_office_location_specified_in_the_shift":
    "Apakah presensi hanya dapat dilakukan di lokasi kantor yang telah ditentukan di shift?",
  "attendance.do_attendance_rules_also_apply_to_attendance_break":
    "Apakah aturan presensi juga berlaku di presensi istirahat?",
  "attendance.does_the_break_schedule_reduce_work_duration_if_the_employee_does_not_attend_a_break":
    "Apakah jadwal istirahat memotong durasi kerja jika karyawan tidak presensi istirahat?",
  "attendance.the_form_will_be_disabled_when_employee_access_can_only_presence_clock_out_when_working_hours_end_is_active":
    "Form akan disabled saat akses karyawan hanya bisa presensi keluar jika jam kerja berakhir aktif",
  "attendance.can_employees_only_be_present_at_the_specified_time":
    "Apakah karyawan hanya bisa presensi pada waktu yang ditentukan?",
  "attendance.minutes_before_attendance_clock_in":
    "Menit sebelum presensi masuk",
  "attendance.minutes_after_attendance_clock_out":
    "Menit sesudah presensi keluar",
  "attendance.if_zero_then_there_is_no_limit":
    "Jika nol, maka tidak ada batasan",
  "attendance.what_is_the_maximum_client_visit_per_day":
    "Berapa maksimal kunjungan klien per hari?",
  "attendance.if_0_then_there_is_no_limit_to_client_visits_per_day":
    "Jika 0, maka kunjungan klien per hari tidak dibatasi.",
  "attendance.please_enter_maximum_client_visits_per_day":
    "Silakan masukkan maksimal kunjungan klien per hari",
  "attendance.what_is_the_maximum_duration_of_overtime_in_a_day":
    "Berapa batas maksimal durasi lembur dalam sehari?",
  "attendance.if_0_then_there_is_no_limit_to_overtime_per_day":
    "Jika 0, maka lembur per hari tidak dibatasi.",
  "attendance.do_employees_have_to_be_client_visit_from_within_the_specified_gps_radius":
    "Apakah karyawan harus kunjungan klien dari dalam radius GPS yang ditentukan?",
  "attendance.is_approval_required_for_client_visit_outside_the_gps_radius":
    "Apakah diperlukan approval untuk kunjungan klien di luar radius GPS?",
  "attendance.time_on_device": "Waktu {{value}} di {{device}}",
  "attendance.location_tracking": "Pelacakan Lokasi",
  "attendance.overtime_type": "Tipe Lembur",
  "attendance.please_select_overtime_type": "Silakan pilih tipe lembur",
  "attendance.presence_location": "Lokasi Presensi",
  "attendance.what_is_the_maximum_duration_of_working_hours_in_a_day":
    "Berapa batas maksimal durasi jam kerja dalam sehari?",
  "attendance.if_0_then_there_is_no_limit_to_working_hours_per_day":
    "Jika 0, maka jam kerja per hari tidak dibatasi.",
  "attendance.export_daily_attendance": "Ekspor Presensi Harian",
  "attendance.export_overtime": "Ekspor Lembur",
  "attendance.export_detail_attendance": "Ekspor Detil Presensi",
  "attendance.organization": "Organisasi",
  "attendance.in_attendance_note": "Catatan Masuk",
  "attendance.in_attendance_location": "Lokasi Masuk",
  "attendance.in_attendance_location_url": "URL Lokasi Masuk",
  "attendance,in_attendance_photo": "Foto Masuk",
  "attendance.out_attendance_note": "Catatan Keluar",
  "attendance.out_attendance_location": "Lokasi Keluar",
  "attendance.out_attendance_location_url": "URL Lokasi Keluar",
  "attendance,out_attendance_photo": "Foto Keluar",
  "attendance.break_started_note": "Catatan Mulai Istirahat",
  "attendance.break_started_location": "Lokasi Mulai Istirahat",
  "attendance.break_started_location_url": "URL Lokasi Mulai Istirahat",
  "attendance.break_started_photo": "Foto Mulai Istirahat",
  "attendance.break_ended_note": "Catatan Selesai Istirahat",
  "attendance.break_ended_location": "Lokasi Selesai Istirahat",
  "attendance.break_ended_location_url": "URL Lokasi Selesai Istirahat",
  "attendance.break_ended_photo": "Foto Selesai Istirahat",
  "attendance.export_late_after_break": "Terlambat Pasca Istirahat",
  "attendance.out_attendance_early": "Presensi Keluar Lebih Cepat",
  "attendance.overtime_started_note": "Catatan Mulai Lembur",
  "attendance.overtime_started_location": "Lokasi Mulai Lembur",
  "attendance.overtime_started_location_url": "URL Lokasi Mulai Lembur",
  "attendance.overtime_started_photo": "Foto Lokasi Mulai Lembur",
  "attendance.overtime_ended_note": "Catatan Selesai Lembur",
  "attendance.overtime_ended_location": "Lokasi Selesai Lembur",
  "attendance.overtime_ended_location_url": "URL Lokasi Selesai Lembur",
  "attendance.overtime_ended_photo": "Foto Selesai Lembur",
  "attendance.overitme_hours": "Jam Lembur",
  "attendance.total_overtime_hours": "Total Jam Lembur",
  "attendance.import_add_overtime": "Import Tambah Lembur",
  "attendance.import_update_overtime": "Import Update Lembur",
  "attendance.overtime_id": "ID Lembur",
  "attendance.office_hours": "Jam Kerja Kantor",
  "attendance.break_hours": "Jam Istirahat",
  "attendance.setting_export": "Pengaturan Ekspor",
  "attendance.attendance_location_filter_description_info":
    "Filter lokasi presensi ini hanya akan menampilkan presensi yang telah dilakukan melalui <strong>aplikasi ESS</strong> atau <strong>Fingerprint</strong>. Jika presensi di input secara manual maka tidak akan tampil karena tidak memiliki catatan lokasi presensi.",

  "attendanceLocation.office_location": "Lokasi Kantor",
  "attendanceLocation.name": "Nama",
  "attendanceLocation.address": "Alamat",
  "attendanceLocation.employee_placement": "Penempatan Karyawan",
  "attendanceLocation.employee": "Karyawan",
  "attendanceLocation.add_location": "Tambah Lokasi",
  "attendanceLocation.location_name": "Nama Lokasi",
  "attendanceLocation.add_office_location": "Tambah Lokasi Kantor",
  "attendanceLocation.edit_office_location": "Ubah Lokasi Kantor",
  "attendanceLocation.please_input_location_name":
    "Silakan masukkan nama lokasi.",
  "attendanceLocation.please_input_address": "Silakan masukkan alamat.",
  "attendanceLocation.please_input_ip_address": "Silakan masukkan alamat IP.",
  "attendanceLocation.please_input_employee": "Silakan masukkan karyawan.",
  "attendanceLocation.please_add_ip_address": "Silakan tambahkan alamat IP.",
  "attendanceLocation.please_select_location": "Silakan pilih lokasi.",
  "attendanceLocation.please_input_ip": "Silakan masukkan IP.",
  "attendanceLocation.latitude": "Latitude",
  "attendanceLocation.longitude": "Longitude",
  "attendanceLocation.ip_address": "Alamat IP",
  "attendanceLocation.ip": "IP",
  "attendanceLocation.please_add_employee_placement":
    "Silakan tambahkan penempatan karyawan.",
  "attendanceLocation.please_input_name": "Silakan masukkan nama.",
  "attendanceLocation.radius": "Radius",
  "attendanceLocation.please_input_radius": "Silakan masukkan radius.",
  "attendanceLocation.are_you_sure_to_delete_office_location":
    "Apakah Anda yakin untuk menghapus lokasi kantor?",
  "attendanceLocation.location": "Lokasi",
  "attendanceLocation.help_radius_info":
    "Tentukan radius lokasi kantor Anda dari titik koordinat. Anda dapat mengatur supaya karyawan hanya dapat melakukan presensi dari dalam radius. {{click_here}} untuk pengaturan validasi presensi tersebut.",
  "attendanceLocation.help_ip_info":
    "Tentukan satu atau lebih alamat IP internet kantor Anda. Anda dapat mengatur supaya karyawan hanya dapat melakukan presensi jika menggunakan alamat IP kantor. {{click_here}} untuk pengaturan validasi presensi tersebut.",
  "attendanceLocation.help_employee_placement_info":
    "Tentukan karyawan mana saja yang dapat melakukan presensi pada lokasi kantor ini.",
  "attendanceLocation.please_select_your_office_location_point":
    "Silakan pilih titik lokasi kantor Anda. Koordinat ini akan digunakan dalam penentuan toleransi radius saat karyawan melakukan absensi",
  "attendanceLocation.without_archive": "Tanpa arsip",
  "attendanceLocation.with_archive": "Dengan arsip",
  "attendanceLocation.show_archive": "Tampilkan Arsip",
  "attendanceLocation.select_office_location": "Pilih Lokasi Kantor",

  "attendanceHistory.select_type": "Pilih tipe",
  "attendanceHistory.type": "Tipe",
  "attendanceHistory.location_status": "Status Lokasi",
  "attendanceHistory.ip_address": "Alamat IP",
  "attendanceHistory.note": "Catatan",
  "attendanceHistory.location": "Lokasi",
  "attendanceHistory.radius": "Radius",
  "attendanceHistory.coordinate": "Koordinat",
  "attendanceHistory.show_map": "Tampilkan Map",
  "attendanceHistory.hide_map": "Sembunyikan Map",
  "attendanceHistory.ip_status": "Status IP",
  "attendanceHistory.phone_type": "Tipe HP",
  "attendanceHistory.within_the_radius": "Di Dalam Radius",
  "attendanceHistory.out_of_radius": "Di Luar Radius",
  "attendanceHistory.within_the_range": "Di Dalam Range",
  "attendanceHistory.out_of_range": "Di Luar Range",

  "attendanceApproval.title": "Approval Presensi",
  "attendanceApproval.type": "Tipe",
  "attendanceApproval.time": "Waktu",
  "attendanceApproval.location": "Lokasi",
  "attendanceApproval.description": "Keterangan",
  "attendanceApproval.action": "Aksi",
  "attendanceApproval.presence": "Presensi",
  "attendanceApproval.break": "Istirahat",
  "attendanceApproval.overtime": "Lembur",
  "attendanceApproval.client_visit": "Kunjungan Klien",
  "attendanceApproval.in_the_office": "Di Kantor",
  "attendanceApproval.out_of_office": "Di Luar Kantor",
  "attendanceApproval.manage_presence_policy": "Atur Kebijakan Presensi",

  "clientVisit.title": "Kunjungan Klien",
  "clientVisit.name": "Nama",
  "clientVisit.in": "Masuk",
  "clientVisit.out": "Keluar",
  "clientVisit.duration": "Durasi",
  "clientVisit.visit_in": "Kunjungan Klien Masuk",
  "clientVisit.visit_out": "Kunjungan Klien Keluar",
  "clientVisit.detail_client_visit": "Detil Kunjungan Klien",
  "clientVisit.view_map": "Lihat Map",
  "clientVisit.hide_map": "Sembunyikan Map",
  "clientVisit.manage_client_visit_policy": "Atur Kebijakan Kunjungan Klien",
  "clientVisit.is_approval_always_required_at_the_time_of_client_visit":
    "Apakah selalu diperlukan approval saat kunjungan klien?",
  "clientVisit.open_google_maps": "Buka Google Maps",
  "clientVisit.empty_message":
    "Kunjungan klien digunakan untuk mencatatkan presensi tim Anda saat mengunjungi klien di luar kantor. <br />Anda dapat mengatur kebijakan kunjungan <Link>klik di sini</Link>.",
  "clientVisit.client_list": "Daftar Klien",
  "clientVisit.client_location": "Lokasi Klien",
  "clientVisit.export_client_visit": "Ekspor Kunjungan Klien",
  "clientVisit.employee_id": "ID Karyawan",
  "clientVisit.day": "Hari",
  "clientVisit.visit_started": "Mulai Kunjungan",
  "clientVisit.visit_ended": "Selesai Kunjungan",
  "clientVisit.visit_started_latitude": "Latitude (Mulai Kunjungan)",
  "clientVisit.visit_started_longitude": "Longitude (Mulai Kunjungan)",
  "clientVisit.visit_started_address": "Alamat Mulai Kunjungan",
  "clientVisit.visit_started_location_url": "URL Lokasi Mulai Kunjungan",
  "clientVisit.visit_ended_latitude": "Latitude (Selesai Kunjungan)",
  "clientVisit.visit_ended_longitude": "Longitude (Selesai Kunjungan)",
  "clientVisit.visit_ended_address": "Alamat Selesai Kunjungan",
  "clientVisit.visit_ended_location_url": "URL Lokasi Selesai Kunjungan",
  "clientVisit.visit_note_in": "Catatan Mulai Kunjungan",
  "clientVisit.visit_note_out": "Catatan Selesai Kunjungan",

  "schedule.schedule": "Jadwal",
  "schedule.work_schedule": "Jadwal Kerja",
  "schedule.all": "Semua",
  "schedule.fixed_schedule": "Jadwal Tetap",
  "schedule.shift_schedule": "Jadwal Shift",
  "schedule.add_work_schedule": "Tambah Jadwal Kerja",
  "schedule.edit_schedule": "Ubah Jadwal Kerja",
  "schedule.detail_schedule": "Detil Jadwal Kerja",
  "schedule.detail": "Detil",
  "schedule.weekdays": "Hari Kerja",
  "schedule.holiday": "Hari Libur",
  "schedule.effective_date": "Tanggal Efektif",
  "schedule.status": "Status",
  "schedule.valid_at_this_time": "Berlaku saat ini",
  "schedule.add_schedule": "Tambah Jadwal",
  "schedule.view_calendar": "Lihat Kalender",
  "schedule.effective_starting_date": "Berlaku efektif mulai tanggal",
  "schedule.change_employee_work_schedule": "Ubah Jadwal Kerja Karyawan",
  "schedule.list_employee": "Daftar Karyawan",
  "schedule.change_the_employee_schedule_above_with_the_following_work_schedule":
    "Ubah jadwal karyawan di atas dengan jadwal kerja sbb",
  "schedule.select_schedule": "Pilih Jadwal",
  "schedule.effective_from_the_date": "Berlaku efektif mulai tanggal",
  "schedule.please_select_schedule": "Silakan pilih jadwal",
  "schedule.please_select_date": "Silakan pilih tanggal",
  "schedule.name": "Nama",
  "schedule.work_pattern_name": "Nama Pola Kerja",
  "schedule.select_shift": "Pilih Shift",
  "schedule.add_line": "Tambah Baris",
  "schedule.effective_from_date": "Berlaku efektif mulai tanggal",
  "schedule.national_holidays_still_in": "Hari libur nasional tetap masuk",
  "schedule.leave_together_still_in": "Cuti bersama tetap masuk",
  "schedule.flexible_working_hours": "Jam Kerja Fleksibel",
  "schedule.delay_tolerance": "Toleransi Keterlambatan",
  "schedule.please_input_schedule_name": "Silakan masukkan nama jadwal.",
  "schedule.please_input_effective_date": "Silakan masukkan tanggal efektif.",
  "schedule.please_input_delay_tolerance":
    "Silakan masukkan toleransi keterlambatan.",
  "schedule.please_add_shift": "Silakan tambahkan shift.",
  "schedule.please_select_shift": "Silakan pilih shift.",
  "schedule.shift_work_schedule": "Shift Jadwal Kerja.",
  "schedule.are_you_sure_to_delete_schedule":
    "Apakah anda yakin untuk menghapus jadwal kerja?",
  "schedule.are_you_sure_to_delete_shift_schedule":
    "Apakah anda yakin untuk menghapus shift jadwal kerja?",
  "schedule.shift_list": "Daftar Shift",
  "schedule.still_in": "tetap masuk",
  "schedule.days_off": "libur",
  "schedule.flexible": "fleksibel",
  "schedule.not_flexible": "tidak fleksibel",
  "schedule.national_holidays": "Hari libur nasional",
  "schedule.joint_leave": "Cuti bersama",
  "schedule.late_tolerance": "Toleransi keterlambatan",
  "schedule.working_hours": "Jam kerja",
  "schedule.minute": "menit",
  "schedule.schedule_name": "Nama Jadwal",
  "schedule.if_flexible_working_hours_are_set_delays_in_employee_attendance_will_be_ignored":
    "Jika diset jam kerja fleksibel, maka keterlambatan presensi karyawan akan diabaikan.",
  "schedule.how_many_minutes_is_the_employees_late_tolerance":
    "Berapa menitkah toleransi keterlambatan karyawan?",
  "schedule.if_the_employee_is_late_for_attendance_below_the_tolerance_then_the_delay_will_be_ignored":
    "Jika karyawan terlambat presensi dibawah toleransi, maka keterlambatan tersebut akan diabaikan.",
  "schedule.add_new_schedule": "Tambah Jadwal Baru",
  "schedule.can_be_edited": "Bisa diubah",
  "schedule.cannot_be_edited": "Tidak bisa diubah",
  "schedule.are_you_sure_mass_edit":
    "Apakah Anda yakin akan mengubah {{count}} jadwal kerja?",
  "schedule.total_can_be_edited_is_and_cannot_be_edited_is":
    "Total jadwal kerja yang bisa diubah adalah {{editable}}, dan yang tidak bisa diubah adalah {{uneditable}}",
  "schedule.confirm_edit_work_schedule": "Konfirmasi Ubah Jadwal Kerja",
  "schedule.work_schedule_details_cannot_be_edited":
    "Detil jadwal kerja tidak bisa diubah",
  "schedule.import_shift_schedule": "Import Jadwal Shift",
  "schedule.shift_date": "Tanggal Shift",
  "schedule.follow_these_steps_to_import_shift_schedule":
    "Ikuti langkah berikut untuk mengimport jadwal shift",
  "schedule.1_download_file_template_shift_schedule":
    "1. Download file template jadwal shift",
  "schedule.start_by_downloading_file_template_shift_schedule":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari jadwal shift.",
  "schedule.2_copy_shift_schedule_data_into_the_template":
    "2. Salin data jadwal shift ke dalam template",
  "schedule.how_to_custom_file_template_shift_schedule":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data jadwal shift ke dalam file template dari Gajihub. Pastikan data jadwal shift sesuai dengan kolom header yang ada di template.",
  "schedule.important_import_info":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data jadwal shift dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.<br/>",
  "schedule.shift_schedule_successfully_imported":
    "{{rowCount}} jadwal shift berhasil di import.",
  "schedule.go_to_the_shift_schedule_list_page":
    "Ke halaman daftar jadwal shift",
  "schedule.if_flexible_break_time_are_set_delays_in_employee_attendance_will_be_ignored":
    "Jika diset jam istirahat fleksibel, maka keterlambatan karyawan setelah istirahat akan diabaikan.",
  "schedule.how_many_minutes_is_the_employees_late_tolerance_after_break_time":
    "Berapa menitkah toleransi keterlambatan karyawan setelah istirahat?",
  "schedule.if_the_employee_is_late_for_attendance_after_break_time_below_the_tolerance_then_the_delay_will_be_ignored":
    "Jika karyawan terlambat setelah istirahat dibawah toleransi, maka keterlambatan tersebut akan diabaikan.",
  "schedule.select_shift_schedule": "Pilih Jadwal Shift",

  "career.career_title": "Karir",
  "career.employee_status": "Status Karyawan",
  "career.work_start_date": "Tanggal Mulai Kerja",
  "career.work_started": "Mulai Kerja",
  "career.working_period": "Masa Kerja",
  "career.organization": "Organisasi",
  "career.job_level": "Pangkat",
  "career.job_position": "Jabatan",
  "career.effective_date": "Tanggal Efektif",
  "career.add_career": "Tambah Karir",
  "career.edit_career": "Ubah Karir",
  "career.terminated": "Berhentikan",
  "career.cancel_terminated": "Batalkan Pemberhentian",
  "career.placement": "Penempatan",
  "career.permanent_employee": "Karyawan Tetap",
  "career.interns": "Karyawan Percobaan",
  "career.pkwt": "PKWT",
  "career.select_employee_status": "Pilih Status Karyawan",
  "career.please_select_employee_status": "Silakan pilih status karyawan.",
  "career.work_placement": "Penempatan Kerja",
  "career.select_work_placement": "Pilih Penempatan Kerja",
  "career.please_select_work_placement": "Silakan pilih penempatan kerja.",
  "career.select_organization": "Pilih Organisasi",
  "career.please_select_organization": "Silakan pilih organisasi.",
  "career.select_job_level": "Pilih Pangkat",
  "career.please_select_job_level": "Silakan pilih pangkat.",
  "career.select_job_position": "Pilih Jabatan",
  "career.please_select_job_position": "Silakan pilih jabatan.",
  "career.please_input_effective_date": "Silakan masukkan tanggal efektif.",
  "career.end_employment_date": "Tanggal Masa Akhir Kerja",
  "career.end_employment": "Akhir Kerja",
  "career.please_input_date": "Silakan masukan tanggal.",
  "career.select_month": "Pilih Bulan",
  "career.please_select_month": "Silakan pilih bulan.",
  "career.termination_employee": "Berhentikan Karyawan",
  "career.reason_termination": "Alasan Berhenti",
  "career.select_reason_termination": "Pilih Alasan Berhenti",
  "career.please_select_reason_termination": "Silakan pilih alasan berhenti.",
  "career.termination_date": "Tanggal Berhenti",
  "career.please_input_termination_date": "Silakan masukkan tanggal berhenti.",
  "career.month_after_effective": "Bulan setelah efektif",
  "career.are_you_sure_to_delete_employee_career":
    "Apakah anda yakin untuk menghapus karir karyawan?",
  "career.income_type": "Tipe Penghasilan",
  "career.please_select_income_type": "Silakan pilih tipe penghasilan",
  "career.income_withholder": "Dipotong Oleh",
  "career.please_select_income_withholder":
    "Silakan pilih pemotongan penghasilan.",
  "career.are_you_sure_to_cancel_terminated_employee":
    "Apakah anda yakin untuk membatalkan pemberhentian karyawan?",

  "shift.add_shift": "Tambah Shift",
  "shift.edit_shift": "Ubah Shift",
  "shift.please_input_shift_name": "Silakan masukkan nama shift.",
  "shift.please_input_time": "Silakan masukkan jam.",
  "shift.name": "Nama",
  "shift.entry_hours": "Jam Masuk",
  "shift.hours_start": "Jam mulai",
  "shift.hours_end": "Jam selesai",
  "shift.background_color": "Warna Background",
  "shift.are_you_sure_to_delete_shift":
    "Apakah anda yakin untuk menghapus Shift?",
  "shift.shift_updated_successfully": "Shift berhasil diperbarui",
  "shift.shift_failed_to_update": "Shift gagal diperbarui",
  "shift.break_time": "Jam Istirahat",
  "shift.break_time_is_flexible": "Jam Istirahat Fleksibel",
  "shift.late_tolerance_after_break":
    "Toleransi Keterlambatan Setelah Istirahat",
  "shift.type_shift": "Tipe Shift",
  "shift.shift_full_day": "Shift Sehari Penuh",
  "shift.shift_half_day": "Shift Setengah Hari",
  "shift.work_location": "Lokasi Kerja",

  "error.an_error_happened": "Terjadi Sebuah Error",
  "error.failed_to_load_data_from_server": "Gagal mengambil data dari server!",
  "error.failed_to_delete_data_from_server":
    "Gagal menghapus data dari server!",
  "error.failed_save_data_to_server": "Gagal menyimpan data ke server!",
  "error.failed_update_data_to_server": "Gagal mengupdate data ke server!",
  "error.max_characters": "Maksimal {{value}} karakter.",
  "error.min_characters": "Minimal {{value}} karakter.",
  "error.sorry_the_page_you_visited_does_not_exist":
    "Maaf, halaman yang Anda kunjungi tidak tersedia.",

  "payroll.title": "Slip Gaji",
  "payroll.payroll": "Payroll",
  "payroll.effective_date": "Tanggal Efektif",
  "payroll.ump": "UMP",
  "payroll.work_safety_guarantee": "Jaminan Keselamatan Kerja",
  "payroll.life_insurance": "Jaminan Kematian",
  "payroll.calculation_method": "Metode Perhitungan PPH 21/26",
  "payroll.are_you_sure_to_delete_payroll":
    "Apakah anda yakin untuk menghapus Payroll?",
  "payroll.monthly_salary": "Gaji Bulanan",
  "payroll.meal_allowances": "Tunjangan Makan",
  "payroll.income_custom": "Custom Pendapatan",
  "payroll.salary_deductions_custom": "Custom Potongan",
  "payroll.total": "Total",
  "payroll.basic_salary": "Gaji Pokok",
  "payroll.fixed_income": "Pendapatan Tetap",
  "payroll.fixed_deductions": "Potongan Tetap",
  "payroll.thr": "THR",
  "payroll.yearly_bonus": "Bonus Tahunan",
  "payroll.payroll_history": "Riwayat Payroll",
  "payroll.salary_adjustment": "Penyesuaian Gaji",
  "payroll.pay_slip": "Slip Gaji",
  "payroll.amount_of_fixed_income_and_fixed_deductions":
    "Jumlah pendapatan tetap dan potongan tetap",
  "payroll.add_payroll": "Tambah Payroll",
  "payroll.duplicate_payroll": "Duplikat Payroll",
  "payroll.edit_payroll": "Ubah Payroll",
  "payroll.select_pay_slip": "Pilih Slip Gaji",
  "payroll.income": "Pendapatan",
  "payroll.deductions": "Potongan",
  "payroll.total_take_home_pay": "Total Take Home Pay",
  "payroll.default_settings": "Pengaturan Default",
  "payroll.advanced_settings": "Pengaturan Lanjutan",
  "payroll.work_accident_insurance_risk_level_group":
    "Kelompok Tingkat Risiko Jaminan Kecelakaan Kerja (JKK)",
  "payroll.death_benefit_percentage": "Persentase Iuran Jaminan Kematian (JKM)",
  "payroll.overtime_pay": "Uang Lembur",
  "payroll.fixed_amount_per_hour": "Jumlah tetap per jam",
  "payroll.meal_allowance": "Tunjangan Makan",
  "payroll.per_presence": "Per Kehadiran",
  "payroll.sales_bonus": "Bonus Penjualan",
  "payroll.multiplier_unit_is_included_in_salary":
    "Unit pengali dimasukkan di gaji",
  "payroll.performance_bonus": "Bonus Performa",
  "payroll.total_income": "Total Pendapatan",
  "payroll.social_gathering_fee": "Iuran Arisan",
  "payroll.late_charge": "Denda Keterlambatan",
  "payroll.marriage_friend_fees": "Iuran Teman Nikah",
  "payroll.total_deductions": "Total Potongan",
  "payroll.add_non_fixed_slip": "Tambah Slip Tidak Tetap",
  "payroll.slip": "Slip",
  "payroll.period": "Periode",
  "payroll.employee": "Karyawan",
  "payroll.ready_to_pay": "Siap Bayar",
  "payroll.already_paid": "Sudah Bayar",
  "payroll.first_period": "Awal Periode",
  "payroll.end_of_period": "Akhir Periode",
  "payroll.name_non_fixed_slip": "Nama Slip Tidak Tetap",
  "payroll.detail": "Detil",
  "payroll.pay_salary": "Bayar Gaji",
  "payroll.change_to_ready_to_pay": "Ubah ke Siap Bayar",
  "payroll.bank": "Bank",
  "payroll.attendance_status": "Status Absensi",
  "payroll.payment_status": "Status Pembayaran",
  "payroll.not_ready": "Belum Siap",
  "payroll.transportation_allowance": "Tunjangan Transportasi",
  "payroll.pph_21": "PPh 21",
  "payroll.income_name": "Nama Pendapatan",
  "payroll.add_thp": "Penambah THP",
  "payroll.taxable": "Kena Pajak",
  "payroll.amount": "Jumlah",
  "payroll.cash_receipt": "Kasbon",
  "payroll.cash_receipt_name": "Nama Kasbon",
  "payroll.will_be_repaid_for": "Akan dicicil selama",
  "payroll.cuts": "Potongan",
  "payroll.cuts_name": "Nama Potongan",
  "payroll.tax_deduction": "Pengurang Pajak",
  "payroll.payment": "Pembayaran",
  "payroll.choose_payment_method": "Pilih metode pembayaran",
  "payroll.payment_confirmation": "Konfirmasi Bayar Gaji",
  "payroll.once_confirmed_salary_slips_cannot_be_changed_or_deleted_again":
    "Setelah dikonfirmasi, slip gaji tidak dapat diubah atau dihapus kembali.",
  "payroll.total_payment": "Total Pembayaran",
  "payroll.total_employee": "Total Karyawan",
  "payroll.are_you_sure_to_confirm_the_following_salary_payments":
    "Apakah yakin mengkonfirmasi pembayaran gaji berikut?",
  "payroll.success": "Berhasil",
  "payroll.payment_confirmation_successful":
    "Konfirmasi pembayaran gaji berhasil!",
  "payroll.salary_payment_steps": "Langkah pembayaran gaji",
  "payroll.payment_instructions_1":
    "Pastikan rekening perusahaan Anda telah ditambahkan. {{click_here}} untuk menambahkan.",
  "payroll.payment_instructions_2":
    "Pastikan slip gaji sudah berstatus Siap Bayar. Hanya yang berstatus Siap Bayar yang akan muncul pada file download.",
  "payroll.payment_instructions_3":
    "Download daftar gaji sesuai bank dari masing-masing karyawan.",
  "payroll.payment_instructions_4":
    "Lakukan pembayaran gaji ke bank masing-masing.",
  "payroll.payment_instructions_5":
    "Kembali ke halaman ini, dan klik tombol Konfirmasi.",
  "payroll.please_input_effective_date": "Silakan masukkan tanggal efektif.",
  "payroll.salary_slip": "Slip Gaji",
  "payroll.add_salary_slip": "Tambah Slip Gaji",
  "payroll.edit_salary_slip": "Ubah Slip Gaji",
  "payroll.name": "Nama",
  "payroll.salary_slip_name": "Nama Slip Gaji",
  "payroll.please_input_salary_slip_name": "Silakan masukkan nama slip gaji.",
  "payroll.period_started": "Periode Dimulai",
  "payroll.period_started_on_date": "Periode Dimulai Pada Tanggal",
  "payroll.please_select_period_started": "Silakan pilih periode dimulai.",
  "payroll.duration_period": "Lama Periode",
  "payroll.please_select_period": "Silakan pilih periode.",
  "payroll.please_input_count_days": "Silakan masukkan jumlah hari.",
  "payroll.please_select_duration_period": "Silakan pilih lama periode.",
  "payroll.number_of_days_in_period": "Jumlah Hari Dalam Periode",
  "payroll.please_select_number_of_days_in_period":
    "Silakan pilih jumlah hari dalam periode.",
  "payroll.previous_slip_date": "Tanggal Slip Sebelumnya",
  "payroll.please_input_previous_slip_date":
    "Silakan masukkan tanggal slip sebelumnya.",
  "payroll.for_the_income_component_depending_on_attendance_how_many_days_of_the_last_attendance_count":
    "Untuk komponen pendapatan tergantung kehadiran, berapa hari absensi terakhir yang masuk hitungan",
  "payroll.days_before_the_end_of_the_period": "Hari sebelum akhir periode",
  "payroll.select_the_income_component_and_the_discount_component_for_this_payslip":
    "Pilih komponen pendapatan dan komponen potongan untuk slip gaji ini",
  "payroll.income_component": "Komponen Pendapatan",
  "payroll.cut_component": "Komponen Pemotongan",
  "payroll.add_income_component": "Tambah Komponen Pendapatan",
  "payroll.edit_income_component": "Ubah Komponen Pendapatan",
  "payroll.detail_income_component": "Detil Komponen Pendapatan",
  "payroll.add_cut_component": "Tambah Komponen Pemotongan",
  "payroll.edit_cut_component": "Ubah Komponen Pemotongan",
  "payroll.detail_cut_component": "Detil Komponen Pemotongan",
  "payroll.please_select_income_component_or_cut_component":
    "Silakan pilih komponen pendapatan atau komponen pemotongan.",
  "payroll.input_count_days": "Masukkan jumlah hari",
  "payroll.component_type": "Tipe Komponen",
  "payroll.please_select_component_type": "Silakan pilih tipe komponen.",
  "payroll.please_input_name": "Silakan masukkan nama.",
  "payroll.taxable_income": "Pendapatan Kena Pajak",
  "payroll.tax_category": "Kategori Pajak",
  "payroll.please_select_tax_category": "Silakan pilih kategori pajak.",
  "payroll.tax_reduction": "Pengurangan Pajak",
  "payroll.are_you_sure_to_delete_income_component":
    "Apakah anda yakin untuk menghapus komponen pendapatan?",
  "payroll.are_you_sure_to_delete_cut_component":
    "Apakah anda yakin untuk menghapus komponen potongan?",
  "payroll.are_you_sure_to_delete_salary_slip":
    "Apakah anda yakin untuk menghapus slip gaji?",
  "payroll.please_add": "Silakan tambahkan.",
  "payroll.please_add_salary_slip": "Silakan tambahkan slip gaji.",
  "payroll.input_every_time_you_make_payroll":
    "Di input setiap melakukan penggajian",
  "payroll.please_input": "Silakan masukkan",
  "payroll.per_hour": "per jam",
  "payroll.please_select_work_accident_insurance_risk_level_group":
    "Silakan pilih kelompok tingkat risiko Jaminan Kecelakaan Kerja (JKK).",
  "payroll.please_select_death_benefit_percentage":
    "Silakan pilih persentase iuaran Jaminan Kematian (JKM).",
  "payroll.please_select_calculation_method":
    "Silakan pilih metode perhitungan PPH 21/26.",
  "payroll.amount_fixed_income_and_fixed_deductions":
    "Jumlah pendapatan tetap dan potongan tetap",
  "payroll.please_select_salary_slip": "Silakan pilih slip gaji.",
  "payroll.please_input_first_period": "Silakan masukkan awal periode.",
  "payroll.please_input_end_period": "Silakan masukkan akhir periode.",
  "payroll.attendance": "Kehadiran",
  "payroll.please_input_income_name": "Silakan masukkan nama pendapatan.",
  "payroll.salary_history": "Riwayat Gaji",
  "payroll.status": "Status",
  "payroll.not_ready_pay": "Belum Siap Bayar",
  "payroll.failed_to_download_file": "Gagal download file",
  "payroll.view_slip": "Lihat Slip",
  "payroll.split_salary_slip": "Pecah Slip Gaji",
  "payroll.cancel_split_salary_slip": "Batalkan Pecah Slip Gaji",
  "payroll.are_you_sure_to_cancel_split_salary_slip":
    "Apakah anda yakin untuk membatalkan pecah slip gaji?",
  "payroll.latest_salary_slip": "Slip Gaji Terbaru",
  "payroll.please_choose_a_payslip_that_has_a_basic_salary":
    "Silakan pilih slip gaji yang memiliki gaji pokok.",
  "payroll.view_employee_details": "Lihat Detil Karyawan",
  "payroll.click_here_to_add_a_new_pay_slip":
    "Klik disini untuk menambah slip gaji baru.",
  "payroll.borne_by_the_company": "ditanggung perusahaan",
  "payroll.please_select": "Silakan pilih <span>{{type}}</span>",
  "payroll.late_charge_per_minutes_description":
    "Potongan ini di ambil berdasarkan jumlah <strong>Keterlambatan</strong> karyawan per menit",
  "payroll.late_charge_description":
    "Potongan ini di ambil berdasarkan jumlah <strong>Keterlambatan</strong> karyawan dalam presensi",
  "payroll.late_after_break_charge_description":
    "Potongan ini di ambil berdasarkan jumlah <strong>Keterlambatan</strong> karyawan setelah istirahat",
  "payroll.deduction_unpaid_leave_description":
    "Potongan ini di ambil berdasarkan jumlah <strong>Unpaid Leave</strong> dari karyawan yang di ajukan dan sudah disetujui",
  "payroll.deduction_absence_description":
    "Potongan ini di ambil berdasarkan jumlah status <strong>Ketidakhadiran</strong> atau <strong>Mangkir</strong> karyawan dalam presensi",
  "payroll.the_payroll_effective_date_selected_cannot_be_less_than_the_current_effective_employee_career_date":
    "<strong>Tanggal efektif payroll</strong> yang dipilih tidak bisa kurang dari <strong>tanggal efektif karir</strong> karyawan yang sedang berlaku",
  "payroll.import": "Import Payroll",
  "payroll.follow_these_steps_to_import_payroll":
    "Ikuti langkah berikut untuk mengimport payroll",
  "payroll.1_download_file_template_payroll":
    "1. Download file template payroll",
  "payroll.start_by_downloading_file_template_payroll":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari payroll.",
  "payroll.2_copy_payroll_data_into_the_template":
    "2. Salin data payroll ke dalam template",
  "payroll.how_to_custom_file_template_payroll":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data payroll ke dalam file template dari Gajihub. Pastikan data payroll sesuai dengan kolom header yang ada di template.",
  "payroll.important_import_info":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data payroll dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.<br/>",
  "payroll.payroll_successfully_imported":
    "{{rowCount}} payroll berhasil di import.",
  "payroll.go_to_the_payroll_list_page": "Ke halaman daftar payroll",
  "payroll.component": "Komponen",
  "payroll.note": "Catatan",
  "payroll.input_note": "Masukkan catatan",
  "payroll.fixed_allowance": "Tunjangan Tetap",
  "payroll.total_payroll_payment_is_empty_please_add_payroll_for_your_employees_first":
    "Total pembayaran payroll kosong, silakan menambahkan payroll untuk karyawan Anda terlebih dahulu.",
  "payroll.based_on_attendance_status": "Status Berdasarkan Kehadiran",
  "payroll.use_in_the_next_period": "Gunakan di periode selanjutnya",
  "payroll.export_tax_calculation": "Ekspor Perhitungan Pajak",
  "payroll.export_prorate_calculation": "Ekspor Perhitungan Prorata",
  "payroll.payroll_tax_calculation": "Perhitungan Pajak Payroll",
  "payroll.payroll_prorate_calculation": "Perhitungan Prorata Payroll",
  "payroll.overtime_calculation_method": "Metode Perhitungan Lembur",
  "payroll.fixed_amount_of_overtime_pay_per_hour":
    "Jumlah Tetap Uang Lembur Per Jam",
  "payroll.the_method_used_for_calculating_employee_overtime_pay":
    "Metode yang digunakan untuk perhitungan uang lembur karyawan",
  "payroll.fixed_nominal_amount_of_employee_overtime_pay":
    "Jumlah Tetap Nominal Uang Lembur Per Jam Karyawan",
  "payroll.please_input_fixed_amount_of_overtime_pay_per_hour":
    "Silakan masukkan jumlah tetap uang lembur per jam.",
  "payroll.formula": "Formula",
  "payroll.manual": "Manual",
  "payroll.dynamic": "Dinamis",
  "payroll.variabel": "Variabel",
  "payroll.operator": "Operator",
  "payroll.numbers": "Angka",
  "payroll.edit_formula": "Ubah Formula",
  "payroll.criteria": "Kriteria",
  "payroll.input": "Input",
  "payroll.output": "Output",
  "payroll.please_add_input": "Silakan tambahkan input.",
  "payroll.please_add_criteria": "Silakan tambahkan kriteria.",
  "payroll.cannot_less_than_1": "Tidak boleh kurang dari 1.",
  "payroll.import_manual_payroll_component": "Import Komponen Manual Payroll",
  "payroll.follow_these_steps_to_import_manual_payroll_components":
    "Ikuti langkah berikut untuk mengimport komponen manual payroll",
  "payroll.1_download_file_template_manual_payroll_component":
    "1. Download file template komponen manual payroll",
  "payroll.start_by_downloading_file_template_manual_payroll_component":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari komponen manual payroll.",
  "payroll.2_copy_manual_payroll_component_data_into_the_template":
    "2. Salin data komponen manual payroll ke dalam template",
  "payroll.how_to_custom_file_template_manual_payroll_component":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data komponen manual payroll ke dalam file template dari Gajihub. Pastikan data komponen manual payroll sesuai dengan kolom header yang ada di template.",
  "payroll.important_import_info_manual_payroll_component":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data komponen manual payroll dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.<br/>",
  "payroll.component_name": "Nama Komponen",
  "payroll.tax_type": "Tipe Pajak",
  "payroll.sustainable": "Berkelanjutan",
  "payroll.manual_payroll_component_successfully_imported":
    "{{rowCount}} komponen manual payroll berhasil di import.",
  "payroll.visit": "Kunjungan",
  "payroll.show_data": "Tampilkan Data",
  "payroll.at_least_one_column_display": "Minimal tampil satu kolom",
  "payroll.manual_income_component": "Komponen Pendapatan Manual",
  "payroll.manual_deduction_component": "Komponen Potongan Manual",
  "payroll.manual_cash_receipt_component": "Komponen Kasbon Manual",
  "payroll.tax_status": "Status Pajak",
  "payroll.not_taxable": "Tidak Kena Pajak",
  "payroll.not_deducted_by_tax": "Tidak Dipotong Pajak",
  "payroll.tax": "Pajak",
  "payroll.import_payroll_notes": "Import Catatan Payroll",
  "payroll.follow_these_steps_to_import_payroll_note":
    "Ikuti langkah berikut untuk mengimport catatan payroll",
  "payroll.1_download_file_template_payroll_note":
    "1. Download file template catatan payroll",
  "payroll.start_by_downloading_file_template_payroll_note":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari catatan payroll.",
  "payroll.2_copy_payroll_note_data_into_the_template":
    "2. Salin data catatan payroll ke dalam template",
  "payroll.how_to_custom_file_template_payroll_note":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data catatan payroll ke dalam file template dari Gajihub. Pastikan data catatan payroll sesuai dengan kolom header yang ada di template.",
  "payroll.important_import_info_payroll_note":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data catatan payroll dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.<br/>",
  "payroll.payroll_note_successfully_imported":
    "{{rowCount}} catatan payroll berhasil di import.",
  "payroll.per_day": "per hari",
  "payroll.sort": "Urutkan",
  "payroll.sort_income_payroll_components":
    "Urutkan Komponen Payroll Pendapatan",
  "payroll.sort_deduction_payroll_components":
    "Urutkan Komponen Payroll Potongan",
  "payroll.the_selected_payroll_component_has_not_been_saved":
    "Komponen payroll yang dipilih belum disimpan",
  "payroll.the_payroll_components_that_can_be_sorted_are_only_the_payroll_components_that_have_been_selected_and_saved_on_the_salary_slip":
    "Komponen payroll yang dapat diurutkan hanya komponen payroll yang sudah dipilih dan tersimpan pada slip gaji.",
  "payroll.download_salary_slip": "Download Slip Gaji",
  "payroll.with_attachments": "Dengan Lampiran",
  "payroll.multi_payroll_payment": "Multi Pembayaran Gaji",
  "payroll.employees_use_old_age_insurance":
    "Karyawan Memakai Jaminan Hari Tua",
  "payroll.percentage_of_old_age_Insurance_covered_by_the_company":
    "Presentase Jaminan Hari Tua Ditanggung Perusahaan",
  "payroll.employees_use_pension_protection":
    "Karyawan Memakai Jaminan Pensiun",
  "payroll.percentage_of_pension_protection_covered_by_the_company":
    "Presentase Jaminan Pensiun Ditanggung Perusahaan",
  "payroll.percentage_of_bpjs_healthcare_covered_by_the_company":
    "Persentase BPJS Kesehatan Ditanggung Perusahaan",
  "payroll.edit_overtime": "Ubah Lembur",
  "payroll.overtime_type": "Tipe Lembur",
  "payroll.select_overtime_type": "Pilih tipe lembur",
  "payroll.excess_or_less_than_the_duration_will_be_distributed_to_overtime_with_the_selected_type":
    "Kelebihan atau kurang dari durasi akan didistribusikan ke lembur dengan tipe yang dipilih.",
  "payroll.are_you_sure_to_reset_overtime":
    "Apakah Anda yakin untuk reset lembur?",
  "payroll.jht_covered_by_the_company_is_calculated_as_pph_21":
    "Jaminan Hari Tua (JHT) Ditanggung Perusahaan Dihitung PPh 21",
  "payroll.mass_salary_slip_download": "Download Slip Gaji Massal",
  "payroll.the_download_results_will_be_sent_to_your_email_address":
    "Hasil unduhan akan dikirimkan ke alamat email Anda",
  "payroll.download_success": "Download Berhasil",
  "payroll.valid_for_the_day": "Berlaku Untuk Hari",
  "payroll.download_payroll_export": "Download Ekspor Payroll {{value}}",
  "payroll.confirmation_of_salary_payment_via_payment_connect_is_successful":
    "Konfirmasi pembayaran gaji melalui payment connect berhasil.",
  "payroll.please_confirm_on_each_payment_connect":
    "Silakan konfirmasi di payment connect masing - masing!",

  "cashReceipt.title": "Kasbon",
  "cashReceipt.balance": "Saldo",
  "cashReceipt.history": "Riwayat",
  "cashReceipt.payment": "Pembayaran",
  "cashReceipt.add_cash_receipt": "Tambah Kasbon",
  "cashReceipt.edit_cash_receipt": "Ubah Kasbon",
  "cashReceipt.detail_cash_receipt": "Detil Kasbon",
  "cashReceipt.name": "Nama",
  "cashReceipt.unpaid_cash_receipt": "Kasbon Belum Dibayar",
  "cashReceipt.last_payment": "Pembayaran Terakhir",
  "cashReceipt.last_payment_date": "Tanggal Pembayaran Terakhir",
  "cashReceipt.description": "Keterangan",
  "cashReceipt.cash_receipt_description": "Keterangan Kasbon",
  "cashReceipt.approved_or_rejected_description":
    "Keterangan Persetujuan/Penolakan",
  "cashReceipt.total_cash_receipt": "Total Kasbon",
  "cashReceipt.unpaid": "Belum Dibayar",
  "cashReceipt.cash_receipt_date": "Tanggal Kasbon",
  "cashReceipt.due_date": "Jatuh Tempo",
  "cashReceipt.term": "Termin",
  "cashReceipt.status": "Status",
  "cashReceipt.date": "Tanggal",
  "cashReceipt.please_input": "Silakan masukkan <span>{{type}}</span>",
  "cashReceipt.please_select": "Silakan pilih <span>{{type}}</span>",
  "cashReceipt.select_term": "Pilih Termin",
  "cashReceipt.add_payment": "Tambah Pembayaran",
  "cashReceipt.edit_payment": "Ubah Pembayaran",
  "cashReceipt.total_payment": "Total Pembayaran",
  "cashReceipt.employee": "Karyawan",
  "cashReceipt.are_you_sure_to_delete_cash_receipt":
    "Apakah Anda yakin untuk menghapus kasbon?",
  "cashReceipt.please_click_here_to_add_bank_account_before_creating_cash_receipt":
    "Silakan <Link><bold>klik disini</bold></Link> untuk menambahkan rekening bank sebelum <span>{{type}}</span>.",
  "cashReceipt.please_click_here_to_edit_plafon_before_creating_cash_receipt":
    "Silakan <Link><bold>klik disini</bold></Link> untuk ubah plafon sebelum <span>{{type}}</span>.",
  "cashReceipt.can_employees_apply_for_cash_receipts_from_ess":
    "Apakah karyawan bisa mengajukan kasbon dari ESS?",
  "cashReceipt.employee_cash_receipt_plafon": "Plafon kasbon karyawan",
  "cashReceipt.maximum_cash_receipt": "Maksimal Kasbon",
  "cashReceipt.cash_receipt_successfully_created": "Kasbon Berhasil Dibuat",
  "cashReceipt.cash_receipt_submission_approved": "Pengajuan Kasbon Disetujui",
  "cashReceipt.cash_receipt_submission_rejected": "Pengajuan Kasbon Ditolak",
  "cashReceipt.the_cash_receipt_you_created_is_in_the_approved_tab":
    "Kasbon yang anda buat berada di dalam tab disetujui",
  "cashReceipt.cash_receipt_that_you_approve_moved_to_the_approved_tab_section":
    "Kasbon yang anda setujui dipindahkan kebagian tab disetujui",
  "cashReceipt.cash_receipt_that_you_rejected_moved_to_the_rejected_tab_section":
    "Kasbon yang anda tolak dipindahkan kebagian tab ditolak",
  "cashReceipt.cash_receipt_payment": "Pembayaran Kasbon",
  "cashReceipt.cash_receipt_pay": "Bayar Kasbon",
  "cashReceipt.date_approved": "Tanggal Disetujui",
  "cashReceipt.date_rejected": "Tanggal Ditolak",
  "cashReceipt.import_cash_receipt": "Import Kasbon",
  "cashReceipt.follow_these_steps_to_import_cash_receipt":
    "Ikuti langkah berikut untuk mengimport kasbon",
  "cashReceipt.1_download_file_template_cash_receipt":
    "1. Download file template kasbon",
  "cashReceipt.start_by_downloading_file_template_cash_receipt":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari kasbon.",
  "cashReceipt.2_copy_cash_receipt_data_into_the_template":
    "2. Salin data kasbon ke dalam template",
  "cashReceipt.how_to_custom_file_template_cash_receipt":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data kasbon ke dalam file template dari Gajihub. Pastikan data kasbon sesuai dengan kolom header yang ada di template.",
  "cashReceipt.important_import_info":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data kasbon dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.<br/>",
  "cashReceipt.cash_receipt_successfully_imported":
    "{{rowCount}} kasbon berhasil di import.",
  "cashReceipt.go_to_the_cash_receipt_list_page": "Ke halaman daftar kasbon",
  "cashReceipt.submission_date": "Tanggal Pengajuan",
  "cashReceipt.payment_date": "Tanggal Pembayaran",
  "cashReceipt.import_cash_receipt_plafon": "Import Plafon Kasbon",
  "cashReceipt.follow_these_steps_to_import_cash_receipt_plafon":
    "Ikuti langkah berikut untuk mengimport plafon kasbon",
  "cashReceipt.1_download_file_template_cash_receipt_plafon":
    "1. Download file template plafon kasbon",
  "cashReceipt.start_by_downloading_file_template_cash_receipt_plafon":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari plafon kasbon.",
  "cashReceipt.2_copy_cash_receipt_plafon_data_into_the_template":
    "2. Salin data plafon kasbon ke dalam template",
  "cashReceipt.how_to_custom_file_template_cash_receipt_plafon":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data plafon kasbon ke dalam file template dari Gajihub. Pastikan data plafon kasbon sesuai dengan kolom header yang ada di template.",
  "cashReceipt.important_import_info_cash_receipt_plafon":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data plafon kasbon dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.<br/>",
  "cashReceipt.cash_receipt_plafon_successfully_imported":
    "{{rowCount}} plafon kasbon berhasil di import.",
  "cashReceipt.go_to_the_cash_receipt_plafon_list_page":
    "Ke halaman daftar plafon kasbon",
  "cashReceipt.payment_start_date": "Tanggal Mulai Pembayaran",
  "cashReceipt.types_of_cash_advance_installment_payments_in_payroll":
    "Jenis pembayaran cicilan kasbon di payroll",
  "cashReceipt.enable_application_terms_of_cash_receipt_submission":
    "Aktifkan penerapan termin pengajuan kasbon",
  "cashReceipt.maximum_terms_cash_receipt_submission":
    "Maksimal termin pengajuan kasbon",
  "cashReceipt.pay_cash_receipt_installments": "Bayar Cicilan Kasbon",
  "cashReceipt.mass_pay_cash_receipt_installments":
    "Bayar Cicilan Kasbon Massal",
  "cashReceipt.pay_cash_receipt": "Bayar Kasbon",
  "cashReceipt.mass_pay_cash_receipt": "Bayar Kasbon Massal",
  "cashReceipt.selected_cash_receipt_with_approved_status":
    "Kasbon yang telah dipilih dengan status disetujui",
  "cashReceipt.selected_cash_receipt_with_paid_status":
    "Kasbon yang telah dipilih dengan status dibayar",
  "cashReceipt.nominal": "Nominal",

  "reimbursement.title": "Reimbursement",
  "reimbursement.add_reimburse": "Tambah Reimbursement",
  "reimbursement.edit_reimburse": "Ubah Reimbursement",
  "reimbursement.detail_reimburse": "Detil Reimbursement",
  "reimbursement.amount_of_money": "Total Uang",
  "reimbursement.please_input": "Silakan masukkan <span>{{type}}</span>",
  "reimbursement.please_select": "Silakan pilih <span>{{type}}</span>",
  "reimbursement.reimbursement_usage_details": "Rincian Penggunaan Reimburse",
  "reimbursement.reimbursement_applicant": "Pengaju Reimbursement",
  "reimbursement.payment": "Pembayaran",
  "reimbursement.paid": "Dibayar",
  "reimbursement.add_payment": "Tambah Pembayaran",
  "reimbursement.pay_reimburse": "Bayar Reimbursement",
  "reimbursement.date_payment": "Tanggal Pembayaran",
  "reimbursement.total_payment": "Total Pembayaran",
  "reimbursement.are_you_sure_to_delete_reimbursement":
    "Apakah anda yakin untuk menghapus reimbursement?",
  "reimbursement.from": "Dari",
  "reimbursement.to": "Ke",
  "reimbursement.bank_account": "Rekening Bank",
  "reimbursement.employee_account": "Rekening Karyawan",
  "reimbursement.payment_method": "Metode Pembayaran",
  "reimbursement.cash": "Tunai",
  "reimbursement.non_cash": "Non Tunai",
  "reimbursement.reimbursement_settings": "Pengaturan Reimbursement",
  "reimbursement.employee_reimbursement_plafon": "Plafon reimburse karyawan",
  "reimbursement.maximum_reimbursement": "Maksimal Reimburse",
  "reimbursement.plafon_type": "Tipe Plafon",

  "timeline.title": "Timeline",
  "timeline.announcement": "Pengumuman",
  "timeline.add_timeline": "Tambah Timeline",
  "timeline.add_announcement": "Tambah Pengumuman",
  "timeline.edit_announcement": "Ubah Pengumuman",
  "timeline.time": "Waktu",
  "timeline.text": "Text",
  "timeline.read": "Dibaca",
  "timeline.body_timeline": "Isi Timeline",
  "timeline.body_announcement": "Isi Pengumuman",
  "timeline.please_input_content": "Silakan masukkan konten.",
  "timeline.please_input_body_announcement": "Silakan masukkan isi pengumuman.",
  "timeline.are_you_sure_to_add_this_announcement":
    "Anda yakin menambah pengumuman ini?",
  "timeline.when_you_click_sure_the_announcement_will_immediately_be_read_by_all_targeted_employees":
    "Saat klik Yakin, pengumuman akan langsung dapat dibaca oleh semua karyawan yang dituju.",
  "timeline.sure": "Yakin",
  "timeline.comments": "Komentar",
  "timeline.setting_leave_on_timeline":
    "Apakah notifikasi izin cuti ditampilkan pada timeline?",
  "timeline.setting_birthday_on_timeline":
    "Apakah notifikasi ulang tahun ditampilkan pada timeline?",
  "timeline.send_to": "Dikirim Ke",
  "timeline.are_you_sure_pin_this_announcement":
    "Apakah anda yakin pin pengumuman ini?",
  "timeline.are_you_sure_unpin_this_announcement":
    "Apakah anda yakin batalkan pin pengumuman ini?",

  "thr.title": "THR",
  "thr.how_many_min_working_month":
    "Berapa masa kerja minimal supaya karyawan mendapatkan THR?",
  "thr.how_much_thr_each_employee": "Berapa besar THR untuk tiap karyawan?",
  "thr.time": "kali",
  "thr.times": "kali",
  "thr.employee_under_1_year_how_thr":
    "Untuk karyawan dengan masa kerja di bawah 1 tahun, bagaimana perhitungan THR-nya?",
  "thr.enable_progressive_thr_calculation":
    "Aktifkan perhitungan THR progresif",
  "thr.minimum_working_duration": "Minimal masa kerja",
  "thr.thr_amount": "Besaran THR",
  "thr.add_row": "Tambah Baris",
  "thr.please_input": "Silakan masukkan",
  "thr.min_month": "Min {{min}} bulan",
  "thr.max_month": "Maks {{max}} bulan",
  "thr.max": "Maks {{max}}",
  "thr.working_time_has_been_used": "Masa kerja sudah digunakan",
  "thr.set_different_thr_amounts_based_on_length_of_service":
    "Atur besaran THR yang berbeda berdasarkan masa kerja",

  "leaveAllowance.title": "Tunjangan Cuti",
  "leaveAllowance.activate_for_employee":
    "Aktifkan tunjangan cuti untuk karyawan?",
  "leaveAllowance.how_much_each_employee":
    "Berapa besar tunjangan cuti untuk tiap karyawan?",
  "leaveAllowance.is_it_with_tax": "Apakah tunjangan cuti kena pajak?",
  "leaveAllowance.what_tax_category": "Apa kategori pajak tunjangan cuti?",
  "leaveAllowance.cannot_less_than_0": "Tidak boleh kurang dari 0.",
  "leaveAllowance.description":
    "Tunjangan cuti adalah tunjangan yang dibayarkan satu tahun sekali pada setiap tanggal bergabungnya karyawan di perusahaan.",

  "overtimePay.title": "Lembur",
  "overtimePay.activate_for_employee": "Aktifkan uang lembur untuk karyawan?",
  "overtimePay.how_company_calculate_overtime":
    "Bagaimana perusahaan menghitung uang lembur?",
  "overtimePay.total_per_hour": "Jumlah rupiah per jam",
  "overtimePay.total_per_day": "Jumlah rupiah per hari",
  "overtimePay.173_description":
    "Angka 173 adalah rata-rata jumlah jam kerja karyawan dalam satu bulan.<br/>Detail perhitungan:<br/>1 tahun = 52 minggu. Perbulan rata-rata terdapat 52 minggu/12 bulan = 4,3 minggu.<br/>Total jam kerja karyawan tiap minggu = 40 jam.<br/>Total jam kerja karyawan 1 bulan = 40 jam/minggu X 4,33 minggu = 173",

  "proRate.title": "Pro Rata",
  "proRate.how_calculate_salary_promoted_employee":
    "Bagaimana perhitungan gaji untuk karyawan yang promosi di tengah periode penggajian?",
  "proRate.how_calculate_salary_resigned_employee":
    "Bagaimana perhitungan gaji untuk karyawan yang keluar di tengah periode penggajian?",
  "proRate.how_calculate_pro_rate":
    "Bagaimana perhitungan pro rata di perusahaan Anda?",
  "proRate.how_is_the_salary_calculation_for_employees_who_enter_or_leave_in_the_middle_of_the_payroll_period":
    "Bagaimana perhitungan gaji untuk karyawan yang masuk atau keluar di tengah periode penggajian?",
  "proRate.select_the_working_days_in_your_company":
    "Pilih hari kerja di perusahaan anda",
  "proRate.do_national_holidays_collective_leave_and_non_holiday_events_reduce_the_prorate":
    "Apakah libur nasional, cuti bersama dan even non libur mengurangi prorata?",

  "tax.title": "Pajak",
  "tax.activate_pph21/26_tax": "Aktifkan pajak PPH 21/26?",
  "tax.npwp_withholding": "NPWP Pemotong",
  "tax.add_npwp_withholding": "Tambah NPWP Pemotong",
  "tax.edit_npwp_withholding": "Ubah NPWP Pemotong",
  "tax.default": "Default",
  "tax.name": "Nama",
  "tax.npwp": "NPWP",
  "tax.only_alphanumeric_allowed": "Hanya alfanumerik yang diizinkan.",
  "tax.please_input_name": "Silakan masukkan nama.",
  "tax.please_input_npwp": "Silakan masukkan NPWP.",
  "tax.npwp_business_entity": "NPWP Badan Usaha",
  "tax.npwp_leader_or_authorization": "NPWP Pimpinan / Kuasa",
  "tax.are_you_sure_to_delete_npwp_withholding":
    "Apakah anda yakin untuk menghapus NPWP pemotong?",
  "tax.set_as_default": "Jadikan sebagai default",
  "tax.npwp_company": "NPWP Perusahaan",
  "tax.leaders_or_those_who_have_the_power_to_sign_the_spt":
    "Pimpinan atau yang mendapatkan kuasa untuk menandatangani SPT",
  "tax.help_tax_activated":
    "Jika pajak PPH diaktifkan, maka pada slip gaji akan secara otomatis dihitungkan komponen pajaknya.",
  "tax.help_npwp_withholder":
    "Masukkan NPWP Pemotong di perusahaan Anda. Jika terdapat lebih dari satu NPWP Pemotong, Anda dapat menentukan per masing-masing karyawan akan dipotongkan menggunakan NPWP pemotong berbeda dari halaman detail karyawan.",
  "tax.help_calculation_method":
    "Nett, adalah metode pemotongan pajak dimana perusahaan menanggung pajak karyawannya.<br/>Gross, adalah metode pemotongan pajak dimana karyawan menanggung sendiri jumlah pajak penghasilannya.<br/>Gross up, adalah metode pemotongan pajak dimana perusahaan memberikan tunjangan pajak yang jumlahnya sama besar dengan jumlah pajak yang dipotong dari karyawan.<br/><br/>Pengaturan ini akan diterapkan secara default untuk semua karyawan. Jika terdapat karyawan yang akan menggunakan metode perhitungan yang berbeda Anda dapat mengaturnya pada halaman detail karyawan.",

  "bpjs.title": "BPJS",
  "bpjs.bpjs_of_employement": "BPJS Ketenagakerjaan",
  "bpjs.bpjs_healthcare": "BPJS Kesehatan",
  "bpjs.setup_bpjs_of_employement": "Setup BPJS Ketenagakerjaan",
  "bpjs.edit_setup_bpjs_of_employement": "Ubah Setup BPJS Ketenagakerjaan",
  "bpjs.setup_bpjs_healthcare": "Setup BPJS Kesehatan",
  "bpjs.edit_setup_bpjs_healthcare": "Ubah Setup BPJS Kesehatan",
  "bpjs.business_entity_code": "Kode Badan Usaha",
  "bpjs.bpjs_dues_multiplier": "Pengali Iuran BPJS",
  "bpjs.percentage_contributions_borne_by_the_company":
    "Persentase Iuran Ditanggung Perusahaan",
  "bpjs.date_started": "Berlaku Mulai",
  "bpjs.please_input_business_entity_code":
    "Silakan masukkan kode badan usaha.",
  "bpjs.please_select_bpjs_dues_multiplier":
    "Silakan pilih pengali iuran BPJS.",
  "bpjs.please_input_percentage_contributions_borne_by_the_company":
    "Silakan masukan persentase iuran ditanggung perusahaan.",
  "bpjs.please_input_date_started": "Silakan masukkan bulan tahun mulai.",
  "bpjs.max_100": "Maks 100",
  "bpjs.min_0": "Min 0",
  "bpjs.percentage": "Persentase",
  "bpjs.percentage_personnel_dependents": "Persentase Tanggungan Personalia",
  "bpjs.are_you_sure_to_non_activate_bpjs_healthcare":
    "Apakah anda yakin untuk non aktifkan BPJS kesehatan?",
  "bpjs.are_you_sure_to_non_activate_bpjs_employement":
    "Apakah anda yakin untuk non aktifkan BPJS ketenagakerjaan?",
  "bpjs.general_information": "Informasi Umum",
  "bpjs.old_age_protection": "Jaminan Hari Tua",
  "bpjs.pension_protection": "Jaminan Pensiun",
  "bpjs.company_registration_number": "Nomor Pendaftaran Perusahaan",
  "bpjs.please_input_company_registration_number":
    "Silakan masukkan nomor pendaftaran perusahaan",
  "bpjs.work_accident_insurance_risk_level_group":
    "Kelompok Tingkat Risiko Jaminan Kecelakaan Kerja (JKK)",
  "bpjs.death_benefit_percentage": "Persentase Iuran Jaminan Kematian (JKM)",
  "bpjs.please_select": "Silakan pilih.",
  "bpjs.employee_use_old_age_protection":
    "Karyawan Memakai Jaminan Hari Tua (JHT)",
  "bpjs.old_age_protection_company_responsibilities":
    "Jaminan Hari Tua (JHT) Ditanggung Perusahaan",
  "bpjs.old_age_protection_employee_responsibilities":
    "Jaminan Hari Tua (JHT) Ditanggung Karyawan",
  "bpjs.jht_company_responsibilities_calculation_pph21":
    "JHT Ditanggung perusahaan dihitung PPh 21?",
  "bpjs.does_the_company_use_pension_insurance":
    "Apakah Perusahaan Memakai Jaminan Pensiun?",
  "bpjs.does_the_company_use_old_age_protection":
    "Apakah Perusahaan Memakai Jaminan Hari Tua?",
  "bpjs.employees_who_are_foreign_nationals_are_counted_as_pension_insurance":
    "Karyawan Berkewarganegaraan Asing Dihitung Jaminan Pensiun?",
  "bpjs.employees_with_age_more_than_57_years_are_counted_as_pension_insurance":
    "Karyawan Dengan Usia > 57 Tahun Dihitung Jaminan Pensiun?",
  "bpjs.company_responsibilities_pension_insurance_is_calculated_pph_21":
    "Jaminan Pensiun Ditanggung Perusahaan Dihitung PPh 21?",
  "bpjs.employee_use_pension_insurance": "Karyawan Memakai Jaminan Pensiun",
  "bpjs.company_responsibilities_pension_insurance":
    "Jaminan Pensiun Ditanggung Perusahaan",
  "bpjs.employee_responsibilities_pension_insurance":
    "Jaminan Pensiun Ditanggung Karyawan",
  "bpjs.please_select_company_responsibilities_pension_insurance":
    "Silakan pilih jaminan pensiun ditanggung perusahaan.",
  "bpjs.max_value_pension_insurance_multiplier":
    "Nilai Maksimal Pengali Jaminan Pensiun",
  "bpjs.max_value_bpjs_healthcare_multiplier":
    "Nilai Maksimal Pengali BPJS Kesehatan",
  "bpjs.description_jht_company_responsibilities":
    "5,7 dikurangi JHT ditanggung perusahaan",
  "bpjs.desctiption_jp_company_responsibilities":
    "3 dikurangi JP ditanggung perusahaan",
  "bpjs.the_number_required_by_the_company_to_register_its_employees_in_the_bpjs_program":
    "Nomor yang dibutuhkan oleh perusahaan untuk mendaftarkan karyawannya pada program BPJS",
  "bpjs.the_basis_of_the_bpjs_calculation_multiplier_is_the_employees_wages_namely_the_basic_salary_and_fixed_allowances":
    "Basis pengali perhitungan BPJS adalah upah karyawan, yaitu gaji pokok dan tunjangan tetap",
  "bpjs.the_higher_the_level_of_risk_in_work_the_greater_the_jkk_contribution_that_must_be_paid_every_month":
    "Semakin tinggi tingkat risiko dalam pekerjaan, maka semakin besar iuran JKK yang harus dibayar setiap bulan",
  "bpjs.jkm_contributions_are_fully_borne_by_the_company_these_contributions_provide_cash_benefits_to_the_heirs_when_the_participant_dies":
    "Iuran JKM ditanggung sepenuhnya oleh perusahaan, iuran ini  memberikan manfaat uang tunai kepada ahli waris ketika peserta meninggal dunia",
  "bpjs.percentage_of_old_age_security_contributions_borne_by_the_company":
    "Persentase besaran iuran Jaminan Hari Tua (JHT) yang ditanggung oleh perusahaan",
  "bpjs.jht_is_calculated_with_pph_21_then": "JHT dihitung dengan PPH 21 maka?",
  "bpjs.the_percentage_of_jht_contributions_by_employees_responsibilities":
    "Besaran persentase iuran JHT yang ditanggung oleh karyawan",
  "bpjs.the_amount_of_the_old_age_protection_guarantee_contribution_for_wage_recipients_income":
    "Besaran iuran Jaminan Hari Tua (JHT) bagi peserta penerima upah adalah sebesar 5.7% dari penghasilan",
  "bpjs.the_amount_of_the_pension_guarantee_jp_contribution_for_wage_recipients_income":
    "Besaran iuran Jaminan Pensiun (JP) bagi peserta penerima upah adalah sebesar 3% dari penghasilan",
  "bpjs.employees_with_foreign_nationality_are_counted_or_not_counted_for_their_pension_insurance":
    "Karyawan dengan kewarganegaraan asing dihitung atau tidak dihitung jaminan pensiunnya",
  "bpjs.employees_who_are_more_than_57_years_old_are_counted_or_not_their_pension_benefits_are_calculated":
    "Karyawan dengan usia lebih dari 57 tahun dihitung atau tidak dhitung jaminan pensiunnya",
  "bpjs.jp_is_calculated_with_pph_21_then": "JP dihitung dengan PPH 21 maka?",
  "bpjs.the_percentage_of_the_pension_insurance_contribution_borne_by_the_company":
    "Besaran persentase iuran jaminan pensiun yang ditanggung oleh perusahaan",
  "bpjs.the_percentage_of_pension_insurance_contributions_borne_by_employees":
    "Besaran persentase iuran jaminan pensiun yang ditanggung oleh karyawan",
  "bpjs.code_used_to_register_several_employees_in_the_bpjs_health_program":
    "Kode yang digunakan untuk mendaftarkan beberapa karyawan pada program BPJS Kesehatan",
  "bpjs.the_percentage_of_contributions_borne_by_the_company":
    "Besaran persentase iuran yang ditanggung oleh perusahaan",
  "bpjs.calculation_of_bpjs_contributions_using_basic_salary_or_basic_salary_fixed_allowance":
    "Perhitungan iuran BPJS apakah menggunakan gaji pokok atau gaji pokok + tunjangan tetap?",
  "bpjs.is_the_jht_contribution_borne_by_the_company_counted_as_taxable_income":
    "Apakah iuran JHT ditanggung perusahaan dihitung sebagai penghasilan kena pajak?",
  "bpjs.what_percentage_of_jht_contributions_are_borne_by_employees":
    "Berapa persen iuran JHT yang ditanggung oleh karyawan?",
  "bpjs.do_employees_with_foreign_citizenship_count_towards_their_retirement_benefits_or_not":
    "Apakah karyawan dengan kewarganegaraan asing dihitung atau tidak dihitung jaminan pensiunnya?",
  "bpjs.are_employees_over_the_age_of_57_years_counted_or_not_counted_for_their_retirement_benefits":
    "Apakah karyawan dengan usia lebih dari 57 tahun dihitung atau tidak dhitung jaminan pensiunnya?",
  "bpjs.is_the_pension_security_contribution_borne_by_the_company_counted_as_taxable_income":
    "Apakah iuran Jaminan Pensiun ditanggung perusahaan dihitung sebagai penghasilan kena pajak?",
  "bpjs.what_percentage_of_the_pension_guarantee_contribution_is_borne_by_the_company":
    "Berapa persen iuran Jaminan Pensiun yang ditanggung oleh perusahaan?",
  "bpjs.what_percentage_of_the_pension_security_contribution_is_borne_by_the_employee":
    "Berapa persen iuran Jaminan Pensiun yang ditanggung oleh karyawan?",
  "bpjs.are_bpjs_contributions_calculated_based_on_basic_salary_or_basic_salary_plus_fixed_allowance":
    "Apakah iuran bpjs dihitung berdasarkan gaji pokok atau gaji pokok + tunjangan tetap?",
  "bpjs.what_percentage_of_contributions_is_borne_by_the_company":
    "Berapa persen iuran yang ditanggung oleh perusahaan?",
  "bpjs.please_input_bpjs_social_security_effective_date":
    "Silakan masukkan tanggal efektif BPJS Ketenagakerjaan",
  "bpjs.please_input_bpjs_healthcare_effective_date":
    "Silakan masukkan tanggal efektif BPJS Kesehatan",
  "bpjs.the_start_date_of_calculating_bpjs_membership_in_the_company_the_company_will_calculate_the_discount_based_on_that_date":
    "Tanggal mulai dihitungnya kepesertaan BPJS di perusahaan, perusahaan akan menghitung potongan berdasarkan tanggal tersebut",
  "bpjs.when_did_this_bpjs_regulation_come_into_effect_in_your_company":
    "Kapan pengaturan BPJS ini mulai diberlakukan di perusahaan Anda?",
  "bpjs.setting_bpjs": "Pengaturan BPJS",
  "bpjs.office_domicile_as_the_basis_for_calculating_ump_bpjs":
    "Domisili kantor sebagai dasar perhitungan UMP BPJS",
  "bpjs.please_select_domicile": "Silakan pilih domisili",
  "bpjs.the_applicable_provincial_minimum_wage_in_your_company":
    "Upah Minimum Provinsi (UMP) yang berlaku di perusahaan anda",
  "bpjs.history_bpjs_of_employement": "Histori BPJS Ketenagakerjaan",
  "bpjs.history_bpjs_healthcare": "Histori BPJS Kesehatan",
  "bpjs.status_applied": "Status Berlaku (Ya/Tidak)",
  "bpjs.bpjs_employement_dues_multiplier": "Pengali Iuran BPJS Ketenagakerjaan",
  "bpjs.bpjs_healthcare_dues_multiplier": "Pengali Iuran BPJS Kesehatan",
  "bpjs.calculation_of_bpjs_employement_contributions_using_basic_salary_or_basic_salary_fixed_allowance":
    "Perhitungan iuran BPJS Ketenagakerjaan apakah menggunakan gaji pokok atau gaji pokok + tunjangan tetap?",
  "bpjs.calculation_of_bpjs_healthcare_contributions_using_basic_salary_or_basic_salary_fixed_allowance":
    "Perhitungan iuran BPJS Kesehatan apakah menggunakan gaji pokok atau gaji pokok + tunjangan tetap?",
  "bpjs.bpjs_employement_multiplier": "Pengali BPJS Ketenagakerjaan",
  "bpjs.bpjs_healthcare_multiplier": "Pengali BPJS Kesehatan",
  "bpjs.type_of_overtime_pay_calculation": "Tipe Perhitungan Uang Lembur",
  "bpjs.total_calculation_of_overtime_pay_per_hour":
    "Jumlah Perhitungan Uang Lembur per Jam",
  "bpjs.bpjs_multiplier_per_employee": "Pengali BPJS per Karyawan",
  "bpjs.bpjs_multiplier_uses_ump_per_employee":
    "Pengali BPJS Menggunakan Upah Minimum Provinsi (UMP) per Karyawan",
  "bpjs.another_multiplier_base": "Basis Pengali Lainnya",
  "bpjs.setting_bpjs_multiplier_employee_help_text_1":
    "Jika UMP karyawan tidak di set ( atau karyawan tidak di tambahkan di sini), defaultnya akan memakai UMP Jakarta",
  "bpjs.setting_bpjs_multiplier_employee_help_text_2":
    "Jika pengali lainnya tidak di set (atau karyawan tidak di tambahkan di sini), akan memakai pengali default",
  "bpjs.bpjs_healthcare_is_covered_by_the_company":
    "BPJS Kesehatan Ditanggung Perusahaan",
  "bpjs.import_bpjs_multiplier_per_employee":
    "Import Pengali BPJS per Karyawan",
  "bpjs.follow_these_steps_to_import_bpjs_multiplier_per_employee":
    "Ikuti langkah berikut untuk mengimport pengali BPJS per karyawan",
  "bpjs.1_download_file_template_bpjs_multiplier_per_employee":
    "1. Download file template pengali BPJS per karyawan",
  "bpjs.start_by_downloading_file_template_bpjs_multiplier_per_employee":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari pengali BPJS per karyawan.",
  "bpjs.2_copy_bpjs_multiplier_per_employee_data_into_the_template":
    "2. Salin data pengali BPJS per karyawan ke dalam template",
  "bpjs.how_to_custom_file_template_bpjs_multiplier_per_employee":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data pengali BPJS per karyawan ke dalam file template dari Gajihub. Pastikan data pengali BPJS per karyawan sesuai dengan kolom header yang ada di template.",
  "bpjs.important_import_info_bpjs_multiplier_per_employee":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data pengali BPJS per karyawan dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.<br/>",
  "bpjs.bpjs_multiplier_per_employee_successfully_imported":
    "{{rowCount}} pengali BPJS per karyawan berhasil di import.",
  "bpjs.go_to_the_bpjs_settings_page": "Ke halaman pengaturan BPJS",
  "bpjs.other_multipliers_bpjs_employment":
    "Pengali Lainnya BPJS Ketenagakerjaan",
  "bpjs.other_multipliers_bpjs_healthcare": "Pengali Lainnya BPJS Kesehatan",

  "unpaidLeave.title": "Unpaid Leave",
  "unpaidLeave.how_is_the_unpaid_leave_discount_calculated":
    "Bagaimana perhitungan potongan unpaid leave?",
  "unpaidLeave.unpaid_leave_discount_calculated":
    "Perhitungan potongan unpaid leave",
  "unpaidLeave.how_to_calculate_the day_divisor_of_unpaid_leave_in_your_company":
    "Bagaimana perhitungan hari pembagi dari unpaid leave di perusahaan anda?",
  "unpaidLeave.number_of_rupiah_pieces_per_day":
    "Jumlah rupiah potongan per hari",
  "unpaidLeave.cannot_less_than_1": "Tidak boleh kurang dari 1.",
  "unpaidLeave.cannot_more_than_31": "Tidak boleh lebih dari 31.",

  "grading.title": "Grading",
  "grading.add_grading": "Tambah Grading",
  "grading.add_system_grading": "Tambah Sistem Grading",
  "grading.edit_system_grading": "Ubah Sistem Grading",
  "grading.method": "Metode",
  "grading.base_salary": "Basis Upah",
  "grading.effective_date": "Tanggal Efektif",
  "grading.system_grading_name": "Nama Sistem Grading",
  "grading.please_input_system_grading_name":
    "Silakan masukkan nama sistem grading.",
  "grading.started_date": "Berlaku Mulai",
  "grading.please_input_date": "Silakan masukkan tanggal.",
  "grading.method_grading": "Metode Grading",
  "grading.please_select_method_grading": "Silakan pilih metode grading.",
  "grading.please_select_base_salary": "Silakan pilih basis upah.",
  "grading.grade_number": "Jumlah Grade",
  "grading.please_select_grade_number": "Silakan pilih jumlah grade.",
  "grading.name": "Nama",
  "grading.save_and_activate": "Simpan & Aktifkan",
  "grading.grade": "Grade",
  "grading.job_group": "Grup Jabatan",
  "grading.range": "Range",
  "grading.minimum_salary": "Upah Minimum",
  "grading.middle_value": "Nilai Tengah",
  "grading.maximum_salary": "Upah Maksimum",
  "grading.please_select": "Silakan pilih.",
  "grading.please_input": "Silakan masukkan.",
  "grading.effective_starting_date": "Berlaku efektif mulai tanggal",
  "grading.are_you_sure_to_delete_this_grading":
    "Apakah anda yakin untuk menghapus grading ini?",

  "company.company": "Perusahaan",
  "company.company_info": "Info Perusahaan",
  "company.organizational_structure": "Struktur Organisasi",
  "company.job_title": "Pangkat",
  "company.add_job_title": "Tambah Pangkat",
  "company.edit_job_title": "Ubah Pangkat",
  "company.position": "Jabatan",
  "company.delete_job_title": "Hapus Pangkat",
  "company.select_job_title": "Pilih Pangkat",
  "company.add_subordinates": "Tambah Bawahan",
  "company.add_organization": "Tambah Organisasi",
  "company.add_sub_organization": "Tambah Sub Organisasi",
  "company.edit_organization": "Ubah Organisasi",
  "company.please_input_position_name": "Silakan masukkan nama jabatan.",
  "company.please_input_job_level_name": "Silakan masukkan nama pangkat.",
  "company.please_input_organization_name": "Silakan masukkan nama organisasi.",
  "company.are_you_sure_to_delete": "Apakah anda yakin untuk menghapus?",
  "company.edit_position": "Ubah Jabatan",
  "company.add_position": "Tambah Jabatan",
  "company.parent": "Parent",
  "company.select_parent": "Pilih Parent",
  "company.please_select_parent": "Silakan pilih parent.",
  "company.success_message_title": "Perusahaanmu berhasil dibuat.",
  "company.success_message_subtitle":
    "Silakan klik tombol di bawah ini untuk melanjutkan.",
  "company.title": "Judul",
  "company.company_policy": "Peraturan Perusahaan",
  "company.add_policy": "Tambah Peraturan",
  "company.edit_policy": "Ubah Peraturan",
  "company.contents_of_rules": "Isi Peraturan",
  "company.please_input_title": "Silakan masukkan judul.",
  "company.please_input_contents_of_rules": "Silakan masukkan isi peraturan.",
  "company.deletable_job_title": "Pangkat yang bisa dihapus",
  "company.not_yet_used_as_a_career_employee":
    "Belum digunakan sebagai karir karyawan.",
  "company.not_yet_used_as_a_approval_rule":
    "Belum digunakan sebagai aturan approval.",
  "company.never_deleted": "Belum pernah dihapus.",
  "company.cannot_be_deleted_if_it_is_still_registered_in_your_employees_career":
    "{{type}} tidak dapat dihapus jika masih terdaftar dalam karir karyawan Anda",

  "property.property": "Properti",
  "property.company": "Perusahaan",
  "property.property_details": "Detil Properti",
  "property.company_details": "Data Perusahaan",
  "property.tax_details": "Pengaturan Pajak",
  "property.system_settings": "Pengaturan Sistem",
  "property.property_logo": "Logo Properti",
  "property.company_logo": "Logo Perusahaan",
  "property.error_cannot_update_tax_details": "tipe Gagal",
  "property.property_details_successfully_updated":
    "Detil properti berhasil diperbarui.",
  "property.company_details_successfully_updated": "tipe Perusahaan",
  "property.error_cannot_update_property_details":
    "Gagal memperbarui detail properti.",
  "property.error_cannot_update_company_details":
    "Tidak dapat mengubah company details.",
  "property.system_details_successfully_updated":
    "Detil sistem berhasil diperbarui.",
  "property.error_cannot_update_system_details": "tipe Gagal",
  "property.tax_details_successfully_updated":
    "Rincian pajak berhasil diperbarui.",
  "property.property_name": "Nama",
  "property.company_name": "Nama",
  "property.please_input_the_property_name": "Silakan masukkan nama properti.",
  "property.please_input_the_company_name": "Silakan masukkan company name.",
  "property.max_100_characters": "Maks 100 karakter.",
  "property.only_alphanumeric_allowed": "Hanya alfanumerik yang diizinkan.",
  "property.email_address": "Alamat email",
  "property.invalid_email_address_format": "Format alamat email tidak valid.",
  "property.phone_number": "Nomor telepon",
  "property.max_20_characters": "Nomor telepon",
  "property.only_number_allowed": "Hanya angka yang diizinkan.",
  "property.address": "Alamat Penagihan",
  "property.address_delivery": "Alamat Pengiriman",
  "property.country": "Negara",
  "property.tax_number": "Nomor NPWP Perusahaan",
  "property.please_select_the_country": "Silakan pilih negara.",
  "property.select_country": "Pilih negara",
  "property.arrival_time": "Jam kedatangan",
  "property.departure_time": "Jam keberangkatan",
  "property.image_file_size_maximal": "Ukuran file gambar maksimal",
  "property.language": "Bahasa",
  "property.select_time_region": "Pilih zona waktu",
  "property.timezone": "Zona waktu",
  "property.please_select_the_language": "Silakan pilih bahasanya.",
  "property.select_timezone": "Pilih zona waktu",
  "property.please_select-the_timezone": "Silakan pilih zona waktu.",
  "property.date_format": "Format tanggal",
  "property.please_select_the_date_format": "Silakan pilih format tanggal.",
  "property.select_date_format": "Pilih format tanggal",
  "property.time_format": "Format waktu",
  "property.please_select_the_time_format": "Silakan pilih format waktu.",
  "property.select_time_format": "Pilih format waktu",
  "property.Currency_Format": "Format mata uang",
  "property.please_select_the_currency_format":
    "Silakan pilih format mata uang.",
  "property.select_currency_format": "Pilih format mata uang ",
  "property.country_tax_identifier_name": "Nama Pengenal Pajak Negara",
  "property.company_tax_number": "Nomor Pajak Perusahaan",
  "property.tax_name": "Nama Pajak",
  "property.tax_rate": "Persentase pajak",
  "property.image_file_dimension_maximal": "Dimensi file gambar maksimal",
  "property.click_or_drag_file_to_this_area_to_upload":
    "Klik atau seret file ke area ini untuk mengunggah",
  "property.preview": "Pratinjau",
  "property.file_uploaded_successfully": "berkas berhasil diunggah.",
  "property.file_upload_failed": "gagal unggah file.",
  "property.you_can_only_upload_jpg_or_png_file_":
    "Anda hanya dapat mengunggah file JPG / PNG.",
  "property.image_must_smaller_than": "Gambar harus lebih kecil dari",
  "property.image_width_must_smaller_than":
    "Lebar gambar harus lebih kecil dari",
  "property.image_height_must_smaller_than":
    "Tinggi gambar harus lebih kecil dari ",
  "property.currency": "Mata uang",
  "property.please_select_the_currency": "Silakan pilih mata uang.",
  "property.select_currency": "Pilih Mata Dana",
  "property.number_after_comma": "Jumlah angka di belakang koma",
  "property.please_select_the_number_after_comma":
    "Silakan pilih jumlah angka di belakang koma.",
  "property.select_number_after_comma": "Pilih jumlah angka di belakang koma",
  "property.upload_failed": "Gagal upload",
  "property.start_using_gajihub": "Mulai Menggunakan Gajihub",
  "property.theme_colors": "Warna Tema",
  "property.enable_theme_color_settings": "Aktifkan Pengaturan Warna Tema",
  "property.reset_theme_color_to_default": "Reset warna default",

  "approval.approval_title": "Approval",
  "approval.edit_approval_title": "Ubah Approval",
  "approval.add_rule_approval": "Tambah Aturan Approval",
  "approval.rule_name": "Nama Aturan",
  "approval.applies_at": "Berlaku Pada",
  "approval.applies_to": "Berlaku Untuk",
  "approval.approval_flow": "Alur Approval",
  "approval.are_you_sure_to_delete_rule_approval":
    "Apakah anda yakin untuk menghapus aturan approval?",
  "approval.please_input_rule_name": "Silakan masukkan nama aturan.",
  "approval.annual_leave": "Cuti Tahunan",
  "approval.special_leave": "Cuti Khusus",
  "approval.cash_receipt": "Kasbon",
  "approval.reimbursement": "Reimbursement",
  "approval.sick": "Sakit",
  "approval.permission": "Izin",
  "approval.unpaid_leave": "Unpaid Leave",
  "approval.overtime": "Lembur",
  "approval.personal_data_change": "Perubahan Data Pribadi",
  "approval.all_employee": "Semua Karyawan",
  "approval.job_position": "Jabatan",
  "approval.job_level": "Pangkat",
  "approval.structure": "Struktur",
  "approval.employee": "Karyawan",
  "approval.select_category": "Pilih Kategori",
  "approval.select": "Pilih",
  "approval.please_select": "Silakan pilih.",
  "approval.please_add": "Silakan tambahkan.",
  "approval.waiting_approval": "Menunggu Persetujuan",
  "approval.approved": "Disetujui",
  "approval.approve": "Setujui",
  "approval.rejected": "Ditolak",
  "approval.reject": "Tolak",
  "approval.type": "Tipe",
  "approval.approval_progress": "Progress Persetujuan",
  "approval.submission_date": "Tanggal Pengajuan",
  "approval.approval_date": "Tanggal Persetujuan",
  "approval.rejection_date": "Tanggal Penolakan",
  "approval.reason_for_approval": "Alasan Persetujuan",
  "approval.reason_for_rejection": "Alasan Penolakan",
  "approval.are_you_sure_approve_to_these_requests":
    "Apakah yakin menyetujui {{count}} request ini?",
  "approval.are_you_sure_reject_to_these_requests":
    "Apakah yakin menolak {{count}} request ini?",
  "approval.confirm_approval": "Konfirmasi Persetujuan",
  "approval.confirm_rejection": "Konfirmasi Penolakan",
  "approval.description": "Keterangan",
  "approval.manage_approval_policy": "Atur Kebijakan Approval",
  "approval.detail_approval_rule": "Detil Aturan Approval",
  "approval.detail": "Detil",
  "approval.approval_of_permission_or_leave_by_superior":
    "Approval izin / cuti oleh atasan",
  "approval.days_approval_by_superior": "hari approval oleh atasan",

  "calendar.title": "Kalender",
  "calendar.add_event": "Tambah Even",
  "calendar.edit_event": "Ubah Even",
  "calendar.delete_calendar": "Hapus Kalender",
  "calendar.event_name": "Nama Even",
  "calendar.date": "Tanggal",
  "calendar.type": "Tipe",
  "calendar.please_input_event_name": "Silakan masukkan nama even.",
  "calendar.please_input_date": "Silakan masukkan tanggal.",
  "calendar.please_select_type": "Silakan pilih tipe.",
  "calendar.select_calendar": "Pilih Kalender",
  "calendar.are_you_sure_to_delete_event_calendar":
    "Apakah anda yakin untuk menghapus event kalender?",
  "calendar.note_description":
    "Catatkan kalender perusahaan Anda, termasuk cuti bersama, hari libur nasional, maupun event internal perusahaan. Libur dan cuti bersama pada kalender ini juga akan mempengaruhi pada perhitungan presensi atau kehadiran.",

  "profile.profile": "Profil",
  "profile.image_profile": "Gambar Profil",
  "profile.my_profile": "Profilku",
  "profile.old_password": "Password lama",
  "profile.please_input_the_old_password": "Silakan masukkan password lama.",
  "profile.new_password": "Password baru",
  "profile.please_input_the_new_password": "Silakan masukkan password baru.",
  "profile.max_100_characters": "Maks 100 karakter.",
  "profile.confirm_new_password": "Ulangi password baru",
  "profile.please_input_the_confirm_new_password":
    "Silakan masukkan password baru yang dikonfirmasi! ",
  "profile.email_or_username": "Email",
  "profile.please_input_the_email/username": "Silakan masukkan email.",
  "profile.the_input_is_not_valid-e-mail": "Silakan masukkan email yang valid",
  "profile.full_name": "Nama lengkap",
  "profile.please_input_the_full_name": "Silakan masukkan nama lengkap.",
  "profile.0nly_alphanumeric_allowed": "Hanya alfanumerik yang diizinkan.",
  "profile.phone_number": "Nomor telepon",
  "profile.please_input_the_phone_number": "Silakan masukkan nomor telepon.",
  "profile.max_20_characters": "Maks 20 karakter.",
  "profile.only_number_allowed": "Hanya angka yang diizinkan.",
  "profile.user_profile_successfully_updated": "Profil berhasil diperbarui.",
  "profile.error_cannot_update_user_profile": "Gagal memperbarui profil.",
  "profile.user_password_successfully_updated": "Password berhasil diperbarui.",
  "profile.error_cannot_update_user_password": "Gagal memperbarui password.",
  "profile.change_password": "Ganti password",
  "profile.two_passwords_that_you_enter_is_inconsistent_":
    "Dua password yang Anda masukkan tidak konsisten.",
  "profile.password_atleast_8_characters_atleast_1_uppercase_and_atleast_1_digit":
    "Password setidaknya 8 karakter, setidaknya 1 huruf besar dan setidaknya 1 digit.",
  "profile.password_atleast_6_characters": "Password setidaknya 6 karakter.",
  "profile.logout_from_all_device": "Logout dari semua perangkat",
  "profile.cannot_logout_from_all_device":
    "Tidak dapat logout dari semua perangkat",

  "roles.add_role": "Tambah Peran",
  "roles.delete_role": "Hapus Peran",
  "roles.select_role_to_delete": "Pilih Peran untuk dihapus",
  "roles.please_select_a_role": "Silakan pilih peran.",
  "roles.select_role": "Pilih peran",
  "roles.edit_role": "Ubah Peran",
  "roles.role": "Peran",
  "roles.roles": "Peran",
  "roles.please_input_the_role_name": "Silakan masukkan nama peran.",
  "roles.max_100_characters": "Maks 100 karakter.",
  "roles.superadmin": "Superadmin",
  "roles.error_cannot_get_roles": "Tidak bisa mendapatkan Peran.",
  "roles.successfully_added": "berhasil ditambahkan.",
  "roles.error_cannot_add": "Tidak bisa menambahkan",
  "roles.role_successfully_updated": "Peran berhasil diperbarui.",
  "roles.error_cannot_update_role": "Gagal memperbarui peran.",
  "roles.successfully_deleted": "berhasil dihapus.",
  "roles.error_cannot_delete": "Gagal menghapus",
  "roles.role_moved_successfully": "Peran berhasil dipindahkan.",
  "roles.error_cannot_move_the_channel": "Gagal memindahkan channel.",
  "roles.search_by_role_name": "Cari berdasarkan nama peran",
  "roles.name": "Nama peran",
  "roles.invoices_payment": "Pembayaran",
  "roles.orders_payment": "Pembayaran",
  "roles.purchase_invoices_payment": "Pembayaran",
  "roles.purchase_orders_payment": "Pembayaran",
  "roles.product_show_purchase_price": "Tampilkan Harga Beli",
  "roles.product_show_sale_price": "Tampilkan Harga Jual",
  "roles.product_show_hpp": "Tampilkan HPP",
  "roles.inventory_show_hpp": "Tampilkan Harga",
  "roles.reports_finance": "Finansial",
  "roles.reports_accounting": "Akuntansi",
  "roles.reports_sales": "Penjualan",
  "roles.reports_purchases": "Pembelian",
  "roles.reports_expenses": "Biaya",
  "roles.reports_taxes": "Pajak",
  "roles.reports_inventory": "Inventori",
  "roles.reports_fixed_assets": "Aset Tetap",
  "roles.reports_budget": "Anggaran",
  "roles.reports_consolidation": "Konsolidasi",
  "roles.apikeys": "API Keys",
  "roles.sales_other_user_access": "Akses Milik User Lain",
  "roles.purchase_other_user_access": "Akses Milik User Lain",
  "roles.expenses_other_user_access": "Akses Milik User Lain",
  "roles.product_show_manufacture_raw": "Tampilkan Bahan Baku Manufaktur",
  "roles.are_you_sure_to_delete_role":
    "Apakah anda yakin untuk menghapus peran?",
  "roles.employee": "Karyawan",
  "roles.admin": "Admin",

  "companyAccount.title": "Rekening Perusahaan",
  "companyAccount.are_you_sure_to_delete_company_account":
    "Apakah anda yakin untuk menghapus rekening perusahaan?",
  "companyAccount.add_account": "Tambah Rekening",
  "companyAccount.add_company_account": "Tambah rekening perusahaan",
  "companyAccount.edit_company_account": "Ubah rekening perusahaan",
  "companyAccount.bank_name": "Nama Bank",
  "companyAccount.bank_account_number": "Nomor Rekening Bank",
  "companyAccount.account_holders_name": "Nama Pemegang Rekening",
  "companyAccount.bank_branch_office": "Kantor Cabang Bank",
  "companyAccount.please_select_bank_name": "Silakan pilih nama bank.",
  "companyAccount.please_input_bank_account_number":
    "Silakan masukkan nomor rekening bank.",
  "companyAccount.please_input_account_holders_name":
    "Silakan masukkan nama pemegang rekening.",
  "companyAccount.please_input_bank_branch_office":
    "Silakan masukkan kantor cabang bank.",
  "companyAccount.only_number_allowed": "Hanya angka yang diizinkan.",
  "companyAccount.note_description":
    "Silakan memasukkan nomor rekening bank yang digunakan perusahaan Anda untuk membayar gaji ke karyawan. Disarankan menggunakan satu rekening untuk melakukan penggajian ke seluruh karyawan.<br/><br/>Saat ini bank yang didukung oleh GajiHub adalah BCA, Bank Mandiri, Bank CIMB, BRI, BNI, Permata, Bank National Nobu, DBS dan Bank BTPN.<br/><br/>Khusus Bank Mandiri, BRI, DBS, Bank BTPN, dan Bank CIMB dapat melakukan payroll ke rekening bank yang berbeda. Selain 5 bank tersebut, hanya dapat melakukan pembayaran payroll ke sesama rekening bank yang sama.",

  "report.report_title": "Laporan",
  "report.payroll_summary": "Ringkasan Gaji",
  "report.payroll_summary_per_organization": "Ringkasan Gaji per Organisasi",
  "report.payroll_summary_per_component": "Ringkasan Gaji per Komponen",
  "report.payroll_payment": "Pembayaran Gaji",
  "report.permanent_employee_tax_data_reference":
    "Referensi Data Pajak Pegawai Tetap",
  "report.proof_of_withholding_annual_permanent_employee_tax":
    "Bukti Potong Pajak Pegawai Tetap Tahunan",
  "report.proof_of_withholding_annual_freelance_employee_tax":
    "Bukti Potong Pajak Pegawai Lepas Tahunan",
  "report.permanent_employee_monthly_tax": "Pajak Bulanan Pegawai Tetap",

  "reportTax.report_espt_pph21": "Laporan e-SPT PPH 21/26",
  "reportTax.please_select": "Silakan pilih",
  "reportTax.tax_period": "Masa Pajak",
  "reportTax.tax_year": "Tahun Pajak",
  "reportTax.correction": "Pembetulan",
  "reportTax.npwp": "NPWP",
  "reportTax.tax_code": "Kode Pajak",
  "reportTax.gross_amount": "Jumlah Bruto",
  "reportTax.pph_amount": "Jumlah PPH",
  "reportTax.country_code": "Kode Negara",
  "reportTax.deduction_proof_number": "Nomor Bukti Potong",
  "reportTax.nik": "NIK",
  "reportTax.address": "Alamat",
  "reportTax.wp_foreign": "WP Luar Negeri",
  "reportTax.dpp_amount": "Jumlah DPP",
  "reportTax.without_npwp": "Tanpa NPWP",
  "reportTax.rate": "Tarif",
  "reportTax.withholder_npwp": "NPWP Pemotong",
  "reportTax.withholder_name": "Nama Pemotong",
  "reportTax.deduction_proof_date": "Tanggal Bukti Potong",
  "reportTax.this_process_requires_a_stable_internet_connection":
    "Proses ini membutuhkan koneksi internet yang stabil",
  "reportTax.we_re_preparing_your_file_recap":
    "Kami sedang mempersiapkan rekap berkas {{type}} Anda....",
  "reportTax.ptkp_status": "Status PTKP",
  "reportTax.job_position_name": "Nama Jabatan",
  "reportTax.initial_acquisition_period": "Masa Perolehan Awal",
  "reportTax.final_acquisition_period": "Masa Perolehan Akhir",
  "reportTax.total_dependents": "Jumlah Tanggungan",
  "reportTax.move_status": "Status Pindah",
  "reportTax.amount": "Jumlah",
  "reportTax.download_form_a1": "Download Form A1",

  "reportPayroll.salary_summary": "Ringkasan Gaji",
  "reportPayroll.detail_salary_summary": "Detil Ringkasan Gaji",
  "reportPayroll.basic_salary": "Gaji Pokok",
  "reportPayroll.total_deduction": "Total Potongan",
  "reportPayroll.total_allowance": "Total Tunjangan",
  "reportPayroll.take_home_pay": "Take Home Pay",
  "reportPayroll.period": "Periode",
  "reportPayroll.salary_payment_recap": "Rekap Pembayaran Gaji",
  "reportPayroll.salary_payment": "Pembayaran Gaji",
  "reportPayroll.slip_count": "Jumlah Slip",
  "reportPayroll.total": "Total",
  "reportPayroll.select_level": "Pilih Level",
  "reportPayroll.organization": "Organisasi",

  "reportBpjs.report_bpjs_employement": "Laporan BPJS Ketenagakerjaan",
  "reportBpjs.report_bpjs_healthcare": "Laporan BPJS Kesehatan",
  "reportBpjs.number_participant": "No. Peserta",
  "reportBpjs.income_amount": "Jumlah Penghasilan",
  "reportBpjs.multiplier_base": "Basis Pengali",
  "reportBpjs.jkk_dues": "Iuran JKK",
  "reportBpjs.jkm_dues": "Iuran JKM",
  "reportBpjs.jht_dues_paid_by_the_company": "Iuran JHT Dibayar Perusahaan",
  "reportBpjs.jht_dues_paid_by_the_personnel": "Iuran JHT Dibayar Personalia",
  "reportBpjs.jp_dues_paid_by_the_company": "Iuran JP Dibayar Perusahaan",
  "reportBpjs.jp_dues_paid_by_the_personnel": "Iuran JP Dibayar Personalia",
  "reportBpjs.bpjs_healthcare_dues_paid_by_the_company":
    "Iuran BPJS Kesehatan Dibayar Perusahaan",
  "reportBpjs.bpjs_healthcare_dues_paid_by_the_personnel":
    "Iuran BPJS Kesehatan Dibayar Personalia",
  "reportBpjs.percentage": "Persen",

  "settings.data_not_found": "Data tidak ditemukan",
  "settings.reset": "Reset",
  "settings.settings": "Pengaturan",
  "settings.company": "Data dan logo perusahaan",
  "settings.users": "Undang staff dan kolegamu menggunakan Gajihub",
  "settings.roles": "Atur hak akses pengguna",
  "settings.profile": "Atur profil dan ubah password",
  "settings.payroll": "Atur pembayaran karyawan",
  "settings.schedule": "Atur jadwal kerja karyawan",
  "settings.billing": "Upgrade dan perpanjangan paket",
  "settings.businessflow": "Atur alur bisnismu",
  "settings.setup": "Hapus data demo dan mulai dari awal",
  "settings.account_mapping": "Pengaturan integrasi software akuntansi",
  "settings.calendar": "Kalender libur dan even perusahaan",
  "settings.approval_rule": "Atur alur approval pengajuan",
  "settings.annual_leave": "Atur cuti tahunan karyawan",
  "settings.special_leave": "Atur cuti khusus karyawan",
  "settings.attendance_validation": "Atur validasi presensi karyawan",
  "settings.attendance_location": "Lokasi presensi karyawan",
  "settings.mobile_menu": "Pilih menu yang ditampilkan di aplikasi",
  "settings.setting_template":
    "Atur template notifikasi email dan aplikasi mobile",
  "settings.apps": "Koneksikan Apps mu dengan API Gajihub",
  "settings.setting_layout": "Atur tampilan slip gaji",
  "settings.setting_audit": "Pantau log perubahan data",
  "settings.cash_receipt": "Atur Kasbon Untuk Karyawan",
  "settings.reimbursement": "Atur Reimburse Untuk Karyawan",
  "settings.sick_leave": "Atur cuti sakit karyawan",
  "settings.location_tracking_rule": "Atur pelacakan lokasi karyawan",
  "settings.setting_overtime": "Atur lembur karyawan",
  "settings.setting_automatic_sequencing": "Atur penomoran otomatis",
  "settings.setting_fingerprint_connect": "Atur fingerprint connect",
  "settings.sso_setting": "Login Gajihub hanya dengan satu akun",
  "settings.setting_task": "Atur tugas karyawan",
  "settings.setting_payment_connect": "Terima pembayaran VA",

  "breadcrumb.finance": "Keuangan",
  "breadcrumb.dashboard": "Dashboard",
  "breadcrumb.presence": "Kehadiran",
  "breadcrumb.accountancy": "Akutansi",
  "breadcrumb.inventory": "Inventaris",
  "breadcrumb.settings": "Pengaturan",
  "breadcrumb.payroll": "Payroll",
  "breadcrumb.leave_and_permission": "Izin dan Cuti",
  "breadcrumb.annual_leave": "Cuti Tahunan",
  "breadcrumb.special_leave": "Cuti Khusus",
  "breadcrumb.work_schedule": "Jadwal Kerja",
  "breadcrumb.approval": "Approval",
  "breadcrumb.detail": "Detil",
  "breadcrumb.404": "404 Tidak ditemukan",

  "dashboard.hello_name": "Halo {{name}},",
  "dashboard.schedule_a_demo_and_consult":
    "Yuk jadwalkan demo dan konsultasi online secara gratis bersama tim hebat Gajihub.",
  "dashboard.demo_online_with_zoom":
    "Demo dilakukan online menggunakan Zoom secara 1 on 1 selama 45 menit.",
  "dashboard.age": "Usia",
  "dashboard.religion": "Agama",
  "dashboard.education": "Pendidikan",
  "dashboard.gender": "Jenis Kelamin",
  "dashboard.marital_status": "Status Perkawinan",
  "dashboard.number_of_employee_per_month": "Jumlah Karyawan per Bulan",
  "dashboard.number_of_employees_per_organization":
    "Jumlah Karyawan per Organisasi",
  "dashboard.new": "Baru",
  "dashboard.active": "Aktif",
  "dashboard.quit_work": "Berhenti Kerja",
  "dashboard.years_of_work": "Lama Kerja",
  "dashboard.total_salary_of_employee": "Total Gaji Karyawan",
  "dashboard.total_salary_this_month": "Total Gaji Bulan Ini",
  "dashboard.average_salary_this_month": "Rata - rata Gaji Bulan Ini",
  "dashboard.from_the_previous_month": "dari bulan sebelumnya",
  "dashboard.salary_per_organization": "Gaji Per Organisasi",
  "dashboard.amount": "Jumlah",
  "dashboard.total": "Total",
  "dashboard.interval": "Interval",
  "dashboard.employee_status": "Jumlah Karyawan Berdasarkan Status",
  "dashboard.total_personalia_based_on_salary":
    "Total Personalia Berdasarkan Gaji",
  "dashboard.custom": "Custom",
  "dashboard.total_salary": "Total Gaji {{daterange}}",
  "dashboard.average_salary": "Rata - rata Gaji {{daterange}}",
  "dashboard.from_the_previous": "dari {{daterange}} sebelumnya",
  "dashboard.total_salary_per_component": "Gaji per Komponen",
  "dashboard.level_organization": "Level Organisasi",
  "dashboard.number_of_attendance_status_employee": "Status Kehadiran Karyawan",
  "dashboard.attendance": "Kehadiran Karyawan",
  "dashboard.list_of_late_employees": "Terlambat",
  "dashboard.list_of_employees_leave": "Izin & Cuti",
  "dashboard.edit": "Ubah Dashboard",
  "dashboard.exit": "Keluar",
  "dashboard.show": "Tampilkan",
  "dashboard.hide": "Sembunyikan",
  "dashboard.employee_of_attendance_rating_statistics":
    "Statistik Kehadiran Karyawan",
  "dashboard.most_late_employees": "Karyawan Terbanyak Terlambat",
  "dashboard.most_attendance_without_late_employees":
    "Karyawan Terbanyak Masuk Tanpa Terlambat",
  "dashboard.most_attendance_with_late_employees":
    "Karyawan Terbanyak Masuk Termasuk Terlambat",
  "dashboard.most_leave_employees": "Karyawan Terbanyak Melakukan Cuti",
  "dashboard.type": "Tipe",
  "dashboard.number_of_days": "Jumlah Hari",
  "dashboard.employee_termination": "Masa Akhir Kerja Karyawan",

  "businessFlow.lang_date_currency": "Bahasa, Tanggal, & Mata Uang",
  "businessFlow.app_lang": "Bahasa aplikasi",
  "businessFlow.app_lang_desc":
    "Bahasa yang digunakan secara default untuk seluruh staff perusahaan Anda",
  "businessFlow.time_zone": "Zona waktu",
  "businessFlow.time_zone_desc": "Zona waktu lokasi perusahaan Anda",
  "businessFlow.date_format": "Format tanggal",
  "businessFlow.date_format_desc": "Tampilan tanggal di aplikasi Gajihub",
  "businessFlow.time_format": "Format waktu",
  "businessFlow.time_format_desc": "Tampilan jam dan menit di aplikasi Gajihub",
  "businessFlow.number_after_comma": "Jumlah angka di belakang koma",
  "businessFlow.number_after_comma_desc":
    "Berapa jumlah angka di belakang koma yang ditampilkan di aplikasi Gajihub?",
  "businessFlow.currency_format": "Format mata uang",
  "businessFlow.currency_format_desc": "Tampilan mata uang di aplikasi Gajihub",
  "businessFlow.activate_multi_currency": "Aktifkan fitur multi mata uang",
  "businessFlow.activate_multi_currency_desc":
    "Apakah perusahaan Anda menggunakan lebih dari satu mata uang?",
  "businessFlow.base_currency": "Mata uang dasar",
  "businessFlow.base_currency_desc":
    "Mata uang yang digunakan sebagai mata uang utama di perusahaan Anda",
  "businessFlow.show_currency_symbol": "Tampilkan simbol mata uang",
  "businessFlow.show_currency_symbol_desc":
    "Apakah simbol mata uang akan ditampilkan di tampilan antar muka?",

  "setup.you_are_using_a_demo_of_gajihub_at_the_moment":
    "Saat ini Anda sedang menggunakan demo gajihub.",
  "setup.slide_button_below_to_clear_the_test_data_and_start_setting_up_your_property":
    "Geser tombol di bawah untuk menghapus data demo dan mulai setup perusahaan Anda ",
  "setup.slide_the_button_below_to_clear_demo_data_all_data_will_be_deleted_and_cannot_be_recovered":
    "Geser tombol dibawah untuk menghapus data demo. Seluruh data akan dihapus dan tidak dapat dikembalikan.",
  "setup.are_you_sure_that_you_want_to_clear_demo_":
    "Anda yakin ingin menghapus demo?",
  "setup.this_will_remove_all_rooms_room_types_rates_and_bookings":
    "Ini akan menghapus semua data, dan tidak dapat dikembalikan.",
  "setup.horee": "Horee!",
  "setup.setup_complete":
    "Data demo telah dihapus dan setup selesai, sekarang kamu bisa mulai menggunakan Gajihub.",
  "setup.go_to_dashboard": "Pergi ke Dashboard",
  "setup.demo":
    "Data yang tampil saat ini adalah data dummy. Setelah Anda siap, {{setup}} untuk mengosongkan data.",
  "setup.demo_link": "klik disini",
  "setup.swipe_to_clear_all_data": "Geser untuk hapus semua data",
  "setup.demo_data_deleted_successfully": "Data demo berhasil dihapus",
  "setup.dummy_data_has_been_deleted_please_start_filling_in_your_employee_data":
    "Data dummy telah dihapus. Silakan mulai mengisi data karyawan anda.",
  "setup.deleting": "Sedang Menghapus",
  "setup.failed_to_delete_data": "Gagal Menghapus Data",

  "accountancy.hooray_the_gajiHub_account_has_been_successfully_connected_to_the_kledo_accounting_software":
    "Horee!, Akun GajiHub sudah berhasil dikoneksikan dengan Software Akuntansi Kledo.",
  "accountancy.go_to_kledo": "Pergi ke Kledo",
  "accountancy.gajihub_is_now_automatically_integrated_with_kledo_accounting_software_this_feature_is_available_for_free":
    "Gajihub kini terintegrasi secara otomatis dengan Software Akuntansi <strong>Kledo</strong>. Fitur ini tersedia <strong>gratis</strong> lho.",
  "accountancy.what_benefits_will_you_get_with_this_integration":
    "Apa saja keuntungan yang akan kamu dapatkan dengan integrasi ini ?",
  "accountancy.employee_payroll_is_automatically_recorded_in_kledo":
    "Penggajian karyawan otomatis tercatat di Kledo.",
  "accountancy.employee_payables_and_installment_payments_are_automatically_recorded_to_the_kledo_accounting_software":
    "Hutang piutang karyawan dan pembayaran cicilan otomatis tercatat ke software akuntansi Kledo.",
  "accountancy.employees_at_gajiHub_are_also_automatically_registered_as_employees_at_kledo":
    "Pegawai di GajiHub juga otomatis tercatat sebagai pegawai di Kledo.",
  "accountancy.username_password_to_login_to_gajiHub_can_be_used_to_login_to_kledo":
    "Username password untuk login ke GajiHub dapat digunakan untuk login ke Kledo.",
  "accountancy.office_inventory_at_gajiHub_is_recorded_in_kledo_automatically":
    "Inventaris kantor di GajiHub tercatat di Kledo secara otomatis.",
  "accountancy.click_the_button_below_to_start_the_integration":
    "Klik tombol dibawah untuk memulai integrasi.",
  "accountancy.open_kledo_app": "Buka aplikasi Kledo",
  "accountancy.integrate_with_kledo": "Integrasikan dengan Kledo",
  "accountancy.please_wait": "Mohon tunggu ya!",
  "accountancy.integrating_kledo_with_gajihub":
    "Sedang mengintegrasikan Gajihub dengan Kledo...",

  "accountMapping.account_mapping": "Pemetaan Akun",
  "accountMapping.enable_gajihub_and_kledo_integration_each_payslip_will_automatically_input_the_accounting_journaling_in_kledo":
    "Aktifkan integrasi Gajihub & Kledo? Setiap slip gaji akan otomatis diinputkan penjurnalan akuntansi nya di Kledo.",
  "accountMapping.coa_account_default_salary_fee":
    "Akun COA default biaya gaji",
  "accountMapping.by_default_salary_costs_will_be_recorded_in_this_coa_account":
    "Secara default, biaya gaji akan dicatatkan pada akun COA ini.",
  "accountMapping.enter_bank_payment_journal_when_making_payroll":
    "Masukkan jurnal pembayaran bank saat melakukan pembayaran gaji?",
  "accountMapping.payroll_bank_default_coa_account":
    "Akun COA default bank pembayaran gaji",
  "accountMapping.when_making_payroll_by_default_it_will_be_recorded_as_payment_from_this_bank_account":
    "Saat melakukan pembayaran gaji, secara default akan dicatatkan sebagai pembayaran dari akun bank ini.",
  "accountMapping.advanced_settings": "Pengaturan Lanjutan",
  "accountMapping.coa_account_mapping_per_salary_component":
    "Pemetaan akun COA per komponen gaji",
  "accountMapping.payments_per_bank_coa_account_mapping":
    "Pemetaan akun COA pembayaran per bank",
  "accountMapping.please_select_account": "Silakan pilih akun",
  "accountMapping.coa_account_default_current_liabilities":
    "Akun COA default kewajiban lancar",
  "accountMapping.by_default_current_liabilities_will_be_recorded_in_this_coa_account_for_example_taxes_payable_bpjs_debt_and_so_on":
    "Secara default, kewajiban lancar akan dicatatkan pada akun COA ini. Misalnya Hutang Pajak, Hutang BPJS dan sebagainya",
  "accountMapping.payable_coa_account": "Akun COA hutang gaji",
  "accountMapping.when_making_payroll_payments_payroll_payables_are_recorded_in_this_coa_account":
    "Saat melakukan pembayaran gaji, hutang gaji dicatatkan pada akun COA ini",
  "accountMapping.leave_allowance": "Tunjangan Cuti",
  "accountMapping.tax_payable": "Hutang Pajak",
  "accountMapping.coa_account_employee_cash_receipt":
    "Akun COA kasbon karyawan",
  "accountMapping.coa_account_employee_reimbursement":
    "Akun COA reimbursement karyawan",
  "accountMapping.activate_custom_coa_every_time_a_reimbursement_is_made":
    "Aktifkan custom COA setiap pembuatan reimbursement?",
  "accountMapping.activate_custom_coa_reimbursement_description":
    "Saat diaktifkan maka Anda akan diarahkan untuk memilih akun COA ketika membuat reimburse. Jika tidak maka secara default akan menggunakan akun COA yang dipilih di pengaturan.",
  "accountMapping.select_account_mapping": "Pilih pemetaan akun",
  "accountMapping.please_select_account_mapping": "Silakan pilih pemetaan akun",
  "accountMapping.employee_reimbursement_cash_payment_coa_account":
    "Akun COA pembayaran tunai reimbursement karyawan",
  "accountMapping.tag_settings": "Pengaturan Tag",
  "accountMapping.select_tag": "Pilih tag",
  "accountMapping.the_tag_is_equated_to_the_employees_office_location":
    "Tag Disamakan Dengan Lokasi Kantor Karyawan",
  "accountMapping.type_of_salary_payment_transaction_date":
    "Jenis tanggal transaksi pembayaran gaji",

  "mobileMenu.application_menu": "Menu Aplikasi",
  "mobileMenu.select_menu_displayed_on_the_ess_app":
    "Pilih menu yang ditampilkan pada aplikasi ESS",
  "mobileMenu.select_the_displayed_shortcut_menu":
    "Pilih menu shortcut yang ditampilkan",
  "mobileMenu.select_the_features_displayed_on_the_application_dashboard":
    "Pilih fitur yang ditampilkan di dashboard aplikasi",
  "mobileMenu.select_the_bottom_menu_that_appears_in_the_application":
    "Pilih menu bawah yang tampil di aplikasi",
  "mobileMenu.attendance": "Kehadiran",
  "mobileMenu.leave": "Izin & Cuti",
  "mobileMenu.salary": "Gaji",
  "mobileMenu.calendar": "Kalender",
  "mobileMenu.company": "Perusahaan",
  "mobileMenu.schedule": "Jadwal Kerja",
  "mobileMenu.presence_in": "Presensi Masuk",
  "mobileMenu.presence_out": "Presensi Keluar",
  "mobileMenu.break_start": "Mulai Istirahat",
  "mobileMenu.break_end": "Selesai Istirahat",
  "mobileMenu.visit_in": "Mulai Kunjungan Klien",
  "mobileMenu.visit_out": "Selesai Kunjungan Klien",
  "mobileMenu.overtime_in": "Mulai Lembur",
  "mobileMenu.overtime_out": "Selesai Lembur",
  "mobileMenu.timeline": "Timeline",
  "mobileMenu.statistic": "Statistik",
  "mobileMenu.employee_attendance_history": "History Absensi Karyawan",
  "mobileMenu.show_menu_employee_attendance_history":
    "Tampilkan menu history absensi karyawan",
  "mobileMenu.select_the_roles_that_can_access_menu_employee_attendance_history":
    "Pilih peran yang dapat akses menu history absensi karyawan",
  "mobileMenu.select_roles": "Pilih peran",
  "mobileMenu.if_a_role_is_not_filled_then_a_menu_will_appear_for_all_employees":
    "Jika peran tidak diisi maka menu history absensi karyawan akan tampil untuk semua karyawan",
  "mobileMenu.task": "Tugas",

  "billing.title": "Billing",
  "billing.account_expired":
    "Akun Anda telah habis masa aktifnya, silakan order perpanjangan untuk kembali dapat mengakses akun Anda",
  "billing.subscription_plan": "Paket Berlangganan",
  "billing.active_until": "Aktif sampai",
  "billing.forever": "Selamanya",
  "billing.addon": "Addon",
  "billing.available_in_package_pro_and_elite": "Tersedia di paket Pro & Elite",
  "billing.this_feature_is_available_in_the_package_pro_and_elite":
    "Fitur ini tersedia di paket Pro & Elite",
  "billing.order_date": "Tanggal Order",
  "billing.upgrade": "Upgrade",
  "billing.upgrade_plan": "Upgrade Paket",
  "billing.renew_plan": "Perpanjang Paket",
  "billing.select_plan": "Silakan pilih paket yang Anda inginkan",
  "billing.please_select_a_plan": "Silakan pilih paket",
  "billing.select_contract_duration": "Silakan pilih lama kontrak",
  "billing.please_select_the_contract_duration": "Silakan pilih lama kontrak",
  "billing.get_25_discount_for_1_year_payment_and_for_6_months":
    "Dapatkan diskon 25% untuk pembayaran 1 tahun dan diskon 2% untuk pembayaran 6 bulan",
  "billing.get_30_discount_for_2_year_payment":
    "Dapatkan diskon 30% untuk pembayaran 2 tahun",
  "billing.total_bill": "Total tagihan",
  "billing.upgrade_now": "Upgrade Sekarang",
  "billing.renew_now": "Perpanjang Sekarang",
  "billing.please_enter_number_of_employee": "Silakan masukkan jumlah karyawan",
  "billing.token_required_to_make_payment":
    "Token diperlukan untuk melakukan pembayaran",
  "billing.payment_success": "Pembayaran Berhasil",
  "billing.payment_pending": "Menunggu Pembayaran",
  "billing.your_account_wiil_be_active_after_payment":
    "Akun Anda akan aktif setelah dilakukan pembayaran. Jika Anda telah melakukan pembayaran, silakan klik tombol berikut. Terimakasih.",
  "billing.your_account_activated_thanks":
    "Akun Anda telah diaktifkan. Terima kasih.",
  "billing.payment_failed": "Pembayaran Gagal",
  "billing.please_contact_gajihub_if_difficulity_on_payment":
    "Silakan menghubungi tim Hebat Gajihub jika mengalami kesulitan dalam pembayaran.",
  "billing.thankyou": "Terimakasih",
  "billing.bill_email_has_been_sent_to_your_email":
    "Email tagihan telah dikirimkan ke alamat email Anda.",
  "billing.please_make_a_payment_of": "Silakan melakukan pembayaran sebesar",
  "billing.by_click_button_below": "dengan klik tombol berikut",
  "billing.pay_now": "Bayar Sekarang",
  "billing.back_to_billing": "Kembali ke Billing",
  "billing.description": "Deskripsi",
  "billing.period": "Periode",
  "billing.payment_date": "Tanggal Bayar",
  "billing.total": "Total",
  "billing.status": "Status",
  "billing.pay": "Bayar",
  "billing.payment_history": "Riwayat Pembayaran",
  "billing.your_free_trial_active": "Akun Free Trial Anda sudah aktif.",
  "billing.account_will_active_until": "Akun akan aktif sampai dengan",
  "billing.you_are_currently_using_dummy_data_please_clear_your_data_first_before_upgrading":
    "Saat ini Anda menggunakan data dummy, silakan kosongkan data Anda terlebih dahulu sebelum melakukan Upgrade. {{click_here}} untuk mengosongkan data",
  "billing.unlimited": "Unlimited",
  "billing.the_number_of_employees_has_exceeded_the_maximum_limit_for_your_company_account":
    "Jumlah karyawan sudah melebihi batas maksimal akun perusahaan Anda.",
  "billing.available_in_elite_package": "Tersedia di paket Elite",
  "billing.this_feature_is_available_in_the_package_elite":
    "Fitur ini tersedia di paket Elite",
  "billing.please_enter_the_number_of_additional_employees":
    "Silakan masukkan jumlah tambahan karyawan",
  "billing.current_number_of_employees": "Jumlah karyawan saat ini",
  "billing.number_of_employees_after_addition":
    "Jumlah karyawan setelah penambahan",
  "billing.order_now": "Order Sekarang",
  "billing.add_addon": "Tambah Addon",
  "billing.hide_addon": "Sembunyikan Addon",
  "billing.location": "Lokasi",
  "billing.calculation_details": "Rincian Perhitungan",
  "billing.produk": "Produk",
  "billing.amount": "Jumlah",
  "billing.contract": "Kontrak",
  "billing.per_month": "Per Bulan",
  "billing.subtotal": "Subtotal",
  "billing.save": "Hemat",
  "billing.terms_of_use_and_privacy_policy":
    "Dengan klik tombol di atas, Anda setuju dengan <LinkA>Ketentuan Penggunaan</LinkA> kami dan mengonfirmasi bahwa Anda telah membaca <LinkB>Kebijakan Privasi</LinkB> kami.",

  "referral.referral": "Referral",
  "referral.read_more": "Baca selengkapnya >>",
  "referral.referral_history": "Riwayat Referal",
  "referral.your_referral_code": "Kode Referalmu",
  "referral.company": "Perusahaan",
  "referral.code": "Kode",
  "referral.copy_code": "Salin Kode",
  "referral.add_code": "Tambah Kode",
  "referral.register": "Daftar",
  "referral.paid": "Dibayar",
  "referral.transfered": "Ditransfer",
  "referral.approved": "Disetujui",
  "referral.lead": "Lead",
  "referral.cannot_get_referral": "Tidak dapat mengambil data referal",
  "referral.cannot_add_referral": "Tidak dapat menambah referal",
  "referral.cannot_delete_referral": "Tidak dapat menghapus referal",
  "referral.invite_your_friends": "Ajak Temanmu Menggunakan Gajihub",
  "referral.get_millions_per_month": "Dapatkan jutaan rupiah per bulan",
  "referral.share_your_referral_code": "Bagikan kode referalmu",
  "referral.share_via_sosmed_or_anything":
    "Bagikan via sosmed, email, whatsapp, atau apapun ke kolegamu",
  "referral.discount_for_your_friends": "Diskon {{value}}% untuk temanmu",
  "referral.your_friends_will_get_discount":
    "Temanmu akan mendapatkan diskon {{value}}% jika mendaftar untuk 1 tahun",
  "referral.reward_per_referral": "Reward 50% per referal",
  "referral.get_reward_per_referral":
    "Dapatkan reward sebesar 50% dari penjualan dengan maksimal Rp 150.000,- tiap referal",
  "referral.please_input_code": "Silakan masukkan kode.",
  "referral.code_has_been_copied": "Kode referral berhasil disalin.",

  "attendance.check_out_time_must_be_after_check_in_time":
    "Jam keluar harus setelah jam masuk!",
  "attendance.break_in_time_must_be_after_check_in_time":
    "Jam mulai istirahat harus setelah jam masuk!",
  "attendance.break_end_time_must_after_break_start_time":
    "Jam selesai istirahat harus setelah jam mulai istirahat!",
  "attendance.radius_from_the_office":
    "Radius {{radius}} meter dari kantor ({{inTheOffice}})",
  "attendance.in_the_office": "di kantor",
  "attendance.outside_the_office": "di luar kantor",
  "attendance.the_time_must_before_out_time": "Harus sebelum waktu keluar!",
  "attendance.the_time_must_after_in_time": "Harus sesudah waktu masuk!",
  "attendance.check_in_time_must_before_check_out_time":
    "Jam masuk harus sebelum jam keluar!",
  "attendance.break_in_time_must_be_before_break_ended_time":
    "Jam mulai istirahat harus sebelum jam keluar istirahat!",
  "attendance.overtime_started_time_must_before_overtime_ended_time":
    "Jam mulai lembur harus sebelum jam selesai lembur!",
  "attendance.overtime_ended_time_must_after_overtime_started_time":
    "Jam selesai lembur harus setelah jam mulai lembur!",

  "schedule.schedule_changes_will_be_applied_to_all_employees_who_use_this_schedule":
    "Perubahan jadwal akan diterapkan ke semua karyawan yang menggunakan jadwal ini.",
  "schedule.this_change_may_cause_a_change_in_the_employees_attendance_status":
    "Perubahan ini dapat menyebabkan perubahan status kehadiran karyawan.",
  "schedule.are_u_sure_to_change_this_work_schedule":
    "Apakah anda yakin mengubah jadwal kerja ini ?",
  "schedule.drag_an_drop_shift_below_into_the_calendar_table_on_the_left":
    "Drag dan drop shift dibawah ini ke tabel kalender di sebelah kiri.",
  "schedule.last_week": "Minggu lalu",
  "schedule.next_week": "Minggu depan",
  "schedule.click_here_to_assign_work_schedules_here_to_each_employee":
    "Klik disini untuk menerapkan jadwal kerja yang ada di sini ke masing - masing karyawan.",

  "attendanceApproval.employee_presence_that_requires_admin_approval_will_be_entered_in_this_table":
    "Presensi karyawan yang membutuhkan approval admin akan masuk di tabel ini.",
  "attendanceApproval.employees_can_make_attendance_from_the_ess_gaihub_application_click_here_to_install_the_android_and_ios_version":
    "Karyawan dapat melakukan presensi dari aplikasi ESS GajiHub, {{link_android}} untuk install versi Android atau {{link_ios}} versi iOS.",
  "attendanceApproval.you_can_set_attendance_criteria_that_require_approval_through_the_settings_menu_here":
    "Anda dapat mengatur kriteria presensi yang membutuhkan approval melalui menu pengaturan {{link_presence_application_validation_setting}}.",
  "attendanceApproval.presence_application_validation_setting":
    "Pengaturan Validasi Aplikasi Presensi",
  "attendanceApproval.in_here": "di sini",
  "attendanceApproval.approval_employee_attendance_will_go_to_this_table":
    "<p>Presensi karyawan yang telah disetujui akan masuk ke tabel ini.</p><p> Tidak semua presensi memerulan approval, Anda dapat mengatur kreteria presensi yang membutuhkan approval melalui menu pengaturan {{link_presence_application_validation_setting}}.</p>",
  "attendanceApproval.the_employee_attendance_that_you_decline_will_go_to_this_table":
    "<p>Presensi karyawan yang Anda tolak akan masuk ke tabel ini.</p><p> Anda dapat mengatur kreteria presensi yang membutuhkan approval melalui menu pengaturan {{link_presence_application_validation_setting}}.</p>",
  "leaves.this_table_will_display__a_list_of_request_for_annual_leave_from_your_employees":
    "<p>Tabel ini akan menampilkan daftar pengajuan cuti tahunan dari karyawan Anda.</p><p> Karyawan Anda dapat mengajukan cuti tahunan melalui aplikasi ESS Gajihub, {{link_android}} untuk install versi Android, dan {{link_ios}} untuk versi iOS.</p> <p>Seluruh pengajuan cuti perlu Anda approve terlebih dahulu sebelum mengurangi saldo cuti karyawan yang bersangkutan.</p>",
  "leaves.special_leave_is_leave_for_employees_who_have_urgent_needs":
    "<p>Cuti khusus merupakan cuti karyawan yang memiliki kepentingan mendesak, misalnya cuti melahirkan, cuti duka dan sebagainya.</p><p>Anda bisa mengatur jenis cuti khusus yang diperbolehkan perusahaan beserta durasi maksimalnya {{link_leaves}}.</p><p>Cuti khusus yang diajukan oleh karyawan Anda melalui aplikasi ESS GajiHub akan tampil pada halaman ini, dan kemudian dapat Anda setujui atau tolak.</p> <p>Karyawan yang disetujui cuti khususnya akan tetap mendapatkan gaji dari perusahaan.</p><p>{{link_android}} untuk install ESS versi Android, dan {{link_ios}} untuk versi iOS.</p>",
  "leaves.your_employees_can_apply_for_sick_leave_via_ess_gajihub":
    "<p>Karyawan Anda dapat mengajukan izin sakit melalui ESS GajiHub.</p><p> Kemudian Anda dapat menyetujui atau menolak pengajuan izin sakit tersebut.</p><p> Karyawan yang disetujui izin sakitnya akan tetap mendapatkan gaji dari perusahaan.</p>",
  "leaves.your_employees_can_apply_for_a_permit_via_ess_gajihub":
    "<p>Karyawan Anda dapat mengajukan izin melalui ESS GajiHub.</p><p> Izin yang dimaksud disini adalah izin selain izin sakit, dan diluar yang dapat dikategorikan sebagai cuti khusus.</p>",
  "leaves.unpaid_leave_is_a_permission_not_to_come_to_work_and_the_employee_will_not_receive_a_salary_during_this_absence":
    "<p>Unpaid Leave merupakan izin tidak masuk kerja dan karyawan tidak akan menerima gaji selama tidak masuk tersebut.</p>",
  "approval.all_submission_from_employees_that_require_you_approval_will_appear_here":
    "<p>Seluruh pengajuan dari karyawan yang membutuhkan approval Anda akan muncul disini.</p><p> Termasuk pengajuan cuti, izin, sakit dan sebagainya. Anda dapat menolak atau menyetujui pengajuan tersebut.</p> ",
  "approval.submissions_from_approved_employees_will_be_displayed_here":
    "Pengajuan dari karyawan yang disetujui akan ditampilkan disini. Anda dapat mengatur alur persetujuan {{link_setting}}.",
  "approval.submission_from_employee_who_have_been_rejected_will_be_displayed_here":
    "Pengajuan dari karyawan yang telah ditolak akan ditampilkan disini. Anda dapat mengatur alur persetujuan {{link_setting}}.",
  "timeline.timeline_is_your_company_internal_socialmedia":
    "<p>Timeline adalah sosial media internal perusahaan Anda.</p><p> Seluruh karyawan bisa saling berinteraksi melalui aplikasi ESS GajiHub.</p><p> Ini akan membuat aura kerja lebih seru dan bersemangat.</p><p> Anda tetap dapat menyembunyikan posting timeline dari karyawan yang Anda anggap kurang pantas.</p>",
  "announcement.announcements_will_be_sent_to_all_employees_via_the_ess_gajihub_application":
    "<p>Pengumuman akan dikirim ke seluruh karyawan melalui aplikasi ESS GajiHub.</p><p> Anda akan mendapatkan laporan siapa saja karyawan yang telah membaca pengumuman.</p>",
  "company.company_regulations_will_be_displayed_on_the_ess_gajihub_application":
    "<p>Peraturan perusahaan akan ditampilkan di aplikasi ESS GajiHub.</p><p> Semua karyawan akan dapat membaca peraturan perusahaan, sehingga membuat perusahaan Anda lebih transparan bagi semua karyawan.</p>",

  "mobileMenu.approval": "Approval",
  "mobileMenu.teams": "Tim",
  "mobileMenu.cash_receipt": "Kasbon",

  "template.notification_templates": "Template Notifikasi",
  "template.email": "Email",
  "template.whatsapp": "Whatsapp",
  "template.mobile_apps": "Aplikasi Mobile",
  "template.edit_email_template": "Ubah Template Email",
  "template.edit_whatsapp_template": "Ubah Template Whatsapp",
  "template.edit_mobile_apps_notification_template":
    "Ubah Template Notifikasi Mobile",
  "template.subject": "Judul",
  "template.message": "Pesan",
  "template.please_input": "Silakan masukkan <span>{{type}}</span>",
  "template.revert_to_default": "Kembalikan ke Default",
  "template.insert_tags": "Sisipkan Tag",
  "template.are_you_sure_revert_to_default":
    "Apakah Anda yakin akan mengembalikan ke default?",
  "template.off": "Off",
  "template.on": "On",

  "layoutSalarySlip.title": "Layout Slip Gaji",
  "layoutSalarySlip.personal_and_secret": "Pribadi dan Rahasia",
  "layoutSalarySlip.resize_logo": "Ubah Ukuran Logo",
  "layoutSalarySlip.resize_logo_footer": "Ubah Ukuran Logo Footer",
  "layoutSalarySlip.settings": "Pengaturan",
  "layoutSalarySlip.show_full_address": "Tampilkan Alamat Lengkap (footer)",
  "layoutSalarySlip.show_leave": "Tampilkan cuti",
  "layoutSalarySlip.back_to_default_settings": "Kembali Pengaturan Awal",
  "layoutSalarySlip.are_you_sure_to_remove_the_company_logo":
    "Apakah anda yakin untuk menghapus logo perusahaan?",
  "layoutSalarySlip.font_size": "Ukuran Font",
  "layoutSalarySlip.show_logo": "Tampilkan Logo",
  "layoutSalarySlip.show_header": 'Tampilkan Teks "Pribadi dan Rahasia"',
  "layoutSalarySlip.show_company_name": "Tampilkan Nama Perusahaan",
  "layoutSalarySlip.show_employee_id": "Tampilkan ID Karyawan",
  "layoutSalarySlip.show_ptkp_status": "Tampilkan Status PTKP",
  "layoutSalarySlip.show_date_joined": "Tampilkan Tanggal Bergabung",
  "layoutSalarySlip.show_take_home_pay": "Tampilkan Take Home Pay",
  "layoutSalarySlip.show_leave_acrued": "Tampilkan Cuti Terhutang",
  "layoutSalarySlip.show_leave_used": "Tampilkan Cuti Digunakan",
  "layoutSalarySlip.show_leave_balance": "Tampilkan Sisa Cuti",
  "layoutSalarySlip.show_note": "Tampilkan Catatan",
  "layoutSalarySlip.show_unpaid_cash_receipt": "Tampilkan Kasbon Belum Dibayar",
  "layoutSalarySlip.show_bpjs": "Tampilkan BPJS",
  "layoutSalarySlip.preview_salary_slip": "Preview Slip Gaji",
  "layoutSalarySlip.show_attendance_summary": "Tampilkan Ringkasan Kehadiran",
  "layoutSalarySlip.show_attendance_summary_working_days":
    "Tampilkan Total Hari Kerja",
  "layoutSalarySlip.show_attendance_summary_day_off":
    "Tampilkan Total Hari Libur",
  "layoutSalarySlip.show_attendance_summary_present_at_working_day":
    "Tampilkan Total Hadir",
  "layoutSalarySlip.show_attendance_summary_national_holiday":
    "Tampilkan Total Libur Nasional",
  "layoutSalarySlip.show_attendance_summary_joint_holiday":
    "Tampilkan Total Cuti Bersama",
  "layoutSalarySlip.show_attendance_summary_non_holiday_event":
    "Tampilkan Total Libur Non Event",
  "layoutSalarySlip.show_signature": "Tampilkan Tanda Tangan",
  "layoutSalarySlip.show_signature_on_right":
    "Tampilkan Tanda Tangan Sebelah Kanan",
  "layoutSalarySlip.show_signature_on_left":
    "Tampilkan Tanda Tangan Sebelah Kiri",
  "layoutSalarySlip.show_signature_in_the_middle":
    "Tampilkan Tanda Tangan Sebelah Tengah",
  "layoutSalarySlip.signature": "Tanda Tangan",
  "layoutSalarySlip.direct_signature": "Tanda Tangan Langsung",
  "layoutSalarySlip.upload_signature": "Upload Tanda Tangan",
  "layoutSalarySlip.are_you_sure_to_remove_the_signature":
    "Apakah anda yakin untuk menghapus tanda tangan?",
  "layoutSalarySlip.please_enter_a_signature": "Silakan masukkan tanda tangan.",
  "layoutSalarySlip.show_late_total": "Tampilkan Total Terlambat",
  "layoutSalarySlip.show_attendance_summary_no_status":
    "Tampilkan Total Tanpa Status",
  "layoutSalarySlip.show_attendance_summary_status_present":
    "Tampilkan Total Status Hadir",
  "layoutSalarySlip.show_attendance_summary_status_present_at_working_day":
    "Tampilkan Total Status Hadir Hari Kerja",
  "layoutSalarySlip.show_attendance_summary_status_present_at_non_working_day":
    "Tampilkan Total Status Hadir Bukan Hari Kerja",
  "layoutSalarySlip.show_attendance_summary_status_absent":
    "Tampilkan Total Status Mangkir",
  "layoutSalarySlip.show_attendance_summary_status_non_working_day":
    "Tampilkan Total Status Bukan Hari Kerja",
  "layoutSalarySlip.show_attendance_summary_status_out_of_office_duty":
    "Tampilkan Total Tugas Luar",
  "layoutSalarySlip.show_attendance_summary_status_sick":
    "Tampilkan Total Status Sakit",
  "layoutSalarySlip.show_attendance_summary_status_permit":
    "Tampilkan Total Status Izin",
  "layoutSalarySlip.show_attendance_summary_status_leave":
    "Tampilkan Total Status Cuti",
  "layoutSalarySlip.show_attendance_summary_status_half_day_leave":
    "Tampilkan Total Status Cuti Setengah Hari",
  "layoutSalarySlip.show_attendance_summary_status_unpaid_leave":
    "Tampilkan Total Status Cuti Tidak Dibayar",
  "layoutSalarySlip.show_attendance_summary_status_joint_holiday":
    "Tampilkan Total Status Cuti Bersama",
  "layoutSalarySlip.show_component_multiplier_details":
    "Tampilkan Detail Pengali Komponen",
  "layoutSalarySlip.automatically_attach_pph_tax_calculation":
    "Otomatis Melampirkan Perhitungan Pajak PPh",
  "layoutSalarySlip.show_attendance_summary_working_hours":
    "Tampilkan Total Jam Kerja Karyawan",
  "layoutSalarySlip.show_attendance_summary_office_hours":
    "Tampilkan Total Jam Kerja Kantor",

  "appSetting.apps": "Aplikasi",
  "appSetting.date_created": "Tanggal Dibuat",
  "appSetting.app_name": "Nama Aplikasi",
  "appSetting.client_id": "Client ID",
  "appSetting.client_id_has_been_copied": "Client ID berhasil disalin",
  "appSetting.secret_has_been_copied": "Secret berhasil disalin",
  "appSetting.endpoint_has_been_copied": "API Endpoint URL berhasil disalin",
  "appSetting.documentation_has_been_copied":
    "Dokumentasi API berhasil disalin",
  "appSetting.api_documentation_endpoint": "Dokumentasi API Endpoint",
  "appSetting.please_input_name": "Silakan masukkan nama aplikasi",
  "appSetting.please_redirect_uri": "Silakan masukkan redirect URI",
  "appSetting.please_valid_uri": "Silakan masukkan URL yang valid",
  "appSetting.error_cannot_get": "Tidak dapat mengambil data aplikasi",
  "appSetting.error_cannot_create": "Tidak dapat membuat aplikasi",
  "appSetting.error_cannot_edit": "Tidak dapat mengubah aplikasi",
  "appSetting.error_cannot_delete": "Tidak dapat menghapus aplikasi",
  "appSetting.app_has_been_created_make_sure_save_secret":
    "Aplikasi baru berhasil dibuat, silakan pastikan simpan kode secret di bawah sebelum berpindah ke halaman lain.",
  "appSetting.secret_code_just_shown_once":
    "Kode secret hanya tampil sekali. Simpan kode ini, karena setelah ini Anda tidak dapat melihat kode secret ini lagi.",
  "appSetting.integrate_your_app_with_gajihub":
    "Integrasikan aplikasimu dengan Gajihub",
  "appSetting.now_app_can_be_integrated_with_gajihub":
    "Sekarang aplikasi, website, maupun software-mu bisa diintegrasikan dengan Gajihub.",
  "appSetting.for_more_details_lets_see_the_video":
    "Untuk lebih jelasnya, yuk lihat videonya",
  "appSetting.watch_video": "Lihat video",
  "appSetting.add_apps": "Tambah Aplikasi",
  "appSetting.edit_apps": "Ubah Aplikasi",
  "appSetting.detail_apps": "Detil Aplikasi",
  "appSetting.are_you_sure_to_delete": "Apakah Anda yakin untuk menghapus?",

  "table.data_empty": "Data Kosong",
  "table.data_x_empty": "Data {{name}} Kosong",
  "table.lets_create_your_first_x": "Yuk mulai buat {{name}} pertamamu!",

  "mobileMenu.reimbursement": "Reimburse",

  "audit.title": "Audit",
  "audit.by": "oleh",
  "audit.show_audit": "Lihat Audit",
  "audit.last_modified_by": "Terakhir diubah oleh",
  "audit.on": "pada",

  "faqs.whats_your_question": "Apa Pertanyaan Anda?",
  "faqs.most_popular": "Terpopuler",
  "faqs.most_help": "Paling Membantu",
  "faqs.search_result": "Hasil Pencarian {{value}}",
  "faqs.title_by_category": "Berdasarkan Kategori {{value}}",
  "faqs.title_by_tag": "Berdasarkan Tag {{value}}",
  "faqs.was_this_answer_helpful": "Apakah jawaban ini membantu?",
  "faqs.last_update": "Update Terakhir",
  "faqs.copy_raw_text": "FAQ berhasil disalin",
  "faqs.category": "Kategori",

  "journal.view_journal_entries": "Lihat entri jurnal",
  "journal.journal_report": "Laporan Jurnal",
  "journal.account": "Akun",
  "journal.debit": "Debit",
  "journal.credit": "Kredit",

  "client.title": "Klien",
  "client.add_client": "Tambah Klien",
  "client.edit_client": "Ubah Klien",
  "client.company": "Perusahaan",
  "client.location": "Lokasi",
  "client.look_on_the_map": "Lihat di map",
  "client.client_name": "Nama Klien",
  "client.please_input": "Silakan masukkan {{value}}.",
  "client.personal_information": "Informasi Pribadi",
  "client.location_name": "Nama Lokasi",
  "client.select_address_via_map": "Pilih alamat melalui map",
  "client.are_you_sure_to_delete_client":
    "Apakah Anda yakin untuk menghapus klien?",
  "client.empty_message":
    "Dengan mencatatkan lokasi klien Anda di sini, Anda dapat mengatur supaya tim hanya bisa melakukan kunjungan klien dari radius lokasi klien yang sudah dicatatkan.<br /> <Link>Klik disini</Link> untuk mengatur kebijakan kunjungan klien!",
  "client.import_client": "Import Klien",
  "client.follow_these_steps_to_import_client":
    "Ikuti langkah berikut untuk mengimport klien",
  "client.1_download_file_template_client": "1. Download file template klien",
  "client.start_by_downloading_file_template_client":
    "Mulai dengan mengunduh file template Excel. File ini mempunyai kolom header yang dibutuhkan Gajihub untuk mengimport detail dari klien.",
  "client.2_copy_client_data_into_the_template":
    "2. Salin data klien ke dalam template",
  "client.how_to_custom_file_template_client":
    "Menggunakan Excel atau software spreadsheet lainnya, masukkan data klien ke dalam file template dari Gajihub. Pastikan data klien sesuai dengan kolom header yang ada di template.",
  "client.important_import_info":
    "Jangan mengubah header kolom di dalam template. Ini dibutuhkan agar import dapat berjalan.<br/> Maksimal 1.000 data klien dapat di import dalam satu waktu.<br/> Saat meng-import, Gajihub hanya akan memasukkan data baru, tidak ada data yang dihapus atau diupdate.<br/>",
  "client.client_successfully_imported":
    "{{rowCount}} klien berhasil di import.",
  "client.go_to_the_client_list_page": "Ke halaman daftar klien",

  "mobileSetting.notifications_use_company_settings":
    "Notifikasi menggunakan pengaturan perusahaan",
  "mobileSetting.cash_receipt": "Kasbon",
  "mobileSetting.company_announcement": "Pengumuman Perusahaan",
  "mobileSetting.friends_birthday": "Ulang Tahun Teman",
  "mobileSetting.presence": "Presensi Masuk",
  "mobileSetting.exit_presence": "Presensi Keluar",
  "mobileSetting.break": "Mulai Istirahat",
  "mobileSetting.after_break": "Selesai Istirahat",
  "mobileSetting.late_presence": "Terlambat Presensi Masuk",
  "mobileSetting.receive_salary": "Terima Gaji",
  "mobileSetting.update_timeline": "Update Timeline",
  "mobileSetting.leave": "Cuti",
  "mobileSetting.approval": "Approval",
  "mobileSetting.reimburse": "Reimburse",
  "mobileSetting.calendar": "Kalender",
  "mobileSetting.teams_use_company_settings":
    "Team menggunakan pengaturan perusahaan",
  "mobileSetting.task": "Tugas",
  "mobiieSetting.task_activity": "Aktifitas Tugas",

  "settingOvertime.overtime_schedule": "Jadwal Lembur",
  "settingOvertime.add_overtime_schedule": "Tambah Jadwal Lembur",
  "settingOvertime.edit_overtime_schedule": "Ubah Jadwal Lembur",
  "settingOvertime.detail_overtime_schedule": "Detil Jadwal Lembur",
  "settingOvertime.overtime_type": "Tipe Lembur",
  "settingOvertime.overtime_time": "Waktu Lembur",
  "settingOvertime.type": "Tipe",
  "settingOvertime.calculation": "Perhitungan",
  "settingOvertime.times": "Waktu",
  "settingOvertime.wages": "Upah",
  "settingOvertime.overtime_wages": "Upah Lembur",
  "settingOvertime.overtime_name": "Nama Lembur",
  "settingOvertime.please_input": "Silakan masukkan {{value}}.",
  "settingOvertime.please_select": "Silakan pilih {{value}}.",
  "settingOvertime.total_wages": "Total Upah",
  "settingOvertime.detail_overtime": "Detil Lembur",
  "settingOvertime.actual_overtime_hours": "Jam Aktual Lembur",
  "settingOvertime.overtime_conversion_hours": "Jam Konversi Lembur",
  "settingOvertime.change_overtime_durations": "Ubah Durasi Lembur",
  "settingOvertime.actual_hours": "Jam Aktual",
  "settingOvertime.conversion_hours": "Jam Konversi",
  "settingOvertime.setting_overtime": "Pengaturan Lembur",
  "settingOvertime.add_setting_overtime": "Tambah Pengaturan Lembur",
  "settingOvertime.edit_setting_overtime": "Ubah Pengaturan Lembur",
  "settingOvertime.detail_setting_overtime": "Detil Pengaturan Lembur",
  "settingOvertime.tooltip_calculation":
    "Upah lembur akan dihitung dibagi dengan jumlah per jam yang telah di atur. Semisal di atur per 2 jam dengan upah 50.000 maka 50.000/2 = 25.000",
  "settingOvertime.tooltip_time":
    "Pembagian waktu lembur. Pagi (00:00 - 08:00), Siang (08:00 - 16:00), Malam (16:00 - 23:59)",
  "settingOvertime.click_here_to_see_the_applied_overtime_settings":
    "Klik disini untuk melihat pengaturan lembur yang diterapkan",
  "settingOvertime.settings_have_been_used": "Pengaturan telah digunakan",

  "tracking.title": "Tracking",
  "tracking.location_records": "Pencatatan Lokasi",
  "tracking.active_employees": "Karyawan Aktif",
  "tracking.are_you_sure_to_enable_location_tracking":
    "Apakah anda yakin untuk mengaktifkan pelacakan lokasi?",
  "tracking.location_tracking_interval": "Interval pelacakan lokasi",
  "tracking.location_tracking_break_duration":
    "Durasi istirahat pelacakan lokasi",
  "tracking.manage_location_tracking": "Atur Pelacakan Lokasi",
  "tracking.see_tracking": "Lihat tracking",
  "tracking.travel_distance": "Jarak Tempuh",
  "tracking.detail_tracking": "Detil Tracking",
  "tracking.starting_location": "Lokasi awal",
  "tracking.current_location": "Lokasi terkini",
  "tracking.records": "Pencatatan {{value}}",
  "tracking.the_current_location_will_be_updated_according_to_the_selected_time_interval":
    "Lokasi terkini akan diperbarui sesuai interval waktu yang dipilih.",
  "tracking.the_duration_for_live_tracking_to_be active_again_before_the_break_is_over":
    "Durasi untuk live tracking aktif kembali sebelum istirahat selesai.",
  "tracking.this_feature_is_only_available_on_the_android_version_of_ess_does_not_support_the_ios_version":
    "Fitur ini hanya tersedia pada ESS versi android, tidak mendukung versi iOS.",
  "tracking.default_map_view": "Tampilan Map Default",
  "tracking.satellite_map_view": "Tampilan Map Satelit",

  "automaticSequencing.title": "Penomoran Otomatis",
  "automaticSequencing.define_the_number_to_be_used":
    "Tentukan nomor yang digunakan untuk membuat penomoran. Nomor dibawah akan otomatis ditambah setiap dokumen baru dibuat.",
  "automaticSequencing.edit_number": "Ubah Penomoran",
  "automaticSequencing.number_format": "Format Penomoran",
  "automaticSequencing.automatic_numbering_output_example":
    "Contoh Output Penomoran Otomatis",
  "automaticSequencing.current_number": "Nomor saat ini",
  "automaticSequencing.reset_number_every": "Reset Nomor Setiap",
  "automaticSequencing.never_reset": "Tidak pernah reset",
  "automaticSequencing.every_month": "Setiap bulan",
  "automaticSequencing.every_year": "Setiap tahun",
  "automaticSequencing.on_date": "Pada Tanggal",
  "automaticSequencing.month": "Bulan",
  "automaticSequencing.please_input": "Silakan masukkan {{value}}",
  "automaticSequencing.add_numbering_code": "Tambah Kode Penomoran",
  "automaticSequencing.the_last_reset_number":
    "Terakhir reset nomor pada {{date}}, [<Link>klik disini</Link>] untuk lihat log",
  "automaticSequencing.title_setting":
    "Nomor dikunci supaya tidak dapat diubah",
  "automaticSequencing.description_setting":
    "Jika aktif, maka nomor akan mengikui sistem Gajihub, tidak dapat diubah oleh pengguna.",
  "automaticSequencing.number": "Nomor",
  "automaticSequencing.please_enter_the_current_number":
    "Silakan isi nomor saat ini",
  "automaticSequencing.tooltip_number":
    "Nomor akan otomatis terbuat oleh sistem, tapi Anda juga bisa mengubahnya disini. Anda bisa mengatur format nomor ini <Link>di sini</Link>.",
  "automaticSequencing.the_number_is_already_in_use_click_here_to_get_a_new_number":
    "Nomor telah dipakai, <Link>klik disini</Link> untuk mendapatkan nomor baru.",

  "fingerprint.fingerprint_connect": "Fingerprint Connect",
  "fingerprint.add": "Tambah",
  "fingerprint.fingerprint_machine": "Mesin Fingerprint",
  "fingerprint.machine_name": "Nama Mesin",
  "fingerprint.machine_id": "ID Mesin",
  "fingerprint.office_location": "Lokasi Kantor",
  "fingerprint.created": "Dibuat",
  "fingerprint.status": "Status",
  "fingerprint.add_fingerprint_connect": "Tambah Fingerprint Connect",
  "fingerprint.edit_fingerprint_connect": "Ubah Fingerprint Connect",
  "fingerprint.select_fingerprint_machine": "Pilih Mesin Fingerprint",
  "fingerprint.description_select_fingerprint_machine":
    "Dengan menggunakan Fingerprint Connect, data presensi karyawan akan otomatis masuk ke sistem GajiHub, asik kan? Yuk hubungkan fingerprintmu dengan GajiHub.",
  "fingerprint.select_the_fingerprint_device_that_will_beconnected_to_gajiHub":
    "Pilih perangkat fingerprint yang akan dihubungkan ke GajiHub.",
  "fingerprint.step_machine_id":
    "ID Mesin bisa dilihat di mesin <bold>Fingerspot</bold> pada Menu -> Opsi -> Jaringan -> No Mesin",
  "fingerprint.select_device": "Pilih Perangkat",
  "fingerprint.please_input": "Silakan masukkan {{value}}",
  "fingerprint.input": "Masukkan {{value}}",
  "fingerprint.add_fingerprint": "Tambah Fingerprint",
  "fingerprint.employee_synchronization": "Sinkronisasi Karyawan",
  "fingerprint.are_you_sure_to_delete":
    "Apakah Anda yakin untuk menghapus {{value}}?",
  "fingerprint.fingerprint_connect_synchronization":
    "Sinkronisasi Fingerprint Connect",
  "fingerprint.pin_and_name_in_fingerprint": "PIN & Nama di Fingerprint",
  "fingerprint.synchronize_employees_with_fingerprint":
    "Sinkronisasi karyawan dengan fingerprint",
  "fingerprint.reload_option":
    "Reload akan menghapus semua data pengguna yang sudah ditarik sebelumnya",
  "fingerprint.update_option":
    "Update akan menarik data pengguna yang belum ada dan memperbarui data pengguna yang sudah ada",
  "fingerprint.integration_instructions": "Petunjuk Integrasi",
  "fingerprint.network_setup_on_the_machine": "Pengaturan jaringan di mesin",
  "fingerprint.network_setup_on_the_machine_description":
    "<ul><li>Masuk ke Menu -> Opsi -> Jaringan.</li><li>Sambungkan mesin ke WIFI (sampai muncul indikator sinyal wifi).</li><li>Atur mesin dalam mode internet, bukan local (sampai muncul indikator awan).</li><li>Atur nilai server menjadi `fdevice.com` dengan port `9002`.</li></ul>",
  "fingerprint.setting_the_presence_type_on_the_machine":
    "Pengaturan tipe presensi di mesin",
  "fingerprint.setting_the_presence_type_on_the_machine_description":
    "<ol><li>Masuk ke Menu -> Opsi -> Perangkat -> Att Status.</li><li>Aktifkan Att Status jika belum aktif, di pilihan paling atas.</li><li>Atur pilihan di bawahnya agar sesuai tipe presensi di GajiHub, sehingga urutannya seperti ini : <ul><li>masuk (0)</li><li>pulang (1)</li><li>istirahat (2)</li><li>kembali (3)</li><li>lembur (4)</li><li>lembur out (5)</li><li>visit (6)</li><li>visit out (7)</li></ul></li><li>Label bisa diubah sesuai selera.</li></ol>",
  "fingerprint.integration_flow_in_gajiHub": "Alur integrasi di GajiHub",
  "fingerprint.integration_flow_in_gajiHub_description":
    "<ol><li>Tambah integrasi dengan Fingerspot dengan mengisi:<ul><li>ID Mesin (didapat dari Menu -> Opsi -> Jaringan -> No Mesin)</li><li>Nama Mesin</li><li>Lokasi Kantor</li></ul></li><li>Semua karyawan yang berada pada lokasi kantor tersebut, disambungkan ke mesin.</li></ol><strong>Untuk pengguna fingerspot baru</strong><ol><li>Semua karyawan didaftarkan ke mesin. Pengguna yang masuk ke mesin akan memiliki `pin` sama seperti `ID` karyawannya.</li><li>Karyawan yang telah terdaftar di mesin, melakukan pemindaian sidik jari di mesin sesuai `pin`-nya.</li></ol><strong>Untuk pengguna fingerspot lama</strong><ol><li>Tarik data pengguna yang terdaftar pada mesin.</li><li>Sambungkan karyawan dengan pengguna yang sudah ditarik dari mesin satu persatu.</li></ol><span>Sinkronisasi manual atau sinkronisasi presensi yang telah dilakukan di mesin sebelumnya, bisa dilakukan melalui tombol sinkronisasi fingerprint connect yang dimaksud.</span>",
  "fingerprint.employee_synchronization_list": "Daftar Sinkronisasi Karyawan",
  "fingerprint.please_wait_a_few_moments_before_you_can_see_the_update_or_update_of_the_entire_user_list":
    "Harap menunggu beberapa saat sebelum dapat melihat pembaruan/update daftar pengguna seluruhnya",
  "fingerprint.attendance_synchronization": "Sinkronisasi Presensi",
  "fingerprint.attendance_date": "Tanggal Kehadiran",
  "fingerprint.please_wait_a_few_moments_before_you_can_see_the_attendance_synchronization_results":
    "Harap menunggu beberapa saat sebelum dapat melihat hasil sinkronisasi presensi.",
  "fingerprint.active": "Aktif",
  "fingerprint.not_active": "Tidak Aktif",
  "fingerprint.error": "Error",
  "fingerprint.fetch_users": "Tarik Pengguna",
  "fingerprint.add_users": "Tambah Pengguna",
  "fingerprint.fetch_users_from_the_fingerspot_machine":
    "Tarik pengguna dari mesin Fingerspot",
  "fingerprint.add_users_into_the_fingerspot_machine":
    "Tambah pengguna ke mesin Fingerspot",
  "fingerprint.add_users_info":
    "Pilih karyawan yang akan dimasukkan ke mesin fingerprint. Hasil yang tergenerate pengguna di mesin fingerprint memiliki pin yang sama dengan id karyawan.",
  "fingerprint.reload": "Memuat Ulang",
  "fingerprint.activation": "Aktivasi",
  "fingerprint.activation_description":
    "Hubungi customer service GajiHub untuk melakukan aktivasi fingerprint connect.",

  "sso.login_with_sso": "Login dengan SSO",
  "sso.continue_with_sso": "Lanjutkan dengan SSO",
  "sso.login_sso_desc":
    "Akun Anda telah mengaktifkan Single Sign-On. Lanjutkan dengan SSO <br /> untuk mengakses akun Gajihub Anda",
  "sso.back_to_login": "Kembali ke login",
  "sso.sso_setting": "Pengaturan SSO",
  "sso.domain_verification": "Verifikasi Domain",
  "sso.domain_verification_successful": "Verifikasi Domain Berhasil",
  "sso.sso_configuration": "Konfigurasi SSO",
  "sso.add_domain": "Tambah Domain",
  "sso.add_domain_desc":
    "Silahkan masukkan alamat email Anda yang mengandung nama domain perusahaan Anda. Misalnya namaku@namaperusahaan.com",
  "users.the_input_is_not_valid_email_": "Silakan masukkan email yang valid.",
  "sso.next_step": "Selanjutnya",
  "sso.domain_successfully_verified":
    "Selamat! Domain {{domainName}} berhasil diverifikasi.",
  "sso.verify_domain_desc":
    "Kode verifikasi telah dikirim ke alamat email Anda. Silahkan masukkan kode verifikasi pada form di bawah",
  "sso.please_input_your_code": "Silakan masukkan kode Anda",
  "sso.resend_code": "Kirim ulang kode",
  "sso.verification": "Verifikasi",
  "sso.verified": "Diverifikasi",
  "sso.pending_verification": "Verifikasi Tertunda",
  "sso.sso_configuration_service_provider_desc":
    "Silahkan masukkan data SP Entity ID dan SP ACS URL di bawah ke dalam halaman administrasi identity provider anda",
  "sso.sso_configuration_identity_provider_desc":
    "Ambil data IDP Entity ID dan SAML Login URL dari identity provider Anda, kemudian masukkan ke form di bawah",
  "sso.sso_please_enter_idp_entity_id": "Silahkan masukkan IDP Entity ID",
  "sso.sso_please_enter_saml_login_url": "Silahkan masukkan SAML Login URL",
  "sso.faq_question_what_is_sso": "Apa itu Single Sign On (SSO)?",
  "sso.faq_answer_what_is_sso":
    "SSO memungkinkan pengguna untuk login ke banyak aplikasi dan website menggunakan satu identity provider. Sehingga pengguna tidak perlu menghafal banyak user dan password untuk masing-masing aplikasi atau website.",
  "sso.faq_question_how_does_sso_work": "Bagaimana cara kerja SSO?",
  "sso.faq_answer_1_how_does_sso_work":
    "Pengguna berusaha login ke Gajihub menggunakan SSO",
  "sso.faq_answer_2_how_does_sso_work":
    "Gajihub mengirimkan data pengguna ke Identity Provider",
  "sso.faq_answer_3_how_does_sso_work":
    "Identity Provider melakukan pengecekan kredensial pengguna",
  "sso.faq_answer_4_how_does_sso_work":
    "Identity Provider mengirimkan respon ke Gajihub, memberitahukan bahwa pengguna diperbolehkan login",
  "sso.faq_answer_5_how_does_sso_work":
    "Gajihub menerima info tersebut dari Identity Provider, dan meloginkan pengguna tersebut ke Gajihub",
  "sso.faq_question_how_to_setup_sso_in_gajihub":
    "Bagaimana cara setup SSO di Gajihub?",
  "sso.faq_answer_how_to_setup_sso_in_gajihub":
    "Klik {{click_here}} untuk tutorial cara setup SSO di Gajihub.",
  "sso.here": "di sini",

  "businessFlow.system": "Sistem",
  "businessFlow.sso": "Aktifkan login dengan SSO?",
  "businessFlow.sso_desc": "Apakah Anda menggunakan SSO untuk login?",

  "task.title": "Tugas",
  "task.add_task": "Tambah Tugas",
  "task.edit_task": "Ubah Tugas",
  "task.duplicate_task": "Duplikat Tugas",
  "task.form_title": "Judul",
  "task.detail": "Detil",
  "task.input": "Masukkan {{value}}",
  "task.select": "Pilih {{value}}",
  "task.please_input": "Silakan masukkan {{value}}",
  "task.please_select": "Silakan pilih {{value}}",
  "task.time_started": "Waktu Mulai",
  "task.deadline": "Batas Waktu",
  "task.location": "Lokasi",
  "task.select_location_via_map": "Pilih lokasi melalui map",
  "task.client": "Klien",
  "task.assigned_to": "Ditugaskan kepada:",
  "task.add_employee": "Tambah Karyawan",
  "task.edit_employee": "Ubah Karyawan",
  "task.status": "Status",
  "task.priorities": "Prioritas",
  "task.weight": "Bobot",
  "task.delete_task": "Hapus Tugas",
  "task.cancel_delete": "Batalkan hapus",
  "task.multiple_delete_confirm":
    "Apakah Anda yakin ingin menghapus tugas ini?, Anda tidak dapat mengembalikannya lagi setelahnya.",
  "task.drag_and_drop_task_here": "Seret dan lepas tugas di sini",
  "task.sort_type": "Tipe Pengurutan",
  "task.sort_by": "Diurutkan Berdasarkan",
  "task.activate_task_integration_with_payroll":
    "Aktifkan integrasi tugas dengan payroll",
  "task.activate_task_integration_with_client_visit":
    "Apakah task yang ditentukan kliennya otomatis tersambung dengan presensi kunjungan klien?",
  "task.archived": "Diarsipkan",
  "task.activities": "Aktivitas",
  "task.show_activities": "Tampilkan Aktivitas",
  "task.hide_activities": "Sembunyikan Aktivitas",
  "task.content": "Konten",
  "task.comment": "Komentar",
  "task.write_comment": "Tulis komentar...",
  "task.edit_status": "Ubah Status",
  "task.the_task_has_exceeded_the_deadline": "Tugas telah melebihi batas waktu",
  "task.change_status": "{{activity}} menjadi {{result}}",
  "task.activity_client_visit": "{{activity}} pada {{datetime}}",

  "paymentConnect.title": "Payment Connect",
  "paymentConnect.what_is_payment_gateway": "Apa itu Payment Gateway?",
  "paymentConnect.payment_gateway_description":
    "Dengan menggunakan payment gateway, Anda dapat melakukan pembayaran payroll yang Anda atur pada perusahaan Anda melalui berbagai vendor pembayaran, seperti Midtrans dan Xendit. Nantinya pembayaran payroll akan dikirimkan pada vendor pembayaran yang Anda pilih dan melakukan approval di vendor terkait barulah status pembayaran di GajiHub menjadi <strong>sudah bayar</strong>.",
  "paymentConnect.what_happens_when_my_customers_pay_through_a_payment_gateway":
    "Apa yang terjadi saat pelanggan saya membayar melalui Payment Gateway?",
  "paymentConnect.customers_pay_through_a_payment_gateway_description":
    "Saat pembayaran dilakukan, maka payroll Anda akan otomatis terupdate status pembayarannya. Sehingga tidak perlu repot melakukan pembayaran melalui bank terkait.",
  "paymentConnect.does_gajiHub_charge_a_fee_for_using_the_payment_gateway":
    "Apakah GajiHub memungut biaya untuk penggunaan Payment Gateway?",
  "paymentConnect.charge_a_fee_for_using_the_payment_gateway_description":
    "Tidak. Namun pihak payment gateway provider akan mengenakan biaya per transaksi. Untuk informasi lebih detailnya, bisa ditanyakan langsung ke pihak Midtrans ataupun Xendit.",
  "paymentConnect.how_to_setup_it": "Bagaimana cara setup nya?",
  "paymentConnect.setup_description":
    "Klik tautan berikut:<br/><ul><li><Link1>Midtrans</Link1></li><li><Link2>Xendit</Link2></li></ul>",
  "paymentConnect.detail": "Detil",
  "paymentConnect.activate_payments_via":
    "Aktifkan pembayaran melalui {{value}}",
  "paymentConnect.midtrans_api_key_help_text":
    "Salin API Key dari akun Midtrans Anda, melalui menu Pengaturan >> Access Keys >> Server Key",
  "paymentConnect.xendit_api_key_help_text":
    "Salin API Key dari akun Xendit Anda, melalui menu Pengaturan >> Developer >> API keys >> Buat secret key baru >> Secret API Key",
  "paymentConnect.token_callback_help_text":
    "Klik tombol salin URL dibawah, kemudian masukkan ke akun {{value}}",
  "paymentConnect.copy": "Salin",
  "paymentConnect.success_copy_url_callback": "Url callback berhasil disalin",
  "paymentConnect.connection_test": "Tes Koneksi",
};

const data = {
  locale: "id-ID",
  antdData,
  messages,
};

export default data;
