import React from "react";
import { FaUserAlt } from "react-icons/fa";

const Avatar = ({ imageUrl }) => {
  if (!imageUrl) {
    return <FaUserAlt className="text-primary" size={16}/>;
  }

  return (
    <img
      src={imageUrl}
      className="rounded-full"
      alt="profile"
    />
  );
};

export default Avatar;
