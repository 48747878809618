import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import * as api from "apis/LocationTracking";
import { message } from "antd";

//Query untuk get location tracking
export function useLocationTrackings(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["locationTrackings/map", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getLocationTrackings(payload);
        response = data;
      } catch (error) {
        message.error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) => {
        message.error(t("error.failed_to_load_data_from_server"));
      },
      ...options,
    }
  );
}
