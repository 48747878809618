import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/schedule";

//Query untuk get all schedules
export function useSchedules({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["schedules", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSchedules(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get schedules pagination
export function useSchedulesPagination({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["schedules/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSchedulesPagination(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail schedule
export function useSchedule({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["schedule", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSchedule(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get schedule effective date
export function useScheduleEffectiveDate({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["schedule/effectiveDate", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getScheduleEffectiveDate(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk add schedule
export const useAddSchedule = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addSchedule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk mass create schedule message
export const useMassCreateScheduleMessage = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.massCreateSchedulesMessage(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        // message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk mass create schedule
export const useMassCreateSchedule = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.massCreateSchedules(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit schedule
export const useEditSchedule = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editSchedule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit schedule date started
export const useEditScheduleDateStarted = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editScheduleDateStarted(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete schedule
export const useDeleteSchedule = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteSchedule(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
