import { Outlet } from "react-router-dom";
import PublicContainer from "components/UI/Layouts/Public";

const PublicLayout = () => {
  return (
    <PublicContainer>
      <Outlet />
    </PublicContainer>
  );
};

export default PublicLayout;
