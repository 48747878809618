import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover, Tooltip } from "antd";
import { MdOutlineArrowDropDown, MdNotifications } from "react-icons/md";
import { css } from "@emotion/css";

import News from "components/apps/News";
import { useDesktopViewSelector } from "hooks/useSelector";

const Notifications = () => {
  const { t } = useTranslation();
  const scrollParentRef = useRef();
  const isDesktopView = useDesktopViewSelector();

  const [open, setOpen] = useState();

  const openChangeHandler = useCallback((visible) => setOpen(visible), []);
  const hideHandler = useCallback(() => setOpen(false), []);

  const content = useMemo(() => {
    return (
      <div className="sm:w-[100vw] lg:w-[400px]">
        <div className="h-[400px] overflow-auto pt-2" ref={scrollParentRef}>
          <News
            scrollProps={{ getScrollParent: () => scrollParentRef.current }}
            onHideParent={hideHandler}
          />
        </div>
      </div>
    );
  }, [hideHandler]);

  return (
    <Popover
      id="popover-news"
      placement={!isDesktopView ? "bottom" : "bottomRight"}
      trigger={["click"]}
      destroyTooltipOnHide={true}
      content={content}
      open={open}
      onOpenChange={openChangeHandler}
      overlayClassName={`pt-4 ${css`
        .ant-popover-arrow {
          display: none !important;
        }
        .ant-popover-inner {
          padding: 0px !important;
        }
      `}`}
    >
      <Tooltip
        open={open ? false : undefined}
        title={t("topBar.latest_gajihub_update")}
        trigger={["hover"]}
      >
        <div className="flex flex-row items-center gap-1 cursor-pointer">
          <div className="flex items-center justify-center">
            <MdNotifications className="text-white" size={22} />
          </div>
          <MdOutlineArrowDropDown className="text-white" />
        </div>
      </Tooltip>
    </Popover>
  );
};

export default Notifications;
