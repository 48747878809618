import { memo } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { BsCheckAll } from "react-icons/bs";
import PropTypes from "prop-types";

const SelectAll = ({
  id = "btn-select-all",
  title,
  icon = <BsCheckAll className="text-xl" />,
  type = "primary",
  onClick = () => {},
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const btnTitle = title ? title : t("button.select_all");
  return (
    <Button
      className={`flex items-center justify-center bg-green1 hover:opacity-70 ${className}`}
      id={id}
      type={type}
      icon={icon}
      onClick={onClick}
      {...props}
    >
      {btnTitle}
    </Button>
  );
};

SelectAll.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
};

export default memo(SelectAll);
