import axios from "utils/axios";

export const getPushNotificationTemplates = () => {
  return axios.get(`/hr/pushNotificationTemplates`);
};

export const getPushNotificationTemplate = (data) => {
  return axios.get(`/hr/pushNotificationTemplates/${data?.id}`);
};

export const editPushNotificationTemplate = (data) => {
  return axios.put(`/hr/pushNotificationTemplates/${data?.id}`, data);
};

export const revertPushNotificationTemplate = (data) => {
  return axios.put(`/hr/pushNotificationTemplates/${data?.id}/revert`);
};
