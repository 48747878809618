import { useMemo } from "react";
import { stringify } from "query-string";
import { getToken } from "utils/cookies";
import { useAppSelector, useConfigSelector } from "hooks/useSelector";

// Hook untuk handle url Kledo
const useUrlKledo = (suffix) => {
  const { website_id: websiteId } = useAppSelector();
  const { endpoint } = useConfigSelector();
  const urlKledo = useMemo(() => {
    const searchs = {
      token: getToken(),
      website_id: websiteId,
      endpoint,
    };
    return `${process.env.REACT_APP_KLEDO_URL}${
      suffix ? suffix : ""
    }?${stringify(searchs)}/#/dashboard`;
  }, [endpoint, websiteId, suffix]);

  return urlKledo;
};

export default useUrlKledo;
