import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import _ from "lodash";

import * as api from "apis/taskEss";

export function useTasksEss(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["tasks/ess", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTasksEss(payload);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useTasksPaginationEss = (payload = {}, options = {}) => {
  // const { t } = useTranslation();
  return useInfiniteQuery(
    ["tasks/pagination/ess", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTasksEss({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) => {
        // message.error(t("error.failed_to_load_data_from_server"));
      },
      ...options,
    }
  );
};

export function useTaskEss(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["task/ess", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTaskEss(payload?.id);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useUpdateStatusTaskEss = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.editStatusTaskEss(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries(["task/ess", { id: payload?.id }]);
      const prev = queryClient.getQueryData(["task/ess", { id: payload?.id }]);
      queryClient.setQueryData(["task/ess", { id: payload?.id }], (old) => {
        if (old) {
          old.hr_task_status = payload.hr_task_status;
          return old;
        }
      });
      return { prev };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, payload, context) => {
      queryClient.setQueryData(["task/ess", { id: payload?.id }], context.prev);
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: (response, error, payload) => {
      queryClient.invalidateQueries(["task/ess", { id: payload?.id }]);
    },
  });
};

export const useEditStatusTaskEss = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) =>
      api.editStatusTaskEss({
        id: payload?.id,
        hr_task_status_id: payload?.hr_task_status_id,
      }),
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries([
          "tasks/pagination/ess",
          payload.source.queryKey,
        ]);
        await queryClient.cancelQueries([
          "tasks/pagination/ess",
          payload.destination.queryKey,
        ]);

        const prevSource = queryClient.getQueryData([
          "tasks/pagination/ess",
          payload.source.queryKey,
        ]);
        const prevDestination = queryClient.getQueryData([
          "tasks/pagination/ess",
          payload.destination.queryKey,
        ]);

        queryClient.setQueryData(
          ["tasks/pagination/ess", payload.source.queryKey],
          (old) => {
            if (old) {
              const pages = _.cloneDeep(old?.pages);
              const newPages = pages.map((page) => {
                const findData = page.data.filter(
                  (row) => row?.id !== payload?.id
                );
                page.total -= 1;
                return { ...page, data: findData };
              });
              old.pages = newPages;
              return old;
            }
          }
        );

        queryClient.setQueryData(
          ["tasks/pagination/ess", payload.destination.queryKey],
          (old) => {
            if (old) {
              const pages = _.cloneDeep(old?.pages);
              const newPages = pages.map((page, index) => {
                if (index === 0) {
                  page.total += 1;
                  if (!_.isEmpty(page?.data)) {
                    page.data = [{ ...payload.source.data }, ...page.data];
                  } else {
                    page.data = [{ ...payload.source.data }];
                  }
                }
                return page;
              });
              old.pages = newPages;
              return old;
            }
          }
        );

        return { prevSource, prevDestination };
      },
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error, payload, context) => {
        queryClient.setQueryData(
          ["tasks/pagination/ess", payload.source.queryKey],
          context.prevSource
        );
        queryClient.setQueryData(
          ["tasks/pagination/ess", payload.destination.queryKey],
          context.prevDestination
        );
        message.error(t("error.failed_save_data_to_server"));
      },
      onSettled: (data, error, payload) => {
        queryClient.invalidateQueries([
          "tasks/pagination/ess",
          payload.source.queryKey,
        ]);
        queryClient.invalidateQueries([
          "tasks/pagination/ess",
          payload.destination.queryKey,
        ]);
      },
    }
  );
};
