import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/jobLevel";

//Query untuk get all job levels
export function useJobLevels(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["jobLevels", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobLevels(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get all job levels pagination
export function useJobLevelsPagination({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["jobLevels/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobLevelsPagination(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail job levels
export function useJobLevel({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["jobLevel", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobLevel(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get job levels can be deleted
export function useJobLevelsCanBeDeleted(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["jobLevel/CanBeDeleted"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobLevelsCanBeDeleted();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk add job level
export const useAddJobLevel = () => {
  return useMutation((payload) => api.addJobLevel(payload));
};

// Query untuk edit job level
export const useEditJobLevel = () => {
  return useMutation((payload) => api.editJobLevel(payload));
};

// Query untuk delete job level
export const useDeleteJobLevel = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteJobLevel(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

//Query untuk archive job level
export const useArchiveJobLevel = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.archiveJobLevel(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

//Query untuk unarchive job level
export const useUnarchiveJobLevel = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.unarchiveJobLevel(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
