import axios from "utils/axios";

export const getLocationTrackingRules = () => {
  return axios.get("/hr/locationTrackingRules");
};

export const getLocationTrackingRule = (data) => {
  return axios.get(`/hr/locationTrackingRules/${data?.id}`);
};

export const addLocationTrackingRule = (data) => {
  return axios.post(`/hr/locationTrackingRules`, data);
};

export const editLocationTrackingRule = (data) => {
  return axios.put(`/hr/locationTrackingRules/${data?.id}`, data);
};

export const deleteLocationTrackingRule = (data) => {
  return axios.delete(`/hr/locationTrackingRules/${data?.id}`);
};
