import { useEffect, useCallback } from "react";
import { Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";

import useCallbackPrompt from "./useCallbackPrompt";
import { useSettingSelector } from "./useSelector";

const { Text } = Typography;
const { confirm } = Modal;

const usePreventingLeavePage = (show) => {
  const { t } = useTranslation();
  const settings = useSettingSelector();

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(show);

  const showConfirm = useCallback(() => {
    return confirm({
      className: settings?.dark_mode
        ? `${css`
            .ant-modal-content {
              background-color: #1f1f1f !important;
            }
            .ant-typography {
              color: white !important;
            }
            .ant-btn-default {
              background-color: #141414 !important;
              border-color: #424242 !important;
            }
            .ant-btn-primary {
              background-color: #c6395d !important;
            }
            .ant-btn {
              color: white !important;
            }
          `}`
        : "",
      title: (
        <div className="flex flex-col">
          <Text>{t("users.leave_page")}</Text>
          <Text className="font-normal">
            {t("users.changes_that_you_made_may_not_be_saved")}
          </Text>
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        confirmNavigation();
      },
      okButtonProps: {
        className: "hover:opacity-80",
      },
      onCancel() {
        cancelNavigation();
      },
      cancelButtonProps: {
        className: "hover:opacity-80",
      },
    });
  }, [t, confirmNavigation, cancelNavigation, settings]);

  useEffect(() => {
    if (showPrompt) {
      showConfirm();
    }
  }, [showPrompt, showConfirm]);
};

export default usePreventingLeavePage;
