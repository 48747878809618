import { memo } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { DownloadOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const ExportButton = ({
  id,
  title,
  type,
  onClick,
  icon,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const btnTitle = title ? title : t("button.export");
  return (
    <Button
      className={`hover:opacity-70 ${className}`}
      id={id}
      type={type}
      icon={icon}
      onClick={onClick}
      {...props}
    >
      {btnTitle}
    </Button>
  );
};

ExportButton.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.object,
  id: PropTypes.string,
};

ExportButton.defaultProps = {
  type: "default",
  icon: <DownloadOutlined />,
  onClick: () => {},
  id: "btn-export",
};

export default memo(ExportButton);
