import axios from "axios";
import { notification } from "antd";
import { t } from "i18next";
import { showLoginModal } from "redux/loginSlice";
import { getToken, setToken } from "./cookies";
import { generateBaseUri } from "./helper";

const customAxios = axios.create({
  baseURL: generateBaseUri(),
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
    "X-App": "hr",
  },
  timeout: 100000,
});

const isAuthorization = (config = {}) => {
  return !!config.headers.Authorization;
};

export const requestHandler = (request) => {
  if (isAuthorization(request)) {
    const token = getToken();
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
  }
  return request;
};

export const errorHandler = (error) => {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  const status = error.response && error.response.status;
  let errorMessage =
    error.response && error.response.data.message
      ? error.response.data.message
      : "Network error";
  switch (status) {
    case 401:
      if (window.appReduxStore && window.customHistory) {
        if (window.customHistory.location.pathname !== "/user/login") {
          window.appReduxStore.dispatch(showLoginModal());
          errorMessage = t("notification.login_expired");
        }
      }
      break;

    case 400: // Bad Request
      break;
    case 404: // Not Found
      break;
    case status >= 500: // Server error
      break;

    case 422:
      // Sentry.captureException(errorMessage);
      break;
    default:
      break;
  }
  if (errorMessage) {
    notification.warning({
      // message: title,
      description: errorMessage,
      key: errorMessage,
    });
  }

  return Promise.reject(error?.response);
};

export const successHandler = (response) => {
  // Jika ada response access_token di set kan ke cookies
  const newToken = response.data?.access_token;
  if (newToken && newToken !== getToken()) {
    setToken(response.data?.access_token);
  }
  return response;
};

// Create custom axios GET with cancellation
export const makeGetRequestCreator = () => {
  let call;
  return (url) => {
    if (call) {
      call.cancel("Only one request allowed at a time.");
    }
    call = axios.CancelToken.source();
    return customAxios.get(url, {
      cancelToken: call.token,
    });
  };
};

export const getOnce = makeGetRequestCreator();

customAxios.interceptors.request.use((request) => requestHandler(request));
customAxios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default customAxios;
