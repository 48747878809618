import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import * as api from "apis/users";
import { successLogin } from "./user";
import { useDispatch } from "react-redux";

//Query untuk get all users
export function useUsers({ payload }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["users", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getUsers(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk checkInvitation token
export function useCheckInvitationToken({ payload }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["users/invitation", { payload }],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.checkInvitationToken(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk add user
export const useAddUser = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addUser(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit user
export const useEditUser = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.updateUser(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete user
export const useDeleteUser = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteUser(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk register user
export const useRegisterUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useMutation((payload) => api.registerUser(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        successLogin(data?.data, dispatch);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk accept invitation user
export const useAcceptInvitation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useMutation((payload) => api.acceptInvitation(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        successLogin(data?.data, dispatch);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk resend invitation user
export const useResendInvitation = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.resendInvitation(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
