import axios from "utils/axios";
import { stringify } from "query-string";

export const getReferrals = () => {
  return axios.get(`/referrals`);
};

export const getLeadsReferrals = (params) => {
  const query = stringify(params);
  return axios.get(`/referrals/leads?${query}`);
};

export const addReferral = (data) => {
  return axios.post("/referrals/codes", data);
};

export const deleteReferral = (id) => {
  return axios.delete(`referrals/codes/${id}`);
};
