import { useCallback } from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const clickHandler = useCallback(() => navigate(-1), [navigate]);

  return (
    <Result
      status="404"
      title="404"
      subTitle={t("error.sorry_the_page_you_visited_does_not_exist")}
      extra={
        <Button type="primary" onClick={clickHandler}>
          {t("button.back")}
        </Button>
      }
    />
  );
};

export default Page;
