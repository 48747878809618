import {useMutation, useQuery} from "react-query";
import {
  addSsoDomain,
  deleteSsoDomain, generateSpSso,
  getSsoConfiguration,
  getSsoDomain, saveSsoConfiguration,
  sendCodeSsoDomain, updateSsoConfiguration,
  verifySsoDomain,
} from "apis/sso";
import {message} from "antd";

export const useSsoDomain = ({ options = {} }) => {
  return useQuery(
    ['sso-domain'],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getSsoDomain()

        response = data
      } catch (error) {
        message.error(error?.data?.message || 'Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useAddSsoDomainMutation = () => {
  return useMutation(addSsoDomain)
}

export const useSendCodeSsoDomainMutation = () => {
  return useMutation(sendCodeSsoDomain)
}

export const useVerifySsoDomainMutation = () => {
  return useMutation(verifySsoDomain)
}

export const useDeleteSsoDomainMutation = () => {
  return useMutation(deleteSsoDomain)
}

export const useSsoConfiguration = ({ options = {} } = {}) => {
  return useQuery(
    ['sso-configuration'],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getSsoConfiguration()

        response = data
      } catch (error) {
        message.error(error?.data?.message || 'Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useGenerateSpSso = ({ options = {} } = {}) => {
  return useQuery(
    ['generate-sp-sso'],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await generateSpSso()

        response = data
      } catch (error) {
        message.error(error?.data?.message || 'Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useSaveSsoConfigurationMutation = () => {
  return useMutation(saveSsoConfiguration)
}

export const useUpdateSsoConfigurationMutation = () => {
  return useMutation(updateSsoConfiguration)
}
