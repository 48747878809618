import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/locationTrackingRule";

export function useLocationTrackingRules(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["locationTrackingRules"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getLocationTrackingRules();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useLocationTrackingRule({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["locationTrackingRule", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getLocationTrackingRule(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAddLocationTrackingRule = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addLocationTrackingRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditLocationTrackingRule = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editLocationTrackingRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteLocationTrackingRule = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteLocationTrackingRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
