import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/clientVisit";

export function useClientVisits({ payload = {} }, options = {}) {
  return useQuery(
    ["clientVisits", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getClientVisits(payload);
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(error.message || "Failed to load data from server!"),
      ...options,
    }
  );
}

export const useEditClientVisit = () => {
  const { t } = useTranslation();
  return useMutation((data) => api.editClientVisit(data), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) =>
      message.error(error?.message || t("error.failed_save_data_to_server")),
  });
};
