import { useMemo, memo } from "react";
import { Row, Dropdown } from "antd";
import { FiEdit } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MoreIcon from "../MoreIcon";

const MoreActionEdit = ({ onEdit }) => {
  const { t } = useTranslation();
  // const [visible, setVisible] = useState(false);

  const menuItems = useMemo(() => {
    const items = [
      {
        key: 1,
        onClick: () => {
          if (typeof onEdit === "function") {
            onEdit();
          }
        },
        label: (
          <Row className="flex items-center space-x-2">
            <FiEdit size={15} />
            <span>{t("button.edit")}</span>
          </Row>
        ),
      },
    ];
    return items;
  }, [t, onEdit]);

  return (
    <Dropdown
      key={1}
      id="btn-more-actions-menu"
      type="text"
      trigger={["click"]}
      menu={{ items: menuItems }}
      overlayClassName="w-[130px]"
    >
      <MoreIcon />
    </Dropdown>
  );
};

MoreActionEdit.propTypes = {
  onEdit: PropTypes.func,
};

MoreActionEdit.defaultProps = {
  onEdit: () => {},
};

export default memo(MoreActionEdit);
