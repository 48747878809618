//handle url menu redirect to main feature
export const urlMenu = (url) => {
  switch (url) {
    case "/presence/attendance":
      return "/presence/attendance/daily";
    case "/presence/approval":
      return "/presence/presence-approval/waiting-approval";
    case "/presence/work-schedule":
      return "/presence/work-schedule/fixed-schedule";
    case "/presence/leaves":
      return "/presence/leaves/annual-leave";
    case "/finance/cash-receipt":
      return "/finance/cash-receipt/balance";
    case "/approval":
      return "/approval/waiting-approval";
    case "/timeline":
      return "/timeline/main";
    default:
      return url;
  }
};
