import axios from "utils/axios";

export const getProrateRules = () => {
  return axios.get(`/hr/prorateRules`);
};

export const getProrateRule = (id) => {
  return axios.get(`/hr/prorateRules/${id}`);
};

export const addProrateRule = (data) => {
  return axios.post("/hr/prorateRules", data);
};

export const editProrateRule = (data) => {
  return axios.put(`/hr/prorateRules/${data?.id}`, data);
};

export const deleteProrateRule = (id) => {
  return axios.delete(`/hr/prorateRules/${id}`);
};
