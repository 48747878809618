import axios from "utils/axios";

export const getAttendanceLogRules = () => {
  return axios.get(`/hr/attendanceLogRules`);
};

export const addAttendanceLogRule = (data) => {
  return axios.post(`/hr/attendanceLogRules`, data);
};

export const editAttendanceLogRule = (data) => {
  return axios.put(`/hr/attendanceLogRules/${data?.id}`, data);
};

export const deleteAttendanceLogRule = (data) => {
  return axios.delete(`/hr/attendanceLogRules/${data?.id}`);
};
