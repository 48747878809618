import { useCallback, useMemo, useState, useEffect, memo } from "react";
import { Button, Select } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import _ from "lodash";
import {
  BackwardOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  ForwardOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";

const { Option } = Select;

const MonthFilter = ({ selected, onSelected }) => {
  const { t } = useTranslation();

  const [selectedYear, setSelectedYear] = useState(dayjs(selected).year());

  useEffect(() => {
    if (selected) {
      setSelectedYear(dayjs(selected).year());
      const section = document?.querySelector(
        `#id-${dayjs(selected).format("M")}`
      );
      if (section) {
        section?.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [selected]);

  const monthData = useMemo(() => {
    const months = dayjs.months();
    const data = [];
    months.forEach((month, index) => {
      data.push({
        id: index + 1,
        month,
        year: selectedYear,
        value: `${selectedYear}-${index + 1}`,
      });
    });
    return data;
  }, [selectedYear]);

  //options month 'MMMM'
  const monthOptions = useMemo(() => {
    const options = [];
    const months = dayjs.months();
    months.map((month, index) => {
      options.push(
        <Option key={index} value={`${index + 1}`}>
          {month}
        </Option>
      );
      return null;
    });
    return options;
  }, []);

  const onPreviousYear = useCallback(() => {
    const section = document.querySelector("#id-1");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
    setSelectedYear((prev) =>
      dayjs().year(prev).subtract(1, "year").format("YYYY")
    );
  }, []);

  const onNextYear = useCallback(() => {
    const section = document.querySelector("#id-1");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
    setSelectedYear((prev) => dayjs().year(prev).add(1, "year").format("YYYY"));
  }, []);

  const onThisMonth = useCallback(() => {
    onSelected(dayjs().format("YYYY-MM-DD"));
    setSelectedYear(dayjs().year());
  }, [onSelected]);

  const onMove = (direction) => {
    onSelected(
      dayjs(selected)
        .add(1 * direction, "month")
        .format("YYYY-MM-DD")
    );
    setSelectedYear(
      dayjs(selected)
        .add(1 * direction, "month")
        .format("YYYY")
    );
  };

  return (
    <>
      <div className="flex flex-row items-center">
        <div
          className="flex bg-gray-200 dark:bg-dark4 items-center justify-center w-[68px] p-2 rounded-md hover:opacity-50 h-12"
          role="button"
          onClick={onPreviousYear}
        >
          <BackwardOutlined />
        </div>
        <div
          id="scroll-content"
          className="flex flex-row overflow-auto mx-[5px] hover:overflow-x-scroll"
        >
          {monthData.map((item) => (
            <div
              id={`id-${item.id}`}
              key={item.id}
              role="button"
              className={`${
                _.isEqual(dayjs(selected).format("YYYY-M"), item.value)
                  ? "bg-primary text-white"
                  : "bg-steamedGreen text-gray-900"
              } min-w-[150px] hover:bg-pinkLight hover:text-white p-2 mx-[5px] flex flex-col items-center justify-center rounded-lg`}
              onClick={() => {
                onSelected(dayjs(item.value).format("YYYY-MM-DD"));
              }}
            >
              <span className="font-semibold">{item.month ?? ""}</span>
              <span>{item.year ?? ""}</span>
            </div>
          ))}
        </div>
        <div
          className="flex bg-gray-200 dark:bg-dark4 items-center justify-center w-[68px] p-2 rounded-md hover:opacity-50 h-12"
          role="button"
          onClick={onNextYear}
        >
          <ForwardOutlined />
        </div>
      </div>
      <div className="justify-end my-5 flex flex-row space-x-2">
        <Button onClick={onThisMonth}>{t("date.this_month")}</Button>
        <Select
          value={dayjs(selected).format("M")}
          style={{ width: 120 }}
          onChange={(e) => {
            onSelected(dayjs(`${selectedYear}-${e}`).format("YYYY-MM-DD"));
          }}
        >
          {monthOptions}
        </Select>
        <Button
          type="primary"
          icon={<CaretLeftOutlined />}
          onClick={() => onMove(-1)}
        />
        <Button
          type="primary"
          icon={<CaretRightOutlined />}
          onClick={() => onMove(1)}
        />
      </div>
    </>
  );
};

MonthFilter.propTypes = {
  selected: PropTypes.string,
  onSelected: PropTypes.func,
};

MonthFilter.defaultProps = {
  selected: dayjs().format("YYYY-MM-DD"),
  onSelected: () => {},
};

export default memo(MonthFilter);
