import { useQuery, useMutation, useInfiniteQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/salarySlip";

//Query untuk get all salary Slips
export function useSalarySlips({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["salarySlips", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSalarySlips(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get all salary Slips pagination
export function useSalarySlipsPagination({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["salarySlips/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSalarySlipsPagination(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get infinite scroll salary slips pagination
export const useSalarySlipsPaginationInfiniteScroll = (
  payload = {},
  options = {}
) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["salarySlips/infinitescroll", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSalarySlipsPagination({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

//Query untuk get detail salary slip
export function useSalarySlip({ payload = {} }) {
  const { t } = useTranslation();
  return useQuery(
    ["salarySlip", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSalarySlip(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

// Query untuk add salary slip
export const useAddSalarySlip = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addSalarySlip(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit salary slip
export const useEditSalarySlip = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editSalarySlip(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk move salary slip
export const useMoveSalarySlip = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.moveSalarySlip(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete salary slip
export const useDeleteSalarySlip = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteSalarySlip(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk archive salary slip
export const useArchiveSalarySlip = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.archiveSalarySlip(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk archive salary slip
export const useUnarchiveSalarySlip = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.unarchiveSalarySlip(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
