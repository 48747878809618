// import { CaretDownFilled } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Select as SelectAntd } from "antd";
import { useSettingSelector } from "hooks/useSelector";
import PropTypes from "prop-types";
import { forwardRef, memo } from "react";
import "./select.css";

const Select = memo(
  ({
    innerRef,
    custom,
    optionsSelect,
    selectedColor,
    textDefault,
    ...props
  }) => {
    const settings = useSettingSelector();
    const innerProps = {
      className: `min-w-[190px] text-gray-900 ${
        custom ? `ant-select-selection-${props?.value}` : ""
      } ${
        selectedColor
          ? css`
              div {
                background-color: ${selectedColor}!important;
                color: ${selectedColor
                  ? textDefault
                    ? "black !important"
                    : "white !important"
                  : ""};
              }
              .ant-select-selection-item {
                color: ${settings?.dark_mode && textDefault
                  ? "black !important"
                  : ""};
              }
            `
          : ""
      } ${props?.className}`,
      popupClassName: "p-0",
      ref: innerRef,
      ...props,
    };
    if (optionsSelect) {
      return <SelectAntd {...innerProps}>{optionsSelect}</SelectAntd>;
    }

    return <SelectAntd {...innerProps} />;
  }
);

Select.propTypes = {
  optionsSelect: PropTypes.array || PropTypes.object,
  custom: PropTypes.bool,
};

Select.defaultProps = {
  optionsSelect: [],
  custom: false,
};

export default forwardRef((props, ref) => <Select innerRef={ref} {...props} />);
