import { useEffect, useCallback } from "react";
import { ConfigProvider as AntdProvider, theme, message } from "antd";
import { useTranslation } from "react-i18next";
import store from "store";
import dayjs from "dayjs";
import "dayjs/locale/id";

import english from "locales/en-US";
import indonesia from "locales/id-ID";
import "locales";
import { useConfigSelector, useSettingSelector } from "hooks/useSelector";

const locales = {
  "en-US": english,
  "id-ID": indonesia,
};

const ConfigProvider = ({ children }) => {
  const config = useConfigSelector();
  const settings = useSettingSelector();
  const { i18n } = useTranslation();
  const locale = config?.locale;

  message.config({
    maxCount: 1,
  });

  const setLocale = useCallback(() => {
    if (locale === "id-ID") {
      dayjs.locale("id");
      i18n.changeLanguage(locale);
    } else {
      dayjs.locale("en");
      i18n.changeLanguage(locale);
    }
  }, [i18n, locale]);

  const setDarkMode = useCallback(() => {
    if (settings && settings?.dark_mode === 1) {
      store.set("app.darkMode", 1);
      document.documentElement.classList.add("dark");
      document.documentElement.setAttribute("data-color-scheme", "dark");
    } else {
      store.remove("app.darkMode");
      document.documentElement.classList.remove("dark");
      document.documentElement.setAttribute("data-color-scheme", "light");
    }
  }, [settings]);

  const setThemeColor = useCallback(() => {
    if (settings && settings?.theme_color_value) {
      document.documentElement.style.setProperty(
        "--primary-color",
        settings?.theme_color_value
      );
      store.set("app.themeColorValue", settings?.theme_color_value);
    } else {
      store.set("app.themeColorValue", "#E53F6A");
      document.documentElement.style.setProperty("--primary-color", "#E53F6A");
    }
  }, [settings]);

  const setTimeZone = useCallback(() => {
    if (settings && settings?.app_timezone) {
      store.set("app.timeZone", settings.app_timezone);
      dayjs.tz.setDefault(settings.app_timezone);
    } else {
      if (Intl) {
        const deviceTimezone =
          Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
        store.set("app.timeZone", deviceTimezone);
        if (deviceTimezone) {
          dayjs.tz.setDefault(deviceTimezone);
        }
      }
    }
  }, [settings]);

  useEffect(() => {
    if (locale) {
      setLocale();
    }
  }, [locale, setLocale]);

  useEffect(() => {
    if (settings) {
      setDarkMode();
    }
  }, [settings, setDarkMode]);

  useEffect(() => {
    if (settings) {
      setThemeColor();
    }
  }, [settings, setThemeColor]);

  useEffect(() => {
    if (settings) {
      setTimeZone();
    }
  }, [settings, setTimeZone]);

  const currentLocale = locales[locale];

  return (
    <AntdProvider
      theme={{
        algorithm:
          settings && settings?.dark_mode === 1
            ? theme.darkAlgorithm
            : theme.defaultAlgorithm,
        token: {
          colorPrimary:
            settings && settings?.theme_color_value
              ? settings?.theme_color_value
              : "#E53F6A",
        },
      }}
      locale={currentLocale?.antdData}
    >
      {children}
    </AntdProvider>
  );
};

export default ConfigProvider;
