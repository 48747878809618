import { useQuery, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import _ from "lodash";

import * as api from "apis/shift";

//Query untuk get all shifts
export function useShifts({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["shifts", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getShifts(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get shifts pagination
export function useShiftsPagination({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["shifts/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getShiftsPagination(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get detail shift
export function useShift({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["shift", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getShift(payload?.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk add shift
export const useAddShift = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addShift(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit shift
export const useEditShift = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editShift(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk move shift
export const useMoveShift = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) =>
      api.moveShift({ id: payload.srcId, destination_id: payload.destId }),
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries(["shifts", {}]);
        const previousRoles = queryClient.getQueryCache(["shifts", {}]);

        queryClient.setQueryData(["shifts", {}], (old) => {
          if (old && Array.isArray(old)) {
            const shifts = [...old];
            const srcRole = shifts[payload.dragIndex];
            shifts.splice(payload.dragIndex, 1);
            shifts.splice(payload.hoverIndex, 0, srcRole);
            old = [...shifts];
            return old;
          }
        });

        return { previousRoles };
      },
      onSuccess: ({ data }) => {
        message.success(data?.message || t("shift.shift_updated_successfully"));
      },
      onError: (_err, role, context) => {
        queryClient.setQueryData(["shifts", {}], context.previousRoles);
        message.error(
          !_.isEmpty(_err) && _.isString(_err)
            ? _err
            : t("shift.shift_failed_to_update")
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(["shifts", {}]);
      },
    }
  );
};

// Query untuk delete shift
export const useDeleteShift = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteShift(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
