import { useCallback, useMemo } from "react";
import { Collapse, Form, Upload as UploadAntd } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import classname from "classname";
import PropTypes from "prop-types";
import _ from "lodash";

import { getToken } from "utils/cookies";
import { generateEndpoint } from "utils/helper";
import ItemAttachment from "components/UI/Upload/ItemAttachment";
import useUpload from "components/UI/Upload/hooks/useUpload";
import HideFeature from "components/UI/HideFeature";

const defaultHeader = {
  Authorization: "",
  "X-app": "hr",
};

const Upload = ({
  name = "attachments",
  payloadName = "attachments[]",
  mimeType = "image/jpeg, image/png, application/pdf",
  maxWeight = 10, //max size file upload on MB
  max = 5,
  headers = defaultHeader,
  disabled = false,
  required = false,
  attachments = [],
  action = "", //endpoint attachments
  formItemProps = {},
  onUpload,
  onRemove,
  code,
  expanded = false,
  isAdd,
}) => {
  const { t } = useTranslation();

  const { watchAttachments, beforeUpload, onChange, onPreview } = useUpload({
    name,
    max,
    maxWeight,
    mimeType,
  });

  const formattedHeaders = useMemo(() => {
    return {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
    };
  }, [headers]);

  const removeHandler = useCallback(
    (file) => {
      if (typeof onRemove === "function") {
        onRemove(file);
      }
    },
    [onRemove]
  );

  const itemRender = useCallback(
    (originNode, file, fileList, actions) => {
      return (
        <ItemAttachment
          file={file}
          actions={actions}
          disabled={disabled}
          isAdd={isAdd}
        />
      );
    },
    [disabled, isAdd]
  );

  const uploadAction = useMemo(() => generateEndpoint(action), [action]);

  const UploadComponent = disabled ? UploadAntd : UploadAntd.Dragger;

  const items = useMemo(
    () => [
      {
        key: "1",
        label: (
          <div className="flex flex-row gap-x-1">
            {!!required && <span className="text-red1">*</span>}
            <span>{t("employee.attachment")}</span>
          </div>
        ),
        children: (
          <HideFeature code={code} disabled={!code}>
            <Form.Item name={name} className="mb-0" {...formItemProps}>
              <UploadComponent
                name={payloadName}
                accept={mimeType}
                headers={formattedHeaders}
                disabled={disabled}
                fileList={watchAttachments || []}
                action={uploadAction}
                itemRender={itemRender}
                beforeUpload={beforeUpload}
                onChange={onChange}
                onPreview={onPreview}
                onRemove={removeHandler}
                listType="text"
                multiple
              >
                {!disabled && (
                  <>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {t("property.click_or_drag_file_to_this_area_to_upload")}
                    </p>
                    <div className="ant-upload-hint text-gray-500">
                      <ul>
                        <li>
                          {t("attachment.maximum_file_size", {
                            value: maxWeight,
                          })}
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </UploadComponent>
            </Form.Item>
          </HideFeature>
        ),
      },
    ],
    [
      beforeUpload,
      disabled,
      formItemProps,
      formattedHeaders,
      itemRender,
      maxWeight,
      mimeType,
      name,
      onChange,
      onPreview,
      payloadName,
      removeHandler,
      t,
      uploadAction,
      watchAttachments,
      required,
      code,
    ]
  );

  return (
    <>
      <Collapse
        className={classname({
          "mb-5":
            !required ||
            (!!required && !_.isEmpty(watchAttachments)) ||
            disabled,
          hidden: !!disabled && _.isEmpty(attachments),
        })}
        defaultActiveKey={(required && !disabled) || expanded ? ["1"] : []}
        items={items}
      />
      {!!required && !disabled && _.isEmpty(watchAttachments) && (
        <div className="my-1">
          <span className="text-red1">
            {t("settingOvertime.please_input", {
              value: t("employee.attachment").toLowerCase(),
            })}
          </span>
        </div>
      )}
    </>
  );
};

Upload.propTypes = {
  name: PropTypes.string,
  payloadName: PropTypes.string,
  mimeType: PropTypes.string,
  maxWeight: PropTypes.number,
  max: PropTypes.number,
  headers: PropTypes.object,
  disabled: PropTypes.bool,
  attachments: PropTypes.array,
  action: PropTypes.string,
  formItemProps: PropTypes.object,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
};

export default Upload;
