import localStore from "store";
import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";

export const getMax = (arr = []) => {
  let max;
  arr.forEach((e, i) => {
    if (i === 0) {
      max = e;
    }
    if (max < e) {
      max = e;
    }
  });
  return max;
};

export const cleanBlankValue = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  });

  return obj;
};

export const stripHTMLTag = (str = "") => {
  return str.replace(/<br \/>/g, "\n").replace(/(<([^>]+)>)/gi, "");
};

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

// Key for list
export const keyExtractor = (item, index) => `${index}`;

export const generateBaseUri = () => {
  // Jika env base url di bypass
  const byPassEndpoint = localStore.get("app.bypassEndpoint");
  if (localStore.get("app.isOverwriteBaseUrl") && !!byPassEndpoint) {
    return byPassEndpoint;
  }

  let apiUrl = process.env.REACT_APP_API_URL;
  let baseUrl = apiUrl
    ? apiUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
    : "";
  const hostname = window.location.hostname;
  if (process.env.NODE_ENV === "production") {
    const splitHostname = hostname.split(".");
    if (splitHostname[0] && localStore.get("app.endpoint")) {
      baseUrl = `${splitHostname[0]}.${baseUrl}`;
    }
  }

  let protocol = _.includes(apiUrl, "https://") ? "https://" : "http://";
  if (_.includes("local.jokolodang.com", hostname)) {
    protocol = "http://";
  }
  if (localStore.get("app.endpoint")) {
    apiUrl = `${protocol}${localStore.get("app.endpoint")}/api/v1`;
  } else {
    apiUrl = `${protocol}${baseUrl}`;
    if (_.includes("localhr.jokolodang.com", hostname)) {
      apiUrl = `http://app.kledo.test/api/v1`;
    } else if (_.includes("localsslhr.jokolodang.com", hostname)) {
      apiUrl = `https://app.kledo.test/api/v1`;
    }
  }

  return apiUrl;
};

// Filter for select antd
export const filterOption = (input, option) => {
  if (option.children)
    return (
      option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  return true;
};

export const generateEndpoint = (url) => `${generateBaseUri()}${url}`;

// Get base64 image
export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

// Get dimension of image
export const getDimensions = (file) => {
  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    img.onload = () => {
      resolve({
        height: img.naturalHeight,
        width: img.naturalWidth,
      });
    };
    img.onerror = reject;

    const reader = new FileReader();
    reader.onloadend = (ended) => {
      img.src = ended.target.result;
    };
    reader.readAsDataURL(file);
  });
};

//generate card character by firstname and lastname
export const generateCardName = (name) => {
  const fullName = name.split(" ");
  if (fullName.length > 2) {
    return `${fullName[0].slice(0, 1)}${fullName[2].slice(0, 1)}`;
  } else if (fullName.length > 1) {
    return `${fullName[0].slice(0, 1)}${fullName[1].slice(0, 1)}`;
  } else {
    return `${fullName[0].slice(0, 1)}`;
  }
};

export const generateDayNames = () => {
  return Array.from({ length: 7 }, (_, i) =>
    dayjs().startOf("week").add(i, "day").format("dddd")
  );
};

export const openNewTab = (moduleUrl = "", withBaseUri = true) => {
  const tempLink = document.createElement("a");
  if (withBaseUri) tempLink.href = `${generateBaseUri()}${moduleUrl}`;
  else tempLink.href = moduleUrl;
  tempLink.setAttribute("target", "_blank", "noopener");
  tempLink.click();
};

export const findHrCompanies = (companies = []) => {
  if (!Array.isArray(companies)) {
    return [];
  }
  const filteredCompanies = (companies || []).filter((company) => {
    let apps = company?.apps || [];
    if (!Array.isArray(apps) && typeof apps === "object") {
      apps = Object.values(apps);
    }
    if (Array.isArray(apps)) {
      apps = apps.filter((app) => app.code === "hr").length > 0;
    }
    return apps;
  });

  return filteredCompanies;
};

export const isFeature = ({ billing, code }) =>
  billing?.hidden_feature &&
  _.isArray(billing?.hidden_feature) &&
  (billing?.hidden_feature || [])?.filter((e) => e === code).length > 0;

export const generateCountData = (
  min,
  max,
  range,
  includeMax = false,
  initialFirst
) => {
  const list = [];
  const arrayData = new Array((max - min) / range).fill("count");
  arrayData.map((e, index) => {
    if (index === 0) {
      return list.push(min);
    }
    return list.push(list[index - 1] + range);
  });
  if (initialFirst) {
    list.unshift(initialFirst);
  }
  if (includeMax) {
    list.push(max);
  }
  return list;
};

export const copyclip = (text) => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

export const parseMinutes = (x) => {
  const hours = Math.floor(x / 60);
  const minutes = x % 60;
  return {
    hours,
    minutes,
  };
};

export const parseHours = (x) => {
  if (!x) {
    return null;
  }
  const valueMinutes = parseFloat(x) * 60;
  const hours = Math.floor(valueMinutes / 60);
  const minutes = Math.floor(valueMinutes % 60);
  return {
    hours,
    minutes,
  };
};

export const parsePeriod = (
  dateStarted,
  dateEnded,
  withYear = true,
  withMonth = true,
  withDay = true
) => {
  if (dateStarted && dateEnded) {
    const diff = dayjs(dateStarted).isBefore(dayjs(dateEnded))
      ? dayjs.preciseDiff(dayjs(dateStarted), dayjs(dateEnded), true)
      : null;
    const year =
      withYear && diff?.years > 0 ? `${diff.years} ${t("date.year")} ` : "";
    const month =
      withMonth && diff?.months > 0 ? `${diff.months} ${t("date.month")} ` : "";
    const day = withDay
      ? diff?.days > 0
        ? `${diff.days} ${
            diff?.days === 1 ? t("employee.day") : t("date.days")
          }`
        : `${
            diff?.years === 0 &&
            diff?.months === 0 &&
            diff?.days === 0 &&
            diff?.hours > 0
              ? `1 ${t("employee.day")}`
              : ""
          }`
      : "";
    const formattedPeriod = `${year}${month}${day}`;
    return formattedPeriod ? formattedPeriod : "-";
  }
  return;
};

export const parsePaginateData = (data, withDetail) => {
  const pageData = [];
  let pageDetail = { data: [], total: 0 };
  if (!data) {
    return withDetail ? pageDetail : pageData;
  }
  if (data && Array.isArray(data?.pages)) {
    data.pages.forEach((page) => {
      if (withDetail) {
        pageDetail.total = page?.total || 0;
        (page?.data || []).forEach((row) => {
          pageDetail.data.push(row);
        });
      } else {
        (page?.data || []).forEach((row) => {
          pageData.push(row);
        });
      }
    });
  }
  return withDetail ? pageDetail : pageData;
};

export const parseAttachmentsData = (data) => {
  if (!data) {
    return [];
  }
  const dataAttachments = (data || []).map((item, index) => ({
    ...item,
    uid: `uid-${index}`,
    name: item?.file_name || `attachments-${index + 1}`,
    status: "done",
  }));
  return dataAttachments;
};

export const formatAttachmentsToDB = (data) => {
  if (!data || !Array.isArray(data)) {
    return [];
  }
  const formatted = data
    .filter((row) => !!row?.attachment_url || !!row?.original_url || !!row?.url)
    .map((item) => item?.attachment_url || item?.original_url || item?.url);

  return formatted;
};

export const parseTotalPaginateData = (data) => {
  let total = 0;
  if (!data) {
    return total;
  }
  if (data && !_.isEmpty(data?.pages)) {
    total = data.pages?.[0]?.total || 0;
  }
  return total;
};

export const parseArrayParams = (data) => {
  const params = _.cloneDeep(data);
  const parseParams = params ? JSON.parse(decodeURIComponent(params)) : [];
  const formattedParams = Array.isArray(parseParams)
    ? parseParams.map((row) => row?.value)
    : [];
  return formattedParams;
};

export const parseObjectParams = (data) => {
  const params = _.cloneDeep(data);
  const parseParams = params ? JSON.parse(params) : {};
  if (parseParams.value === 0 || parseParams.value === "0") {
    parseParams.value = null;
  }
  return { name: parseParams?.name || "", value: parseParams?.value || null };
};

//parse to uri multiple setting filter
export const parseMultipleFilterSettingToUri = (key, name, setting) => {
  if (name && key && setting) {
    const filters = {
      [name]: [],
    };
    if (Array.isArray(setting?.[name]) && !_.isEmpty(setting?.[name])) {
      filters[name] = setting[name].map((row) => ({
        label: row?.name,
        value: row?.id,
      }));

      const parseToUri = encodeURIComponent(JSON.stringify(filters[name]));
      return `${key}=${parseToUri}`;
    }
  }
  return "";
};

export const createMockData = (count = 3) => {
  const data = [];
  for (let i = 0; i <= count - 1; i++) {
    data.push({ id: i });
  }

  return data;
};

//color default approval status
export const colorApprovalStatus = (id) => {
  switch (id) {
    case 1:
      return "#FFE0A3";
    case 2:
      return "#D9F489";
    case 3:
      return "#FFA1AC";
    case 4:
      return "#F2994A";
    default:
      return "#FFFFFF";
  }
};

//color default status on cash receipt
export const colorCashReceiptStatus = (id) => {
  switch (id) {
    case 1:
      return "#FFE0A3";
    case 2:
      return "#D9F489";
    case 3:
      return "#FFA1AC";
    case 4:
      return "#46be8a";
    case 5:
      return "#59DD8E";
    case 6:
      return "#E85077";
    default:
      return "#FFFFFF";
  }
};

//key down only allowed number only
export const keyDownNumberOnly = (event) => {
  const allowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Backspace",
    "ArrowLeft",
    "ArrowRight",
    "Delete",
    "Del",
  ];
  if (!allowedKeys.includes(event.key) && !event.ctrlKey) {
    event.preventDefault();
  }
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'ios', 'android', 'windows_phone', or 'other'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "windows_phone";
  }

  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return "other";
};

export function showObjectsWithUniqueProperties(arr, prop1, prop2) {
  return arr.filter((item, index, self) => {
    return (
      index ===
      self.findIndex(
        (t) => t[prop1] === item[prop1] && t[prop2] === item[prop2]
      )
    );
  });
}
