import { memo } from "react";
import { Link } from "react-router-dom";
import { Alert } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useSettingSelector } from "hooks/useSelector";
import { useInitEmployee } from "hooks";

function DemoWarning({ showIcon = true, style, ...props }) {
  const { t } = useTranslation();
  const setting = useSettingSelector();
  const { isRoleEmployee } = useInitEmployee();

  if (setting?.app_demo !== 1 || isRoleEmployee) {
    return null;
  }
  return (
    <Alert
      style={{ backgroundColor: "#ffefb3", color: "black", ...style }}
      message={
        <Trans
          i18nKey={"setup.demo"}
          values={{
            setup: `<Link><bold>${t("setup.demo_link")}</bold></Link>`,
          }}
          components={{ Link: <Link to="/settings/setup" />, bold: <strong /> }}
        />
      }
      type="warning"
      showIcon={showIcon}
      {...props}
    />
  );
}

export default memo(DemoWarning);
