import { memo } from "react";
import { Space } from "antd";
import ProfileMenu from "./ProfileMenu";
import News from "./News";
import AuditActions from "./AuditActions";
import LanguageSelector from "./LanguageSelector";
import CompanySelector from "./CompanySelector";
import ThemeColors from "./ThemeColors";

const TopBar = ({
  hideContent,
  hideCompanySelector,
  hideLanguageSelector,
  hideTimeline,
  hideNews,
  hideDarkModeSelector,
}) => {
  return (
    <div className="bg-primary px-5 lg:h-14 md:h-20 sm:h-20 flex w-full items-center justify-between flex-row">
      <div />
      <Space size="middle" hidden={hideContent}>
        {!hideCompanySelector && <CompanySelector />}
        {!hideLanguageSelector && <LanguageSelector />}
        {!hideTimeline && <AuditActions />}
        {!hideNews && <News />}
        {!hideDarkModeSelector && <ThemeColors />}
        <ProfileMenu />
      </Space>
    </div>
  );
};

export default memo(TopBar);
