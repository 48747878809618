import store from "store";
import _ from "lodash";
import { useAppSelector } from "hooks/useSelector";

const useDefaultPath = () => {
  const { menu } = useAppSelector();
  const defaultPath = (init) => {
    const menuInit = init ? init?.user?.menus?.dashboard : menu ? menu : [];
    if (!_.isEmpty(menuInit)) {
      const firstMenu = _.head(menuInit);
      if (!_.isEmpty(firstMenu?.sub)) {
        const firstSubMenu = _.head(firstMenu.sub);
        return firstSubMenu?.url || "/dashboard";
      }
      return firstMenu?.url || "/dashboard";
    }
    return store.get("app.previousPathname") || "/dashboard";
  };
  return defaultPath;
};

export default useDefaultPath;
