import dayjs from "dayjs";
import { useSelector } from "react-redux";
import currencyFormatter from "currency-formatter";
import { settingSelector } from "redux/selectors";

const useFormatting = () => {
  const setting = useSelector(settingSelector);

  //get date format from inits setting
  const formatDateText = () => setting?.app_date_format || "DD/MM/YYYY";

  //get time format from inits setting
  const formatTimeText = () => setting?.app_time_format || "HH:mm";

  //formatting date by formatDateText
  const formatDate = (date = dayjs(), format) => {
    const defaultFormat = formatDateText();
    const newDate = dayjs(date);

    return newDate.isValid()
      ? newDate.format(format ? format : defaultFormat)
      : "";
  };

  const toDbFormat = (date) => dayjs(date).format("YYYY-MM-DD");

  const formatTime = (date = new Date()) => {
    return dayjs(date).format(formatTimeText());
  };

  const formatDateTime = (date = dayjs()) => {
    return `${formatDate(date)} ${formatTime(date)}`;
  };

  const stringDateToDayjs = (date) => {
    const castToMoment = dayjs(date);
    return castToMoment.isValid() ? castToMoment : dayjs();
  };

  //formating currency by inits setting app_currency_format
  const currency = (
    val,
    unformat = false,
    symbolNon = false,
    ifNull = false,
    precision,
    symbolDefault
  ) => {
    let symbol = setting?.app_currency_symbol
      ? `${setting?.app_currency_symbol} `
      : "Rp ";

    if (!setting?.show_app_currency_symbol) {
      symbol = "";
    }

    if (symbolNon) {
      symbol = "";
    }

    if (symbolDefault) {
      symbol = symbolDefault;
    }

    if (precision === undefined) {
      if (setting?.app_decimals !== null) {
        precision = setting?.app_decimals;
      } else {
        precision = 0;
      }
    }

    let format = { symbol, decimal: ".", precision: 0 };
    if (setting?.app_currency_format === "0.,") {
      format = { symbol, thousand: ".", precision: 0 };
    } else if (setting?.app_currency_format === "2-.") {
      format = { symbol, thousand: "", decimal: ".", precision };
    } else if (setting?.app_currency_format === "2-,") {
      format = { symbol, thousand: "", decimal: ",", precision };
    } else if (setting?.app_currency_format === "2.,") {
      format = { symbol, thousand: ".", decimal: ",", precision };
    } else if (setting?.app_currency_format === "2,.") {
      format = { symbol, thousand: ",", decimal: ".", precision };
    } else if (setting?.app_currency_format === "2 .") {
      format = { symbol, thousand: " ", decimal: ".", precision };
    } else if (setting?.app_currency_format === "2 ,") {
      format = { symbol, thousand: " ", decimal: ",", precision };
    }
    let result = 0;
    if (unformat) {
      val = `${val || ""}`.split(format.thousand || "").join("");
      result = currencyFormatter.unformat(val || 0, format);
    } else {
      result = currencyFormatter.format(val || 0, format);
    }

    if (ifNull && result === "0") {
      return "-";
    }
    if (unformat === "format") {
      return format;
    }
    return result;
  };

  //formatting qty
  const qtyToCurrency = (value, customPrecision) => {
    const qty = parseFloat(value);
    const result = qty - Math.floor(qty) !== 0;
    if (result) {
      const count = qty.toString().split(".")[1];
      let precision = 2;
      if (count) {
        precision = count.length;
      }
      if (Number.isInteger(customPrecision)) {
        precision = customPrecision;
      }
      return currency(qty, false, true, false, precision);
    }

    return currency(qty, false, true, false, 0);
  };

  return {
    formatDateText,
    formatTimeText,
    formatDate,
    currency,
    qtyToCurrency,
    toDbFormat,
    formatTime,
    formatDateTime,
    stringDateToDayjs,
  };
};

export default useFormatting;
