import { createSelector } from "reselect";

export const configSelector = createSelector(
  (state) => state.gajihub?.config,
  (config) => config
);
export const appSelector = createSelector(
  (state) => state.gajihub.app,
  (app) => app
);
export const selectedCompanySelector = createSelector(
  (state) => state.gajihub.app,
  (app) => {
    const findCompany = (app?.companies || [])?.find(
      (company) => company.website_id === app.website_id
    );
    return findCompany;
  }
);
export const titleSelector = createSelector(
  (state) => state.gajihub.app,
  (app) => (app.translation && app.translation.menu ? app.translation.menu : {})
);
export const dashboardSelector = createSelector(
  (state) => state.gajihub.app.dashboard,
  (dashboard) => dashboard
);
export const optionsSelector = createSelector(
  (state) => state.gajihub.app.option,
  (option) => option
);
export const billingSelector = createSelector(
  (state) => state.gajihub.app.billing,
  (billing) => billing
);
export const userSelector = createSelector(
  (state) => state.gajihub.app.user,
  (user) => user
);
export const settingSelector = createSelector(
  (state) => state.gajihub.app?.setting,
  (setting) => setting
);
export const announcementSelector = createSelector(
  (state) => state.gajihub.app.announcement,
  (announcement) => announcement
);
export const permissionSelector = createSelector(
  (state) => state.gajihub.app.permissions || [],
  (permissions) => permissions
);
export const referenceSelector = createSelector(
  (state) => state.references,
  (references) => references
);
export const loginModalSelector = createSelector(
  (state) => state.loginModal,
  (loginModal) => loginModal
);

export const loadingInitSelector = createSelector(
  (state) => state.gajihub.app.loadingInit,
  (loadingInit) => loadingInit
);

export const loadedInitSelector = createSelector(
  (state) => state.gajihub.app.loadedInit,
  (loadedInit) => loadedInit
);

export const isMobileViewSelector = createSelector(
  (state) => state.gajihub.config.isMobileView,
  (isMobileView) => isMobileView
);

export const isTabViewSelector = createSelector(
  (state) => state.gajihub.config.isTabView,
  (isTabView) => isTabView
);

export const isDesktopViewSelector = createSelector(
  (state) => state.gajihub.config,
  (config) => !config.isMobileView && !config.isTabView
);

export const companiesSelector = createSelector(
  (state) => state.gajihub.app.companies,
  (companies) => companies || []
);

export const localeSelector = createSelector(
  (state) => state.gajihub.config.locale,
  (locale) => locale
);

export const roleSelector = createSelector(
  (state) => state.gajihub.config.role,
  (role) => role
);

export const employeeCountAvailableSelector = createSelector(
  (state) => state.gajihub.app.employeeCountAvailable || [],
  (employeeCountAvailable) => employeeCountAvailable
);

export const defaultMinEmployeeOrderSelector = createSelector(
  (state) => state.gajihub.app.defaultMinEmployeeOrder || 0,
  (defaultMinEmployeeOrder) => defaultMinEmployeeOrder
);
