import axios from "utils/axios";
import { stringify } from "query-string";

export const getAttendanceLocations = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/attendanceLocations?${query}`);
};

export const getAttendanceLocation = (data) => {
  return axios.get(`/hr/attendanceLocations/${data?.id}`);
};

export const addAttendanceLocation = (data) => {
  return axios.post(`/hr/attendanceLocations`, data);
};

export const editAttendanceLocation = (data) => {
  return axios.put(`/hr/attendanceLocations/${data?.id}`, data);
};

export const deleteAttendanceLocation = (id) => {
  return axios.delete(`/hr/attendanceLocations/${id}`);
};

export const archiveAttendanceLocation = (id) => {
  return axios.patch(`/hr/attendanceLocations/archive/${id}`);
};

export const unarchiveAttendanceLocation = (id) => {
  return axios.patch(`/hr/attendanceLocations/unarchive/${id}`);
};
