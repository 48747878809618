import { Col, Modal, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const ExportContent = ({ visible, onCancel, onExport, title }) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      okButtonProps={{ className: "hidden" }}
      cancelText={t("button.close")}
      title={title || t("button.export")}
    >
      <div className="my-5">
        <Row justify="space-around" className="border-b border-b-gray-200 py-3">
          <Col>
            <Text
              className="text-link-primary hover:cursor-pointer"
              onClick={() => {
                onExport("xls");
              }}
            >
              {t("attendance.download", { type: "xlsx" })}
            </Text>
          </Col>
          <Col>
            <Text
              className="text-link-primary hover:cursor-pointer"
              onClick={() => {
                onExport("csv");
              }}
            >
              {t("attendance.download", { type: "csv" })}
            </Text>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ExportContent;
