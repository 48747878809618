import { useQuery, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/bpjs";

export const useBpjsSocialSecurityPeriods = (options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["bpjsSocialSecurity/periods"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsSocialSecurityPeriods();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useBpjsHealthcarePeriods = (options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["bpjsHealthcare/periods"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsHealthcarePeriods();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useBpjsSocialSecurityPeriod = ({ payload = {} }, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["bpjsSocialSecurity/period", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsSocialSecurityPeriod(payload?.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useBpjsHealthcarePeriod = ({ payload = {} }, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["bpjsHealthcare/period", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsHealthcarePeriod(payload?.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useBpjsOtherMultiplierBases = (options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["bpjsOtherMultiplierBases"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsOtherMultiplierBases();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useAddBpjsOtherMultiplierBases = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation((payload) => api.addBpjsOtherMultiplierBases(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries(["bpjsOtherMultiplierBases"]);
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteBpjsSocialSecurityPeriod = () => {
  const { t } = useTranslation();
  return useMutation(
    (payload) => api.deleteBpjsSocialSecurityPeriod(payload?.id),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => message.error(t("error.failed_save_data_to_server")),
    }
  );
};

export const useDeleteBpjsHealthcarePeriod = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteBpjsHealthcarePeriod(payload?.id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
