import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/journal";

//Query untuk get finance transactions journal
export function useTransactionsJournal(payload, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["transactions/journal", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getFinanceTransactionsJournal(payload?.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}
