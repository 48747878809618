import { useMutation, useQuery, useQueryClient } from "react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { setSetting } from "redux/configSlice";
import { settingSelector } from "redux/selectors";
import {
  editLeaveAllowance,
  editOvertimePay,
  editProRate,
  editThr,
  editSettingAnnualLeave,
  getLeaveAllowance,
  getOvertimePay,
  getProRate,
  getThr,
  getSettingAnnualLeave,
  getPph21s,
  editPph21s,
  getBpjsSocialSecurities,
  editBpjsSocialSecurities,
  getBpjsHealthcares,
  editBpjsHealthcares,
  getUnpaidLeaveDeductions,
  editUnpaidLeaveDeductions,
  getMobileMenus,
  editMobileMenus,
  getSettingApps,
  editSettingApps,
  getSettingAttendanceLogRule,
  editSettingAttendanceLogRule,
  getSettingsThemeColors,
  editSettingThemeColors,
  getSettingsTimeline,
  editSettingTimeline,
  getSettingsBpjsBasics,
  editSettingBpjsBasics,
  getSettingsCashReceipts,
  editSettingCashReceipts,
  getSettingsReminders,
  editSettingReminders,
  getSettingsPaginations,
  editSettingPaginations,
  getSettingsShowColumns,
  editSettingShowColumns,
  getSettingsCompanyNotificationEss,
  editSettingCompanyNotificationEss,
  getSettingSickLeave,
  editSettingSickLeave,
  uploadImportBpjsMultiplierPerEmployee,
  executeImportBpjsMultiplierPerEmployee,
  getSettingsLocationTrackingRule,
  editSettingLocationTrackingRule,
  getSettingsCompanyTeamEss,
  editSettingCompanyTeamEss,
  getSettingsNumber,
  editSettingNumber,
  getSettingsReimbursement,
  editSettingReimbursment,
  getSettingsFilters,
  editSettingsFilters,
  getSettingsTask,
  editSettingTask,
  getSettingsOvertimeExport,
  editSettingOvertimeExport,
} from "apis";
import { useSettingSelector } from "hooks/useSelector";

export function useThr(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "settings/thr",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getThr();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useEditThr = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editThr(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries("settings/thr");
      const previousInits = queryClient.getQueryData("settings/thr");
      queryClient.setQueryData("settings/thr", payload);
      return { previousInits };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, val, context) => {
      queryClient.setQueryData("settings/thr", context.previousInits);
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("settings/thr");
    },
  });
};

export function useLeaveAllowance(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "settings/leaveAllowance",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getLeaveAllowance();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useEditLeaveAllowance = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editLeaveAllowance(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries("settings/leaveAllowance");
      const previousInits = queryClient.getQueryData("settings/leaveAllowance");
      queryClient.setQueryData("settings/leaveAllowance", payload);
      return { previousInits };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, val, context) => {
      queryClient.setQueryData(
        "settings/leaveAllowance",
        context.previousInits
      );
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("settings/leaveAllowance");
    },
  });
};

export function useOvertimePay(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "settings/overtimePay",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getOvertimePay();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useEditOvertimePay = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editOvertimePay(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries("settings/overtimePay");
      const previousInits = queryClient.getQueryData("settings/overtimePay");
      queryClient.setQueryData("settings/overtimePay", payload);
      return { previousInits };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, val, context) => {
      queryClient.setQueryData("settings/overtimePay", context.previousInits);
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("settings/overtimePay");
    },
  });
};

export function useProRate(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "settings/proRate",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getProRate();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useEditProRate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editProRate(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries("settings/proRate");
      const previousInits = queryClient.getQueryData("settings/proRate");
      queryClient.setQueryData("settings/proRate", payload);
      return { previousInits };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, val, context) => {
      queryClient.setQueryData("settings/proRate", context.previousInits);
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("settings/proRate");
    },
  });
};

export function useSettingAnnualLeave() {
  const { t } = useTranslation();
  return useQuery(
    "settings/annualLeave",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingAnnualLeave();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export const useEditSettingAnnualLeave = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation((payload) => editSettingAnnualLeave(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries("settings/annualLeave");
      const previousInits = queryClient.getQueryData("settings/annualLeave");
      queryClient.setQueryData("settings/annualLeave", {
        ...previousInits,
        ...payload,
      });
      return { previousInits };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, val, context) => {
      queryClient.setQueryData("settings/annualLeave", context.previousInits);
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("settings/annualLeave");
    },
  });
};

export function useSettingPph21s() {
  const { t } = useTranslation();
  return useQuery(
    "settings/pph21s",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getPph21s();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export const useEditSettingPph21s = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation((payload) => editPph21s(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries("settings/pph21s");
      const previousInits = queryClient.getQueryData("settings/pph21s");
      queryClient.setQueryData("settings/pph21s", payload);
      return { previousInits };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, val, context) => {
      queryClient.setQueryData("settings/pph21s", context.previousInits);
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("settings/pph21s");
    },
  });
};

export function useSettingBpjsSocialSecurities() {
  const { t } = useTranslation();
  return useQuery(
    "settings/bpjsSocialSecurities",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getBpjsSocialSecurities();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export const useEditSettingBpjsSocialSecurities = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation((payload) => editBpjsSocialSecurities(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries("settings/bpjsSocialSecurities");
      const previousInits = queryClient.getQueryData(
        "settings/bpjsSocialSecurities"
      );
      queryClient.setQueryData("settings/bpjsSocialSecurities", payload);
      return { previousInits };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, val, context) => {
      queryClient.setQueryData(
        "settings/bpjsSocialSecurities",
        context.previousInits
      );
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("settings/bpjsSocialSecurities");
    },
  });
};

export function useSettingBpjsHealthcares() {
  const { t } = useTranslation();
  return useQuery(
    "settings/bpjsHealthcares",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getBpjsHealthcares();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error("error.failed_to_load_data_from_server"),
    }
  );
}

export const useEditSettingBpjsHealthcares = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation((payload) => editBpjsHealthcares(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries("settings/bpjsHealthcares");
      const previousInits = queryClient.getQueryData(
        "settings/bpjsHealthcares"
      );
      queryClient.setQueryData("settings/bpjsHealthcares", payload);
      return { previousInits };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, val, context) => {
      queryClient.setQueryData(
        "settings/bpjsHealthcares",
        context.previousInits
      );
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("settings/bpjsHealthcares");
    },
  });
};

export function useSettingUnpaidLeaveDeductions() {
  const { t } = useTranslation();
  return useQuery(
    "settings/unpaidLeaveDeduction",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getUnpaidLeaveDeductions();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export const useEditSettingUnpaidLeaveDeductions = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation((payload) => editUnpaidLeaveDeductions(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries("settings/unpaidLeaveDeduction");
      const previousInits = queryClient.getQueryData(
        "settings/unpaidLeaveDeduction"
      );
      queryClient.setQueryData("settings/unpaidLeaveDeduction", payload);
      return { previousInits };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, val, context) => {
      queryClient.setQueryData(
        "settings/unpaidLeaveDeduction",
        context.previousInits
      );
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("settings/unpaidLeaveDeduction");
    },
  });
};

//Query untuk get setting mobile menu
export function useSettingMobileMenus(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/mobileMenus",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getMobileMenus();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings mobile menu
export const useEditSettingMobileMenu = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation((payload) => editMobileMenus(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries("/settings/mobileMenus");
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query untuk get setting attendance log
export function useSettingAttendanceLog(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/attendanceLog",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingAttendanceLogRule();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings attendance log
export const useEditSettingAttendanceLog = () => {
  // const { t } = useTranslation();
  return useMutation((payload) => editSettingAttendanceLogRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    // onError: (error) => {
    //   message.error(t("error.failed_save_data_to_server"));
    // },
  });
};

//Query untuk get setting theme colors
export function useSettingThemeColors(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/themeColors",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsThemeColors();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings theme colors
export const useEditSettingThemeColors = () => {
  const { t } = useTranslation();
  return useMutation((payload) => editSettingThemeColors(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

export function useSettingApps(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/apps",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingApps();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk update settings apps
export const useEditSettingApps = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const oldSettings = useSelector(settingSelector);
  return useMutation((payload) => editSettingApps(payload), {
    onMutate: async (payload) => {
      dispatch(setSetting(payload));
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      dispatch(setSetting(oldSettings));
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query untuk get setting timeline
export function useSettingTimelines(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/timelines",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsTimeline();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings timeline
export const useEditSettingTimeline = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editSettingTimeline(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
        queryClient.invalidateQueries("/settings/timelines");
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query untuk get setting ump bpjs
export function useSettingBpjsBasics(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/bpjsBasics",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsBpjsBasics();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings ump bpjs
export const useEditSettingBpjsBasics = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editSettingBpjsBasics(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
        queryClient.invalidateQueries("/settings/bpjsBasics");
        queryClient.invalidateQueries(["bpjsOtherMultiplierBases"]);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query untuk get setting kasbon
export function useSettingCashReceipts(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/cashReceipts",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsCashReceipts();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings kasbon
export const useEditSettingCashReceipts = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editSettingCashReceipts(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
        queryClient.invalidateQueries("/settings/cashReceipts");
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query untuk get setting pagination
export function useSettingPaginations(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/paginations",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsPaginations();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings pagination
export const useEditSettingPaginations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useMutation((payload) => editSettingPaginations(payload), {
    onMutate: async (payload) => {
      dispatch(setSetting(payload));
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query untuk get setting reminders
export function useSettingReminders(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/reminders",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsReminders();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings reminders
export const useEditSettingReminders = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editSettingReminders(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
        queryClient.invalidateQueries("/settings/reminders");
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query untuk get setting show columns
export function useSettingShowColumns(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/showColumns",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsShowColumns();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings show columns
export const useEditSettingShowColumns = () => {
  const setting = useSettingSelector();
  const dispatch = useDispatch();
  return useMutation((payload) => editSettingShowColumns(payload), {
    onMutate: async (payload) => {
      const prevSetting = setting;
      dispatch(setSetting(payload));
      return { setting: prevSetting };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
      } else {
        message.error(data?.message);
      }
    },
    onError: (_err, payload, context) => {
      const payloadKey = Object.keys(payload).map((key) => key);
      if (!_.isEmpty(payloadKey)) {
        const key = payloadKey[0];
        dispatch(setSetting({ [key]: context.setting[key] }));
      }
    },
  });
};

//Query untuk get setting filters
export function useSettingFilters(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/filters",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsFilters();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings filters
export const useEditSettingFilters = () => {
  const setting = useSettingSelector();
  const dispatch = useDispatch();
  return useMutation((payload) => editSettingsFilters(payload?.data), {
    onMutate: async (payload) => {
      const prevSetting = setting;
      dispatch(setSetting(payload?.params));
      return { setting: prevSetting };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
      } else {
        message.error(data?.message);
      }
    },
    onError: (_err, payload, context) => {
      const payloadKey = Object.keys(payload).map((key) => key);
      if (!_.isEmpty(payloadKey)) {
        const key = payloadKey[0];
        dispatch(setSetting({ [key]: context.setting[key] }));
      }
    },
  });
};

//Query untuk get setting company notification ESS
export function useSettingCompanyNotificationEss(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/company-notification-ess",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsCompanyNotificationEss();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings company notification ESS
export const useEditSettingCompanyNotificationEss = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editSettingCompanyNotificationEss(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
        queryClient.invalidateQueries("/settings/company-notification-ess");
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query get setting cuti sakit
export function useSettingSickLeave() {
  const { t } = useTranslation();
  return useQuery(
    "settings/sickLeave",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingSickLeave();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk edit setting cuti sakit
export const useEditSettingSickLeave = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation((payload) => editSettingSickLeave(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries("settings/sickLeave");
      const previousSetting = queryClient.getQueryData("settings/sickLeave");
      queryClient.setQueryData("settings/sickLeave", payload);
      return { previousSetting };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, val, context) => {
      queryClient.setQueryData("settings/sickLeave", context.previousSetting);
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("settings/sickLeave");
    },
  });
};

//Query untuk get setting location tracking rule
export function useSettingsLocationTrackingRule(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/locationTrackingRule",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsLocationTrackingRule();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings location tracking rule
export const useEditSettingLocationTrackingRule = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editSettingLocationTrackingRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
        queryClient.invalidateQueries(["/settings/locationTrackingRule"]);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query untuk get setting company team ESS
export function useSettingsCompanyTeamEss(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "/settings/companyTeamEss",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsCompanyTeamEss();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update settings company team ESS
export const useEditSettingCompanyTeamEss = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editSettingCompanyTeamEss(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
        queryClient.invalidateQueries(["/settings/companyTeamEss"]);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query untuk get setting number
export function useSettingsNumber(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "settings/numbers",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsNumber();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update setting number
export const useEditSettingNumber = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useMutation((payload) => editSettingNumber(payload), {
    onMutate: async (payload) => {
      dispatch(setSetting(payload));
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query untuk get setting reimbursement
export function useSettingsReimbursement(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "settings/reimbursement",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsReimbursement();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update setting reimbursement
export const useEditSettingReimbursement = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editSettingReimbursment(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries(["/settings/reimbursement"]);
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query untuk get setting task
export function useSettingsTask(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "settings/task",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsTask();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update setting task
export const useEditSettingTask = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => editSettingTask(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries(["/settings/task"]);
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

//Query untuk get setting overtime export
export function useSettingsOvertimeExport(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "settings/overtimeExports",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSettingsOvertimeExport();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk update setting overtime export
export const useEditSettingOvertimeExport = () => {
  const { t } = useTranslation();
  const setting = useSettingSelector();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation((payload) => editSettingOvertimeExport(payload), {
    onMutate: async (payload) => {
      const prevSetting = setting;
      dispatch(setSetting(payload));
      return { setting: prevSetting };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries(["settings/overtimeExports"]);
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (_err, payload, context) => {
      message.error(t("error.failed_save_data_to_server"));
      const payloadKey = Object.keys(payload).map((key) => key);
      if (!_.isEmpty(payloadKey)) {
        const key = payloadKey[0];
        dispatch(setSetting({ [key]: context.setting[key] }));
      }
    },
  });
};

// Query upload import multiplier bpjs per employee
export const useUploadImportBpjsMultiplierPerEmployee = () => {
  return useMutation(
    (payload) => uploadImportBpjsMultiplierPerEmployee(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => {},
    }
  );
};

// Query execute import multiplier bpjs per employee
export const useExecuteImportBpjsMultiplierPerEmployee = () => {
  const { t } = useTranslation();
  return useMutation(
    (payload) => executeImportBpjsMultiplierPerEmployee(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => message.error(t("error.failed_save_data_to_server")),
    }
  );
};
