import axios from "utils/axios";
import { stringify } from "query-string";

export const getShifts = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/shifts?${query}`);
};

export const getShiftsPagination = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/shifts/pagination?${query}`);
};

export const getShift = (id) => {
  return axios.get(`/hr/shifts/${id}`);
};

export const addShift = (data) => {
  return axios.post("/hr/shifts", data);
};

export const editShift = (data) => {
  return axios.put(`/hr/shifts/${data.id}`, data);
};

export const moveShift = (data) => {
  return axios.patch(`/hr/shifts/${data?.id}/move`, data);
};

export const deleteShift = (id) => {
  return axios.delete(`/hr/shifts/${id}`);
};
