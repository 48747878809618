import axios from "utils/axios";
import { stringify } from "query-string";

export const getAttendances = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/attendances/pagination?${query}`);
};

export const getAttendancesDaily = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/attendances/daily/pagination?${query}`);
};

export const getAttendancesSummary = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/attendances/summary/pagination?${query}`);
};

export const editAttendance = (data) => {
  return axios.patch(`/hr/attendances/${data?.id}`, data);
};

export const massUpdateAttendances = (data) => {
  return axios.patch(`/hr/attendances/massUpdate`, data);
};

export const uploadImportAttendances = (data) => {
  return axios.post("/hr/attendances/uploadImport", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const executeImportAttendances = (data) => {
  return axios.post("/hr/attendances/executeImport", data);
};
