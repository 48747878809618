import { Button, Empty } from "antd";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const EmptyMessage = ({ onReset }) => {
  const { t } = useTranslation();
  return (
    <Empty description={<span>{t("settings.data_not_found")}</span>}>
      <Button type="primary" onClick={onReset}>
        {t("settings.reset")}
      </Button>
    </Empty>
  );
};

export default memo(EmptyMessage);
