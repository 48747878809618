import _ from "lodash";
import Player from "../Player";
import InfiniteScroll from "react-infinite-scroller";
import { useNews } from "queries";
import { useMemo } from "react";
import { Skeleton } from "components/UI";
import { List, Spin } from "antd";
import dayjs from "dayjs";

const per_page = 15;

const Media = ({ videoId, imageUrl, title }) => {
  if (!_.isEmpty(videoId)) {
    return (
      <Player
        containerClassName="video-responsive rounded shadow"
        className="youtube-player"
        videoId={videoId}
        autoplay={0}
      />
    );
  }

  if (!_.isEmpty(imageUrl) && _.isEmpty(videoId)) {
    return (
      <div className="flex w-full">
        <img src={imageUrl} alt={title} className="rounded shadow" />
      </div>
    );
  }

  return null;
};

const News = ({ scrollProps, isMain = false }) => {
  const payload = useMemo(
    () => ({
      per_page,
    }),
    []
  );

  const { status, data, fetchNextPage, isFetchingNextPage } = useNews(payload);

  const formattedData = useMemo(() => {
    if (!data) {
      return { data: [] };
    }
    let newData = { data: [] };
    let total = 0;
    (data?.pages || []).forEach((page) => {
      total = page?.total;
      (page?.data || []).forEach((row) => {
        newData.data.push(row);
      });
    });
    return { ...newData, total };
  }, [data]);

  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={1}
      loadMore={fetchNextPage}
      hasMore={formattedData.data.length < formattedData?.total}
      useWindow={isMain}
      {...scrollProps}
    >
      <Skeleton className='px-5' loading={status === "loading"} active>
        <List
          dataSource={formattedData?.data || []}
          renderItem={(item) => (
            <List.Item key={item.id} className="px-5 flexw-full border-b-0">
              <div className="flex flex-col w-full">
                <span className="font-bold text-base uppercase">
                  {item?.title}
                </span>
                <span className="text-xs text-gray-400">
                  {dayjs(item?.created_at).fromNow()}
                </span>
                <div className={item?.youtube_url ? "my-3" : ""}>
                  <Media
                    videoId={item?.youtube_url}
                    imageUrl={item?.image_url}
                    title={item?.title}
                  />
                </div>
                <div className={item?.youtube_url ? "" : "my-3"}> 
                  <span>{item?.body}</span>
                </div>
              </div>
            </List.Item>
          )}
        />
        {isFetchingNextPage && (
          <div className="flex w-full justify-center items-center my-5">
            <Spin />
          </div>
        )}
      </Skeleton>
    </InfiniteScroll>
  );
};

export default News;
