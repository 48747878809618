import React, { memo } from "react";
import YouTube from "react-youtube";
import "./style.css";

const Player = ({
  videoId,
  options,
  autoplay = 1,
  containerClassName,
  ...props
}) => {
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay,
      mute: 0,
    },
    ...options,
  };

  return (
    <div
      className={containerClassName ? containerClassName : "video-responsive"}
    >
      <YouTube
        className="youtube-player"
        videoId={videoId}
        opts={opts}
        {...props}
      />
    </div>
  );
};

export default memo(Player);
