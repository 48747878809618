import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import _ from "lodash";

import * as api from "apis/taskActivity";

export const useTaskActivities = (payload = {}, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["taskActivities", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTaskActivities(payload);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useTaskActivitiesPagination = (payload = {}, options = {}) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["taskActivities/pagination", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTaskActivities({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export function useTaskActivity(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["task", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTaskActivity(payload?.id);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAddTaskActivity = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addTaskActivity(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditTaskActivity = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => {
      const editPayload = _.cloneDeep(payload);
      delete editPayload.payloadList;
      return api.editTaskActivity(editPayload);
    },
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries([
          "taskActivities/pagination",
          payload.payloadList,
        ]);

        const prev = queryClient.getQueryData([
          "taskActivities/pagination",
          payload.payloadList,
        ]);

        queryClient.setQueryData(
          ["taskActivities/pagination", payload.payloadList],
          (old) => {
            if (old) {
              const pages = _.cloneDeep(old?.pages);
              const newPages = pages.map((page) => {
                const updateData = page.data.map((row) => {
                  if (row?.id === payload?.id) {
                    row.content = payload.content;
                  }
                  return row;
                });
                return { ...page, data: updateData };
              });
              old.pages = newPages;
              return old;
            }
          }
        );
        return { prev };
      },
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error, payload, context) => {
        queryClient.setQueryData(
          ["taskActivities/pagination", payload.payloadList],
          context.prev
        );
        message.error(t("error.failed_save_data_to_server"));
      },
      onSettled: (data, error, payload) => {
        queryClient.invalidateQueries([
          "taskActivities/pagination",
          payload.payloadList,
        ]);
      },
    }
  );
};

export const useDeleteTaskActivity = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation((payload) => api.deleteTaskActivity(payload?.id), {
    onMutate: async (payload) => {
      await queryClient.cancelMutations([
        "taskActivities/pagination",
        payload.payloadList,
      ]);

      const prev = queryClient.getQueryData([
        "taskActivities/pagination",
        payload.payloadList,
      ]);

      queryClient.setQueryData(
        ["taskActivities/pagination", payload.payloadList],
        (old) => {
          if (old) {
            const pages = _.cloneDeep(old.pages);
            const newPages = pages.map((page) => {
              const filterPage = page.data.filter(
                (row) => row?.id !== payload?.id
              );
              return { ...page, data: filterPage };
            });
            old.pages = newPages;
            return old;
          }
        }
      );
      return { prev };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, payload, context) => {
      queryClient.setQueryData(
        ["taskActivities/pagination", payload.payloadList],
        context.prev
      );
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: (data, error, payload) => {
      queryClient.invalidateQueries([
        "taskActivities/pagination",
        payload.payloadList,
      ]);
    },
  });
};

export const useDeleteAttachmentTaskActivity = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteAttachmentTaskActivity(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
