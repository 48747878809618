import axios from "utils/axios";
import { stringify } from "query-string";

export const getOvertimeComponentTypes = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/overtimeComponentTypes/pagination?${query}`);
};

export const getOvertimeComponentType = (data) => {
  return axios.get(`/hr/overtimeComponentTypes/${data?.id}`);
};

export const getOvertimeComponentTypeEmployee = (data) => {
  return axios.get(`/hr/overtimeComponentTypes/employees/${data?.id}`);
};

export const addOvertimeComponentType = (data) => {
  return axios.post(`/hr/overtimeComponentTypes`, data);
};

export const editOvertimeComponentType = (data) => {
  return axios.put(`/hr/overtimeComponentTypes/${data?.id}`, data);
};

export const deleteOvertimeComponentType = (id) => {
  return axios.delete(`/hr/overtimeComponentTypes/${id}`);
};
