import { useCallback, useMemo } from "react";
import { Empty, Skeleton, Spin, Timeline, Typography } from "antd";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import dayjs from "dayjs";
import _ from "lodash";

import { useAuditLogs } from "queries";
import { LinkTransType } from "components/UI";
import { css } from "@emotion/css";
import { FaDotCircle } from "react-icons/fa";

const { Text } = Typography;

const per_page = 15;

const hideExtraLog = [
  "hr_approval_case_id",
  "date",
  "date_started",
  "date_ended",
  "period_started",
  "leave_type",
  "hr_employee_id",
];

const AuditLog = ({
  paramsPayload,
  scrollProps,
  isMain = false,
  onHideParent,
}) => {
  const { t } = useTranslation();

  const payload = useMemo(
    () => ({
      per_page,
    }),
    []
  );

  const { status, data, fetchNextPage, isFetchingNextPage } = useAuditLogs({
    payload: paramsPayload ? paramsPayload : payload,
  });

  const formattedData = useMemo(() => {
    if (!data) {
      return { data: [] };
    }
    let newData = { data: [] };
    let total = 0;
    (data?.pages || []).forEach((page) => {
      total = page?.total;
      (page?.data || []).forEach((row) => {
        newData.data.push(row);
      });
    });
    return { ...newData, total };
  }, [data]);

  const iconColor = useCallback((action) => {
    switch (action) {
      case "add":
        return "green2";
      case "delete":
        return "red3";
      default:
        return "primary";
    }
  }, []);

  const timelineItems = useMemo(() => {
    const items = [];
    if (!_.isEmpty(formattedData?.data) && Array.isArray(formattedData.data)) {
      formattedData.data.forEach((item) => {
        items.push({
          dot: (
            <FaDotCircle
              className={`text-sm relative top-1 text-${iconColor(
                item?.event_category
              )}`}
            />
          ),
          label: (
            <Text className="text-xs">
              {dayjs.tz(item?.created_at).from(dayjs().tz())}
            </Text>
          ),
          children: (
            <div className="flex flex-col pt-[2px]">
              <div className="text-[13px]">
                <LinkTransType
                  onClick={
                    typeof onHideParent === "function" ? onHideParent : null
                  }
                  title={item?.desc?.tran_type}
                  transTypeId={item?.tran_type_id}
                  data={{
                    id: item?.tran_id,
                    extra: item?.extra,
                    event_category: item?.event_category,
                  }}
                />
                {` ${item?.desc?.event} `}
                {!!item?.desc?.name && `${t("audit.by")} ${item?.desc?.name}`}
              </div>
              {!!item?.extra &&
                Object.keys(item.extra).map((key, idx) => (
                  <div key={idx}>
                    {!!item.extra[key] &&
                      !Array.isArray(item.extra[key]) &&
                      !hideExtraLog.includes(key) && (
                        <Text className="text-[10px]">{`${key}: ${
                          typeof item.extra[key] === "object"
                            ? item.extra[key]?.name
                            : item.extra[key]
                        }`}</Text>
                      )}
                  </div>
                ))}
              {isMain && item?.remote_addr ? (
                <Text className="text-[13px]">{item.remote_addr}</Text>
              ) : (
                <br />
              )}
              <Text className="text-[10px] mt-1">
                {dayjs(item.created_at).format("DD MMM YYYY HH:mm")}
              </Text>
            </div>
          ),
        });
      });
    }
    return items;
  }, [formattedData, t, isMain, iconColor, onHideParent]);

  return (
    <>
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={fetchNextPage}
        hasMore={formattedData.data.length < formattedData?.total}
        useWindow={isMain}
        {...scrollProps}
      >
        <Skeleton className={`px-5`} loading={status === "loading"} active>
          {_.isEmpty(formattedData?.data) ? (
            <Empty className="my-10" />
          ) : (
            <Timeline
              mode="left"
              className={`pr-3 overflow-hidden ${css`
                .ant-timeline-item-pending {
                  height: 30px !important;
                }
              `}`}
              items={timelineItems}
              pending={isFetchingNextPage}
              pendingDot={
                <div className="mt-5">
                  <Spin />
                </div>
              }
            />
          )}
        </Skeleton>
      </InfiniteScroll>
    </>
  );
};

export default AuditLog;
