import { useCallback, useState } from "react";
import { Layout } from "antd";
import dayjs from "dayjs";

import Logo from "assets/images/logo.png";
import LogoLight from "assets/images/logo-light.png";
import ChangeBaseUrlModal from "pages/user/login/ChangeBaseUrlModal";

const { Footer } = Layout;

const PublicFooter = ({ inverse }) => {
  const [countClick, setCountClick] = useState(0);
  const [showChangeBaseUrl, setShowChangeBaseUrl] = useState(false);

  const clickLayout = useCallback(() => {
    setCountClick((prev) => prev + 1);
    if (countClick + 1 > 10) {
      setShowChangeBaseUrl(true);
    }
  }, [countClick]);

  const closeModal = useCallback(() => {
    setCountClick(0);
    setShowChangeBaseUrl(false);
  }, []);

  return (
    <>
      <Footer className={`p-3 ${inverse ? "bg-primary dark:bg-dark1" : ""}`}>
        <div className="flex flex-col gap-1 items-center" onClick={clickLayout}>
          <span className={inverse ? "text-white" : "text-gray-500"}>
            Powered By
          </span>
          <a
            href="https://gajihub.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-link-primary"
          >
            <img width={70} src={inverse ? LogoLight : Logo} alt="Logo" />
          </a>
          <span className={inverse ? "text-gray-200" : "text-gray-500"}>
            &copy;{` ${dayjs().format("YYYY")} GajiHub. All rights reserved.`}
          </span>
        </div>
      </Footer>
      {!!showChangeBaseUrl && (
        <ChangeBaseUrlModal visible={showChangeBaseUrl} onCancel={closeModal} />
      )}
    </>
  );
};

export default PublicFooter;
