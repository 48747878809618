import axios from "utils/axios";
import { stringify } from "query-string";

export const getFaqsByName = (name) => axios.get(`/faqs/${name}`);

export const getFaqCategory = (params) => {
  const query = stringify(params);
  return axios.get(`/faqs/category?${query}`);
};

export const getFaqs = (params) => {
  const query = stringify(params);
  return axios.get(`/faqs?${query}`);
};

export const getFaqDetail = (id) => axios.get(`/faqs/${id}/detail`);

export const likeFaq = (id) => axios.post(`/faqs/${id}/like`);

export const dislikeFaq = (id) => axios.post(`/faqs/${id}/dislike`);
