import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Popconfirm, Progress, Spin, Typography } from "antd";
import {
  FileImageOutlined,
  FilePdfOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { TbFileOff } from "react-icons/tb";
import { MdOutlineImageNotSupported } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import classname from "classname";
import _ from "lodash";

const ItemAttachment = ({ file, actions, disabled, isAdd }) => {
  const { t } = useTranslation();

  const fileType = file?.name?.split(".").pop() || "png";

  const data = useMemo(() => {
    if (!_.isEmpty(file?.response?.data)) {
      return _.first(file.response.data);
    }
    return {};
  }, [file?.response?.data]);

  const previewHandler = useCallback(() => {
    if (file?.status === "done") {
      actions.preview();
    }
  }, [file?.status, actions]);

  const generateThumbnail = useMemo(() => {
    if (file?.status === "uploading") {
      return <Spin indicator={<LoadingOutlined />} size="large" spinning />;
    } else if (file?.status === "error") {
      if (fileType === "pdf") {
        return <TbFileOff className="w-[35px] h-[35px] text-primary" />;
      } else {
        return (
          <MdOutlineImageNotSupported className="w-[35px] h-[35px] text-primary" />
        );
      }
    } else {
      if (fileType === "pdf") {
        return (
          <FilePdfOutlined
            className="text-primary text-[35px] hover:cursor-pointer hover:opacity-80"
            onClick={previewHandler}
          />
        );
      } else {
        return data?.thumbnail_url || file?.thumbnail_url ? (
          <img
            className="w-[50px] h-[50px] object-cover hover:cursor-pointer hover:opacity-80 rounded-md"
            src={data?.thumbnail_url || file?.thumbnail_url}
            alt="thumbnail-attachment"
            onClick={previewHandler}
          />
        ) : (
          <FileImageOutlined
            className="text-primary text-[35px] hover:cursor-pointer hover:opacity-80"
            onClick={previewHandler}
          />
        );
      }
    }
  }, [file, fileType, data, previewHandler]);

  return (
    <Card
      className="mt-3"
      bodyStyle={{
        paddingTop: 15,
        paddingBottom: 15,
      }}
    >
      <div className="flex flex-row items-center justify-between">
        <div
          className={classname("flex flex-row items-center gap-x-2", {
            "w-full": file?.status === "uploading",
            "w-fit": file?.status !== "uploading",
          })}
        >
          <div>{generateThumbnail}</div>
          {file?.status === "uploading" ? (
            <Progress percent={file?.percent ? Math.ceil(file?.percent) : 0} />
          ) : (
            <Typography.Text
              className={classname({
                "text-red1": file?.status === "error",
                "text-link-primary hover:cursor-pointer":
                  file?.status === "done",
              })}
              onClick={previewHandler}
            >{`${file?.name}${
              file?.source_file ? `${" "}(${t(`${file.source_file}`)})` : ""
            }${
              file?.status === "error"
                ? ` (${t("attachment.upload_failed")})`
                : ""
            }`}</Typography.Text>
          )}
        </div>
        {!disabled && file?.status !== "uploading" && (
          <>
            {file?.status === "done" ? (
              <Popconfirm
                title={t("attachment.delete_attachment")}
                description={t("popconfirm.title.delete")}
                onConfirm={actions?.remove}
              >
                <FaRegTrashAlt className="w-[20px] h-[20px] text-primary hover:opacity-75 hover:cursor-pointer" />
              </Popconfirm>
            ) : (
              <FaRegTrashAlt
                className="w-[20px] h-[20px] text-primary hover:opacity-75 hover:cursor-pointer"
                onClick={actions?.remove}
              />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default ItemAttachment;
