import {
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

const IconAttachment = ({ fileName, ...props }) => {
  const formattedFileName = fileName.split(".");
  const fileType =
    formattedFileName.length > 0
      ? formattedFileName[formattedFileName.length - 1]
      : null;
  switch (fileType) {
    case "pdf":
      return <FilePdfOutlined {...props} />;
    case "xls":
    case "xlsx":
      return <FileExcelOutlined {...props} />;
    case "png":
    case "jpg":
    case "jpeg":
      return <FileImageOutlined {...props} />;
    default:
      return <FileOutlined {...props} />;
  }
};

export default IconAttachment;
