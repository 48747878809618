import { useState, useEffect } from "react";
import { Button, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { getMobileOperatingSystem } from "utils/helper";

const InstallPromptWrapper = () => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [platform, setPlatform] = useState();

  useEffect(() => {
    if (getMobileOperatingSystem() === "android") {
      setVisible(true);
      setPlatform("android");
    }
    if (getMobileOperatingSystem() === "ios") {
      setVisible(true);
      setPlatform("ios");
    }
  }, []);

  return (
    <div
      className="w-full bg-white dark:bg-dark1 border-t-[1px] border-t-white dark:border-t-gray-500 fixed bottom-0 py-4 z-50"
      style={{ display: visible ? "flex" : "none" }}
    >
      <div className="flex flex-col flex-1 items-center justify-center">
        <Typography.Text className="text-center font-semibold text-[14px]">
          {t(
            platform === "ios"
              ? "notification.gajihub_is_now_on_app_store"
              : "notification.gajihub_is_now_on_google_play"
          )}
        </Typography.Text>
        <div className="my-3 space-x-2">
          <Button
            type="primary"
            target="_blank"
            rel="noopener noreferrer"
            href={
              platform === "ios"
                ? "https://apps.apple.com/gb/app/gajihub-payroll-absensi/id1604540147"
                : "https://play.google.com/store/apps/details?id=com.gajihub.app"
            }
          >
            {t("notification.install_now")}
          </Button>
          <Button onClick={() => setVisible(false)}>
            {t("notification.later")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InstallPromptWrapper;
