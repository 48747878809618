import { useQueryClient } from "react-query";

const useRefetchQueries = () => {
  const queryClient = useQueryClient();
  // refetch all active queries partially matching a query key:
  const listKeys = [
    "taskActivities/pagination",
    "taskActivities/pagination/ess",
    "connectFinanceSetting",
    "/settings/cashReceipts",
  ];
  const refetchQueries = async () => {
    // Iterate over the list of query keys and refetch each one individually
    await Promise.all(
      listKeys.map((key) => queryClient.refetchQueries(key, { active: true }))
    );
  };

  return { refetchQueries };
};

export default useRefetchQueries;
