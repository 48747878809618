import { stringify } from "query-string";
import axios from "utils/axios";

export const getNumbers = () => {
  return axios.get(`/hr/numbers`);
};

export const getNumbersFormat = () => {
  return axios.get(`/hr/numbers/format`);
};

export const getNumber = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/numbers/${params?.id}?${query}`);
};

export const getNumbersLogs = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/numbers/logs?${query}`);
};

export const getDetailNumbersLogs = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/numbers/logs/${params?.id}?${query}`);
};

export const getNumberNext = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/numbers/next/${params?.name}?${query}`);
};

export const getNumberExist = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/numbers/exists/${params?.name}?${query}`);
};

export const editNumber = (data) => {
  return axios.put(`/hr/numbers/${data.id}`, data);
};
