import axios from "utils/axios";
import { stringify } from "query-string";

export const getSptPph21Report = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/reportings/sptPph21?${query}`);
};

export const getPayrollPaymentSummaryReport = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/payrollPayments/report/summary?${query}`);
};

export const getDetailPayrollPaymentSummaryReport = (id, params) => {
  const query = stringify(params);
  return axios.get(`/hr/payrollPayments/${id}/report/summary?${query}`);
};

export const getPayrollSummaryPerComponentReport = (params) => {
  const query = stringify(params);
  return axios.get(
    `/hr/payrollPayments/report/summaryPerSalaryComponent?${query}`
  );
};

export const getDetailPayrollSummaryPerComponentReport = (id, params) => {
  const query = stringify(params);
  return axios.get(
    `/hr/payrollPayments/${id}/report/summaryPerSalaryComponent?${query}`
  );
};

export const getPayrollPaymentSummaryReportPerOrganization = (params) => {
  const query = stringify(params);
  return axios.get(
    `/hr/payrollPayments/report/summaryPerOrganization?${query}`
  );
};

export const getDetailPayrollPaymentSummaryReportPerOrganization = (
  id,
  params
) => {
  const query = stringify(params);
  return axios.get(
    `/hr/payrollPayments/${id}/report/summaryPerOrganization?${query}`
  );
};

export const getPayrollPaymentReport = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/payrollPayments/report/paymentSlip?${query}`);
};

export const getDetailPayrollPaymentReport = (id) => {
  return axios.get(`/hr/payrollPayments/report/paymentSlip/${id}`);
};
