import axios  from "utils/axios";
import { stringify } from "query-string";

export const getSchedulePatterns = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/schedulePatterns?${query}`);
};

export const getSchedulePatternsPagination = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/schedulePatterns/pagination?${query}`);
};

export const getSchedulePattern = (id) => {
  return axios.get(`/hr/schedulePatterns/${id}`);
};

export const addSchedulePattern = (data) => {
  return axios.post("/hr/schedulePatterns", data);
};

export const editSchedulePattern = (data) => {
  return axios.put(`/hr/schedulePatterns/${data.id}`, data);
};

export const deleteSchedulePattern = (id) => {
  return axios.delete(`/hr/schedulePatterns/${id}`);
};

export const archiveSchedulePattern = (id) => {
  return axios.patch(`/hr/schedulePatterns/${id}/archive`);
};

export const unarchiveSchedulePattern = (id) => {
  return axios.patch(`/hr/schedulePatterns/${id}/unarchive`);
};