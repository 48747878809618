import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { titleSelector } from "redux/selectors";

const TranslationBreadcrumb = ({ name }) => {
  const { t } = useTranslation();
  const titleMenu = useSelector(titleSelector);

  const title = useMemo(() => {
    if (titleMenu[name]) {
      return titleMenu[name];
    }
    return t(name);
  }, [name, t, titleMenu]);
  return <span>{title}</span>;
};

export default TranslationBreadcrumb;
