import { Skeleton as SkeletonAntd } from "antd";
import { memo } from "react";

const Skeleton = ({ children, ...props }) => {
  return (
    <SkeletonAntd className="my-5" {...props}>
      {children}
    </SkeletonAntd>
  );
};

export default memo(Skeleton);
