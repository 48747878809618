import axios from "utils/axios";

export const getTaxWithholders = () => {
  return axios.get("/hr/pph21Withholders");
};

export const addTaxWithholder = (data) => {
  return axios.post("/hr/pph21Withholders", data);
};

export const editTaxWithholder = (data) => {
  return axios.put(`/hr/pph21Withholders/${data.id}`, data);
};

export const deleteTaxWithholder = (id) => {
  return axios.delete(`/hr/pph21Withholders/${id}`);
};
