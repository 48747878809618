import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/overtime";

export function useOvertimes({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["overtimes/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getOvertimes(payload);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAddOvertime = () => {
  //const { t } = useTranslation();
  return useMutation((payload) => api.addOvertime(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    // onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditOvertime = () => {
  //const { t } = useTranslation();
  return useMutation((payload) => api.updateOvertime(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    //onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteOvertime = () => {
  //const { t } = useTranslation();
  return useMutation((payload) => api.deleteOvertime(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    //onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query upload import overtime
export const useUploadImportOvertimes = () => {
  return useMutation((payload) => api.uploadImportOvertimes(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query upload import update overtime
export const useUploadImportUpdateOvertimes = () => {
  return useMutation((payload) => api.uploadImportUpdateOvertimes(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query execute import overtime
export const useExecuteImportOvertimes = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.executeImportOvertimes(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query execute import update overtime
export const useExecuteImportUpdateOvertimes = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.executeImportUpdateOvertimes(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
