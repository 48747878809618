import axios from "utils/axios";

export const getBpjsSocialSecurityPeriods = () => {
  return axios.get(`/hr/bpjsSocialSecurityPeriods`);
};

export const getBpjsHealthcarePeriods = () => {
  return axios.get(`/hr/bpjsHealthcarePeriods`);
};

export const getBpjsSocialSecurityPeriod = (id) => {
  return axios.get(`/hr/bpjsSocialSecurityPeriods/${id}`);
};

export const getBpjsHealthcarePeriod = (id) => {
  return axios.get(`/hr/bpjsHealthcarePeriods/${id}`);
};

export const getBpjsOtherMultiplierBases = () => {
  return axios.get(`/hr/bpjsOtherMultiplierBases`);
};

export const addBpjsOtherMultiplierBases = (data) => {
  return axios.post(`/hr/bpjsOtherMultiplierBases`, data);
};

export const deleteBpjsSocialSecurityPeriod = (id) => {
  return axios.delete(`/hr/bpjsSocialSecurityPeriods/${id}`);
};

export const deleteBpjsHealthcarePeriod = (id) => {
  return axios.delete(`/hr/bpjsHealthcarePeriods/${id}`);
};
