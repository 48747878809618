import { useCallback, useMemo, useState } from "react";
import { Dropdown, Tooltip } from "antd";
import className from "classname";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { useAppSelector, useConfigSelector } from "hooks/useSelector";
import { useChangeLanguage } from "queries";
import { useTranslation } from "react-i18next";

const LaguangeSelector = () => {
  const { t } = useTranslation();
  const { lang } = useAppSelector();
  const { locale } = useConfigSelector();
  const changeLanguage = useChangeLanguage();

  const [open, setOpen] = useState(false);

  const openChangeHandler = useCallback((visible) => setOpen(visible), []);
  const hideHandler = useCallback(() => setOpen(false), []);

  const language = locale.substr(0, 2);

  const onChangeLanguage = useCallback(
    ({ key }) => {
      hideHandler();
      changeLanguage.mutate({ locale: key });
    },
    [changeLanguage, hideHandler]
  );

  const menuItems = useMemo(() => {
    const items = (lang || [])?.map((item) => ({
      key: item?.value,
      label: item?.name,
      icon: (
        <div
          className={className(
            "rounded-full w-6 h-6 flex items-center justify-center text-white",
            item?.value === locale ? "bg-green-400" : "bg-gray-300"
          )}
        >
          <span className="uppercase">{(item?.value || "")?.substr(0, 2)}</span>
        </div>
      ),
    }));
    return items;
  }, [lang, locale]);

  return (
    <Dropdown
      overlayClassName="pt-0"
      placement="bottomRight"
      open={open}
      onOpenChange={openChangeHandler}
      menu={{
        items: menuItems,
        selectedKeys: [locale],
        onClick: onChangeLanguage,
      }}
      trigger={["click"]}
    >
      <Tooltip
        open={open ? false : undefined}
        title={t("topBar.change_language")}
        trigger={["hover"]}
        overlayClassName="absolute top-[48px]"
      >
        <div className="flex flex-row items-center gap-1 cursor-pointer">
          <span className="text-white font-medium uppercase">{language}</span>
          <MdOutlineArrowDropDown className="text-white" />
        </div>
      </Tooltip>
    </Dropdown>
  );
};

export default LaguangeSelector;
