import { memo, useCallback } from "react";
import { Pagination as PaginationAntd } from "antd";
import PropTypes from "prop-types";
import { useEditSettingPaginations } from "queries";

const Pagination = ({
  onShowSizeChange,
  keyPerPage,
  hideOnSinglePage,
  ...props
}) => {
  const editPagination = useEditSettingPaginations();

  const onChangePageSize = useCallback(
    (current, size) => {
      if (keyPerPage) {
        editPagination.mutate({
          [keyPerPage]: size,
        });
      }
      if (typeof onShowSizeChange === "function") {
        onShowSizeChange(current, size);
      }
    },
    [onShowSizeChange, editPagination, keyPerPage]
  );

  return (
    <PaginationAntd
      pageSizeOptions={props?.pageSizeOptions || []}
      onShowSizeChange={onChangePageSize}
      hideOnSinglePage={
        hideOnSinglePage
          ? hideOnSinglePage
          : props.total <= props?.minPageSize
          ? true
          : false
      }
      {...props}
    />
  );
};

Pagination.propTypes = {
  current: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
  hideOnSinglePage: PropTypes.bool,
  showSizeChanger: PropTypes.bool,
  keyPerPage: PropTypes.string,
  minPageSize: PropTypes.number,
  pageSizeOptions: PropTypes.array,
};

Pagination.defaultProps = {
  current: 1,
  pageSize: 15,
  onChange: () => {},
  onShowSizeChange: () => {},
  total: 0,
  showSizeChanger: false,
  keyPerPage: "",
  minPageSize: 15,
  pageSizeOptions: [15, 25, 50, 100],
};

export default memo(Pagination);
