import { memo } from "react";
import { Tooltip, Typography } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const { Text } = Typography;

const LabelForm = ({ label, tooltip, required, labelProps }) => {
  return (
    <div className="mb-2 flex flex-row items-center">
      <Text {...labelProps}>
        {required && <span className="text-[14px]">{`${"*"}${" "}`}</span>}
        {label}
        {!!tooltip && (
          <Tooltip className="ml-1" title={tooltip}>
            <QuestionCircleOutlined className="text-[#9C9C9C]" />
          </Tooltip>
        )}
      </Text>
    </div>
  );
};

LabelForm.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
};

LabelForm.defaultProps = {
  label: "",
  tooltip: "",
  required: false,
};

export default memo(LabelForm);
