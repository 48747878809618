import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/schedulePattern";

//Query untuk get all schedule patterns
export function useSchedulePatterns({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["schedulePatterns", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSchedulePatterns(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get all schedule patterns pagination
export function useSchedulePatternsPagination({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["schedulePatterns/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSchedulePatternsPagination(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail schedule pattern
export function useSchedulePattern({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["schedulePattern", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSchedulePattern(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

// Query untuk add schedule pattern
export const useAddSchedulePattern = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addSchedulePattern(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit schedule pattern
export const useEditSchedulePattern = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editSchedulePattern(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete schedule pattern
export const useDeleteSchedulePattern = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteSchedulePattern(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

//Query untuk archive schedule pattern
export const useArchiveSchedulePattern = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.archiveSchedulePattern(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

//Query untuk unarchive schedule pattern
export const useUnarchiveSchedulePattern = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.unarchiveSchedulePattern(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
