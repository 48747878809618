import axios from "utils/axios";
import { stringify } from "query-string";

export const getPosts = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/posts/pagination?${query}`);
};

export const getPost = (id) => {
  return axios.get(`/hr/posts/${id}`);
};

export const addPost = (data) => {
  return axios.post(`/hr/posts`, data);
};

export const editPost = (data) => {
  return axios.put(`/hr/posts/${data?.id}`, data);
};

export const restorePost = (id) => {
  return axios.put(`/hr/posts/${id}/restore`);
};

export const restoreComment = (id) => {
  return axios.put(`/hr/posts/comments/${id}/restore`);
};

export const pinPost = (id) => {
  return axios.put(`/hr/posts/${id}/pin`);
};

export const unpinPost = (id) => {
  return axios.put(`/hr/posts/${id}/unpin`);
};

export const deletePost = (id) => {
  return axios.delete(`/hr/posts/${id}`);
};

export const deleteComment = (id) => {
  return axios.delete(`/hr/posts/comments/${id}`);
};

export const deleteAttachmentPost = (data) => {
  return axios.delete(
    `/hr/posts/${data?.postId}/attachment/delete/${data?.id}`
  );
};
