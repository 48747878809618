import { useState, useRef, useEffect } from "react";

const useOuterClick = (initialValue) => {
  const [clickedOutside, setClickedOutside] = useState(initialValue);
  const ref = useRef();

  const handleClickOutside = (e) => {
    if (!ref?.current?.contains(e?.target)) {
      setClickedOutside(true);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return { ref, handleClickInside, handleClickOutside, clickedOutside };
};

export default useOuterClick;
