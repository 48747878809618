import { PageHeader as PageHeaderAntd,  } from "@ant-design/pro-layout";
import { css } from "@emotion/css";

const PageHeader = ({ title, extra, ...props }) => {
  return (
    <PageHeaderAntd
      ghost={false}
      title={title}
      className={`px-0 mb-3 sm:mt-5 lg:mt-0 ${css`
        .ant-page-header-heading-extra {
          display: flex !important;
          align-items: center !important;
        }
      `}`}
      extra={extra}
      {...props}
    />
  );
};

export default PageHeader;
