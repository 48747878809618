import { MaskedInput as MaskedInputAntd } from "antd-mask-input";

const MaskedInput = ({ ...props }) => {
  return (
    <MaskedInputAntd
      className="bg-white text-gray-900 border-[#d9d9d9] hover:border-primary dark:bg-dark1 dark:border-dark4 dark:hover:border-[#dc627e] dark:text-white placeholder:text-gray-900 placeholder:dark:text-white"
      {...props}
    />
  );
};

export default MaskedInput;
