import { useQuery, useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import * as api from "apis/payrollPayment";

//Query untuk get all payroll payments
export function usePayrollPayments({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["payrollPayments", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPayrollPayments(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get detail payroll payment
export function usePayrollPayment({ payload = {} }) {
  const { t } = useTranslation();
  return useQuery(
    ["payrollPayment", payload],
    async () => {
      let response = {};
      try {
        if (payload && payload.id) {
          const {
            data: { data },
          } = await api.getPayrollPayment(payload.id, payload);
          response = data;
        }
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get payroll payment history
export function usePayrollPaymentHistory({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["payrollPayment/history", payload],
    async () => {
      let response = {};
      try {
        if (payload && payload.id) {
          const {
            data: { data },
          } = await api.getPayrollPaymentHistory(payload);
          response = data;
        }
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get payroll payment preview
export function usePayrollPaymentPreview({ payload = {} }) {
  const { t } = useTranslation();
  return useQuery(
    ["payrollPayment/preview", payload],
    async () => {
      let response = {};
      try {
        if (payload && payload.id) {
          const { data } = await api.getPayrollPaymentPreview(payload);
          response = data;
        }
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get payroll payment methods
export function usePayrollPaymentMethods(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["payrollPayment/methods", payload],
    async () => {
      let response = {};
      try {
        if (payload && payload.id) {
          const {
            data: { data },
          } = await api.getPayrollPaymentMethods(payload.id);
          response = data;
        }
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get multiple payroll payment methods
export function useMultiplePayrollPaymentMethods(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["multiplePayrollPayment/methods", payload],
    async () => {
      let response = {};
      try {
        if (payload && payload.id) {
          const {
            data: { data },
          } = await api.getMultiplePayrollPaymentMethods(payload.id);
          response = data;
        }
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get payroll payment connect methods
export function usePayrollPaymentConnectMethods(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["payrollPaymentConnect/methods", payload],
    async () => {
      let response = {};
      try {
        if (payload?.id) {
          const {
            data: { data },
          } = await api.getPayrollPaymentConnectMethods(payload.id);
          response = data;
        }
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get multiple payroll payment connect methods
export function useMultiplePayrollPaymentConnectMethods(
  payload = {},
  options = {}
) {
  const { t } = useTranslation();
  return useQuery(
    ["multiplePayrollPaymentConnect/methods", payload],
    async () => {
      let response = {};
      try {
        if (payload?.id) {
          const {
            data: { data },
          } = await api.getMultiplePayrollPaymentConnectMethods(payload.id);
          response = data;
        }
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get report bpjs ketenagakerjaan
export function useReportBpjsSocialSecurities({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["bpjsSocialSecurities/report", payload],
    async () => {
      let response = {};
      try {
        const {
          data: { data },
        } = await api.getReportBpjsSocialSecurities(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get report bpjs kesehatan
export function useReportBpjsHealthcares({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["bpjsHealthcares/report", payload],
    async () => {
      let response = {};
      try {
        const {
          data: { data },
        } = await api.getReportBpjsHealthcares(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk add payroll payment non fixed salary slip
export const useAddPayrollPaymentNonFixedSalarySlip = () => {
  const { t } = useTranslation();
  return useMutation(
    (payload) => api.addPayrollPaymentNonFixedSalarySlip(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => message.error(t("error.failed_save_data_to_server")),
    }
  );
};

// Query untuk split payroll payment slip
export const useSplitPayrollPaymentSlip = () => {
  return useMutation((payload) => api.splitPayrollPaymentSlip(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query untuk unsplit payroll payment slip
export const useUnsplitPayrollPaymentSlip = () => {
  return useMutation((payload) => api.unsplitPayrollPaymentSlip(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query untuk edit payroll payment
export const useEditPayrollPayment = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editPayrollPayment(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit income manual component
export const useEditIncomeManualComponent = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editIncomeManualComponent(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit deduction manual component
export const useEditDeductionManualComponent = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editDeductionManualComponent(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit payroll component overtimes
export const useEditPayrollComponentOvertimes = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editPayrollComponentOvertimes(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit shortcut payroll component overtime
export const useEditShortcutPayrollComponentOvertime = () => {
  const { t } = useTranslation();
  return useMutation(
    (payload) => api.editShortcutPayrollComponentOvertime(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => message.error(t("error.failed_save_data_to_server")),
    }
  );
};

// Query untuk reset payroll component overtime
export const useResetPayrollComponentOvertime = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.resetPayrollComponentOvertime(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk update status payroll payment
export const useUpdatePayrollPaymentStatus = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.updatePayrollPaymentStatus(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk update status multi payroll payment
export const useUpdatePayrollPaymentReadyToPay = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.updatePayrollPaymentReadyToPay(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk confirm payroll payment
export const useUpdatePayrollPaymentConfirm = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.confirmPayrollPayment(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk confirm multiple payroll payment
export const useUpdateMultiplePayrollPaymentConfirm = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.confirmMultiplePayrollPayment(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk confirm payroll payment connect
export const useUpdatePayrollPaymentConnectConfirm = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.confirmPayrollPaymentConnect(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk confirm multiple payroll payment connect
export const useUpdateMultiplePayrollPaymentConnectConfirm = () => {
  const { t } = useTranslation();
  return useMutation(
    (payload) => api.confirmMultiplePayrollPaymentConnect(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => message.error(t("error.failed_save_data_to_server")),
    }
  );
};

// Query untuk update payroll payment note
export const useUpdatePayrollPayment = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.updatePayrollPayment(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk mass download salary slip send to email
export const useMassPayrollPaymentsDownload = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.massPayrollPaymentsDownload(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) =>
      message.error(t("error.failed_to_load_data_from_server")),
  });
};

// Query untuk export download payroll send to email
export const useExportPayrollDownload = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.exportPayrollDownload(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) =>
      message.error(t("error.failed_to_load_data_from_server")),
  });
};

// Query untuk delete payroll payment non fixed salary slip
export const useDeletePayrollPaymentNonFixedSalarySlip = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((id) => api.deletePayrollPaymentNonFixedSalarySlip(id), {
    onSuccess: ({ data }, id) => {
      if (data?.success) {
        message.success(data?.message);
        queryClient.removeQueries(["payrollPayment", id]);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

//Query untuk delete attachment payroll payment
export const useDeleteAttachmentPayrollPayment = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteAttachmentPayrollPayment(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query upload import payroll payment
export const useUploadImportPayrollPayment = () => {
  return useMutation((payload) => api.uploadImportPayrollPayment(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query execute import payroll payment
export const useExecuteImportPayrollPayment = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.executeImportPayrollPayment(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query upload import note payroll payment
export const useUploadImportNotePayrollPayment = () => {
  return useMutation((payload) => api.uploadImportNotePayrollPayment(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query execute import note payroll payment
export const useExecuteImportNotePayrollPayment = () => {
  const { t } = useTranslation();
  return useMutation(
    (payload) => api.executeImportNotePayrollPayment(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => message.error(t("error.failed_save_data_to_server")),
    }
  );
};
