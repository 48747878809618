import { useQueryClient, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/nps";
import { useDispatch, useSelector } from "react-redux";
import { appSelector } from "redux/selectors";
import { setApp } from "redux/configSlice";

// Query untuk create nps
export const useCreateNps = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.createNps(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk ignore nps
export const useIgnoreNps = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const oldApp = useSelector(appSelector);
  return useMutation(() => api.ignoreNps(), {
    onMutate: async () => {
      await queryClient.cancelQueries("inits");
      const previousInits = queryClient.getQueryData("inits");
      dispatch(setApp({ showNps: 0 }));
      queryClient.setQueryData("inits", (old) => {
        if (old) {
          return { ...old, show_nps: 0 };
        }
      });
      return { previousInits };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (_err, lockDate, context) => {
      queryClient.setQueryData("inits", context.previousInits);
      dispatch(setApp(oldApp));
    },
    onSettled: () => {
      queryClient.invalidateQueries("inits");
    },
  });
};
