import axios from "utils/axios";
import { stringify } from "query-string";

export const getSchedules = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/schedules?${query}`);
};

export const getSchedulesPagination = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/schedules/pagination?${query}`);
};

export const getSchedule = (id) => {
  return axios.get(`/hr/schedules/${id}`);
};

export const getScheduleEffectiveDate = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/schedules/new?${query}`);
};

export const addSchedule = (data) => {
  return axios.post("/hr/schedules", data);
};

export const massCreateSchedulesMessage = (data) => {
  return axios.post("hr/schedules/massCreate/message", data);
};

export const massCreateSchedules = (data) => {
  return axios.post("hr/schedules/massCreate", data);
};

export const editSchedule = (data) => {
  return axios.put(`/hr/schedules/${data.id}`, data);
};

export const editScheduleDateStarted = (data) => {
  return axios.patch(`hr/schedules/${data?.id}/dateStarted`, data);
};

export const deleteSchedule = (id) => {
  return axios.delete(`/hr/schedules/${id}`);
};
