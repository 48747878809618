import axios from "utils/axios";
import { stringify } from "query-string";

export const getEmployees = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/employees/pagination?${query}`);
};

export const getEmployeesPersonalIdentity = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/employees/personalIdentity/pagination?${query}`);
};

export const getEmployeesPersonalContact = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/employees/personalContact/pagination?${query}`);
};

export const getEmployeesCareer = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/employees/career/pagination?${query}`);
};

export const getEmployeesPayroll = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/employees/payroll/pagination?${query}`);
};

export const getEmployeesLoginEss = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/employees/loginEss/pagination?${query}`);
};

export const getEmployee = (id) => {
  return axios.get(`/hr/employees/${id}`);
};

export const getDateStartedWorkMax = (id) => {
  return axios.get(`/hr/employees/${id}/dateStartedWorkMax`);
};

export const getEmployeesTotal = () => {
  return axios.get(`/hr/employees/total`);
};

export const addEmployeePersonal = (data) => {
  return axios.post("/hr/employees/personal", data);
};

export const addEmployeeCareer = (data) => {
  return axios.post("/hr/employees/career", data);
};

export const addEmployeePayroll = (data) => {
  return axios.post("/hr/employees/payroll", data);
};

export const addEmployeeTermination = (data) => {
  return axios.post("/hr/employeeTerminations", data);
};

export const rehireEmployeeTermination = (data) => {
  return axios.post(`/hr/employeeTerminations/${data?.id}/rehire`, data);
};

export const uploadImportEmployee = (data) => {
  return axios.post("/hr/employees/uploadImport", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const executeImportEmployee = (data) => {
  return axios.post("/hr/employees/executeImport", data);
};

export const uploadImportUpdateEmployee = (data) => {
  return axios.post("/hr/employees/update/uploadImport", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const executeImportUpdateEmployee = (data) => {
  return axios.post("/hr/employees/update/executeImport", data);
};

export const editPersonalIdentity = (data) => {
  return axios.patch(`/hr/employees/${data.id}/personalIdentity`, data);
};

export const editPersonalContact = (data) => {
  return axios.patch(`/hr/employees/${data.id}/personalContact`, data);
};

export const editPersonalEducation = (data) => {
  return axios.patch(`/hr/employees/${data.id}/personalEducation`, data);
};

export const editDateStartedWork = (data) => {
  return axios.patch(`/hr/employees/${data.id}/dateStartedWork`, data);
};

export const editPayrollNpwp = (data) => {
  return axios.patch(`/hr/employees/${data.id}/payrollNpwp`, data);
};

export const editPayrollBpjs = (data) => {
  return axios.patch(`/hr/employees/${data.id}/payrollBpjs`, data);
};

export const editPayrollBankAccount = (data) => {
  return axios.patch(`/hr/employees/${data.id}/payrollBankAccount`, data);
};

export const updateEmployeePhoto = (data) => {
  return axios.patch(`/hr/employees/${data.id}/attachment/updateAvatar`, data);
};

export const updateEmployeeAttachment = (data) => {
  return axios.patch(
    `/hr/employees/${data.id}/attachment/updateDocuments`,
    data
  );
};

export const resetEmployeePassword = (id) => {
  return axios.patch(`/hr/employees/${id}/resetPassword`);
};

export const deleteEmployee = (id) => {
  return axios.delete(`/hr/employees/${id}`);
};

export const deleteEmployeeTermination = (data) => {
  return axios.delete(`/hr/employeeTerminations/${data?.id}`);
};
