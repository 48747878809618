import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Upload, message } from "antd";
import { useTranslation } from "react-i18next";
import {
  DeleteOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import _ from "lodash";

import axios from "utils/axios";
import { generateEndpoint } from "utils/helper";
import { getToken } from "utils/cookies";

// const authorization = `Bearer ${getToken()}`;

const UploadAttachment = ({
  mimeType,
  name,
  action,
  headers,
  maxWeight,
  onUpload,
  onRemove,
  data,
  isAdd,
  disabled,
  linkPreview,
  isLinkPreview,
}) => {
  const { t } = useTranslation();
  const [attachment, setAttachment] = useState([]);

  useEffect(() => {
    if (data) {
      setAttachment(data);
    }
  }, [data]);

  const defaultHeaders = useMemo(() => {
    return {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
    };
  }, [headers]);

  const uploadAction = useMemo(() => generateEndpoint(action), [action]);

  const changeHandler = useCallback(
    ({ file, fileList }) => {
      const files = [];
      (fileList || []).forEach((row) => {
        if (row?.url) {
          files.push({ ...row, file_name: row?.name });
        } else {
          if (row?.response?.data && Array.isArray(row?.response?.data)) {
            files.push({
              ...row,
              uid: row?.uid,
              name: row?.name,
              file_name: row?.name,
              status: row?.status,
              url:
                row?.response &&
                Array.isArray(row?.response?.data) &&
                row?.response?.data.length > 0
                  ? row?.response?.data[0]
                  : row.response?.data?.url,
            });
          } else {
            const responseFiles = row?.response?.data?.attachments || [];
            const findFile = responseFiles.filter(
              (e) => files.filter((val) => val?.id === e?.id).length === 0
            );
            const currentFiles =
              responseFiles.length > 1
                ? findFile.length === 1
                  ? findFile[0]
                  : null
                : responseFiles.length === 1
                ? responseFiles[0]
                : null;
            files.push({
              ...row,
              uid: row?.uid,
              name: row?.name,
              file_name: row?.name,
              status: row?.status,
              id: currentFiles ? currentFiles?.id : null,
              url: currentFiles ? currentFiles?.original_url : "",
              preview_url:
                isLinkPreview && linkPreview && currentFiles?.id
                  ? `${linkPreview}/${currentFiles?.id}`
                  : currentFiles
                  ? currentFiles?.url
                  : "",
            });
          }
        }
      });
      setAttachment(files);
      if (file?.status === "uploading") {
        return;
      }
      if (file?.status === "done") {
        message.success(
          `${file?.name} ${t("property.file_uploaded_successfully")}`
        );
        onUpload(files);
      } else if (file?.status === "error") {
        message.error(`${file.name} ${t("property.file_upload_failed")}`);
      }
    },
    [onUpload, t, isLinkPreview, linkPreview]
  );

  const beforeUploadHandler = useCallback(
    async (file) => {
      const isAttachmentMax = attachment ? attachment.length >= 5 : false;
      if (isAttachmentMax) {
        message.error("You can only upload up to 5 files");
      }
      const isValidMime = mimeType.includes(file.type);
      if (!isValidMime) {
        message.error("You can only upload .jpg, .png or .pdf file.");
      }
      const isLt1M = file.size / 1024 / 1024 <= maxWeight;
      if (!isLt1M) {
        message.error(`File must smaller than ${maxWeight}MB!`);
      }

      return new Promise((resolve, reject) => {
        if (isValidMime && isLt1M && !isAttachmentMax) {
          return resolve(true);
        }
        return reject;
      });
    },
    [attachment, maxWeight, mimeType]
  );

  const handleRemove = useCallback(
    (file) => {
      if (
        file.status === "removed" ||
        file.status === "done" ||
        file.status === "success"
      ) {
        if (onRemove && typeof onRemove === "function") {
          onRemove(file);
        }
      }
    },
    [onRemove]
  );

  const handlePreview = useCallback(
    (file) => {
      if (!isAdd) {
        axios
          .get(
            isLinkPreview && file.preview_url ? file?.preview_url : file?.url,
            {
              responseType: "blob",
            }
          )
          .then((r) => {
            const blobURL = URL.createObjectURL(r.data);
            const tempLink = document.createElement("a");
            tempLink.href = blobURL;
            tempLink.setAttribute("download", `${file?.name}`);
            tempLink.click();
          })
          .catch((e) => {
            message.error(t("error.failed_to_load_data_from_server"));
          });
      }
    },
    [isAdd, isLinkPreview, t]
  );

  return (
    <div>
      <Upload.Dragger
        accept={mimeType}
        name={name}
        action={uploadAction}
        beforeUpload={beforeUploadHandler}
        onChange={changeHandler}
        onRemove={handleRemove}
        onPreview={handlePreview}
        headers={defaultHeaders}
        multiple
        listType="text"
        fileList={attachment}
        style={{ marginBottom: _.isEmpty(data) ? 0 : 10 }}
        disabled={disabled}
        itemRender={(originNode, file, fileList, actions) => {
          const fileName = file?.name.split(".");
          const fileType =
            fileName.length > 0 ? fileName[fileName.length - 1] : "png";
          return (
            <div className="flex flex-row items-center justify-between hover:opacity-70 hover:bg-red-100 hover:text-gray-800">
              <div
                className={`space-x-[10px] ${
                  !isAdd
                    ? "hover:cursor-pointer hover:opacity-80 hover:underline"
                    : ""
                }`}
                onClick={actions.preview}
              >
                {fileType === "pdf" ? (
                  <FilePdfOutlined className="text-primary" />
                ) : (
                  <FileImageOutlined className="text-primary" />
                )}
                <span>{`${file?.name} ${
                  file?.source_file ? `(${t(`${file?.source_file}`)})` : ""
                }`}</span>
              </div>
              {!disabled && (
                <DeleteOutlined
                  className="hover:cursor-pointer text-primary"
                  onClick={actions.remove}
                />
              )}
            </div>
          );
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {t("property.click_or_drag_file_to_this_area_to_upload")}
        </p>
        <div className="ant-upload-hint mt-5">
          <ul className="text-left pl-5">
            <li>File size maximal {maxWeight} MB</li>
          </ul>
        </div>
      </Upload.Dragger>
    </div>
  );
};

UploadAttachment.propTypes = {
  mimeType: PropTypes.string,
  maxWeight: PropTypes.number,
  action: PropTypes.string,
  name: PropTypes.string,
  headers: PropTypes.object,
};

UploadAttachment.defaultProps = {
  name: "attachment",
  mimeType: "image/jpeg, image/png, application/pdf",
  maxWeight: 10,
  action: "",
  headers: {
    Authorization: "",
    "X-app": "hr",
  },
};

export default UploadAttachment;
