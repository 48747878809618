import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/leave";

export function useAnnualLeaves({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["annualLeaves/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getAnnualLeaves(payload);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export function useAnnualLeavesRemainingQuota({
  payload = {},
  enabled = false,
}) {
  const { t } = useTranslation();
  return useQuery(
    ["annualLeaves/remainingQuota/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getAnnualLeavesRemainingQuota(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export function useSpecialLeaves({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["specialLeaves/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSpecialLeaves(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export function useSpecialLeavesStats({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["specialLeaves/stats", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSpecialLeavesStats(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export function useSickLeaves({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["sickLeaves/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSickLeaves(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export function useOtherLeaves({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["otherLeaves/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getOtherLeaves(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export function useUnpaidLeaves({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["unpaidLeaves/pagination", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getUnpaidLeaves(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export function useAnnualLeave({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["annualLeave", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getAnnualLeave(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export function useSpecialLeave({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["specialLeave", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSpecialLeave(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export function useSickLeave({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["sickLeave", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSickLeave(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export function useOtherLeave({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["otherLeave", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getOtherLeave(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export function useUnpaidLeave({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["unpaidLeave", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getUnpaidLeave(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export function useAnnualLeaveMovements({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["annualLeave/movements", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getAnnualLeaveMovements(payload?.id, payload?.params);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useBalanceAnnualLeaveMovements(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["annualLeave/movements/balanced", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBalancedAnnualLeaveMovements(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAddAnnualLeave = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addAnnualLeave(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) =>
      message.error(t("leave.unable_to_apply_for_annual_leave")),
  });
};

export const useAddSpecialLeave = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addSpecialLeave(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useAddSickLeave = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addSickLeave(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useAddOtherLeave = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addOtherLeave(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useAddUnpaidLeave = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addUnpaidLeave(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditAnnualLeave = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editAnnualLeave(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) =>
      message.error(error?.message || t("error.failed_save_data_to_server")),
  });
};

export const useEditSpecialLeave = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editSpecialLeave(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditSickLeave = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editSickLeave(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditOtherLeave = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editOtherLeave(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditUnpaidLeave = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editUnpaidLeave(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditAnnualLeaveRemainingQuota = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editAnnualLeaveRemainingQuota(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useRestoreLeave = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.restoreLeave(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteAnnualLeave = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteAnnualLeave(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteSpecialLeave = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteSpecialLeave(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteSickLeave = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteSickLeave(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteOtherLeave = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteOtherLeave(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteUnpaidLeave = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteUnpaidLeave(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteAttachmentLeave = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteAttachmentLeave(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query  upload import remaining annual leave
export const useUploadImportAnnualLeaveRemainingQuota = () => {
  return useMutation(
    (payload) => api.uploadImportAnnualLeaveRemainingQuota(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => {},
    }
  );
};

// Query  execute import remaining annual leave
export const useExecuteImportAnnualLeaveRemainingQuota = () => {
  const { t } = useTranslation();
  return useMutation(
    (payload) => api.executeImportAnnualLeaveRemainingQuota(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => message.error(t("error.failed_save_data_to_server")),
    }
  );
};
