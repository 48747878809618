import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import * as api from "apis/referral";

export function useReferrals(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["referrals"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getReferrals();
        response = data;
      } catch (error) {}
      return response;
    },
    {
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useLeadsReferrals({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["referrals/leads", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getLeadsReferrals(payload);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAddReferral = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addReferral(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) =>
      message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteReferral = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteReferral(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) =>
      message.error(t("error.failed_save_data_to_server")),
  });
};
