import { memo } from "react";
import { Avatar as AvatarAntd } from "antd";

const Avatar = ({ children, containerProps, ...props }) => {
  return (
    <div className="w-[32px]" {...containerProps}>
      <AvatarAntd {...props}>{children}</AvatarAntd>
    </div>
  );
};

export default memo(Avatar);
