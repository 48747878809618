import { useSelector } from "react-redux";

import { Link } from "components/UI";
import { permissionSelector } from "redux/selectors";
import {
  approvalTypeId,
  leaveType,
  leaveTypeId,
  transType,
} from "utils/static";
import dayjs from "dayjs";
import { formatDateDB } from "utils/formatting";

const statusApproval = (type) => {
  switch (type ? parseInt(type) : 0) {
    case 1:
      return "waiting-approval";
    case 2:
      return "approved";
    case 3:
      return "rejected";
    default:
      return "waiting-approval";
  }
};

const typeApproval = (type) => {
  switch (type) {
    case approvalTypeId.ANNUAL_LEAVE:
    case approvalTypeId.SPECIAL_LEAVE:
    case approvalTypeId.PERMISSION_LEAVE:
    case approvalTypeId.SICK_LEAVE:
    case approvalTypeId.UNPAID_LEAVE:
      return "leave";
    case approvalTypeId.REIMBURSE:
      return "reimbursement";
    default:
      break;
  }
};

const idLeave = (id) => {
  switch (id) {
    case leaveTypeId.ANNUAL_LEAVE:
      return leaveType.ANNUAL_LEAVE;
    case leaveTypeId.SPECIAL_LEAVE:
      return leaveType.SPECIAL_LEAVE;
    case leaveTypeId.UNPAID_LEAVE:
      return leaveType.UNPAID_LEAVE;
    case leaveTypeId.PERMISSION_LEAVE:
      return leaveType.PERMISSION_LEAVE;
    case leaveTypeId.SICK_LEAVE:
      return leaveType.SICK_LEAVE;
    default:
      return leaveType.ANNUAL_LEAVE;
  }
};

const getUrl = (transTypeId, data, permissions = [], force) => {
  let url = "";
  let name = "";
  let childName = "";

  if (
    transTypeId === transType.EMPLOYEE ||
    transTypeId === transType.EMPLOYEE_CREATED ||
    transTypeId === transType.EMPLOYEE_IDENTITY ||
    transTypeId === transType.EMPLOYEE_CONTACT ||
    transTypeId === transType.EMPLOYEE_PERSONAL_EDUCATION ||
    transTypeId === transType.EMPLOYEE_ATTACHMENT
  ) {
    name = "employee";
    if (data && data?.event_category !== "delete") {
      if (data.id) {
        url = `/employee/detail/${data.id}/personal`;
      } else {
        url = `/employee`;
      }
    } else {
      url = null;
    }
  }
  if (
    transTypeId === transType.EMPLOYEE_WORK_START_DATE ||
    transTypeId === transType.EMPLOYEE_NPWP ||
    transTypeId === transType.EMPLOYEE_BPJS ||
    transTypeId === transType.EMPLOYEE_BANK_ACCOUNT
  ) {
    name = "employee";
    if (data?.id) {
      url = `/employee/detail/${data.id}/personal/career`;
    } else {
      url = `/employee`;
    }
  }
  if (transTypeId === transType.TAXPAYER_STATUS) {
    name = "employee";
    if (data && data?.extra && data?.extra?.hr_employee_id) {
      url = `/employee/detail/${data?.extra?.hr_employee_id}/personal/career`;
    } else {
      url = `/employee?type=3&search=${data?.extra?.name}`;
    }
  }
  if (
    transTypeId === transType.CAREER ||
    transTypeId === transType.EMPLOYEE_CAREER
  ) {
    name = "employee";
    if (data?.id) {
      url = `/employee/detail/${data?.id}/career`;
    } else {
      url = `/employee`;
    }
  }
  if (
    transTypeId === transType.PAYROLL ||
    transTypeId === transType.PAYROLL_SLIP_STATUS ||
    transTypeId === transType.EMPLOYEE_PAYROLL
  ) {
    name = "payroll_payment";
    if (data?.id) {
      url = `/employee/detail/${data?.id}/payroll`;
    } else {
      url = `/payroll`;
    }
  }
  if (
    transTypeId === transType.PAYROLL_SLIP ||
    transTypeId === transType.CASH_RECEIPT_PAYROLL
  ) {
    name = "payroll_payment";
    if (data?.id) {
      url = `/payroll/detail/${data?.id}`;
    } else {
      url = `/payroll`;
    }
  }
  if (transTypeId === transType.PAYROLL_SLIP_CONFIRMATION) {
    name = "payroll_payment";
    url = `/payroll`;
  }
  if (transTypeId === transType.SALARY_SLIP) {
    name = "setting";
    childName = "setting_payroll";
    if (data?.id) {
      url = `/settings/payroll/salary-slip/edit/${data?.id}`;
    } else {
      url = `/settings/payroll/salary-slip`;
    }
  }
  if (transTypeId === transType.PRESENCE) {
    name = "attendance";
    childName = "presence";
    if (data?.id) {
      url = `/presence/attendance/daily`;
    } else {
      url = `/presence/attendance/daily`;
    }
  }
  if (transTypeId === transType.WORK_SCHEDULE) {
    name = "attendance";
    childName = "work_schedule";
    if (data && data?.event_category !== "delete") {
      if (data?.id && data?.extra && data?.extra?.hr_employee_id) {
        url = `/presence/work-schedule/detail/${data?.extra?.hr_employee_id}/schedule/${data?.id}`;
      } else {
        url = `/presence/work-schedule/fixed-schedule`;
      }
    } else {
      url = null;
    }
  }
  if (transTypeId === transType.SHIFT_SCHEDULE) {
    name = "attendance";
    childName = "work_schedule";
    if (data?.extra) {
      url = `/presence/work-schedule/shift-schedule${
        data.extra?.date
          ? `?date=${formatDateDB(
              dayjs(data.extra?.date, "DD/MM/YYYY").toDate()
            )}`
          : ""
      }`;
    } else {
      url = `/presence/work-schedule/shift-schedule`;
    }
  }
  if (transTypeId === transType.SCHEDULE_PATTERN) {
    name = "setting";
    childName = "setting_schedule";
    if (data?.id) {
      url = `/settings/schedule/detail/${data?.id}`;
    } else {
      url = `/settings/schedule`;
    }
  }
  if (transTypeId === transType.ATTENDANCE) {
    name = "attendance";
    childName = "presence";
    if (data?.extra) {
      url = `/presence/attendance/daily${
        data.extra?.date
          ? `?date=${formatDateDB(
              dayjs(data.extra?.date, "DD/MM/YYYY").toDate()
            )}`
          : ""
      }`;
    } else {
      url = `/presence/attendance/daily`;
    }
  }
  if (
    transTypeId === transType.LEAVE ||
    transTypeId === transType.LEAVE_ATTACHMENT
  ) {
    name = "attendance";
    childName = "leaves";
    if (data?.id && data?.extra) {
      url = `/presence/leaves/${idLeave(data?.extra?.type?.id)}/detail/${
        data?.id
      }`;
    } else {
      url = `/presence/leaves/annual-leave`;
    }
  }
  if (transTypeId === transType.CLIENT_VISIT) {
    name = "attendance";
    childName = "client_visit";
    if (data?.extra) {
      url = `/presence/client-visit${
        data.extra?.date
          ? `?date_started=${formatDateDB(
              dayjs(data.extra?.date, "DD/MM/YYYY").toDate()
            )}&date_ended=${formatDateDB(
              dayjs(data.extra?.date, "DD/MM/YYYY").toDate()
            )}`
          : ""
      }`;
    } else {
      url = `/presence/client-visit`;
    }
  }
  if (transTypeId === transType.PAYROLL_SETTING) {
    name = "setting";
    childName = "setting_payroll";
    url = `/settings/payroll/salary-slip`;
  }
  if (transTypeId === transType.APPROVAL) {
    const approvalType = statusApproval(data?.extra?.status?.id || "");
    name = "approval";
    if (data?.id && data?.extra) {
      url = `/approval/${approvalType}/${typeApproval(
        data?.extra?.type?.id
      )}/detail/${data?.extra?.hr_approval_case_id}?type_id=${
        data?.extra?.type?.id
      }`;
    } else {
      url = `/approval/${approvalType}`;
    }
  }
  if (
    transTypeId === transType.TIMELINE ||
    transTypeId === transType.TIMELINE_ATTACHMENT
  ) {
    name = "timeline";
    if (data?.id && data?.extra) {
      url = `/timeline/${
        data?.extra?.type === "Pengumuman"
          ? `announcement/${data.id}`
          : `main/${data.id}`
      }`;
    } else {
      url = `/timeline/main`;
    }
  }
  if (transTypeId === transType.TIMELINE_COMMENT) {
    name = "timeline";
    if (data?.id) {
      url = `/timeline/main/${data?.id}`;
    } else {
      url = `/timeline/main`;
    }
  }
  if (transTypeId === transType.BILLING) {
    name = "setting";
    childName = "setting_billing";
    url = `/settings/billing`;
  }
  if (transTypeId === transType.ORGANIZATION_STRUCTURE) {
    name = "setting";
    childName = "setting_company";
    if (data?.id) {
      url = `/settings/company/organitation-structure/edit/${data?.id}`;
    } else {
      url = `/settings/company/organitation-structure`;
    }
  }
  if (transTypeId === transType.JOB_LEVEL) {
    name = "setting";
    childName = "setting_company";
    if (data?.id) {
      url = `/settings/company/job-title/detail/${data?.id}`;
    } else {
      url = `/settings/company/job-title`;
    }
  }
  if (transTypeId === transType.JOB_POSITION) {
    name = "setting";
    childName = "setting_company";
    if (data?.id) {
      url = `/settings/company/position/edit/${data?.id}`;
    } else {
      url = `/settings/company/position`;
    }
  }
  if (transTypeId === transType.COMPANY_POLICY) {
    name = "setting";
    childName = "setting_company";
    if (data?.id) {
      url = `/settings/company/company-policy/detail/${data.id}`;
    } else {
      url = `/settings/company/company-policy`;
    }
  }
  if (transTypeId === transType.CALENDAR) {
    name = "setting";
    childName = "setting_calendar";
    if (data?.id) {
      url = `/settings/calendar/detail/${data.id}`;
    } else {
      url = `/settings/calendar`;
    }
  }
  if (transTypeId === transType.SALARY_SLIP_COMPONENT) {
    name = "setting";
    childName = "setting_payroll";
    if (data?.id && data?.extra) {
      url = `/settings/payroll/salary-slip/add/${
        data.extra?.type === "Pendapatan" ? "income" : "cut"
      }/${data?.id}`;
    } else {
      url = `/settings/payroll/salary-slip/add`;
    }
  }
  if (transTypeId === transType.TAX_WITHHOLDING) {
    name = "setting";
    childName = "setting_payroll";
    url = `/settings/payroll/tax`;
  }
  if (transTypeId === transType.COMPANY_ACCOUNT) {
    name = "setting";
    childName = "setting_payroll";
    url = `/settings/payroll/companyAccount`;
  }
  if (transTypeId === transType.SHIFT) {
    name = "attendance";
    childName = "work_schedule";
    if (data && data?.event_category !== "delete") {
      if (data?.id) {
        url = `/presence/work-schedule/shift-schedule/shift/${data?.id}`;
      } else {
        url = `/presence/work-schedule/shift-schedule`;
      }
    } else {
      url = null;
    }
  }
  if (transTypeId === transType.PRESENCE_APPLICATION_VALIDATION) {
    name = "setting";
    childName = "setting_attendance_validation";
    if (data && data?.event_category !== "delete") {
      if (data?.id) {
        url = `/settings/attendance/presence?rule_id=${data?.id}`;
      } else {
        url = `/settings/attendance/presence`;
      }
    } else {
      url = null;
    }
  }
  if (transTypeId === transType.ATTENDANCE_LOCATION) {
    name = "setting";
    childName = "setting_attendance_location";
    if (data?.id) {
      url = `/settings/attendance-location/edit/${data?.id}`;
    } else {
      url = `/settings/attendance-location`;
    }
  }
  if (transTypeId === transType.APPROVAL_RULE) {
    name = "setting";
    childName = "setting_approval_rule";
    if (data?.id) {
      url = `/settings/approval-rule/detail/${data?.id}`;
    } else {
      url = `/settings/approval-rule`;
    }
  }
  if (transTypeId === transType.EMAIL_NOTIFICATION_TEMPLATE) {
    name = "setting";
    childName = "setting_template";
    if (data?.id) {
      url = `/settings/template/email`;
    } else {
      url = `/settings/template/email`;
    }
  }
  if (transTypeId === transType.PUSH_NOTIFICATION_TEMPLATE) {
    name = "setting";
    childName = "setting_template";
    if (data?.id) {
      url = `/settings/template/mobile-apps`;
    } else {
      url = `/settings/template/mobile-apps`;
    }
  }
  if (transTypeId === transType.ANNUAL_LEAVE_PERIOD) {
    name = "attendance";
    childName = "leaves";
    url = `/presence/leaves/annual-leave/remaining-leave`;
  }
  if (transTypeId === transType.CASH_RECEIPT) {
    name = "finance";
    childName = "cash_receipt";
    if (data?.id) {
      url = `/finance/cash-receipt/history/detail/${data?.id}`;
    } else {
      url = `/finance/cash-receipt/history`;
    }
  }
  if (transTypeId === transType.OVERTIME_COMPONENT_TYPE) {
    name = "setting";
    childName = "setting_overtime";
    if (data && data?.event_category !== "delete") {
      if (data?.id) {
        url = `/settings/overtime/detail/${data?.id}`;
      } else {
        url = `/settings/overtime`;
      }
    } else {
      url = null;
    }
  }
  if (transTypeId === transType.CASH_RECEIPT_PAYMENT) {
    name = "finance";
    childName = "cash_receipt";
    if (data?.id) {
      url = `/finance/cash-receipt/payment/detail/${data?.id}`;
    } else {
      url = `/finance/cash-receipt/payment`;
    }
  }
  if (transTypeId === transType.CASH_RECEIPT_PAYROLL_PAYMENT) {
    name = "finance";
    childName = "cash_receipt";
    if (data?.id && data?.extra) {
      url = `/finance/cash-receipt/payment/detail/${data?.extra?.hr_cash_receipt_id}`;
    } else {
      url = `/finance/cash-receipt/payment`;
    }
  }
  if (
    transTypeId === transType.REIMBURSEMENT ||
    transTypeId === transType.REIMBURSE_ATTACHMENT ||
    transTypeId === transType.DETAIL_REIMBURSEMENT
  ) {
    name = "finance";
    childName = "reimbursement";
    if (data?.id) {
      url = `/finance/reimbursement/edit/${data?.id}`;
    } else {
      url = `/finance/reimbursement`;
    }
  }
  if (transTypeId === transType.REIMBURSE_PAYMENT) {
    name = "finance";
    childName = "reimbursement";
    if (data?.id) {
      url = `/finance/reimbursement/detail/${data?.id}`;
    } else {
      url = `/finance/reimbursement`;
    }
  }
  if (transTypeId === transType.CLIENT_MANAGEMENT) {
    name = "client";
    if (data && data?.event_category !== "delete") {
      if (data?.id) {
        url = `/client/edit/${data?.id}`;
      } else {
        url = `/client`;
      }
    } else {
      url = null;
    }
  }
  if (transTypeId === transType.PAYROLL_PRORATE) {
    name = "setting";
    childName = "setting_payroll";
    if (data && data?.event_category !== "delete") {
      if (data?.id) {
        url = `/settings/payroll/proRate?rule_id=${data?.id}`;
      } else {
        url = `/settings/payroll/proRate`;
      }
    } else {
      url = null;
    }
  }
  if (transTypeId === transType.TASK) {
    name = "task";
    if (data && data?.event_category !== "delete") {
      if (data?.id) {
        url = `/task/detail/${data?.id}`;
      } else {
        url = `/task`;
      }
    } else {
      url = null;
    }
  }
  if (name) {
    const findPermission = permissions.find((row) => row.name === name);
    if (!findPermission && !force) {
      url = null;
    }
    if (findPermission && Array.isArray(findPermission?.child) && childName) {
      const findChild = (findPermission?.child || []).find(
        (row) => row?.name === childName
      );
      if (!findChild) {
        url = null;
      }
    }
  }
  return url;
};

const LinkTransType = ({ title, transTypeId, data, ...props }) => {
  const permissions = useSelector(permissionSelector);

  const uri = getUrl(transTypeId, data, permissions);

  if (!uri) {
    return <span>{title}</span>;
  }

  return (
    <Link to={uri} {...props}>
      {title}
    </Link>
  );
};

export default LinkTransType;
