import { useQuery, QueryClient } from "react-query";
import { dehydrate } from "react-query/hydration";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { getInits, getInitsPublic } from "apis/inits";
import { removeToken } from "utils/cookies";
import { useDispatch } from "react-redux";
import { resetData, setLoadingInit } from "redux/configSlice";

export function useInits(options = {}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useQuery(
    "inits",
    async () => {
      dispatch(setLoadingInit(true));
      let response;
      try {
        const {
          data: { data },
        } = await getInits();
        response = data;
      } catch (error) {
        if (error && error.status && error.status === 401) {
          removeToken();
          dispatch(resetData());
        }
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) => {
        dispatch(resetData());
        message.error(t("error.failed_to_load_data_from_server"));
      },
      onSettled: () => {
        dispatch(setLoadingInit(false));
      },
      ...options,
    }
  );
}

export const prefetchInit = async () => {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery("inits", async () => {
    let response;
    try {
      const {
        data: { data },
      } = await getInits();
      response = data;
    } catch (error) {
      throw new Error("Failed to load data from server!");
    }
    return response;
  });

  return dehydrate(queryClient);
};

export function useInitsPublic() {
  const { t } = useTranslation();
  return useQuery(
    ["inits/public"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getInitsPublic();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}
