import { useCallback, useMemo, useState, useEffect, memo } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  BackwardOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  ForwardOutlined,
} from "@ant-design/icons";
import { formatDateDB } from "utils/formatting";
import DatePicker from "../DatePicker";

const CardDate = memo(({ item, index, selected, onSelected }) => {
  return (
    <div
      id={`id-${index + 1}`}
      key={item?.id}
      role="button"
      className={`${
        _.isEqual(selected, item?.id)
          ? "bg-primary text-white"
          : "bg-steamedGreen text-gray-900"
      } min-w-[150px] hover:bg-pinkLight hover:text-white p-2 mx-[5px] flex flex-col items-center justify-center rounded-lg`}
      onClick={() => {
        onSelected(item?.id);
      }}
    >
      <span className="font-semibold">{item?.day ?? ""}</span>
      <span>{dayjs(item?.id).format("DD MMM YYYY") ?? ""}</span>
    </div>
  );
});

const DaysFilter = ({ selected, onSelected }) => {
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(selected);

  useEffect(() => {
    if (selected) {
      setSelectedDate(selected);
      const section = document?.querySelector(
        `#id-${dayjs(selected).format("D")}`
      );
      if(section){
        section?.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [selected]);

  const daysData = useMemo(() => {
    const days = [];
    if (selectedDate) {
      const daysSelectedMonth = new Array(
        dayjs(selectedDate).daysInMonth()
      ).fill("");
      daysSelectedMonth.forEach((day, index) => {
        const d = new Date(
          dayjs(selectedDate).year(),
          dayjs(selectedDate).month(),
          index + 1
        );
        const date = dayjs(d);
        days.push({
          id: formatDateDB(date),
          day: date.format("dddd"),
        });
      });
    }
    return days;
  }, [selectedDate]);

  const onPreviousYear = useCallback(() => {
    const section = document.querySelector(`#id-${1}`);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
    setSelectedDate((prev) =>
      dayjs(prev).subtract(1, "year").format("YYYY-MM-DD")
    );
  }, []);

  const onNextYear = useCallback(() => {
    const section = document.querySelector(`#id-${1}`);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
    setSelectedDate((prev) => dayjs(prev).add(1, "year").format("YYYY-MM-DD"));
  }, []);

  const onToday = useCallback(() => {
    const section = document.querySelector(`#id-${dayjs().format("D")}`);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
    onSelected(dayjs().format("YYYY-MM-DD"));
    setSelectedDate(dayjs().format("YYYY-MM-DD"));
  }, [onSelected]);

  const onChangeDatePicker = useCallback(
    (e) => {
      const section = document.querySelector(`#id-${dayjs(e).format("D")}`);
      section?.scrollIntoView({ behavior: "smooth", block: "start" });
      onSelected(dayjs(e).format("YYYY-MM-DD"));
      setSelectedDate(dayjs(e).format("YYYY-MM-DD"));
    },
    [onSelected]
  );

  const onMove = useCallback((direction) => {
    setSelectedDate((prev) =>
      dayjs(prev)
        .add(1 * direction, "month")
        .format("YYYY-MM-DD")
    );
  }, []);

  const onSelectDay = useCallback(
    (value) => {
      const section = document.querySelector(`#id-${dayjs(value).format("D")}`);
      section?.scrollIntoView({ behavior: "smooth", block: "start" });
      onSelected(value);
    },
    [onSelected]
  );

  return (
    <>
      <div className="flex flex-row items-center">
        <div
          className="flex bg-gray-200 dark:bg-dark4 items-center justify-center w-[68px] p-2 rounded-md hover:opacity-50 h-12"
          role="button"
          onClick={onPreviousYear}
        >
          <BackwardOutlined />
        </div>
        <div
          id="scroll-content"
          className="flex flex-row overflow-auto mx-[5px] hover:overflow-x-scroll"
        >
          {daysData.map((item, index) => (
            <CardDate
              key={item?.id}
              index={index}
              item={item}
              selected={selected}
              onSelected={onSelectDay}
            />
          ))}
        </div>
        <div
          className="flex bg-gray-200 dark:bg-dark4 items-center justify-center w-[68px] p-2 rounded-md hover:opacity-50 h-12"
          role="button"
          onClick={onNextYear}
        >
          <ForwardOutlined />
        </div>
      </div>
      <div className="justify-end my-5 flex flex-row space-x-2">
        <Button onClick={onToday}>{t("date.today")}</Button>
        <DatePicker
          value={dayjs(selected)}
          onChange={onChangeDatePicker}
          allowClear={false}
        />
        <Button
          type="primary"
          icon={<CaretLeftOutlined />}
          onClick={() => onMove(-1)}
        />
        <Button
          type="primary"
          icon={<CaretRightOutlined />}
          onClick={() => onMove(1)}
        />
      </div>
    </>
  );
};

DaysFilter.propTypes = {
  selected: PropTypes.string,
  onSelected: PropTypes.func,
};

DaysFilter.defaultProps = {
  selected: dayjs().format("YYYY-MM-DD"),
  onSelected: () => {},
};

export default memo(DaysFilter);
