import axios from "utils/axios";
import { stringify } from "query-string";

export const getReimbursements = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/reimbursements/pagination?${query}`);
};

export const getEmployeeReimbursements = (params) => {
  const query = stringify(params);
  return axios.get(
    `/hr/reimbursements/employee/${params?.id}/pagination?${query}`
  );
};

export const getReimbursement = (data) => {
  return axios.get(`/hr/reimbursements/${data?.id}`);
};

export const addReimbursement = (data) => {
  return axios.post("/hr/reimbursements", data);
};

export const editReimbursement = (data) => {
  return axios.patch(`/hr/reimbursements/${data?.id}`, data);
};

export const paymentReimbursement = (data) => {
  return axios.patch(`/hr/reimbursements/${data?.id}/payment`, data);
};

export const deleteReimbursement = (data) => {
  return axios.delete(`/hr/reimbursements/${data?.id}`);
};

export const deleteAttachmentReimbursement = (data) => {
  return axios.delete(
    `/hr/reimbursements/${data?.reimbursementId}/attachment/delete/${data?.id}`
  );
};
