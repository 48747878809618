import { message } from "antd";
import { useTranslation } from "react-i18next";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

import {
  dislikeFaq,
  getFaqCategory,
  getFaqDetail,
  getFaqs,
  likeFaq,
} from "apis/faq";

export const useFaqCategory = (payload, options) => {
  const { t } = useTranslation();
  return useQuery(
    ["faqCategory", payload],
    async () => {
      const {
        data: { data },
      } = await getFaqCategory(payload);
      return data;
    },
    {
      onError: (error) => {
        message.error(
          (error && error.message) || t("error.failed_to_load_data_from_server")
        );
      },
      ...options,
    }
  );
};

export const useFaqs = ({ payload = {} }, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["faqs", payload],
    async () => {
      const {
        data: { data },
      } = await getFaqs(payload);
      return data;
    },
    {
      onError: (error) => {
        message.error(
          (error && error.message) || t("error.failed_to_load_data_from_server")
        );
      },
      ...options,
    }
  );
};

export const useInfiniteFAQs = (payload, options = {}) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["infinite/faqs", payload],
    async ({ pageParam = 0 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await getFaqs({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) =>
        message.error(
          error.message || t("error.failed_to_load_data_from_server")
        ),
      keepPreviousData: true,
      ...options,
    }
  );
};

export const useFaqDetail = ({ id, options = {} }) => {
  const { t } = useTranslation();
  return useQuery(
    ["faqDetail", id],
    async () => {
      const {
        data: { data },
      } = await getFaqDetail(id);
      return data;
    },
    {
      onError: (error) => {
        message.error(
          (error && error.message) || t("error.failed_to_load_data_from_server")
        );
      },
      ...options,
    }
  );
};

export const useLikeFaq = ({ options = {} } = {}) => {
  const queryClient = useQueryClient();
  return useMutation((payload) => likeFaq(payload), {
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["faqDetail", `${response.data.data.id}`],
        () => response.data.data
      );
      message.success(response.data.message);
    },
    ...options,
  });
};

export const useDislikeFaq = ({ options = {} } = {}) => {
  const queryClient = useQueryClient();
  return useMutation((payload) => dislikeFaq(payload), {
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["faqDetail", `${response.data.data.id}`],
        () => response.data.data
      );
      message.success(response.data.message);
    },
    ...options,
  });
};
