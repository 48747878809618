import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const Import = ({ title, icon, type, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button
      type={type || "default"}
      icon={icon || <UploadOutlined />}
      {...props}
    >
      {title || t("button.import")}
    </Button>
  );
};

export default Import;
