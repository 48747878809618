import axios from "utils/axios";

export const getCoaRules = () => {
  return axios.get(`/hr/financeCoaRules`);
};

export const getCoaRule = (id) => {
  return axios.get(`/hr/financeCoaRule/${id}`);
};

export const addCoaRule = (data) => {
  return axios.post("/hr/financeCoaRules", data);
};

export const editCoaRule = (data) => {
  return axios.put(`/hr/financeCoaRules/${data?.id}`, data);
};

export const deleteCoaRule = (id) => {
  return axios.delete(`/hr/financeCoaRules/${id}`);
};
