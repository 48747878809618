import { useQuery, useMutation, useQueryClient } from "react-query";

import { message } from "antd";
import * as api from "apis/layoutSalarySlip";
import { useTranslation } from "react-i18next";

export const useLayoutSalarySlip = (options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["layoutSalarySlip"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getLayoutsSalarySlip();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const usePreviewLayoutSalarySlip = (options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["layoutSalarySlip/preview"],
    async () => {
      let response;
      try {
        const { data } = await api.getPreviewLayoutSalarySlip();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useEditLayoutSalarySlip = () => {
  //const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.editLayoutsSalarySlip(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries("layoutSalarySlip");
      const previous = queryClient.getQueryData("layoutSalarySlip");
      queryClient.setQueryData("layoutSalarySlip", { ...previous, ...payload });
      return { previous };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, val, context) => {
      queryClient.setQueryData("layoutSalarySlip", context.previous);
      // message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("layoutSalarySlip");
    },
  });
};

export const useResetLayoutSalarySlip = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.resetLayoutsSalarySlip(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries("layoutSalarySlip");
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useResetImageLayoutSalarySlip = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.resetLogoLayoutsSalarySlip(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries("layoutSalarySlip");
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useUploadSignatureSalarySlip = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.uploadSignatureSalarySlip(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
