import { useCallback, useMemo } from "react";
import { Checkbox, Form, Input, Modal, Typography, message } from "antd";
import { useQueryClient } from "react-query";
import { css } from "@emotion/css";
import localStore from "store";

import { Cancel, Reset, Save } from "components/UI";
import Axios from "utils/axios";
import { generateBaseUri } from "utils/helper";

const ChangeBaseUrlModal = ({ visible, onCancel }) => {
  const queryClient = useQueryClient();

  const submitHandler = useCallback(
    (values) => {
      localStore.set("app.bypassEndpoint", values.base_url);
      localStore.set("app.isOverwriteBaseUrl", values.overwrite_baseurl);
      queryClient.clear();
      Axios.defaults.baseURL = values.base_url;
      message.success("Base URL berhasil diganti");
      onCancel();
    },
    [onCancel, queryClient]
  );

  const resetHandler = useCallback(() => {
    localStore.set("app.bypassEndpoint", "");
    localStore.set("app.isOverwriteBaseUrl", false);
    queryClient.clear();
    Axios.defaults.baseURL = generateBaseUri();
    message.success("Base URL berhasil direset");
    onCancel();
  }, [onCancel, queryClient]);

  const initialValues = useMemo(() => {
    const isOverwriteBaseUrl = localStore.get("app.isOverwriteBaseUrl");
    return {
      base_url:
        localStore.get("app.bypassEndpoint") || process.env.REACT_APP_API_URL,
      overwrite_baseurl:
        typeof isOverwriteBaseUrl !== "undefined" ? isOverwriteBaseUrl : true,
    };
  }, []);

  return (
    <Modal
      title="Ganti Base Url"
      open={visible}
      destroyOnClose
      onCancel={onCancel}
      maskTransitionName=""
      maskClosable={false}
      className={`${css`
        .ant-modal-footer {
          display: flex;
          justify-content: end;
          column-gap: 5px;
        }
      `}`}
      footer={[
        <Cancel key="cancel" onClick={onCancel} />,
        <Reset type="primary" key="reset" onClick={resetHandler} />,
        <Save form="changeBaseUrlForm" key="submit" htmlType="submit" />,
      ]}
    >
      <Form
        initialValues={initialValues}
        layout="vertical"
        name="changeBaseUrlForm"
        onFinish={submitHandler}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: "Silahkan masukkan base url",
            },
          ]}
          name="base_url"
          label="Base URL"
        >
          <Input
            placeholder="contoh: https://apihr.jokolodang.com/api/v1"
            autoFocus
          />
        </Form.Item>
        <Form.Item name="overwrite_baseurl" valuePropName="checked">
          <Checkbox
            className={`${css`
              span {
                margin-top: 3px !important;
                align-self: start !important;
              }
            `}`}
          >
            <Typography.Text strong>
              Jika dicentang, hanya berlaku untuk login yang memiliki 1
              perusahaan. Overwrite disini artinya baseurl yang diinputkan akan
              mengganti endpoint di response init.
            </Typography.Text>
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangeBaseUrlModal;
