import axios from "utils/axios";
import { stringify } from "query-string";

export const getOvertimes = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/overtimes/pagination?${query}`);
};

export const addOvertime = (data) => {
  return axios.post(`/hr/overtimes`, data);
};

export const updateOvertime = (data) => {
  return axios.patch(`/hr/overtimes/${data.id}`, data);
};

export const deleteOvertime = (data) => {
  return axios.delete(`/hr/overtimes/${data.id}`);
};

export const uploadImportOvertimes = (data) => {
  return axios.post("/hr/overtimes/uploadImport", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadImportUpdateOvertimes = (data) => {
  return axios.post("/hr/overtimes/update/uploadImport", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const executeImportOvertimes = (data) => {
  return axios.post("/hr/overtimes/executeImport", data);
};

export const executeImportUpdateOvertimes = (data) => {
  return axios.post("/hr/overtimes/update/executeImport", data);
};
