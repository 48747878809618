import { useEffect } from "react";
import { Layout } from "antd";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Progressbar from "@badrap/bar-of-progress";
import { useDispatch } from "react-redux";
import store from "store";
import { isTokenExist } from "utils/cookies";
import useLoadInits from "hooks/useLoadInits";
import { useAppSelector } from "hooks/useSelector";
import ChatSupport from "components/apps/ChatSupport";
import { logout } from "redux/configSlice";
import TopBar from "./TopBar";
import Footer from "./Footer";

const { Header, Content } = Layout;

const progress = new Progressbar({
  size: 4,
  color: "#46be8a",
  className: "z-40",
  delay: 100,
});

const Blank = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { loadingInit } = useAppSelector();
  const tokenExist = isTokenExist();
  const skipCheckEndpoint = true;

  useEffect(() => {
    if (!tokenExist) {
      // store.set("app.previousPathname", location.pathname);
      store.remove("app.endpoint");
      dispatch(logout());
      navigate("/user/login", { replace: true, state: { from: location } });
    }
  }, [dispatch, location, navigate, tokenExist]);

  // useEffect(() => {
  //   if (companies.length === 1) {
  //     window.location.href = "/dashboard";
  //   } else if (companies.length > 1) {
  //     window.location.href = "/user/select-company";
  //   }
  // }, [companies.length]);

  useLoadInits(skipCheckEndpoint);

  useEffect(() => {
    if (loadingInit) {
      progress.start();
    } else {
      progress.finish();
    }
  }, [loadingInit]);

  if (!tokenExist) {
    return null;
  }

  return (
    <Layout className="min-h-screen">
      <Layout className="">
        <Header className="bg-white dark:bg-dark1 p-0 h-14">
          <TopBar
            hideLanguageSelector
            hideCompanySelector
            hideTimeline
            hideNews
            hideDarkModeSelector
          />
        </Header>
        <Content className="p-2 md:p-5 flex items-center justify-center">
          <Outlet />
        </Content>
        <Footer className="flex items-center" />
        <ChatSupport />
      </Layout>
    </Layout>
  );
};

export default Blank;
