import { forwardRef } from "react";
import { Switch as SwitchAntd } from "antd";

import "./index.css";

const Switch = forwardRef((props, ref) => {
  return <SwitchAntd {...props} ref={ref} />;
});

export default Switch;
