import { memo } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { PrinterOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const Print = ({ id, title, icon, type, onClick, ...props }) => {
  const { t } = useTranslation();
  const btnTitle = title ? title : t("button.print");
  return (
    <Button
      className="flex items-center justify-center"
      id={id}
      type={type}
      icon={icon}
      onClick={onClick}
      {...props}
    >
      {btnTitle}
    </Button>
  );
};

Print.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

Print.defaultProps = {
  type: "default",
  icon: <PrinterOutlined />,
  onClick: () => {},
  id: "btn-add",
};

export default memo(Print);
