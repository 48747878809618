import { useQuery, useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import * as api from "apis/accountMapping";

export function useConnectFinanceSetting(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["connectFinanceSetting"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getConnectFinanceSetting();
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useConnectFinanceTags(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["connectFinanceTags"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getConnectFinanceTags();
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useUpdateConnectFinanceSetting = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.updateConnectFinanceSetting(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries("connectFinanceSetting");
      const previousData = queryClient.getQueryData("connectFinanceSetting");
      queryClient.setQueryData("connectFinanceSetting", {
        ...previousData,
        ...payload,
      });
      return { previousData };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.success(data?.message);
      }
    },
    onError: (error, val, context) => {
      queryClient.setQueryData("connectFinanceSetting", context.previousData);
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("connectFinanceSetting");
    },
  });
};

export const useUpdateConnectFinanceTag = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.updateConnectFinanceTag(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries("connectFinanceTags");
        message.success(data?.message);
      } else {
        message.success(data?.message);
      }
    },
    onError: (error) => {
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

export const useUpdateConnectFinanceSalaryComponent = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => api.updateConnectFinanceSalaryComponent(payload),
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries("connectFinanceSetting");
        const previousData = queryClient.getQueryData("connectFinanceSetting");
        return { previousData };
      },
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.success(data?.message);
        }
      },
      onError: (error, val, context) => {
        queryClient.setQueryData("connectFinanceSetting", context.previousData);
        message.error(t("error.failed_save_data_to_server"));
      },
      onSettled: () => {
        queryClient.invalidateQueries("connectFinanceSetting");
      },
    }
  );
};

export const useUpdateConnectFinanceBankAccount = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => api.updateConnectFinanceBankAccount(payload),
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries("connectFinanceSetting");
        const previousData = queryClient.getQueryData("connectFinanceSetting");
        return { previousData };
      },
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.success(data?.message);
        }
      },
      onError: (error, val, context) => {
        queryClient.setQueryData("connectFinanceSetting", context.previousData);
        message.error(t("error.failed_save_data_to_server"));
      },
      onSettled: () => {
        queryClient.invalidateQueries("connectFinanceSetting");
      },
    }
  );
};
