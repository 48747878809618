import axios from "utils/axios";

export const getEmailTemplates = () => {
  return axios.get(`/hr/emailTemplates`);
};

export const getEmailTemplate = (data) => {
  return axios.get(`/hr/emailTemplates/${data?.id}`);
};

export const editEmailTemplate = (data) => {
  return axios.put(`/hr/emailTemplates/${data?.id}`, data);
};

export const revertEmailTemplate = (data) => {
  return axios.put(`/hr/emailTemplates/${data?.id}/revert`);
};
