import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Typography } from "antd";

import useFormatting from "hooks/useFormatting";
import { useLoadedInitSelector } from "hooks/useSelector";
import { useQueues } from "queries";

const { Text } = Typography;

const QueueStats = ({ enabled }) => {
  const { t } = useTranslation();
  const { qtyToCurrency } = useFormatting();
  const loadedInit = useLoadedInitSelector();

  //direfetch setiap 2 detik
  const { data } = useQueues({
    enabled: !!loadedInit && !!enabled,
    refetchInterval: 2000,
  });
  // tidak ditampilkan jika <= 1
  if (!data || data?.count <= 1) {
    return null;
  }

  return (
    <div className="flex items-center gap-x-2 p-3">
      <Spin indicator={<LoadingOutlined />} spinning />
      <Text strong>
        {t("queue.data_is_waiting_to_be_processed", {
          value: qtyToCurrency(data?.count || 0),
        })}
      </Text>
    </div>
  );
};

export default QueueStats;
