import { useQuery, useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import * as api from "apis/coaRule";

//Query untuk get option list coa rules
export function useCoaRules(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["coaRules"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCoaRules();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail coa rule
export function useCoaRule({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["coaRule", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCoaRule(payload?.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk add coa rule
export const useAddCoaRule = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.addCoaRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries(["coaRules"]);
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit coa rule
export const useEditCoaRule = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.editCoaRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries(["coaRules"]);
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete coa rule
export const useDeleteCoaRule = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.deleteCoaRule(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        queryClient.invalidateQueries(["coaRules"]);
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) =>
      message.error(t("error.failed_to_delete_data_from_server")),
  });
};
