import axios from "utils/axios";
import { stringify } from "query-string";

export const getTaskActivitiesEss = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/taskActivities/employees/pagination?${query}`);
};

export const getTaskActivityEss = (id) => {
  return axios.get(`/hr/taskActivities/employees/${id}`);
};

export const addTaskActivityEss = (data) => {
  return axios.post("/hr/taskActivities/employees", data);
};

export const editTaskActivityEss = (data) => {
  return axios.put(`/hr/taskActivities/employees/${data?.id}`, data);
};

export const deleteTaskActivityEss = (id) => {
  return axios.delete(`/hr/taskActivities/employees/${id}`);
};

export const deleteAttachmentTaskActivityEss = (data) => {
  return axios.delete(
    `/hr/taskActivities/employees/${data?.taskActivityId}/attachment/delete/${data?.id}`
  );
};
