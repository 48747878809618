import { useMemo } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import _ from "lodash";

const ClearFilter = ({
  defaultParams = {},
  params = {},
  onClear = () => {},
  ...props
}) => {
  const { t } = useTranslation();

  const formattedParams = useMemo(() => {
    const formatted = {};
    Object.keys(defaultParams).forEach((key) => {
      formatted[key] = params?.[key] || null;
    });
    return formatted;
  }, [defaultParams, params]);

  const isHidden = useMemo(
    () => _.isEqual(defaultParams, formattedParams),
    [defaultParams, formattedParams]
  );

  return (
    <Button
      icon={<CloseOutlined />}
      type="default"
      onClick={onClear}
      hidden={isHidden}
      danger
      {...props}
    >
      {t("button.clear_filter")}
    </Button>
  );
};

export default ClearFilter;
