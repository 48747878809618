import { forwardRef, memo } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import PropsTypes from "prop-types";

const SearchWidget = memo(({ innerRef, placeholder, ...props }) => {
  const { t } = useTranslation();
  return (
    <Input
      ref={innerRef}
      prefix={<SearchOutlined className="text-gray-400 dark:text-primary" />}
      placeholder={placeholder ? placeholder : t("input.search")}
      id="input-search"
      className="w-[180px]"
      allowClear
      {...props}
    />
  );
});

SearchWidget.propTypes = {
  placeholder: PropsTypes.string,
  onChange: PropsTypes.func,
};

SearchWidget.defaultProps = {
  placeholder: "",
};

export default forwardRef((props, ref) => (
  <SearchWidget innerRef={ref} {...props} />
));
