const Tag = ({ className, labelClassName, backgroundColor, label }) => {
  return (
    <div
      className={
        className
          ? className
          : `flex items-center justify-center px-2 py-1 rounded-lg w-fit ${
              !backgroundColor ? "bg-primary" : ""
            }`
      }
      style={{ backgroundColor }}
    >
      <span className={`${labelClassName} text-center text-gray-900`}>{label || ""}</span>
    </div>
  );
};

export default Tag;
