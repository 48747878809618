import { useCallback } from "react";
import { Switch } from "antd";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { useDispatch } from "react-redux";
import { css } from "@emotion/css";
import store from "store";

import { setSetting } from "redux/configSlice";
import { useSettingSelector } from "hooks/useSelector";
import { useEditSettingThemeColors } from "queries";

const ThemeColors = () => {
  const dispatch = useDispatch();
  const settings = useSettingSelector();
  const editSettingThemeColors = useEditSettingThemeColors();

  const changeThemeHandler = useCallback(
    (value) => {
      if (value !== !!settings?.dark_mode) {
        dispatch(setSetting({ dark_mode: value ? 1 : 0 }));
        editSettingThemeColors.mutate(
          { dark_mode: value ? 1 : 0 },
          {
            onSuccess: (response) => {
              if (response.data.success) {
                if (value) {
                  store.set("app.darkMode", value ? 1 : 0);
                } else {
                  store.remove("app.darkMode");
                }
              } else {
                dispatch(setSetting({ dark_mode: value ? 0 : 1 }));
              }
            },
            onError: (err) => {
              dispatch(setSetting({ dark_mode: value ? 0 : 1 }));
            },
          }
        );
      }
    },
    [dispatch, settings, editSettingThemeColors]
  );

  return (
    <>
      <div className="flex items-center mr-5">
        <Switch
          className={`bg-white ${css`
            .ant-switch-handle::before {
              background-color: var(--primary-color) !important;
            }
            .ant-switch-inner-checked {
              margin-top: 2px !important;
            }
            .ant-switch-inner-unchecked {
              margin-top: -24px !important;
            }
          `}`}
          checkedChildren={<MdDarkMode className="text-black text-lg" />}
          unCheckedChildren={
            <MdLightMode className="text-yellow-400 text-lg" />
          }
          checked={settings?.dark_mode === 1 ? true : false}
          onChange={changeThemeHandler}
        />
      </div>
    </>
  );
};

export default ThemeColors;
