const { Spin } = require("antd");
const { Suspense } = require("react");

const CustomSuspense = ({ children }) => (
  <Suspense
    fallback={
      <div className="w-full h-full flex items-center justify-center">
        <Spin />
      </div>
    }
  >
    {children}
  </Suspense>
);

export default CustomSuspense;
