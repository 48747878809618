import axios from "utils/axios";
import { stringify } from "query-string";

export const getSalarySlips = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/salarySlips?${query}`);
};

export const getSalarySlipsPagination = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/salarySlips/pagination?${query}`);
};

export const getSalarySlip = (id) => {
  return axios.get(`/hr/salarySlips/${id}`);
};

export const addSalarySlip = (data) => {
  return axios.post("/hr/salarySlips", data);
};

export const editSalarySlip = (data) => {
  return axios.put(`/hr/salarySlips/${data.id}`, data);
};

export const moveSalarySlip = (data) => {
  return axios.patch(`/hr/salarySlips/${data?.id}/move`, data);
};

export const deleteSalarySlip = (id) => {
  return axios.delete(`/hr/salarySlips/${id}`);
};

export const archiveSalarySlip = (id) => {
  return axios.patch(`/hr/salarySlips/${id}/archive`);
};

export const unarchiveSalarySlip = (id) => {
  return axios.patch(`/hr/salarySlips/${id}/unarchive`);
};
