import { memo } from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import { UnorderedListOutlined } from "@ant-design/icons";

const Filter = ({ id, title, icon, type, onClick, ...props }) => {
  const btnTitle = title ? title : '';
  return (
    <Button
      id={id}
      type={type}
      icon={icon}
      onClick={onClick}
      {...props}
    >
      {btnTitle}
    </Button>
  );
};

Filter.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

Filter.defaultProps = {
  type: "default",
  icon: <UnorderedListOutlined />,
  onClick: () => {},
  id: "btn-show-column",
};

export default memo(Filter);
