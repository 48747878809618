import { useQuery } from "react-query";
import { message } from "antd";

import * as api from "apis/queue";

export function useQueues(options = {}) {
  return useQuery(
    ["queues"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getQueues();
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(error.message || "Failed to load data from server!"),
      ...options,
    }
  );
}
